import { Box, BoxProps } from './box';

export type FlexProps = BoxProps;

/**
 * @deprecated Use Box instead
 */
export const Flex = (props: FlexProps) => (
  <Box flexDirection="row" {...props} />
);
