import { ThemeProvider } from '@shopify/restyle';
import React from 'react';
import { restyleTheme } from './theme';

export interface RestyleThemeProviderProps {
  children: React.ReactNode;
}

export const RestyleThemeProvider = ({
  children,
}: RestyleThemeProviderProps) => (
  <ThemeProvider theme={restyleTheme}>{children}</ThemeProvider>
);
