import classNames from 'classnames';
import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';

export interface IconCircleProps {
  colorClassName: string;
  icon: JSX.Element;
  externalTo?: string;
  size?: 'sm' | 'lg' | 'xs';
}

export const IconCircle: React.FC<IconCircleProps> = ({
  colorClassName,
  icon,
  externalTo,
  size = 'lg',
}) => {
  const element = (
    <div
      className={classNames(
        {
          'h-20 w-20': size === 'lg',
          'h-7 w-7': size === 'sm',
          'h-5 w-5': size === 'xs',
        },
        'flex items-center justify-center rounded-full',
        colorClassName,
      )}
    >
      {React.cloneElement(icon, {
        scale: size === 'lg' ? 2.5 : size === 'sm' ? 1 : 0.5,
        fill: colors.white,
      })}
    </div>
  );
  return externalTo ? <a href={externalTo}>{element}</a> : element;
};
