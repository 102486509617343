import { CacheExchangeOpts, ResolverConfig } from '@urql/exchange-graphcache';
import { TypedDocumentNode } from 'urql';
import { NO_ID_TYPES } from 'xo/graphql/constants/no-id-types.generated';
import schema from './codegen/introspection.json';

const localResolvers: ResolverConfig = {
  Query: {
    readFragment: (
      _parent,
      args: { fragmentDoc: TypedDocumentNode<any>; id: string },
      cache,
    ) => {
      const { fragmentDoc, id } = args;
      return cache.readFragment(fragmentDoc, { id });
    },
  },
};

export const sharedGraphCacheConfig: CacheExchangeOpts = {
  schema,
  resolvers: localResolvers,
  keys: {
    /*
    Prevents warnings like, since these objects don't have their own ID:
    Invalid key: The GraphQL query at the field at `CurrentUser:09f62552-3f6b-5b4c-a386-1db9cf9bb4f6.preferences.transportNotifications.0` has a selection set, but no key could be generated for the data at this field.
    You have to request `id` or `_id` fields for all selection sets or create a custom `keys` config for `TransportSiteNotificationPreferences`.
    Entities without keys will be embedded directly on the parent entity. If this is intentional, create a `keys` config for `TransportSiteNotificationPreferences` that always returns null.

    Can also specify a key function that returns a customised ID
  */
    ...Object.fromEntries(NO_ID_TYPES.map(name => [name, () => null])),
  },
};
