import React, { useCallback } from 'react';
import { useAuthSession } from 'xo/auth-utils';

export interface SessionContextProps {
  session?: string;
  onResetSession: () => void;
}

export const SessionContext = React.createContext<SessionContextProps>(
  {} as any,
);

export const SessionProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { session, loaded, setSession, setLoaded } = useAuthSession();

  const onResetSession = useCallback(() => {
    setSession(undefined);
    setLoaded(false);
  }, [setSession, setLoaded]);

  if (!loaded) {
    return null;
  }

  return (
    <SessionContext.Provider value={{ session, onResetSession }}>
      {children}
    </SessionContext.Provider>
  );
};
