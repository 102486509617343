import { LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useTimedFlag } from 'xo/hooks/component-hooks';
import { getWindowUrl } from '../../utils/url-utils';

export const useCopy = (text: string) => {
  const [copied, setCopied] = useTimedFlag(2000);

  const onCopy = useCallback(() => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(text);

      setCopied();
    }
  }, [text, setCopied]);

  return {
    copied,
    onCopy,
    canCopy: !!navigator?.clipboard?.writeText,
  };
};

export const useCopyLink = (location: LocationDescriptor) => {
  const url = getWindowUrl(location);
  return {
    url,
    ...useCopy(url),
  };
};
