import { cloneElement, useCallback, useState } from 'react';
import {
  ActivityIndicator,
  LayoutChangeEvent,
  LayoutRectangle,
  Pressable,
  PressableStateCallbackType,
} from 'react-native';
import { Box, BoxProps, Text, TextProps } from 'xo/core';
import { Color, FontSize, FontWeight, Spacing } from 'xo/styles/restyle/theme';
import { hexFromThemeColor } from 'xo/utils/theme-utils';

export type ButtonVariant =
  // old branding variants
  | 'outline'
  | 'outline-warning'
  | 'solid'
  | 'subtle'
  | 'ghost'
  | 'white'
  | 'offline'
  // new branding variants
  | 'brand'
  | 'brand-grey'
  | 'brand-ghost';

type ButtonVariantStyle = {
  bg: Color;
  color: Color;
  borderColor: Color;
  iconColor?: Color;
  fontWeight?: FontWeight;
};

const variantMap: Record<
  ButtonVariant,
  {
    base: ButtonVariantStyle;
    pressed: Partial<ButtonVariantStyle>;
    hovered: Partial<ButtonVariantStyle>;
    disabled: Partial<ButtonVariantStyle>;
  }
> = {
  outline: {
    base: { bg: 'white', borderColor: 'blue.600', color: 'blue.600' },
    hovered: { bg: 'blue.100', borderColor: 'blue.200', color: 'blue.400' },
    pressed: { bg: 'grey.50', borderColor: 'blue.700', color: 'blue.700' },
    disabled: { bg: 'grey.50', borderColor: 'grey.400', color: 'grey.500' },
  },
  'outline-warning': {
    base: { bg: 'white', borderColor: 'red.800', color: 'red.800' },
    hovered: { bg: 'grey.50', borderColor: 'red.800', color: 'red.800' },
    pressed: { bg: 'red.100', borderColor: 'red.800', color: 'red.800' },
    disabled: { bg: 'grey.50', borderColor: 'grey.400', color: 'grey.500' },
  },
  solid: {
    base: { bg: 'blue.600', borderColor: 'blue.600', color: 'white' },
    hovered: { bg: 'blue.400', borderColor: 'blue.400', color: 'white' },
    pressed: { bg: 'blue.700', borderColor: 'blue.700' },
    disabled: { bg: 'grey.400', borderColor: 'grey.400' },
  },
  subtle: {
    base: { bg: 'blue.400', borderColor: 'blue.400', color: 'white' },
    hovered: { bg: 'blue.300', borderColor: 'blue.300', color: 'white' },
    pressed: { bg: 'blue.600', borderColor: 'blue.600' },
    disabled: { bg: 'grey.400', borderColor: 'grey.400' },
  },
  ghost: {
    base: {
      bg: 'transparent',
      borderColor: 'transparent',
      color: 'blue.600',
      iconColor: 'blue.400',
      fontWeight: '700',
    },
    hovered: { color: 'blue.500' },
    pressed: { color: 'blue.700' },
    disabled: { color: 'grey.500' },
  },
  white: {
    base: { bg: 'white', borderColor: 'white', color: 'blue.600' },
    hovered: { bg: 'grey.50', borderColor: 'grey.50', color: 'blue.400' },
    pressed: { bg: 'grey.100', borderColor: 'grey.100', color: 'blue.700' },
    disabled: { bg: 'grey.50', borderColor: 'grey.50', color: 'grey.500' },
  },
  offline: {
    base: { bg: 'blue.50', borderColor: 'blue.400', color: 'blue.600' },
    hovered: { bg: 'blue.100', borderColor: 'blue.600', color: 'blue.700' },
    pressed: { bg: 'blue.200', borderColor: 'blue.700', color: 'blue.700' },
    disabled: { bg: 'grey.400', borderColor: 'grey.400', color: 'grey.50' },
  },
  brand: {
    base: {
      bg: 'brandOrange.500',
      borderColor: 'brandOrange.500',
      color: 'white',
    },
    hovered: { bg: 'orange.200', borderColor: 'orange.200', color: 'white' },
    pressed: { bg: 'orange.600', borderColor: 'orange.600', color: 'grey.50' },
    disabled: {
      bg: 'brandOrange.400',
      borderColor: 'brandOrange.400',
      color: 'white',
    },
  },
  'brand-grey': {
    base: { bg: 'brandGrey.600', borderColor: 'brandGrey.600', color: 'white' },
    hovered: {
      bg: 'brandGrey.400',
      borderColor: 'brandGrey.400',
      color: 'white',
    },
    pressed: { bg: 'grey.800', borderColor: 'grey.800', color: 'grey.50' },
    disabled: {
      bg: 'grey.500',
      borderColor: 'grey.500',
      color: 'grey.50',
    },
  },
  'brand-ghost': {
    base: {
      bg: 'transparent',
      borderColor: 'transparent',
      color: 'brandGrey.600',
      iconColor: 'brandGrey.600',
      fontWeight: '700',
    },
    hovered: { color: 'brandGrey.400' },
    pressed: { color: 'grey.700' },
    disabled: { color: 'grey.400' },
  },
};

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const sizeMap: Record<
  ButtonSize,
  { fontSize: FontSize; px: Spacing; py: Spacing; pxIcon: Spacing }
> = {
  xs: {
    fontSize: 'xs',
    px: '1',
    py: '0.5',
    pxIcon: '0.5',
  },
  sm: {
    fontSize: 'sm',
    px: '2',
    py: '1',
    pxIcon: '1',
  },
  md: {
    fontSize: 'md',
    px: '3',
    py: '1.5',
    pxIcon: '1.5',
  },
  lg: {
    fontSize: 'lg',
    px: '4',
    py: '2',
    pxIcon: '2',
  },
  xl: {
    fontSize: 'xl',
    px: '5',
    py: '3',
    pxIcon: '3',
  },
};

const roundedVariants: ButtonVariant[] = ['brand', 'brand-grey'];
const rounded = new Set(roundedVariants);

export type ButtonIconProps = { icon: JSX.Element; fill: string } & BoxProps;

const ButtonIcon = ({ icon, fill, ...rest }: ButtonIconProps) => (
  <Box {...rest}>{cloneElement(icon, { fill })}</Box>
);

export interface ButtonProps extends Omit<BoxProps, 'children'> {
  variant?: ButtonVariant;
  onPress?: () => void;
  disabled?: boolean;
  loading?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  size?: ButtonSize;
  children?: string;
  leftIconProps?: Partial<ButtonIconProps>;
  rightIconProps?: Partial<ButtonIconProps>;
  // FIXME LocationDescriptor
  href?: string;
  textTransform?: TextProps['textTransform'];
}

// FIXME Need a different mechanism to render links on web, since the href on the Box doesn't get intercepted by MemoryRouter in tests
export const Button = ({
  variant = 'outline',
  onPress,
  disabled,
  loading,
  leftIcon,
  rightIcon,
  children,
  size = 'lg',
  leftIconProps,
  rightIconProps,
  href,
  testID,
  textTransform,
  ...rest
}: ButtonProps) => {
  const [hovered, onHover] = useState(false);
  const onHoverIn = useCallback(() => onHover(true), [onHover]);
  const onHoverOut = useCallback(() => onHover(false), [onHover]);

  const sizeStyle = sizeMap[size];

  const [layout, setLayout] = useState<LayoutRectangle>();
  const onLayout = useCallback(
    (e: LayoutChangeEvent) => setLayout(e.nativeEvent.layout),
    [setLayout],
  );

  const render = useCallback(
    ({ pressed }: PressableStateCallbackType) => {
      const style = variantMap[variant];
      const base = style.base;
      let applyStyle: Partial<ButtonVariantStyle> = base;
      if (disabled || loading) {
        applyStyle = style.disabled;
      } else if (pressed) {
        applyStyle = style.pressed;
      } else if (hovered) {
        applyStyle = style.hovered;
      }

      const { bg, color, borderColor, iconColor, fontWeight } = applyStyle;
      const textColor: Color = color ?? base.color;
      const fill = hexFromThemeColor(iconColor ?? textColor);

      return (
        <Box
          direction="row"
          align="center"
          justify="center"
          borderWidth="2"
          borderStyle="solid"
          borderColor={borderColor ?? base.borderColor}
          bg={bg ?? base.bg}
          px={sizeStyle.px}
          py={sizeStyle.py}
          onLayout={onLayout}
          href={href}
          rounded={rounded.has(variant) ? 'full' : undefined}
          className={loading || disabled ? 'cursor-auto' : 'cursor-pointer'}
          {...rest}
        >
          <Box
            opacity={loading ? 0 : 100}
            direction="row"
            align="center"
            justify="center"
          >
            {leftIcon && (
              <ButtonIcon
                mr={children ? sizeStyle.pxIcon : undefined}
                fill={fill}
                icon={leftIcon}
                {...leftIconProps}
              />
            )}
            {children && (
              <Text
                fontWeight={fontWeight ?? '600'}
                color={textColor}
                fontSize={sizeStyle.fontSize}
                textAlign="center"
                textTransform={textTransform}
              >
                {children}
              </Text>
            )}
            {rightIcon && (
              <ButtonIcon
                ml={children ? sizeStyle.pxIcon : undefined}
                fill={fill}
                icon={rightIcon}
                {...rightIconProps}
              />
            )}
          </Box>
          {loading && (
            <Box
              position="absolute"
              left={0}
              right={0}
              bottom={0}
              style={{
                top: layout?.height ? layout.height / 2 - 12 : 0,
              }}
            >
              <ActivityIndicator color={fill} />
            </Box>
          )}
        </Box>
      );
    },
    [
      variant,
      disabled,
      loading,
      leftIcon,
      rightIcon,
      sizeStyle,
      layout,
      children,
      hovered,
      onLayout,
      rest,
      leftIconProps,
      rightIconProps,
      href,
      textTransform,
    ],
  );

  return (
    <Pressable
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
      // on web, these render as links so already have the correct role
      accessibilityRole={href ? undefined : 'button'}
    >
      {render}
    </Pressable>
  );
};
