import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgNotFound } from 'xo/svg/svg-not-found';
import { SvgWarning } from 'xo/svg/svg-warning';
import { ErrorResponseType } from '../../api-models';
import styles from './error-page.module.css';

const notFoundIcon = (
  <span className={styles.svg}>
    <SvgNotFound width={137} height={144} />
  </span>
);

export const errorPageTypes: Partial<
  Record<ErrorResponseType, ErrorPageProps>
> = {
  LINK_EXPIRED_PAGE: {
    icon: notFoundIcon,
    title: 'Link expired',
    message:
      'Sorry, that link has expired. Please look for a more recent link.',
  },
  NOT_FOUND_PAGE: {
    icon: notFoundIcon,
    title: 'Page not found',
    message: "Sorry, we can't find the page you were looking for.",
    action: (
      <Link to="/" className="mb-8 text-blue-600 underline">
        Go to home page
      </Link>
    ),
  },
};

export interface ErrorPageProps {
  icon?: JSX.Element;
  title?: string;
  message?: string;
  action?: JSX.Element;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  icon,
  title = 'An error occurred',
  message = "Sorry, something went wrong. We'll look into it.",
  action,
}) => (
  <div className="fixed z-50 h-screen w-screen bg-white">
    <div className="flex h-screen flex-col items-center justify-center">
      <div className={styles.background} />
      {icon ?? (
        <span className="-mt-16 h-32">
          <SvgWarning fill={colors.white} scale={3} />
        </span>
      )}
      <h1 className="text-3xl text-blue-600">{title}</h1>
      <p className="px-16 text-center text-lg">{message}</p>
      {action}
    </div>
  </div>
);

export const NotFoundPage: React.FC<{ autoAssuming?: boolean }> = ({
  autoAssuming,
}) => (
  <ErrorPage
    {...errorPageTypes.NOT_FOUND_PAGE}
    message={
      autoAssuming
        ? 'Hold on while we auto-assume'
        : errorPageTypes.NOT_FOUND_PAGE!.message
    }
    action={
      autoAssuming ? (
        <div className="mt-8">
          <LoadingOutlined spin />
        </div>
      ) : (
        errorPageTypes.NOT_FOUND_PAGE!.action
      )
    }
  />
);
