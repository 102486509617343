import { Tooltip as AntTooltip } from 'antd';
import { TooltipRendererProps } from './tooltip-renderer.props';

// React Native Elements tooltip has issues with positioning when zoomed out a lot (rc7)
const TooltipWeb = ({ overlay, children }: TooltipRendererProps) => (
  <AntTooltip
    trigger={['hover', 'click']}
    overlay={<div className="text-center">{overlay}</div>}
  >
    <button>{children}</button>
  </AntTooltip>
);

export default TooltipWeb;
