import classNames from 'classnames';
import React from 'react';
import { formatPhoneNumberHref } from 'xo/utils/link-utils';

export interface TelLinkProps {
  underline?: boolean;
  phoneNumber: string;
}

export const TelLink: React.FC<TelLinkProps> = ({ phoneNumber, underline }) => (
  <a
    className={classNames('text-blue-600', { underline })}
    href={formatPhoneNumberHref(phoneNumber)}
  >
    {phoneNumber}
  </a>
);
