import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgDepot: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M19.1258 0.152826C15.671 0.152826 1.44001 0.108765 1.44001 0.108765V15.9929C1.44001 15.9929 1.09782 19.68 5.06905 19.68H22.8V3.70229C22.8 3.70229 22.5806 0.152925 19.1258 0.152925V0.152826ZM6.76105 17.8473C5.74573 17.8473 4.9226 17.0242 4.9226 16.0089C4.9226 14.9935 5.74573 14.1704 6.76105 14.1704C7.77637 14.1704 8.5995 14.9935 8.5995 16.0089C8.59856 17.0251 7.77637 17.8473 6.76105 17.8473V17.8473ZM10.572 17.7601H9.87356C9.87356 17.7601 9.27824 17.7601 9.27824 17.2801H14.276C14.276 17.2801 14.8713 17.2801 14.8713 17.7601H10.572ZM10.572 16.3201H9.87356C9.87356 16.3201 9.27824 16.3201 9.27824 15.8401H14.276C14.276 15.8401 14.8713 15.8401 14.8713 16.3201H10.572ZM10.572 15.1201H9.87356C9.87356 15.1201 9.27824 15.1201 9.27824 14.4001H14.276C14.276 14.4001 14.8713 14.4629 14.8713 15.0583L10.572 15.1201ZM17.3465 17.8472C16.3312 17.8472 15.509 17.0241 15.509 16.0088C15.509 14.9935 16.3321 14.1703 17.3465 14.1703C18.3618 14.1703 19.1849 14.9935 19.1849 16.0088C19.1849 17.0251 18.3618 17.8472 17.3465 17.8472ZM21.12 12.4801H6.80521C3.25969 12.4801 3.12001 8.93916 3.12001 8.93916V1.9098C3.12001 1.9098 13.9313 1.89292 17.4055 1.89292C20.8807 1.89292 21.12 5.41973 21.12 5.41973V12.4801Z"
        fill={fill}
      />
      <Path
        d="M3.24842 20.4C3.24842 20.4 3.32717 22.3922 3.24842 22.6547C3.24748 22.6594 3.24561 22.636 3.24561 22.6407C3.28404 22.8507 3.47811 23.5003 4.40061 23.6944C4.57123 23.73 4.76529 23.76 4.98935 23.76H5.0381C5.05404 23.76 5.07091 23.7375 5.08685 23.7375C5.30997 23.7375 5.50404 23.7038 5.67372 23.6681C6.59811 23.4741 6.73121 22.8291 6.76872 22.6191C6.7781 22.5769 6.71997 22.545 6.71997 22.545V20.4H6.82685L3.24842 20.4Z"
        fill={fill}
      />
      <Path
        d="M17.2264 20.4C17.2264 20.4 17.3052 22.3922 17.2264 22.6547C17.2255 22.6594 17.2246 22.636 17.2236 22.6407C17.2621 22.8507 17.4561 23.5003 18.3796 23.6944C18.5493 23.73 18.7433 23.76 18.9674 23.76H19.0161C19.0321 23.76 19.0489 23.7375 19.0649 23.7375C19.2889 23.7375 19.4821 23.7038 19.6527 23.6681C20.5771 23.4741 20.8002 22.8291 20.8386 22.6191C20.8471 22.5769 20.8799 22.545 20.8799 22.545V20.4L17.2264 20.4Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
