import { isBoolean, isString } from 'lodash';
import type { Path } from 'react-hook-form';
import { formatNameList } from '../utils';
import { TableColumnProps } from './table';

export type SearchColumnProps<T> = Pick<
  TableColumnProps<T>,
  'search' | 'title' | 'dataIndex'
>;

export const getTableSearchPaths = <T extends object>(
  columns: SearchColumnProps<T>[],
) => {
  const searchColumns = columns.filter(c => c.search);
  const searchPaths: Path<T>[] = searchColumns
    .flatMap(c => {
      if (isBoolean(c.search)) {
        if (!c.dataIndex) {
          console.warn(
            `column ${c.title} is ignored in search because it has no dataIndex but doesn't specify the field value`,
          );
          return [];
        }

        return [
          Array.isArray(c.dataIndex) ? c.dataIndex.join('.') : c.dataIndex,
        ] as Path<T>[];
      }

      return Array.isArray(c.search) ? c.search : [c.search];
    })
    .filter(Boolean)
    .map(p => p!);

  return searchPaths;
};

export const getSearchTooltip = <T>(
  columns: Pick<TableColumnProps<T>, 'search' | 'title' | 'searchTitle'>[],
) => {
  const searchColumns = columns.filter(c => c.search);
  if (!searchColumns.length) {
    return;
  }
  const searchColumnTitles = searchColumns
    .map(c => {
      const columnTitle =
        c.searchTitle ??
        (isString(c.title) ? c.title?.toLowerCase() : undefined);
      if (!columnTitle) {
        console.warn(`searching column with no title or searchTitle`);
      }
      return columnTitle!;
    })
    .filter(Boolean);

  return `Search by ${formatNameList(searchColumnTitles, 'or')}`;
};
