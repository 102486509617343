import classNames from 'classnames';
import React from 'react';

export interface PanelProps {
  className?: string;
  backgroundClassName?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Panel: React.FC<PanelProps> = ({
  className,
  backgroundClassName,
  children,
  ...rest
}) => (
  <div
    className={classNames(className, backgroundClassName, 'rounded px-2 py-4', {
      'border border-grey-300': !backgroundClassName,
    })}
    {...rest}
  >
    {children}
  </div>
);
