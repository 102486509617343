import { usePrevious } from '@mantine/hooks';
import { clamp } from 'lodash';
import { MotiView } from 'moti';
import React, { useEffect, useState } from 'react';
import { Easing } from 'react-native-reanimated';
import { Box } from 'xo/core';
import { borderRadii } from 'xo/styles/restyle/border-radii';
import { colors } from 'xo/styles/restyle/colors';
import { spacing } from 'xo/styles/restyle/spacing';
import { Color, Spacing } from 'xo/styles/restyle/theme';

export interface ProgressBarProps {
  current: number;
  total?: number;
  size?: 'sm' | 'lg';
  barBg?: Color;
  highlightBg?: Color;
}

const TRANSITION_SECS = 0.25;

export const ProgressBar: React.FC<ProgressBarProps> = ({
  current,
  total = 100,
  size = 'lg',
  barBg = 'blue.400',
  highlightBg = 'blue.200',
}) => {
  current = clamp(current, 0, total);

  const prevCurrent = usePrevious(current);
  const [changing, setChanging] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    if (prevCurrent && prevCurrent !== current) {
      setChanging(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(
        () => {
          setChanging(false);
          timeoutId = undefined;
        },
        (TRANSITION_SECS / 1.5) * 1000,
      );
    }
  }, [setChanging, current, prevCurrent]);

  const height: Spacing = size === 'sm' ? '2' : '4';

  return (
    <Box w="full">
      <Box
        align="center"
        position="relative"
        bg="grey.200"
        rounded="lg"
        w="full"
        height={height}
        shadow="2"
      />
      <MotiView
        style={{
          flexDirection: 'row',
          borderRadius: borderRadii.lg,
          top: 0,
          left: 0,
          height: spacing[height],
          backgroundColor: colors[barBg],
          transform: [{ translateY: -spacing[height] }],
        }}
        animate={{
          width: `${Math.round((current / total) * 100)}%`,
          backgroundColor: changing ? colors[highlightBg] : colors[barBg],
        }}
        transition={{
          easing: Easing.linear,
          duration: TRANSITION_SECS * 1000,
        }}
      />
    </Box>
  );
};
