import { spacing } from 'xo/styles/restyle/spacing';
import { Box, BoxProps } from './box';

export type VStackProps = BoxProps & {
  space?: keyof typeof spacing;
};

export const VStack = ({ children, space = '4', ...rest }: VStackProps) => (
  <Box
    direction="column"
    align="center"
    justify="space-between"
    gap={space}
    {...rest}
  >
    {children}
  </Box>
);
