import { Form } from 'antd';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HStack, VStack } from 'xo/core';
import { padding, screens } from 'xo/styles/tailwind-theme';
import { toId } from 'xo/utils/string-utils';
import { Button, ButtonLine } from '../components/button';
import { DateTimePicker } from './date-time-picker';
import './separate-date-time-pickers.overrides.css';

const combineDateAndTime = (date: Dayjs, time: Dayjs) =>
  `${date.format('YYYY-MM-DD')}T${time.format('HH:mm:ss')}`;

export interface SeparateDateTimePickersProps {
  id?: string;
  timeLabel?: string;
  dateLabel?: string;
  onChange?: (value: Dayjs | null, dateString: string) => void;
  value?: Dayjs;
  required?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
  timeButtons?: SeparateDateTimePickerTimeButton[];
  dateFooter?: JSX.Element;
  dateDisabled?: boolean;
  horizontal?: boolean;
}

export interface SeparateDateTimePickerTimeButton {
  label: string;
  onClick: () => void;
}

export const SeparateDateTimePickers: React.FC<
  SeparateDateTimePickersProps
> = ({
  id,
  timeLabel = 'Time',
  dateLabel = 'Date',
  onChange,
  value,
  disabled,
  required,
  minDate,
  maxDate,
  timeButtons,
  dateFooter,
  dateDisabled,
  horizontal,
}) => {
  const [time, setTime] = useState(value);
  const [date, setDate] = useState(value);

  useEffect(() => {
    setTime(value);
    setDate(value);
  }, [value, setTime, setDate]);

  const onTimeChange = useCallback(
    (value?: Dayjs) => {
      if (value && onChange) {
        const combinedString = combineDateAndTime(date || dayjs(), value);
        onChange(dayjs(combinedString), combinedString);
      }
    },
    [onChange, date],
  );

  const onDateChange = useCallback(
    (value?: Dayjs) => {
      if (value && onChange) {
        const combinedString = combineDateAndTime(value, time || dayjs());
        onChange(dayjs(combinedString), combinedString);
      }
    },
    [onChange, time],
  );

  const isSmScreen = useMediaQuery({ minWidth: screens.sm });

  const renderedTimeButtons = timeButtons ? (
    <>
      {timeButtons.map(({ label, onClick }) => (
        <ButtonLine key={label}>
          <Button
            id={`${label.toLowerCase()}-btn`}
            onClick={onClick}
            style={{
              paddingLeft: padding[isSmScreen ? 2 : 0.5],
              paddingRight: padding[isSmScreen ? 2 : 0.5],
            }}
          >
            {label}
          </Button>
        </ButtonLine>
      ))}
    </>
  ) : undefined;

  const pickers = [
    {
      picker: 'date',
      label: dateLabel,
      onChange: onDateChange,
      value: date,
      disabled: disabled || dateDisabled,
    },
    {
      picker: 'time',
      label: timeLabel,
      onChange: onTimeChange,
      value: time,
      disabled,
    },
  ].map(({ picker, label, value, onChange, disabled }) => (
    <div
      key={picker}
      className={classNames('mx-1 w-full', {
        'mt-4': picker === 'time' && !horizontal,
      })}
    >
      <Form.Item
        label={label}
        colon={false}
        required={!disabled ? required : undefined}
        className="xo-ant-sepdt relative flex flex-col"
      >
        <DateTimePicker
          id={toId(label)}
          picker={picker as 'date' | 'time'}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={onChange}
          icon={isSmScreen}
          extraRight={picker === 'time' ? renderedTimeButtons : undefined}
        />
      </Form.Item>
      {picker === 'date' && dateFooter ? (
        <div className="mt-1">{dateFooter}</div>
      ) : undefined}
    </div>
  ));

  return horizontal ? (
    <HStack id={id} align="flex-start">
      {pickers}
    </HStack>
  ) : (
    <VStack id={id} space="0">
      {pickers}
    </VStack>
  );
};
