import { ApiSiteActiveStatus } from 'xo/graphql/api/enums/site-active-status.generated';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';

import gql from 'graphql-tag';
import {
  ApiOrganisationBriefSummaryFragment,
  OrganisationBriefSummaryFragmentDoc,
} from './organisation-brief-summary-fragment.generated';
import {
  ApiSiteBriefSummaryFragment,
  SiteBriefSummaryFragmentDoc,
} from './site-brief-summary-fragment.generated';
export type ApiSiteSummaryFragment = {
  __typename?: 'Site';
  kind: ApiSiteKind;
  timezone: string;
  features: Array<string>;
  activeStatus: ApiSiteActiveStatus;
  organisation: {
    __typename?: 'Organisation';
  } & ApiOrganisationBriefSummaryFragment;
} & ApiSiteBriefSummaryFragment;

export const SiteSummaryFragmentDoc = gql`
  fragment SiteSummary on Site {
    __typename
    ...SiteBriefSummary
    organisation {
      ...OrganisationBriefSummary
    }
    kind
    timezone
    features
    activeStatus
  }
  ${SiteBriefSummaryFragmentDoc}
  ${OrganisationBriefSummaryFragmentDoc}
`;
