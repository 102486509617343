import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { sortBy } from 'lodash';
import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { appRoutes } from 'xo/navigation/web-routes';
import { FormKind, VisitState } from '../../api-models';
import { useFormSubmit } from '../../hooks/shared-hooks';
import { useForceVisit, useGetVisit } from '../../hooks/visit-network-hooks';
import { FormSummaryModel } from '../../models/visitor-log-models';
import { Button } from '../components/button';
import { ButtonGroup } from '../components/button-group';
import { Checkbox } from '../components/checkbox';
import { DeleteButton } from '../components/delete-button';
import { Header } from '../components/header';
import { Panel } from '../components/panel';
import { PanelMessage } from '../components/panel-message';
import { Option, Select } from '../components/select';
import { SkeletonLoader } from '../components/skeleton-loader';
import { Form, FormItem } from '../forms/form';
import { SeparateDateTimePickers } from '../forms/separate-date-time-pickers';
import { notify } from '../notifications';
import { AdminCheckInAppLink } from './admin-check-in-app-link';
import { AdminIdsTable } from './admin-ids-table';
import { nowAfterSoftDeleteCutOff } from './admin-utils';

export const AdminVisit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();

  const { isLoading: visitLoading, data: visit } = useGetVisit(id);

  const { mutate: forceVisit } = useForceVisit();

  const { loading: forceVisitLoading, onSubmit: onSubmitForceVisit } =
    useFormSubmit({
      form,
      onSubmit: (values, options) =>
        forceVisit(
          {
            id: id!,
            state: values.state,
            nextAlert: values.nextAlert === 'now' ? dayjs() : undefined,
            nextAlertIsIncomplete: values.nextAlertIsIncomplete,
            actualStartDatetime: values.actualStartDatetime,
            actualEndDatetime: values.actualEndDatetime,
          },
          options,
        ),
      onSuccess: useCallback(() => {
        notify.info('Visit state forcibly updated');
      }, []),
    });

  const { mutate: forceVisitDelete, isLoading: deleting } = useForceVisit();
  const onDelete = useCallback(() => {
    forceVisitDelete({
      id,
      state: 'DELETED',
      nextAlertIsIncomplete: false,
    });
  }, [forceVisitDelete, id]);

  // This should be kept up to date with the states that are used.
  const states: VisitState[] = [
    'PREVISIT_VISITOR_WAITING',
    'PREVISIT_VISITOR_COMPLETED_HOST_NOTIFIED',
    'EARLY_VISITOR_WAITING',
    'EARLY_VISITOR_NOTIFIED',
    'EARLY_VISITOR_COMPLETED_HOST_NOTIFIED',
    'EARLY_VISITOR_COMPLETED_APPROVED_WITHOUT_REVIEW',
    'EARLY_REJECTED',
    'ONSITE_VISITOR_WAITING',
    'ONSITE_VISITOR_NOTIFIED',
    'ONSITE_VISITOR_COMPLETED_HOST_NOTIFIED',
    'ONSITE_VISITOR_COMPLETED_APPROVED_WITHOUT_REVIEW',
    'ONSITE_REJECTED',
    'SIGN_IN_VISITOR_WAITING',
    'LEAVING_VISITOR_WAITING',
    'INCOMPLETE_VISITOR_WAITING',
    'COMPLETED',
    'CANCELLED',
  ];

  const nextAlertOptions = [
    { label: 'Now', value: 'now' },
    { label: 'Never', value: 'never' },
  ];

  const formatDate = (date?: Dayjs) =>
    !date
      ? ''
      : date.isSame(dayjs(), 'day')
        ? date.format('HH:mm')
        : date.format('YYYY-MM-DD HH:mm');

  const linkForForm = (form: FormSummaryModel) => {
    if (form.kind === 'EARLY_HOST') {
      return appRoutes.review.early(visit?.id);
    } else if (form.kind === 'ONSITE_HOST') {
      return appRoutes.review.onsite(visit?.id);
    }

    const path = form.kind === 'LEAVING' ? 'signout' : form.kind.toLowerCase();
    return `/${path}/${form.id}`;
  };

  const formOrder: FormKind[] = [
    'PREVISIT',
    'PREVISIT_HOST',
    'EARLY',
    'EARLY_HOST',
    'ONSITE',
    'ONSITE_HOST',
    'LEAVING',
  ];

  const orderedForms = sortBy(visit?.forms, f => formOrder.indexOf(f.kind));

  const initialValues = {
    state: visit?.status,
    nextAlertIsIncomplete: false,
    actualStartDatetime: visit?.actualStartDatetime,
    actualEndDatetime: visit?.actualEndDatetime,
  };

  const afterSoftDeleteCutOff = nowAfterSoftDeleteCutOff(visit?.createdAt);

  return (
    <Panel>
      <Header level={2} underline={true} className="mb-4">
        Visit admin
      </Header>

      <DeleteButton
        entity="visit"
        deleted={visit?.status === 'DELETED'}
        deleting={deleting}
        onClick={onDelete}
        otherRestoreInstructions="Deleted. To undelete, force to another state."
        disabled={forceVisitLoading || afterSoftDeleteCutOff}
      />
      {afterSoftDeleteCutOff && (
        <PanelMessage type="warning" className="mt-2">
          This visit cannot be deleted because it is from a previous quarter,
          for which reporting has already been finalised. Contact engineering to
          discuss options, if required.
        </PanelMessage>
      )}

      {visit && (
        <div className="my-4">
          <AdminCheckInAppLink site={visit.site} />
        </div>
      )}

      <SkeletonLoader loading={visitLoading}>
        <Header level={2} className="mb-2 mt-8">
          IDs
        </Header>
        <AdminIdsTable
          entities={[
            { name: 'Visit', id: visit?.id, link: appRoutes.visits.details },
            {
              name: 'Site',
              id: visit?.site.id,
              link: appRoutes.settings.siteDetails,
            },
            { name: 'Visitor', id: visit?.visitor.id },
            {
              name: 'Host',
              id: visit?.host.id,
              link: appRoutes.settings.userDetails,
            },
          ]}
        />
        <Header level={2} className="mb-2 mt-8">
          Forms
        </Header>
        <p>
          These links will only work for the visit host, not for the visitor.
          Check with engineering before you send these links to someone.
        </p>
        {visit && (
          <table className="mt-2 text-xs">
            <thead className="border-b-2 border-blue-600 uppercase text-blue-600">
              <tr>
                <th>ID</th>
                <th>Kind</th>
                <th>Completed at</th>
                <th>Risk assessment</th>
              </tr>
            </thead>
            <tbody>
              {orderedForms.map((form, idx) => (
                <tr
                  className={classNames('pt-2', {
                    'bg-grey-100': idx % 2 === 1,
                  })}
                >
                  <td>
                    <Link
                      to={linkForForm(form)}
                      className="text-blue-600 underline"
                    >
                      <code>{form.id}</code>
                    </Link>
                  </td>
                  <td>{form.kind}</td>
                  <td>{formatDate(form.completedAt)}</td>
                  <td>{form.riskAssessment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Header level={2} className="mb-2 mt-8">
          State History
        </Header>
        {visit && (
          <table className="mt-2 text-xs">
            <thead className="border-b-2 border-blue-600 uppercase text-blue-600">
              <tr>
                <th>State</th>
                <th>Transition time</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {visit.history.map((state, idx) => {
                return (
                  <tr
                    className={classNames('pt-2', {
                      'bg-grey-100': idx % 2 === 1,
                    })}
                  >
                    <td>
                      <code>{state.status}</code>
                    </td>
                    <td>{formatDate(state.date)}</td>
                    <td>{state.reason}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Form form={form} initialValues={initialValues}>
          <Header className="mb-2 mt-8" level={2} underline={true}>
            New values
          </Header>
          <FormItem name="state" label="State" rules={[{ required: true }]}>
            <Select>
              {states.map(state => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name="nextAlertIsIncomplete"
            label="Alert as if the visit is incomplete"
            valuePropName="checked"
          >
            <Checkbox />
          </FormItem>
          <FormItem
            name="nextAlert"
            label="Next Alert"
            rules={[{ required: true }]}
          >
            <ButtonGroup options={nextAlertOptions} />
          </FormItem>
          <FormItem name="actualStartDatetime" rules={[{ required: false }]}>
            <SeparateDateTimePickers
              timeLabel="Sign-in time"
              dateLabel="Sign-in date"
              required={false}
            />
          </FormItem>

          <FormItem name="actualEndDatetime" rules={[{ required: false }]}>
            <SeparateDateTimePickers
              timeLabel="Sign-out time"
              dateLabel="Sign-out date"
              required={false}
            />
          </FormItem>
        </Form>
        <div className="flex items-center justify-between">
          <Button
            onClick={onSubmitForceVisit}
            loading={forceVisitLoading}
            disabled={deleting}
          >
            Force visit
          </Button>
        </div>
      </SkeletonLoader>
    </Panel>
  );
};
