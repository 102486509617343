import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgErrorOutline: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 14.9994H12.9998V16.9994H10.9998V14.9994ZM10.9998 6.99961H12.9998V12.9996H10.9998V6.99961ZM11.9898 2C6.46976 2 1.99976 6.48 1.99976 12C1.99976 17.52 6.46976 22 11.9898 22C17.5198 22 21.9998 17.52 21.9998 12C21.9998 6.48 17.5198 2 11.9898 2ZM12 19.9996C7.58 19.9996 4 16.4196 4 11.9996C4 7.57961 7.58 3.99961 12 3.99961C16.42 3.99961 20 7.57961 20 11.9996C20 16.4196 16.42 19.9996 12 19.9996Z"
        fill={fill}
      ></Path>
    </SvgIcon>
  );
};
