import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
import getClientId from 'xo/client-id';
import { env, STAGE } from '../app/env';
import history from '../hooks/history';

export const SentryRoute = Sentry.withSentryRouting(Route as any);

if (!env.REACT_APP_STORYBOOK) {
  Sentry.init({
    enabled: !!env.REACT_APP_SENTRY_DSN,
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: STAGE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    sendDefaultPii: false,
    release: env.REACT_APP_COMMIT_FRONTEND,
    initialScope: {
      user: {
        clientId: getClientId(),
      },
    },
    replaysOnErrorSampleRate: 0.05,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
}

export const updateSentryUser = (data: Partial<Sentry.User>) =>
  Sentry.configureScope(scope =>
    scope.setUser({ ...scope.getUser(), ...data }),
  );
