// Cannot destructure import.meta.env - Vite needs the whole string specified
// Added to this file to support Jest, so we can mock this file easily. Node doesn't like import.meta usage with CJS
export const env = {
  MODE: import.meta.env.MODE,
  REACT_APP_COMMIT_BACKEND: import.meta.env.REACT_APP_COMMIT_BACKEND,
  REACT_APP_COMMIT_FRONTEND: import.meta.env.REACT_APP_COMMIT_FRONTEND,
  REACT_APP_COGNITO_POOL_ID: import.meta.env.REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_POOL_WEB_CLIENT_ID: import.meta.env
    .REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
  REACT_APP_COGNITO_POOL_MOBILE_CLIENT_ID: import.meta.env
    .REACT_APP_COGNITO_POOL_MOBILE_CLIENT_ID,
  REACT_APP_GOOGLE_API_KEY: import.meta.env.REACT_APP_GOOGLE_API_KEY,
  REACT_APP_MAPBOX_TOKEN: import.meta.env.REACT_APP_MAPBOX_TOKEN,
  REACT_APP_PROXY_STAGE: import.meta.env.REACT_APP_PROXY_STAGE,
  REACT_APP_STAGE: import.meta.env.REACT_APP_STAGE,
  REACT_APP_POSTHOG_TOKEN: import.meta.env.REACT_APP_POSTHOG_TOKEN,
  REACT_APP_SENTRY_DSN: import.meta.env.REACT_APP_SENTRY_DSN,
  REACT_APP_STORYBOOK: import.meta.env.REACT_APP_STORYBOOK,
};

export const STAGE =
  env.REACT_APP_PROXY_STAGE?.toUpperCase() ??
  env.REACT_APP_STAGE?.toUpperCase() ??
  'LOCAL';
