import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxGroupProps as AntCheckboxGroupProps } from 'antd/es/checkbox/Group';
import classNames from 'classnames';
import React from 'react';
import { transitionAll } from '../app-constants';
import { Checkbox, CheckboxProps } from './checkbox';
import './checkbox-group.overrides.css';
import { ElementOptionType } from './component-models';
import { HelpTooltip } from './help-tooltip';

export type CheckboxOptionType = ElementOptionType & {
  icon?: JSX.Element;
  subLabel?: JSX.Element | string;
  disabled?: boolean;
  hidden?: boolean;
  tooltip?: string;
};

export interface CheckboxGroupProps extends AntCheckboxGroupProps {
  id?: string;
  options: CheckboxOptionType[];
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = props => {
  return (
    <div id={props.id} className="xo-ant-checkbox-group w-full">
      <AntCheckbox.Group
        {...props}
        options={undefined}
        className={classNames(props.className, 'w-full')}
      >
        {props.options?.map(option => (
          <div
            className={classNames(
              'relative flex w-full items-center border-b border-solid border-grey-600 p-2',
              {
                hidden: option.hidden,
                'bg-grey-100': option.disabled || props.disabled,
              },
            )}
            key={option.value.toString()}
          >
            <CheckboxGroupCheckbox
              option={option}
              disabled={props.disabled}
              selected={props.value?.includes(option.value)}
            />
          </div>
        ))}
      </AntCheckbox.Group>
    </div>
  );
};

export const CheckboxGroupCheckbox = ({
  option,
  disabled,
  selected,
  ...rest
}: {
  option: CheckboxOptionType;
  disabled?: boolean;
  selected?: boolean;
} & CheckboxProps) => (
  <Checkbox
    value={option.value}
    disabled={option.disabled || disabled}
    checkboxPosition="fullRight"
    {...rest}
  >
    <span
      className={classNames('mr-6 flex items-start', transitionAll, {
        'xo-checkbox-item-disabled text-grey-600': option.disabled || disabled,
      })}
    >
      {option.icon && <span className="mr-4">{option.icon}</span>}
      <span className="flex flex-col items-start">
        <div className="flex w-full items-center">
          <div
            className={classNames({
              'font-bold': selected,
              'text-blue-400': selected && !disabled && !option.disabled,
            })}
          >
            {option.label}
          </div>
          {option.tooltip && (
            <HelpTooltip className="ml-4">{option.tooltip}</HelpTooltip>
          )}
        </div>
        {option.subLabel && (
          <span className="w-full text-sm">{option.subLabel}</span>
        )}
      </span>
    </span>
  </Checkbox>
);
