import dayjs, { Dayjs } from 'dayjs';
import { last } from 'lodash';
import { formatDuration } from 'xo/transport/trip-summary-utils';
import { OptionType } from './hooks/component-hooks';
import logger from './logger';

const dateTimeFormat = Intl.DateTimeFormat && Intl.DateTimeFormat();
const resolvedOptions =
  dateTimeFormat?.resolvedOptions && dateTimeFormat.resolvedOptions();
export const currentTimeZoneName = resolvedOptions?.timeZone;

export const renderDate = (
  date?: Dayjs,
  opts?: { shortYear?: boolean; fallback?: string },
) =>
  date?.format(`D/MM/${opts?.shortYear ? 'YY' : 'YYYY'}`) ??
  opts?.fallback ??
  'N/A';
export const renderLongDate = (date?: Dayjs) =>
  date?.format('dddd, D MMM YYYY');
export const renderTime = (date?: Dayjs) => date?.format('h:mma') ?? 'N/A';
export const renderDateTime = (date?: Dayjs) =>
  date?.format('DD/MM/YYYY h:mma') ?? 'N/A';
export const renderDateTimeWithUser = (
  date?: Dayjs,
  userDisplayName?: string,
) => {
  return (
    date
      ? [date.format('DD/MM/YYYY'), 'at', date.format('h:mma')]
      : ['Unknown time']
  )
    .concat(userDisplayName ? ['by', userDisplayName] : [])
    .join(' ');
};
export const renderTimeOrDateTime = (
  date: Dayjs | undefined,
  comparisonDate: Dayjs,
) =>
  date?.isSame(comparisonDate, 'day') ? renderTime(date) : renderDateTime(date);
export const renderStartToEndTime = (start: Dayjs, end: Dayjs) =>
  `${renderTime(start)} - ${renderTime(end)}`;
export const renderStartToEndDate = (
  start: Dayjs,
  end: Dayjs,
  opts?: { shortYear?: boolean },
) =>
  start.isSame(end, 'day')
    ? renderDate(start, opts)
    : `${renderDate(start, opts)} - ${renderDate(end, opts)}`;

// The last seen column only has data from the 1.22 release so lastSeen: null for a user older isn't a guarantee of never-seeing
const LAST_SEEN_RELIABLE_FROM = dayjs(`2022-02-01T00:00+11:00`);
export const renderUserLastSeen = (lastSeen?: Dayjs, createdAt?: Dayjs) =>
  lastSeen
    ? renderDateTime(lastSeen)
    : createdAt?.isAfter(LAST_SEEN_RELIABLE_FROM)
      ? 'Never'
      : 'UNKNOWN';

export const isAfterOrSame = (date: Dayjs, other: Dayjs) =>
  date.isSame(other, 'minute') || date.isAfter(other, 'minute');

export const formatIsoDate = (date: Dayjs) => date.format('YYYY-MM-DD');
export const formatLocalDateTime = (date: Dayjs) =>
  date.format('YYYY-MM-DDTHH:mm:ss');
export const formatMaybeLocalDateTime = (date?: Dayjs) =>
  date ? formatLocalDateTime(date) : undefined;
export const formatShortDate = (date: Dayjs) => date.format('D/MM/YYYY');

export const parseTime = (timeString: string) => dayjs(timeString, 'HH:mm');
export const parseMaybeTime = (timeString: string | undefined) =>
  timeString ? parseTime(timeString) : undefined;
export const parseFormatTime = (timeString: string) =>
  parseTime(timeString).format('h:mma');
export const parseDate = (dateString: string) =>
  dayjs(dateString, 'YYYY-MM-DD');

export const segmentDateRanges = (dates: Dayjs[]): Dayjs[][] =>
  dates.reduce((acc, n) => {
    if (last(last(acc))?.add(1, 'day').isSame(n, 'day')) {
      acc.splice(acc.length - 1, 1, last(acc)!.concat(n));
      return acc;
    }

    return acc.concat([[n]]);
  }, [] as Dayjs[][]);

export const minutesFromMidnight = (date: Dayjs) => {
  const localDate = date.local();
  return localDate.diff(localDate.startOf('day'), 'minute');
};

export enum DatePeriod {
  ThisWeek = 'This week',
  NextWeek = 'Next week',
  LastWeek = 'Last week',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  DateRange = 'Date range',
}

export const datePeriodToRange = (period: DatePeriod): [Dayjs, Dayjs] => {
  const from = dayjs().startOf('day');
  const to = dayjs().endOf('day');

  const config: Record<DatePeriod, () => [Dayjs, Dayjs]> = {
    [DatePeriod.ThisWeek]: () => [from.day(0), to.day(6)],
    [DatePeriod.NextWeek]: () => [from.day(7), to.day(13)],
    [DatePeriod.LastWeek]: () => [from.day(-7), to.day(-1)],
    [DatePeriod.Today]: () => [from, to],
    [DatePeriod.Tomorrow]: () => [from.add(1, 'days'), to.add(1, 'days')],
    [DatePeriod.Yesterday]: () => [
      from.subtract(1, 'days'),
      to.subtract(1, 'days'),
    ],
    // Configured by user
    [DatePeriod.DateRange]: () => [from, to],
  };

  return config[period]();
};

export const datePeriodList = [
  DatePeriod.ThisWeek,
  DatePeriod.NextWeek,
  DatePeriod.LastWeek,
  DatePeriod.Today,
  DatePeriod.Tomorrow,
  DatePeriod.Yesterday,
  DatePeriod.DateRange,
];

export const formatTime = (dateTime: Dayjs) => dateTime.format('h:mmA');
export const formatTimeAndDate = (dateTime: Dayjs) =>
  dateTime?.format('h:mmA, D/MM/YYYY') ?? '';

// Some FYs don't follow the first-Sunday rule
const financialYearStartSpecialCases = new Map([[2024, dayjs('2024-06-30')]]);

export const financialYearAndWeekToDateRange = ({
  week,
  year,
}: {
  // 1 - 53
  week: number;
  // 2022 for 2022/2023 etc
  year: number;
}) => {
  const firstDayOfFy =
    financialYearStartSpecialCases.get(year) ??
    dayjs(new Date(year, 6, 1))
      .startOf('day')
      // First Sunday after the 1st July
      .weekday(7);
  const from = firstDayOfFy.add(week - 1, 'week');

  return {
    from,
    to: from.add(1, 'week').subtract(1, 'day').endOf('day'),
  };
};

// Get the year of the first Sunday of the previous July
export const dateToFinancialYear = (date: Dayjs) => {
  const year = date.year();
  // when does this FY start?
  const { from } = financialYearAndWeekToDateRange({ week: 1, year });

  return date.isBefore(from) ? year - 1 : year;
};

export const unixToDate = (unix: number) => dayjs(unix);

export const combineDateAndTime = (date: Dayjs, time: Dayjs, local?: boolean) =>
  dayjs(
    `${date.format('YYYY-MM-DD')}T${time.format(`HH:mm:ss${local ? '' : 'Z'}`)}`,
  );

export const getTodayYesterdayAppender = (date: Dayjs) => {
  const today = dayjs();
  const extraText = date.isSame(today, 'day')
    ? ' (Today)'
    : date.isSame(today.subtract(1, 'day'), 'day')
      ? ' (Yesterday)'
      : '';

  return extraText;
};

// Tries to work out the current timezone, but falls back if it can't be determined (can happen on some browsers). Consumers can define their own more sensible fallback if desired
export const getCurrentTimezone = (
  defaultTz: string = 'Australia/Sydney',
): string => Intl.DateTimeFormat().resolvedOptions().timeZone ?? defaultTz;

export const defaultPeriod = DatePeriod.Today;
export const defaultPeriodRange = datePeriodToRange(defaultPeriod);

export const formatDateRange = (start: Dayjs, end: Dayjs) => {
  const same = start.isSame(end, 'date');
  return `${same ? 'on' : 'between'} ${formatShortDate(start)}${
    same ? '' : ` and ${formatShortDate(end)}`
  }`;
};

export const formatElapsedTime = (dateTime: Dayjs) => {
  const now = dayjs();
  const duration = formatDuration({
    start: dateTime,
    end: now,
    compact: true,
  });
  return duration.totalMinutes < 0
    ? formatTimeAndDate(dateTime)
    : duration.totalMinutes === 0
      ? 'now'
      : duration.totalMinutes <= 720
        ? duration.desc + ' ago'
        : dateTime.isSame(now, 'day')
          ? formatTime(dateTime)
          : formatShortDate(dateTime);
};

export const createTimeOption = (dateTime: Dayjs) => ({
  key: dateTime.toISOString(),
  label: formatTime(dateTime),
  value: dateTime.toISOString(),
});

export const getTimeOptionsFromRange = ({
  minHour,
  maxHour,
}: {
  minHour: number;
  maxHour: number;
}): OptionType<string>[] => {
  if (minHour < 0 || minHour > 23 || maxHour < 0 || maxHour > 23) {
    logger.error(
      `Invalid hour range provided: minHour: ${minHour}, maxHour: ${maxHour}`,
    );
    return [];
  }

  const hourCount = maxHour - minHour;

  const options = Array.from({ length: hourCount + 1 }, (_, i) => {
    const dateTime = dayjs()
      .hour(minHour + i)
      .startOf('hour');

    return createTimeOption(dateTime);
  });

  return options;
};
