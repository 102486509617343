import { Target, useScrollTo } from '@nandorojo/anchor';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

// Adapted from https://www.npmjs.com/package/@nandorojo/anchor#formik-error-usage
function isObject(value?: object) {
  return value && typeof value === 'object' && value.constructor === Object;
}

function getRecursiveName(object?: Record<string, any>): string {
  if (!object || !isObject(object)) {
    return '';
  }
  const currentKey = Object.keys(object)[0];
  if (!currentKey) {
    return '';
  }
  if (!getRecursiveName(object[currentKey])) {
    return currentKey;
  }
  return currentKey + '.' + getRecursiveName(object[currentKey]);
}

export const ScrollToErrorField = ({
  name,
  error,
}: {
  name: string;
  error?: string;
}) => {
  const {
    formState: { submitCount, errors },
  } = useFormContext();

  const { scrollTo } = useScrollTo();
  const previousSubmitCount = useRef(submitCount);
  const errorPath = getRecursiveName(errors);

  useEffect(
    function scrollOnSubmissionError() {
      if (!error) return;

      if (submitCount > previousSubmitCount.current && name) {
        if (error) {
          scrollTo(name);
        }
      }
      previousSubmitCount.current = submitCount;
    },
    [errorPath, errors, name, scrollTo, submitCount, error],
  );

  return <Target name={name} />;
};
