import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgDelivery: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M15.5522 9.88779L8.97669 7.72779C8.9045 7.70341 8.85669 7.75216 8.85669 7.82341V16.7757C8.85669 16.8235 8.88106 16.8478 8.92888 16.8713L15.5044 19.0313C15.5522 19.0557 15.6244 19.0069 15.6244 18.9357V9.98437C15.6 9.93562 15.5756 9.91216 15.5522 9.88779Z"
        fill={fill}
      />
      <Path
        d="M22.0078 6.88777L16.1522 4.96777H16.1044L10.2489 6.88777C10.1533 6.91215 10.1533 7.03215 10.2489 7.05559L16.1044 8.97559H16.1522L22.0078 7.05559C22.0799 7.05559 22.0799 6.93559 22.0078 6.88777Z"
        fill={fill}
      />
      <Path
        d="M23.28 7.72779L16.7044 9.88779C16.6566 9.91216 16.6323 9.9356 16.6323 9.98341V18.9357C16.6323 19.0078 16.7045 19.0313 16.7523 19.0313L23.3278 16.8713C23.3756 16.8469 23.4 16.8235 23.4 16.7757V7.82437C23.4 7.75219 23.3278 7.70435 23.28 7.72779Z"
        fill={fill}
      />
      <Path
        d="M5.99999 7.7045H1.27223C0.912229 7.7045 0.600037 7.99231 0.600037 8.37669C0.600037 8.73669 0.887845 9.04888 1.27223 9.04888L5.99999 9.04795C6.35999 9.04795 6.67218 8.76014 6.67218 8.37575C6.64781 8.01575 6.35999 7.7045 5.99999 7.7045Z"
        fill={fill}
      />
      <Path
        d="M5.99999 11.4245H1.27223C0.912229 11.4245 0.600037 11.7123 0.600037 12.0967C0.600037 12.4567 0.887845 12.7689 1.27223 12.7689L5.99999 12.7679C6.35999 12.7679 6.67218 12.4801 6.67218 12.0958C6.64781 11.7358 6.35999 11.4245 5.99999 11.4245Z"
        fill={fill}
      />
      <Path
        d="M5.99999 15.1678H1.27223C0.912229 15.1678 0.600037 15.4556 0.600037 15.84C0.600037 16.2243 0.887845 16.5122 1.27223 16.5122H5.99999C6.35999 16.5122 6.67218 16.2243 6.67218 15.84C6.67218 15.4556 6.35999 15.1678 5.99999 15.1678Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
