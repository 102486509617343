import './app/app-imports';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import { App } from './app/app';
import history from './hooks/history';

const client = new QueryClient();

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      {/* @ts-ignore Router has no children type */}
      <Router history={history}>
        <App />
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
);
