import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getAuthToken,
  initiateAuth,
  respondToAuthChallenge,
} from 'xo/auth-utils';
import { Button, ButtonProps } from 'xo/components/button';
import { info } from 'xo/components/web-modal';
import { mockJwtToken } from 'xo/constants';
import { ApiSiteActiveStatus } from 'xo/graphql/api/enums/site-active-status.generated';
import { useGetSitesKioskModeQuery } from 'xo/graphql/api/get-sites-kiosk-mode-query.generated';
import logger from 'xo/logger';
import { visitorRoutes } from 'xo/navigation/web-routes';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgLocation } from 'xo/svg/svg-location';
import { SvgPhone } from 'xo/svg/svg-phone';
import { useIsVisitorClassic, useSiteMap } from '../../hooks/shared-hooks';
import { modelsToOptions } from '../../hooks/transformers';
import { env } from '../env';
import { FormItem } from '../forms/form';
import { LocalStorage } from '../shared/local-storage';
import { HelpTooltip } from './help-tooltip';
import { Modal } from './modal';
import { Select } from './select';
import { StatusMessage } from './status-message';

export const KIOSK_BUTTON_LABEL = 'KIOSK CHECK-IN';

export const useKioskSites = () => {
  const siteMap = useSiteMap();
  const classic = useIsVisitorClassic();
  const [{ data, fetching }] = useGetSitesKioskModeQuery({ pause: classic });
  const kioskSites = useMemo(
    () =>
      data?.sites.filter(
        s =>
          s.canSwitchToKiosk &&
          s.kioskUser?.cognitoUsername &&
          s.activeStatus === ApiSiteActiveStatus.Active &&
          !!siteMap.get(s.id)?.hosts?.length,
      ) ?? [],
    [siteMap, data],
  );

  return { kioskSites, fetching, classic };
};

export interface KioskModeButtonProps
  extends Omit<ButtonProps, 'children' | 'onPress' | 'isLoading'> {
  defaultModalVisible?: boolean;
  className?: string;
}

export const KioskModeButton = (props: KioskModeButtonProps) => {
  const { kioskSites, fetching, classic } = useKioskSites();

  const [modalVisible, setModalVisible] = useState(!!props.defaultModalVisible);
  const [siteShortCode, setSiteShortCode] = useState<string>();

  useEffect(() => {
    if (kioskSites.length === 1) {
      setSiteShortCode(kioskSites[0].shortCode);
    }
  }, [kioskSites]);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onNext = async () => {
    const site = kioskSites.find(s => s.shortCode === siteShortCode);
    setLoading(true);
    if (site?.kioskUser?.cognitoUsername) {
      try {
        // FIXME we shouldn't be checking for Storybook here, but to do that we need to effectively mock token fetching from storage for tests
        const token = env.REACT_APP_STORYBOOK
          ? mockJwtToken
          : await getAuthToken();
        if (!token) {
          throw new Error('didnt find an auth token');
        }

        const assumedUser = LocalStorage.getAssumedUser();
        const challengeResponse = JSON.stringify({
          token: token,
          xoAdminAssumeUser: assumedUser?.userId,
        });

        await initiateAuth(site.kioskUser.cognitoUsername);
        await respondToAuthChallenge(challengeResponse);

        // storybook interaction tests don't handle direct pathname changes
        if (env.REACT_APP_STORYBOOK) {
          history.push(visitorRoutes.kioskRoot(siteShortCode));
        } else {
          // trigger a full reload to ensure the new user is logged in
          window.location.pathname = visitorRoutes.kioskRoot(siteShortCode);
        }
      } catch (e) {
        logger.warn(
          `error switching to kiosk mode for ${site.kioskUser.cognitoUsername}`,
        );
        logger.error(e);
        setLoading(false);
        info({
          content: 'Sorry, an error occurred switching to kiosk mode',
        });
      }
    } else {
      logger.error(new Error(`no valid kioskUser found for site ${site?.id}`));
    }
  };

  if (classic) return null;

  return (
    <>
      <div className={props.className}>
        <div className="flex items-center">
          <Button
            rounded="lg"
            leftIcon={<SvgPhone fill={colors.blue[600]} />}
            onPress={() => setModalVisible(true)}
            height="10"
            loading={fetching}
            disabled={!fetching && !kioskSites.length}
            {...props}
          >
            {KIOSK_BUTTON_LABEL}
          </Button>
          <HelpTooltip className="ml-1" fill={colors.white}>
            {/* U+2011 = non-breaking hyphen */}
            Turn this device into an open check&#x2011;in kiosk for visitors
          </HelpTooltip>
        </div>
      </div>

      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={onNext}
        okButtonProps={{ disabled: !siteShortCode, loading }}
        okText="NEXT"
        cancelButtonProps={{ children: 'BACK' }}
        cancelText="BACK"
      >
        <StatusMessage className="my-4 w-full" type="info">
          Activating Kiosk check-in will log you out
        </StatusMessage>

        <div className="ant-form ant-form-vertical">
          <FormItem label="Select the site to check-in other people">
            <Select
              prefixIcon={<SvgLocation fill={colors.black} />}
              options={modelsToOptions(kioskSites, 'shortCode')}
              placeholder="Select site"
              onChange={setSiteShortCode}
              value={siteShortCode}
            />
          </FormItem>
        </div>
      </Modal>
    </>
  );
};
