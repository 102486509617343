import { ApiProvider } from 'xo/graphql/api/enums/provider.generated';

import gql from 'graphql-tag';
export type ApiWeatherObservationFragment = {
  __typename?: 'WeatherObservation';
  id: string;
  provider: ApiProvider;
  temperature: number;
  humidity?: number;
};

export const WeatherObservationFragmentDoc = gql`
  fragment WeatherObservation on WeatherObservation {
    __typename
    id
    provider
    temperature
    humidity
  }
`;
