import { ApiSiteActiveStatus } from 'xo/graphql/api/enums/site-active-status.generated';

import gql from 'graphql-tag';
export type ApiSiteKioskFragment = {
  __typename?: 'Site';
  id: string;
  name: string;
  shortCode: string;
  canSwitchToKiosk: boolean;
  activeStatus: ApiSiteActiveStatus;
  kioskUser?: { __typename?: 'User'; id: string; cognitoUsername?: string };
};

export const SiteKioskFragmentDoc = gql`
  fragment SiteKiosk on Site {
    __typename
    id
    name
    shortCode
    kioskUser {
      __typename
      id
      cognitoUsername
    }
    canSwitchToKiosk
    activeStatus
  }
`;
