import { ApiIngestScheduledMovementSpreadsheetInput } from 'xo/graphql/api/inputs/ingest-scheduled-movement-spreadsheet-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiIngestScheduledMovementSpreadsheetErrorFragment,
  IngestScheduledMovementSpreadsheetErrorFragmentDoc,
} from './ingest-scheduled-movement-spreadsheet-error-fragment.generated';
import {
  ApiScheduledMovementIngestionFragment,
  ScheduledMovementIngestionFragmentDoc,
} from './scheduled-movement-ingestion-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiIngestScheduledMovementSpreadsheetMutationVariables = Exact<{
  input: ApiIngestScheduledMovementSpreadsheetInput;
}>;

export type ApiIngestScheduledMovementSpreadsheetMutation = {
  __typename?: 'Mutation';
  ingestScheduledMovementSpreadsheet:
    | ({
        __typename?: 'IngestScheduledMovementSpreadsheetError';
      } & ApiIngestScheduledMovementSpreadsheetErrorFragment)
    | ({
        __typename?: 'ScheduledMovementIngestion';
      } & ApiScheduledMovementIngestionFragment);
};

export const IngestScheduledMovementSpreadsheetDocument = gql`
  mutation IngestScheduledMovementSpreadsheet(
    $input: IngestScheduledMovementSpreadsheetInput!
  ) {
    __typename
    ingestScheduledMovementSpreadsheet(input: $input) {
      __typename
      ...ScheduledMovementIngestion
      ...IngestScheduledMovementSpreadsheetError
    }
  }
  ${ScheduledMovementIngestionFragmentDoc}
  ${IngestScheduledMovementSpreadsheetErrorFragmentDoc}
`;

export function useIngestScheduledMovementSpreadsheetMutation() {
  return Urql.useMutation<
    ApiIngestScheduledMovementSpreadsheetMutation,
    ApiIngestScheduledMovementSpreadsheetMutationVariables
  >(IngestScheduledMovementSpreadsheetDocument);
}
