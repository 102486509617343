import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { CognitoError } from 'xo/account-utils';
import {
  finishMfaSetup,
  getMfaStatus,
  getMfaUri,
  removeMfa,
  startMfaSetup,
} from 'xo/auth-utils';
import Collapse from 'xo/components/collapse';
import { SvgKey } from 'xo/svg/svg-key';
import { flexCenterBetween, flexColCenterCenter } from '../app-constants';
import { Button } from '../components/button';
import { Panel } from '../components/panel';
import { StatusMessage } from '../components/status-message';
import { STAGE } from '../env';
import { Form, FormItem } from '../forms/form';
import { Input } from '../forms/input';

export const AdminMfa: React.FC = () => {
  const [sharedSecret, setSharedSecret] = useState<string>();
  const [mfaUri, setMfaUri] = useState<string>();
  const [error, setError] = useState<CognitoError>();
  const [loading, setLoading] = useState(false);
  const [mfaStatus, setMfaStatus] = useState<boolean>();

  useEffect(() => {
    getMfaStatus().then(setMfaStatus);
  }, [setMfaStatus]);

  const onStartMfaSetup = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const sharedSecret = await startMfaSetup();
      const uri = await getMfaUri({ stage: STAGE, sharedSecret });
      setSharedSecret(sharedSecret);
      setMfaUri(uri);
    } catch (e) {
      setError(e as CognitoError);
    }
    setLoading(false);
  };

  const [form] = useForm();
  const onFinishMfaSetup = async () => {
    setLoading(true);
    setError(undefined);
    const code = form.getFieldValue('code');
    try {
      const mfaStatus = await finishMfaSetup(code);
      setMfaStatus(mfaStatus);
    } catch (e) {
      setError(e as CognitoError);
    }

    setLoading(false);
  };

  const onRemoveMfaSetup = async () => {
    setLoading(true);
    setError(undefined);

    try {
      const mfaStatus = await removeMfa();
      setMfaStatus(mfaStatus);
    } catch (e) {
      setError(e as CognitoError);
    }

    setLoading(false);
  };

  const errorMessage = (
    <Collapse active={!!error}>
      <StatusMessage className="m-4" type="error">
        {error?.message}
      </StatusMessage>
    </Collapse>
  );

  return (
    <div className="my-4">
      <div className={classNames('mt-4')}>
        {mfaStatus ? (
          <>
            <StatusMessage type="success">
              <div className={flexCenterBetween}>
                <span>MFA is configured</span>
                <Button
                  type="grey"
                  onClick={onRemoveMfaSetup}
                  loading={loading}
                >
                  Remove
                </Button>
              </div>
            </StatusMessage>
            {errorMessage}
          </>
        ) : mfaUri ? (
          <Panel
            className={flexColCenterCenter}
            backgroundClassName="bg-grey-100"
          >
            <div className={classNames('my-4', flexColCenterCenter)}>
              <div>Enter this code into your authenticator app</div>
              <span className="my-2 font-bold">{sharedSecret}</span>
            </div>
            <div className="my-2 text-lg font-bold">OR</div>
            <div className="my-4">Scan the QR code</div>
            <QRCode value={mfaUri} size={200} />

            <div className="my-4">and verify a code</div>

            <Form form={form}>
              <FormItem
                name="code"
                label="Enter a code from the authenticator app"
              >
                <Input type="number" pattern="[0-9]*" prefix={<SvgKey />} />
              </FormItem>
            </Form>

            <Button type="primary" onClick={onFinishMfaSetup} loading={loading}>
              Verify
            </Button>

            {errorMessage}
          </Panel>
        ) : (
          <>
            <Button type="primary" onClick={onStartMfaSetup} loading={loading}>
              Setup MFA
            </Button>

            {errorMessage}
          </>
        )}
      </div>
    </div>
  );
};
