import { useContext, useId } from 'react';
import { DateTimePickerWeb } from './date-time-picker-web';
import './date-time-picker.overrides.css';
import { DatePickerProps } from './date-time-picker.props';
import { FormControlContext } from './form-control';

const DatePickerWeb = ({
  placeholder,
  disabled,
  onChange,
  value,
  style,
  clearable,
  invalid,
  variant,
  icon,
  onBlur,
  onFocus,
  minDate,
  maxDate,
  renderSelected,
  testIdPrefix,
  ariaLabel,
}: DatePickerProps) => {
  const control = useContext(FormControlContext);

  const fallbackId = useId();

  const onBlurCb = () => {
    onBlur && onBlur();
    control?.onBlur();
  };

  const onFocusCb = () => {
    onFocus && onFocus();
    control?.onFocus();
  };

  return (
    <DateTimePickerWeb
      id={`${testIdPrefix ?? fallbackId}_date`}
      value={value}
      onChange={onChange}
      disabled={disabled}
      picker="date"
      placeholder={placeholder}
      allowClear={clearable}
      variant={variant}
      icon={icon}
      onBlur={onBlurCb}
      onFocus={onFocusCb}
      minDate={minDate}
      maxDate={maxDate}
      renderSelected={renderSelected}
      ariaLabel={ariaLabel}
      // FIXME Support invalid
      // invalid={invalid}
    />
  );
};

export default DatePickerWeb;
