import { sortBy } from 'lodash';
import { useContext } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import {
  DetailedSiteReadModel,
  SiteCreateUpdateRequest,
  SiteGetResponse,
  SiteListResponse,
  SiteOpenGetResponse,
  SiteReadModel,
} from '../api-models';
import { useCurrentUser } from '../app/account/user-provider';
import { ErrorPageContext } from '../app/components/error-page-provider';
import { UserModel } from '../models/visitor-log-models';
import { AnalyticsEvent, captureEvent } from './analytics';
import { getApi } from './axios-instance';
import { writeSiteTransformer } from './transformers';
import { userQueryKeys } from './user-network-hooks';

const siteApi = getApi('site');
export const siteQueryKeys = {
  getSites: 'getSites',
  adminSites: 'adminSites',
  site: (id: string) => `site:${id}`,
};

export const useGetSites = (enabled: boolean = true) =>
  useQuery<DetailedSiteReadModel[]>(
    siteQueryKeys.getSites,
    () =>
      siteApi
        .get<SiteListResponse>('/')
        .then(response => sortBy(response.data.sites, 'name')),
    { enabled },
  );

export const useGetSite = ({ id }: { id?: string }) => {
  const { onNotFoundAdminAutoAssume } = useContext(ErrorPageContext);
  return useQuery<DetailedSiteReadModel>(
    siteQueryKeys.site(id ?? ''),
    () =>
      getApi('site', {
        notifyOnError: false,
        onErrorPage: onNotFoundAdminAutoAssume({ type: 'site', id: id! }),
      })
        .get<SiteGetResponse>(`/${id}`)
        .then(response => response.data.site),
    { enabled: !!id },
  );
};

export const useGetSiteOpen = (
  { shortCode }: { shortCode?: string },
  options?: UseQueryOptions<SiteReadModel, unknown, SiteReadModel>,
) => {
  return useQuery<SiteReadModel>(
    siteQueryKeys.site(shortCode ?? ''),
    () =>
      getApi('site', {
        notifyOnError: false,
        open: true,
      })
        .get<SiteOpenGetResponse>(`/${shortCode}`)
        .then(response => response.data.site),
    { ...options, enabled: !!shortCode },
  );
};
export const usePostSite = () => {
  const client = useQueryClient();

  return useMutation(
    (site: DetailedSiteReadModel) => {
      const request: SiteCreateUpdateRequest = {
        site: writeSiteTransformer(site),
      };

      return siteApi.post<SiteGetResponse>('/', request).then(response => {
        const site = response.data.site;
        client.setQueryData(siteQueryKeys.site(site.id), site);
        client.setQueryData(siteQueryKeys.site(site.shortCode), site);
        client.setQueryData<SiteReadModel[]>(siteQueryKeys.getSites, sites =>
          sortBy((sites ?? []).concat(site), s => s.name),
        );
        return site;
      });
    },
    { retry: false },
  );
};

export const useUpdateQueryClientSite = () => {
  const client = useQueryClient();
  const user = useCurrentUser();

  return (site: Partial<DetailedSiteReadModel> & { id: string }) => {
    const cacheSite =
      client.getQueryData<DetailedSiteReadModel>(siteQueryKeys.site(site.id)) ??
      [siteQueryKeys.getSites, siteQueryKeys.adminSites]
        .flatMap(key => client.getQueryData<DetailedSiteReadModel[]>(key))
        ?.find(s => s?.id === site.id);

    if (cacheSite) {
      const newSite: DetailedSiteReadModel = {
        ...cacheSite,
        ...site,
      };

      const newUser: UserModel = {
        ...user,
        hostAtSites: user.hostAtSites.map(s =>
          s.id === newSite.id ? newSite : s,
        ),
      };
      client.setQueryData(userQueryKeys.currentUser(true), newUser);

      client.setQueryData(siteQueryKeys.site(cacheSite.id), newSite);
      client.setQueryData(siteQueryKeys.site(cacheSite.shortCode), newSite);
      [siteQueryKeys.getSites, siteQueryKeys.adminSites].forEach(key =>
        client.setQueryData<DetailedSiteReadModel[]>(
          key,
          sites => sites?.map(s => (s.id === newSite.id ? newSite : s)) ?? [],
        ),
      );
    }
  };
};

export const usePutSite = () => {
  const updateSite = useUpdateQueryClientSite();

  return useMutation(
    (site: DetailedSiteReadModel & { id: string }) => {
      captureEvent(AnalyticsEvent.SiteEdit, {
        site,
      });

      const request: SiteCreateUpdateRequest = {
        site: writeSiteTransformer(site),
      };

      return siteApi
        .put<SiteGetResponse>(`/${site.id}`, request)
        .then(response => {
          const site = response.data.site;
          updateSite(site);
          return site;
        });
    },
    { retry: false },
  );
};

export const useDeleteSite = () => {
  const client = useQueryClient();
  return useMutation(
    (request: { id: string }) =>
      siteApi.delete<SiteGetResponse>(`/${request.id}`).then(response => {
        const site = response.data.site;
        client.setQueryData(siteQueryKeys.site(site.id), site);
        client.setQueryData(siteQueryKeys.site(site.shortCode), site);
        client.setQueryData<SiteReadModel[]>(
          siteQueryKeys.getSites,
          sites => sites?.map(s => (s.id === site.id ? site : s)) ?? [],
        );
        return site;
      }),
    { retry: false },
  );
};
