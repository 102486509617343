import { ApiTripMatchFailureReason } from 'xo/graphql/api/enums/trip-match-failure-reason.generated';
import { ApiTripStatus } from 'xo/graphql/api/enums/trip-status.generated';

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
import {
  ApiDriverFragment,
  DriverFragmentDoc,
} from './driver-fragment.generated';
import {
  ApiPositionDirectionInTimeFragment,
  PositionDirectionInTimeFragmentDoc,
} from './position-direction-in-time-fragment.generated';
import {
  ApiSubLoadFragment,
  SubLoadFragmentDoc,
} from './subload-fragment.generated';
import {
  ApiTransporterFragment,
  TransporterFragmentDoc,
} from './transporter-fragment.generated';
import {
  ApiTripEventFragment,
  TripEventFragmentDoc,
} from './trip-event-fragment.generated';
import {
  ApiVehicleFragment,
  VehicleFragmentDoc,
} from './vehicle-fragment.generated';
export type ApiTripFragment = {
  __typename?: 'Trip';
  id: any;
  loadNumber: number;
  date: Dayjs;
  status: ApiTripStatus;
  isLive: boolean;
  notStartedReason?: ApiTripMatchFailureReason;
  currentLocation?: {
    __typename?: 'PositionDirectionInTime';
  } & ApiPositionDirectionInTimeFragment;
  transporter: { __typename?: 'Transporter' } & ApiTransporterFragment;
  events: Array<{ __typename?: 'TripEvent' } & ApiTripEventFragment>;
  driver?: { __typename?: 'Driver' } & ApiDriverFragment;
  vehicle: { __typename?: 'Vehicle' } & ApiVehicleFragment;
  subloads: Array<{ __typename?: 'SubLoad' } & ApiSubLoadFragment>;
};

export const TripFragmentDoc = gql`
  fragment Trip on Trip {
    __typename
    id
    loadNumber
    date
    status
    isLive
    currentLocation {
      ...PositionDirectionInTime
    }
    transporter {
      ...Transporter
    }
    events {
      ...TripEvent
    }
    driver {
      ...Driver
    }
    vehicle {
      ...Vehicle
    }
    subloads {
      ...SubLoad
    }
    notStartedReason
  }
  ${PositionDirectionInTimeFragmentDoc}
  ${TransporterFragmentDoc}
  ${TripEventFragmentDoc}
  ${DriverFragmentDoc}
  ${VehicleFragmentDoc}
  ${SubLoadFragmentDoc}
`;
