import React from 'react';
import { fontFamily, fontSize, fontWeight } from 'xo/styles/tailwind-theme';
import { useId } from '../../hooks/component-hooks';
import { SvgProps } from './svg';

export const SvgLocationOrder: React.FC<
  SvgProps & { order?: number }
> = props => {
  const { fill, order, scale = 1 } = props;
  const filterId = useId();

  return (
    <svg
      width={28}
      height={38}
      viewBox="0 0 28 38"
      style={{ transform: `scale(${scale})` }}
    >
      <g filter={`url(#${filterId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.768 1.938c-6.402 0-11.58 5.135-11.58 11.484 0 8.613 11.58 21.328 11.58 21.328S25.35 22.035 25.35 13.422c0-6.35-5.178-11.485-11.58-11.485Z"
          fill={fill}
        />
        <circle cx={14} cy="13.75" r="9.188" fill="#fff" />
        {typeof order === 'number' && (
          <text
            style={{
              fontWeight: fontWeight.bold as any,
              fontFamily: fontFamily.sans[0],
              fontSize: fontSize.xs,
            }}
            x="10"
            y="18"
            fill={fill}
          >
            {order}
          </text>
        )}
      </g>
      <defs>
        <filter
          id={filterId}
          x=".214"
          y=".622"
          width="27.109"
          height="36.76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy=".658" />
          <feGaussianBlur stdDeviation=".987" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_89_571"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.316" />
          <feGaussianBlur stdDeviation=".329" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend
            in2="effect1_dropShadow_89_571"
            result="effect2_dropShadow_89_571"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy=".658" />
          <feGaussianBlur stdDeviation=".329" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend
            in2="effect2_dropShadow_89_571"
            result="effect3_dropShadow_89_571"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect3_dropShadow_89_571"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
