import classNames from 'classnames';
import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCross } from 'xo/svg/svg-cross';

export interface ClearButtonProps {
  buttonClassName?: string;
  crossClassName?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const ClearButton = ({
  buttonClassName,
  crossClassName,
  onClick,
}: ClearButtonProps) => (
  <button
    className={classNames(
      buttonClassName,
      'z-50 flex items-center justify-center',
    )}
    onClick={onClick}
  >
    <div
      className={classNames(
        crossClassName,
        'rounded-full bg-grey-500 hover:bg-grey-400',
      )}
    >
      <SvgCross fill={colors.white} scale={0.65} />
    </div>
  </button>
);
