import { FieldValues } from 'react-hook-form';
import { Checkbox, CheckboxProps } from './checkbox';
import {
  ControlledFormControl,
  ControlledFormItemProps,
} from './controlled-form-control';
import { FormMode, useFormModeContext } from './form';

export type ControlledCheckboxProps<TFieldValues extends FieldValues> = Omit<
  ControlledFormItemProps<TFieldValues, {}>,
  'label'
> &
  CheckboxProps;

export const ControlledCheckbox = <TFieldValues extends FieldValues>(
  props: ControlledCheckboxProps<TFieldValues>,
) => {
  const { name, label, isRequired, formControl, disabled, ...rest } = props;

  const formMode = useFormModeContext();
  return (
    <ControlledFormControl name={name} isRequired={isRequired} {...formControl}>
      {({ onChange, value }) => (
        <Checkbox
          label={label}
          onChange={onChange}
          value={value}
          disabled={formMode.mode !== FormMode.Edit || disabled}
          loading={formMode.mode === FormMode.Loading}
          {...rest}
        />
      )}
    </ControlledFormControl>
  );
};
