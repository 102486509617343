import dayjs, { Dayjs } from 'dayjs';
import { validateUuidLike } from 'xo/utils/validation-utils';

const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const localStorageAutoAssumeLockedKey = 'auto-assume-locked-until';
const whitelistLocalStorageKeys = [
  'CognitoIdentityServiceProvider',
  'assumed-user',
  localStorageAutoAssumeLockedKey,
];

export type AssumeUserDetails = {
  userId: string;
  assumedUntil: Dayjs;
  name: string;
};

// FIXME Collect all local storage keys and verify uniqueness
export class LocalStorage {
  static commitBackend: string;
  static commitFrontend: string;

  // FIXME temporary (hopefully) cludge to allow mocking the wrapped localStorage
  static storage: Storage = localStorage;

  static key(
    partialKey: string,
    version: string = LocalStorage.commitFrontend,
  ) {
    return `${version}:${partialKey}`;
  }

  static getItem<T>(key: string): T | undefined {
    const json = LocalStorage.storage?.getItem(key);
    if (!json) return;

    return JSON.parse(json, (_key: string, value: any) => {
      if (value && typeof value === 'string' && !validateUuidLike(value)) {
        const dayjsValue = dayjs.utc(value, ISO_8601_FORMAT);
        return dayjsValue.isValid() ? dayjsValue : value;
      }

      return value;
    }) as T;
  }

  static setItem(key: string, data: any) {
    LocalStorage.storage?.setItem(key, JSON.stringify(data));
  }

  static removeItem(key: string) {
    LocalStorage.storage?.removeItem(key);
  }

  static getAssumedUserKey() {
    return 'assumed-user';
  }

  static getAssumedUser() {
    const assumedUser: AssumeUserDetails | undefined =
      LocalStorage.getItem<AssumeUserDetails>(LocalStorage.getAssumedUserKey());

    return assumedUser;
  }

  static setAssumedUser(details: AssumeUserDetails) {
    return LocalStorage.setItem(LocalStorage.getAssumedUserKey(), details);
  }

  static clearAllExceptAuth() {
    if (LocalStorage.storage) {
      for (let i = 0; i < LocalStorage.storage.length; ++i) {
        const key = LocalStorage.storage.key(i) ?? '';
        if (whitelistLocalStorageKeys.every(k => !key.startsWith(k))) {
          LocalStorage.storage.removeItem(key);
        }
      }
    }
  }
}
