import { isNaN, isNil, sortBy } from 'lodash';
import { OptionType, OptionTypeValue } from 'xo/hooks/component-hooks';

export const createStringOptions = (strings: string[]): OptionType<string>[] =>
  strings.map(s => ({ key: s, label: s, value: s }));

export const createEnumOptions = <
  T extends OptionTypeValue,
  TOptionType extends OptionType<T>,
>(
  enumType: Record<string, T>,
  overrides?: Record<string, Partial<TOptionType>>,
  sort?: (option: TOptionType) => any,
) => {
  const result = createOptions(
    Object.values(enumType).map(value => {
      const override = overrides && overrides[String(value)];
      const label = String(value).replace(/_/g, ' ');

      return {
        key: String(value),
        label: label[0].toUpperCase() + label.substring(1).toLowerCase(),
        value,
        ...override,
      };
    }),
  );

  result.options = (
    sort ? sortBy(result.options, sort) : result.options
  ) as TOptionType[];

  return result;
};

export const createEnumOption = <T extends OptionTypeValue>(
  enumValue: T,
  label: string,
): OptionType<T> => ({ key: String(enumValue), value: enumValue, label });

export const createOptions = <
  TOptionType extends OptionType<TOptionValueType>,
  TOptionValueType extends OptionTypeValue,
>(
  options: TOptionType[],
) => {
  const map = new Map(options.map(o => [o.value, o]));

  return {
    options,
    map,
    getLabel: (value?: TOptionValueType) =>
      !isNil(value) && !isNaN(value)
        ? map.get(value)?.label ?? undefined
        : undefined,
  };
};

export const setFromValue = (v?: OptionTypeValue | OptionTypeValue[]) =>
  new Set(Array.isArray(v) ? v : !isNil(v) ? [v] : []);
