import { Dayjs } from 'dayjs';
import { getTodayYesterdayAppender } from 'xo/date-utils';

const DateTime = ({
  dateTime,
  showDate = true,
  showAppender = false,
}: {
  dateTime: Dayjs;
  showDate?: boolean;
  showAppender?: boolean;
}) => (
  <span>
    {dateTime.format(`h:mma${showDate ? ', D/MM/YY' : ''}`)}
    {showAppender && getTodayYesterdayAppender(dateTime)}
  </span>
);

export interface StartToEndDateTimeProps {
  start: Dayjs;
  end?: Dayjs;
}

export const StartToEndDateTime = ({ start, end }: StartToEndDateTimeProps) => (
  <span className="flex items-center">
    <DateTime
      dateTime={start}
      showDate={!end?.isSame(start, 'day')}
      showAppender={!end?.isSame(start, 'day')}
    />

    {end && (
      <>
        <span className="px-1">-</span>
        <DateTime dateTime={end} showDate showAppender />
      </>
    )}
  </span>
);
