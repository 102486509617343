import { Dayjs } from 'dayjs';
import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  VisitCancelRequest,
  VisitCreateUpdateRequest,
  VisitForceRequest,
  VisitFormsResponse,
  VisitResponse,
  VisitSeriesCancelRequest,
  VisitSeriesCreateRequest,
  VisitSeriesResponse,
  VisitState,
} from '../api-models';
import { ErrorPageContext } from '../app/components/error-page-provider';
import {
  CancelVisitRequest,
  FormMap,
  VisitModel,
  VisitRequest,
  VisitSeriesReadModel,
} from '../models/visitor-log-models';
import { getApi } from './axios-instance';
import { datetimeTransformer } from './network-transformers';
import {
  readFormModelTransformer,
  readVisitSeriesTransformer,
  readVisitTransformer,
  writeVisitSeriesTransformer,
  writeVisitTransformer,
} from './transformers';

const visitApi = getApi('visit');

export const visitQueryKeys = {
  visit: (id: string) => `visit:${id}`,
  visitForms: (id: string) => `visitForms:${id}`,
};

export const useGetVisit = (id?: string) => {
  const { onNotFoundAdminAutoAssume } = useContext(ErrorPageContext);

  return useQuery<VisitModel>(
    visitQueryKeys.visit(id!),
    () =>
      getApi('visit', {
        onErrorPage: onNotFoundAdminAutoAssume({ type: 'visit', id: id! }),
      })
        .get<VisitResponse>(`/${id}`)
        .then(response => readVisitTransformer(response.data.visit)),
    { enabled: !!id, retry: false, cacheTime: 0 },
  );
};

export const usePostVisit = () =>
  useMutation<VisitModel, unknown, VisitRequest, unknown>(
    (request: VisitRequest) => {
      const data: VisitCreateUpdateRequest = {
        visit: writeVisitTransformer(request.visit),
      };

      return visitApi
        .post<VisitResponse>('/', data)
        .then(response => readVisitTransformer(response.data.visit));
    },
    { retry: false },
  );

export const usePutVisit = () => {
  const client = useQueryClient();

  return useMutation<VisitModel, unknown, VisitRequest, unknown>(
    (request: VisitRequest) => {
      const data: VisitCreateUpdateRequest = {
        visit: writeVisitTransformer(request.visit),
      };

      return visitApi
        .put<VisitResponse>(`/${request.visit.id}`, data)
        .then(response => {
          const visit = readVisitTransformer(response.data.visit);
          // Required when editing because page doesn't re-mount
          client.setQueryData(visitQueryKeys.visit(visit.id), visit);
          return visit;
        });
    },
    { retry: false },
  );
};

export const useCancelVisit = () =>
  useMutation(
    (request: CancelVisitRequest) => {
      const data: VisitCancelRequest = {
        reason: request.reason ?? '',
        sendReasonToVisitor: request.sendReasonToVisitor ?? false,
      };
      return visitApi
        .post<VisitResponse>(`/${request.id}/cancel`, data)
        .then(response => readVisitTransformer(response.data.visit));
    },
    { retry: false },
  );

export const useForceVisit = () => {
  const client = useQueryClient();

  return useMutation(
    (request: {
      state: VisitState;
      nextAlert?: Dayjs;
      nextAlertIsIncomplete: boolean;
      actualStartDatetime?: Dayjs;
      actualEndDatetime?: Dayjs;
      id: string;
    }) => {
      const data: VisitForceRequest = {
        state: request.state,
        nextAlert: request.nextAlert?.format(),
        nextAlertIsIncomplete: request.nextAlertIsIncomplete,
        actualStartDatetime:
          request.actualStartDatetime &&
          datetimeTransformer(request.actualStartDatetime),
        actualEndDatetime:
          request.actualEndDatetime &&
          datetimeTransformer(request.actualEndDatetime),
      };
      return visitApi
        .post<VisitResponse>(`/${request.id}/force`, data)
        .then(response => {
          const visit = readVisitTransformer(response.data.visit);
          // Required when editing because page doesn't re-mount
          client.setQueryData(visitQueryKeys.visit(visit.id), visit);
          return visit;
        });
    },
    { retry: false },
  );
};

export const visitSeriesQueryKeys = {
  visitSeries: (id: string) => `visitSeries:${id}`,
};

export const useGetVisitSeries = (id: string) => {
  const { onNotFoundAdminAutoAssume } = useContext(ErrorPageContext);

  return useQuery<VisitSeriesReadModel>(
    visitSeriesQueryKeys.visitSeries(id),
    () =>
      getApi('visit-series', {
        onErrorPage: onNotFoundAdminAutoAssume({
          type: 'visit-series',
          id,
        }),
      })
        .get<VisitSeriesResponse>(`/${id}`)
        .then(response =>
          readVisitSeriesTransformer(response.data.visitSeries),
        ),
    { cacheTime: 0 },
  );
};

const visitSeriesApi = getApi('visit-series');
export const usePostVisitSeries = () =>
  useMutation<VisitSeriesReadModel, unknown, VisitRequest, unknown>(
    (request: VisitRequest) => {
      const data: VisitSeriesCreateRequest = {
        seriesNew: writeVisitSeriesTransformer(request.visit),
      };

      return visitSeriesApi
        .post<VisitSeriesResponse>('/', data)
        .then(response =>
          readVisitSeriesTransformer(response.data.visitSeries),
        );
    },
    { retry: false },
  );

export const useCancelVisitsInSeries = () => {
  const client = useQueryClient();

  return useMutation(
    (request: CancelVisitRequest & { visitIds: string[] }) => {
      const data: VisitSeriesCancelRequest = {
        reason: request.reason ?? '',
        sendReasonToVisitor: request.sendReasonToVisitor ?? false,
        // FIXME Pydantic model outputs weird TS type
        visitsToCancel: request.visitIds as [string, ...string[]],
      };
      return visitSeriesApi
        .post<VisitSeriesResponse>(`/${request.id}/cancel`, data)
        .then(response => {
          const series = readVisitSeriesTransformer(response.data.visitSeries);
          client.setQueryData(
            visitSeriesQueryKeys.visitSeries(series.id),
            series,
          );
          return series;
        });
    },
    { retry: false },
  );
};

export const useGetVisitForms = ({
  id,
  cache,
}: {
  id?: string;
  cache?: boolean;
}) => {
  const { onErrorPage } = useContext(ErrorPageContext);

  return useQuery(
    visitQueryKeys.visitForms(id ?? ''),
    () =>
      getApi('visit', { onErrorPage })
        .post<VisitFormsResponse>(`/${id}/forms`, [])
        .then(
          response =>
            Object.fromEntries(
              Object.entries(response.data.forms).map(([kind, form]) => [
                kind,
                readFormModelTransformer(form),
              ]),
            ) as FormMap,
        ),
    {
      enabled: !!id,
      ...(cache
        ? {
            cacheTime: Number.POSITIVE_INFINITY,
            staleTime: Number.POSITIVE_INFINITY,
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
          }
        : undefined),
    },
  );
};
