import type { PermissionResponse as NotificationPermissionResponse } from 'expo-notifications';

export enum WebViewActionType {
  Navigate = 'navigate',
  UpdateStore = 'updateStore',
}

export interface WebViewAction<T extends object> {
  type: WebViewActionType;
  data?: T;
}

export interface WebViewNavigateData {
  pathname: string;
}

export interface WebViewStoreState {
  push: NotificationPermissionResponse | undefined;
}
