export enum ApiSiteArchetype {
  Abattoir = 'ABATTOIR',
  AiCentre = 'AI_CENTRE',
  Butcher = 'BUTCHER',
  ClearingDip = 'CLEARING_DIP',
  CrownLand = 'CROWN_LAND',
  ExportDepot = 'EXPORT_DEPOT',
  Feedlot = 'FEEDLOT',
  Knackery = 'KNACKERY',
  LiveImportQuarantineStation = 'LIVE_IMPORT_QUARANTINE_STATION',
  MilkFactory = 'MILK_FACTORY',
  Port = 'PORT',
  Pound = 'POUND',
  Producer = 'PRODUCER',
  Saleyard = 'SALEYARD',
  Showground = 'SHOWGROUND',
  StockAgent = 'STOCK_AGENT',
  TransitYards = 'TRANSIT_YARDS',
  TransportDepot = 'TRANSPORT_DEPOT',
  VetPrivatePractice = 'VET_PRIVATE_PRACTICE',
}
