import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgNotFound: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <Path
        d="M28.866 64.384h32.388v-2.768H45.446c-.728 0-1.317-.618-1.317-1.383s.59-1.384 1.317-1.384h15.808V47.773l-32.388-.005-7.904 8.308 7.904 8.308z"
        fill="#FB8C00"
      />
      <Path
        d="M104.179 39.459H71.791v4.151h15.808c.728 0 1.317.619 1.317 1.384 0 .765-.589 1.384-1.317 1.384H71.791v9.692h32.388l7.904-8.308-7.904-8.303z"
        fill="#0C5EB0"
      />
      <Path
        d="M61.912 27h9.22c1.092 0 1.975-.934 1.975-2.076 0-1.147-.888-2.075-1.975-2.075h-9.22c-1.092 0-1.975.933-1.975 2.075 0 1.142.888 2.076 1.975 2.076zM24.37 32.54c2.179 0 3.95-1.861 3.95-4.15 0-2.29-1.771-4.152-3.95-4.152-2.177 0-3.949 1.862-3.949 4.152-.005 2.283 1.772 4.15 3.95 4.15zM108.022 27c3.992 0 7.246-3.414 7.246-7.616-.005-4.196-3.254-7.616-7.246-7.616-3.992 0-7.246 3.414-7.246 7.616S104.024 27 108.022 27z"
        fill="#000"
      />
      <Path
        d="M92.326 58.849H71.792v16.61h20.534l7.904-8.308-7.904-8.302z"
        fill="#6DB8FD"
      />
      <Path
        d="M125.805 94.849H88.921c-.727 0-1.316.618-1.316 1.383s.589 1.384 1.316 1.384h36.884c.727 0 1.316-.619 1.316-1.384 0-.765-.589-1.383-1.316-1.383zM32.276 97.616c0-.765-.589-1.384-1.317-1.384H11.201c-.728 0-1.316.62-1.316 1.384 0 .765.588 1.384 1.316 1.384H30.96c.728 0 1.317-.619 1.317-1.384zM38.863 104.541c.728 0 1.316-.619 1.316-1.384 0-.765-.588-1.384-1.316-1.384H28.326c-.728 0-1.317.619-1.317 1.384 0 .765.589 1.384 1.317 1.384h10.537zM81.012 89.31c-.728 0-1.316.618-1.316 1.383s.588 1.384 1.316 1.384h17.125c.728 0 1.317-.619 1.317-1.384 0-.765-.589-1.383-1.317-1.383H81.012zM111.313 112.152c0 2.773-3.95 2.773-3.95 0 0-2.768 3.95-2.768 3.95 0zM32.276 116.309c0 2.768-3.955 2.768-3.955 0 0-2.767 3.955-2.767 3.955 0zM111.313 80.31c0 2.767-3.95 2.767-3.95 0 0-2.769 3.95-2.769 3.95 0zM123.166 37.384c0 2.768-3.95 2.768-3.95 0 0-2.767 3.95-2.767 3.95 0zM91.554 16.617c0 2.767-3.954 2.767-3.954 0 0-2.768 3.954-2.768 3.954 0zM49.401 18c0 2.768-3.955 2.768-3.955 0 0-2.767 3.955-2.767 3.955 0zM15.151 46.384c0 2.768-3.955 2.768-3.955 0 0-2.767 3.955-2.767 3.955 0zM32.276 77.54c0 2.768-3.955 2.768-3.955 0 0-2.772 3.955-2.772 3.955 0z"
        fill="#000"
      />
      <Path
        d="M112.629 129.459H84.967v-8.308H48.084v2.768h25.03c.728 0 1.317.619 1.317 1.384 0 .765-.59 1.383-1.317 1.383h-25.03v2.768H36.23c-.727 0-1.316.619-1.316 1.384 0 .765.589 1.383 1.316 1.383h76.405c.728 0 1.317-.618 1.317-1.383-.005-.754-.594-1.378-1.322-1.378l-.001-.001z"
        fill="#0C5EB0"
      />
      <Path
        d="M17.783 93.459h46.103v24.925h5.271v-15.233h25.688c.728 0 1.316-.619 1.316-1.384 0-.765-.588-1.383-1.316-1.383H69.157V29.768h-5.271v60.924H17.783c-.728 0-1.317.62-1.317 1.384 0 .765.589 1.384 1.317 1.384v-.001z"
        fill="#000"
      />
    </SvgIcon>
  );
};
