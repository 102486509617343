import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

/*
We need to modify the APP_URL for the webview here because currently iOS needs localhost and Android needs the local IP address of the server

iOS requires localhost because it doesn't allow non-HTTPS requests (HTTP) to anything other than localhost. We've attempted to turn this off for local development using NSAppTransportSecurity in app.config.ts so we can use the local IP address like Android, but it hasn't worked yet

Android's emulator uses a proxy address so we can't use localhost, we need to use the local IP address of the server (and so must expose it with --host when running Vite)

This logic is here because we need to determine the current platform to determine the APP_URL during local development and it's not clear how or if we can do that during the app.config.ts setup phase with Expo
*/
let localIp = Constants.expoConfig?.extra?.localIp;
let appUrl = Constants.expoConfig?.extra?.appUrl;
export const APP_URL =
  localIp && appUrl.includes('localhost') && Platform.OS === 'android'
    ? appUrl.replace('localhost', localIp)
    : appUrl;
export const STAGE = (
  Constants.expoConfig?.extra?.stage ?? 'local'
).toUpperCase();
export const USER_POOL_ID = Constants.expoConfig?.extra?.userPoolId;
export const USER_POOL_CLIENT_ID =
  Constants.expoConfig?.extra?.userPoolClientId;
export const API_URL = Constants.expoConfig?.extra?.apiUrl;
export const API_AUTH_HEADER = Constants.expoConfig?.extra?.apiAuthHeader;
export const API_BASIC_AUTH_USERNAME =
  Constants.expoConfig?.extra?.basicAuthUsername;
export const API_BASIC_AUTH_PASSWORD =
  Constants.expoConfig?.extra?.basicAuthPassword;
export const COMMIT = Constants.expoConfig?.extra?.commit;
export const STORYBOOK = Constants.expoConfig?.extra?.storybook;
export const SENTRY_DSN = Constants.expoConfig?.extra?.sentryDsn;
export const POSTHOG_TOKEN = Constants.expoConfig?.extra?.posthogToken;
export const UPDATES_IS_EMBEDDED = Updates.isEmbeddedLaunch;
export const UPDATES_IS_EMERGENCY = Updates.isEmergencyLaunch;
export const UPDATES_USING_EMBEDDED = Updates.isUsingEmbeddedAssets;
export const UPDATES_ID = Updates.updateId;
export const UPDATES_CHANNEL = Updates.channel;
export const APP_VERSION = Constants.expoConfig?.extra?.appVersion;
export const APP_STORE_LINK =
  Platform.OS === 'android'
    ? 'https://play.google.com/store/apps/details?id=io.exoflare.app'
    : 'https://apps.apple.com/au/app/exoflare/id6446268457';

export const CREDENTIALS_STORAGE_KEY = 'XO_CREDENTIALS';
