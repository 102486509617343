export const isTest = process.env.NODE_ENV === 'test';
export const isProd = (stage: string) => stage.toLowerCase() === 'prod';
export const isUat = (stage: string) => stage.toLowerCase() === 'uat';
export const isLocal = (stage?: string) =>
  !stage || stage.toLowerCase() === 'local';

export const getBuildInfo = ({
  stage,
  appVersion,
  embedded,
  updateId,
}: {
  stage: string;
  appVersion: string;
  embedded: boolean;
  updateId: string | null;
}) =>
  `${!isProd(stage) ? `[${stage}] ` : ''}App Version ${appVersion}${
    updateId && !embedded ? ` (${updateId.substring(0, 4)})` : ''
  }`;
