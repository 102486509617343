import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useUserModulePermissions } from '../../hooks/auth-utils';
import { useIsVisitorClassic } from '../../hooks/shared-hooks';
import { TooltipButton } from './tooltip-button';

export interface VisitorVersionProps {
  className?: string;
}

export const VisitorVersion = ({ className }: VisitorVersionProps) => {
  const classic = useIsVisitorClassic();
  const { visitorPermission } = useUserModulePermissions();

  if (!visitorPermission) return null;

  return (
    <Tooltip
      trigger={['click', 'hover']}
      overlay={
        <div className="text-center">
          Your organisation has ExoFlare{' '}
          <span>{classic ? 'Visitor (V1)' : 'People (V2)'}</span> enabled
        </div>
      }
    >
      <TooltipButton className={classNames(className, 'bg-grey-500')}>
        {classic ? 'V1' : 'V2'}
      </TooltipButton>
    </Tooltip>
  );
};
