import React from 'react';
import { Box, BoxProps } from 'xo/core';
import { colors } from 'xo/styles/tailwind-theme';

export interface InputIconProps extends BoxProps {
  children: JSX.Element;
  fill?: string;
  disabled?: boolean;
}

export const InputIcon = ({
  fill,
  disabled,
  children,
  ...rest
}: InputIconProps) => (
  <Box {...rest}>
    {React.cloneElement(children, {
      fill: disabled ? colors.grey['400'] : fill ?? colors.black,
    })}
  </Box>
);
