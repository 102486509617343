export enum ApiFindOrganisationType {
  AddressBookContact = 'addressBookContact',
  AddressBookSite = 'addressBookSite',
  ConsignmentNote = 'consignmentNote',
  ConsignmentNoteByClientId = 'consignmentNoteByClientId',
  Delivery = 'delivery',
  FileExport = 'fileExport',
  Organisation = 'organisation',
  ScheduledMovement = 'scheduledMovement',
  Site = 'site',
  Trip = 'trip',
  User = 'user',
  Vehicle = 'vehicle',
  Visit = 'visit',
  VisitSeries = 'visitSeries',
}
