/* eslint jsx-a11y/heading-has-content: 0 */
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { fontSize, margin } from 'xo/styles/tailwind-theme';
import { toId } from 'xo/utils/string-utils';
import { HelpIconButton } from './help-icon-button';

export type HeaderLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface HeaderProps {
  level: HeaderLevel;
  underline?: boolean;
  color?: 'blue' | 'black' | 'red';
  className?: string;
  labelFor?: string;
  tooltip?: string;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  level,
  underline,
  className,
  labelFor,
  tooltip,
  children,
  color = 'blue',
}) => {
  const elem = {
    1: <h1 />,
    2: <h2 />,
    3: <h3 />,
    4: <h4 />,
    5: <h5 />,
    6: <h6 />,
  }[level];

  const renderedChildren = tooltip ? (
    <div className="flex items-center">
      {children}
      <Tooltip
        title={tooltip}
        placement="top"
        trigger={['click', 'hover']}
        overlayInnerStyle={{ textAlign: 'center' }}
      >
        <HelpIconButton style={{ marginLeft: margin[2] }} />
      </Tooltip>
    </div>
  ) : (
    children
  );

  return (
    <>
      {React.cloneElement(
        labelFor ? (
          <label htmlFor={labelFor} style={{ fontSize: fontSize.lg }} />
        ) : (
          elem
        ),
        {
          children: renderedChildren,
          className: classNames(
            className,
            'block text-lg font-extrabold uppercase',
            {
              'border-blue-600 text-blue-600': color === 'blue',
              'border-red-700 text-red-700': color === 'red',
              'border-b-4 border-solid': underline,
            },
          ),
          id: typeof children === 'string' ? toId(children) : undefined,
        },
      )}
    </>
  );
};
