import { authExchange } from '@urql/exchange-auth';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import {
  AnyVariables,
  Client,
  Exchange,
  Operation,
  Provider,
  CombinedError as UrqlCombinedError,
  OperationResult as UrqlOperationResult,
  createClient,
  errorExchange,
  fetchExchange,
  gql,
  useMutation,
  useQuery,
} from 'urql';
import { CHARLES_LOCALHOST } from 'xo/constants';
import logger from 'xo/logger';
import { emitSignOut } from 'xo/login/use-signout-listener';
import { isLocal } from 'xo/utils/build-utils';
import { isOfflineError } from 'xo/utils/offline-utils';
import { createStore } from 'zustand';
import { createStoreProvider } from '../store/store-factory';
import { getAuthExchangeConfig } from './exchanges/auth-exchange-config';
import { transformerExchange } from './exchanges/transformer-exchange';

// this should never be committed with "true"
const ENABLE_CHARLES_PROXY = false;

export type CombinedError = UrqlCombinedError;
export type OperationResult<TData> = UrqlOperationResult<TData, AnyVariables>;

export type UseUrqlClientProps = {
  // FIXME Share this implementation between mobile and web
  getRequestHeaders: () => Promise<Record<string, string>>;
  apiUrl?: string;
  onNotifyServerError: () => void;
  onCommitMismatch: () => void;
  onOfflineError?: () => void;
  cacheExchange: () => Exchange;
  modifyExchanges?: (exchanges: Exchange[]) => Exchange[];
  children: React.ReactNode;
  stage: string;
  alwaysNotifyOfflineErrors?: boolean;
};

export const getUrqlClient = ({
  apiUrl = '',
  getRequestHeaders,
  onCommitMismatch,
  onNotifyServerError,
  onOfflineError,
  modifyExchanges,
  cacheExchange,
  stage,
}: Omit<UseUrqlClientProps, 'children'>) => {
  const defaultExchanges: Exchange[] = [
    transformerExchange,
    cacheExchange(),
    authExchange(getAuthExchangeConfig(getRequestHeaders)),
    errorExchange({
      onError: onUrqlErrorHandler({
        apiUrl,
        onCommitMismatch,
        onNotifyServerError,
        onOfflineError,
        stage,
      }),
    }),
    fetchExchange,
  ];

  const defaultHost = `${apiUrl}/api/graphql`;

  return createClient({
    url: Platform.select({
      web: defaultHost,
      native:
        ENABLE_CHARLES_PROXY && isLocal(stage)
          ? `${CHARLES_LOCALHOST}/api/graphql`
          : defaultHost,
    })!,
    exchanges: modifyExchanges
      ? modifyExchanges(defaultExchanges)
      : defaultExchanges,
  });
};

interface UrqlClientStoreState {
  client: Client;
  clientProps: UseUrqlClientProps;
  createClient: (props: UseUrqlClientProps) => void;
  resetClient: () => void;
}

export const createClientStore = () =>
  createStore<UrqlClientStoreState>(set => ({
    client: null as any,
    clientProps: null as any,
    createClient: (props: UseUrqlClientProps) =>
      set({ client: getUrqlClient(props), clientProps: props }),
    resetClient: () =>
      set(state => ({ client: getUrqlClient(state.clientProps) })),
  }));

type UrqlClientStore = ReturnType<typeof createClientStore>;

const { Provider: UrqlClientProvider, useStore } = createStoreProvider<
  UrqlClientStoreState,
  UrqlClientStore
>(createClientStore);

// Separate out the urql client into its own provider higher in the tree so it can be reset (and cache cleared)
export const UrqlProvider = (props: UseUrqlClientProps) => {
  const { client, createClient } = useStore(state => state);
  useEffect(() => {
    if (!client) {
      createClient(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  if (!client) return null;

  return <Provider value={client}>{props.children}</Provider>;
};

export const useResetClient = (storage: { clear: () => Promise<any> }) => {
  const resetClient = useStore(state => state.resetClient);

  return async () => {
    try {
      await storage.clear();
    } catch (err) {
      if (
        !(err as Error)?.message.includes('Failed to delete storage directory')
      ) {
        throw err;
      }
    }
    resetClient();
  };
};

export const useClient = () => useStore(state => state.client);

export { UrqlClientProvider, gql, useMutation, useQuery };

export const onUrqlErrorHandler =
  ({
    onOfflineError,
    apiUrl,
    stage,
    onCommitMismatch,
    onNotifyServerError,
    alwaysNotifyOfflineErrors,
  }: Pick<
    UseUrqlClientProps,
    | 'onOfflineError'
    | 'apiUrl'
    | 'stage'
    | 'onCommitMismatch'
    | 'onNotifyServerError'
    | 'alwaysNotifyOfflineErrors'
  >) =>
  async (error: CombinedError, operation: Operation) => {
    // ignore offline errors on mobile for requests allowed to fetch from cache
    if (
      isOfflineError(error) &&
      onOfflineError &&
      !alwaysNotifyOfflineErrors &&
      (operation.context.originalRequestPolicy !== 'network-only' ||
        operation.kind === 'mutation')
    ) {
      return;
    }

    const isNotifiableOfflineError =
      isOfflineError(error) &&
      onOfflineError &&
      (operation.context.requestPolicy === 'network-only' ||
        alwaysNotifyOfflineErrors);

    if (
      !isNotifiableOfflineError &&
      !error.graphQLErrors?.length &&
      !Object.keys(error.networkError ?? {}).length &&
      isLocal(stage)
    ) {
      console.warn(`Is the API running at ${apiUrl}`);
    }

    const response: Response | undefined = error.response;

    // FIXME Ideally this would use the error "type", but the body has already been read so we can't use clone() or getReader()
    // may need a custom fetchExchange to access the body
    if (response?.status === 499) {
      // COMMIT_MISMATCH
      onCommitMismatch();
    } else if (response?.status === 401) {
      // UNAUTHORISED
      emitSignOut();
    } else if (isNotifiableOfflineError) {
      onOfflineError();
    } else {
      logger.error(error);

      // if it's the mobile app and a mutation, no need to pop a generic server error, since it'll be handled by other UI
      if (onOfflineError && operation.kind === 'mutation') {
        return;
      }

      onNotifyServerError();
    }
  };
