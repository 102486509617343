import React from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { useClipPathId } from 'xo/utils/svg-utils';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgSpray: React.FC<SvgIconProps> = props => {
  const { fill } = props;
  const { id, clipPath } = useClipPathId();

  return (
    <SvgIcon {...props}>
      <G clipPath={clipPath}>
        <Path
          d="M11.2574 20.0722C12.1559 20.0722 12.1559 21.42 11.2574 21.42C10.3589 21.42 10.3589 20.0722 11.2574 20.0722Z"
          fill={fill}
        />
        <Path
          d="M11.2574 17.6471C12.1559 17.6471 12.1559 18.9949 11.2574 18.9949C10.3589 18.9949 10.3589 17.6471 11.2574 17.6471Z"
          fill={fill}
        />
        <Path
          d="M11.2574 15.1762C12.1559 15.1762 12.1559 16.524 11.2574 16.524C10.3589 16.524 10.3589 15.1762 11.2574 15.1762Z"
          fill={fill}
        />
        <Path
          d="M11.2574 12.7487C12.1559 12.7487 12.1559 14.0966 11.2574 14.0966C10.3589 14.0966 10.3589 12.7487 11.2574 12.7487Z"
          fill={fill}
        />
        <Path
          d="M11.2574 10.3236C12.1559 10.3236 12.1559 11.6714 11.2574 11.6714C10.3589 11.6714 10.3589 10.3236 11.2574 10.3236Z"
          fill={fill}
        />
        <Path
          d="M11.2574 5.42502C12.1559 5.42502 12.1559 6.77283 11.2574 6.77283C10.3589 6.77283 10.3589 5.42502 11.2574 5.42502Z"
          fill={fill}
        />
        <Path
          d="M11.2574 2.99984C12.1559 2.99984 12.1559 4.34766 11.2574 4.34766C10.3589 4.34766 10.3589 2.99984 11.2574 2.99984Z"
          fill={fill}
        />
        <Path
          d="M6.18755 19.981C6.41921 19.114 7.7214 19.4615 7.48971 20.3302C7.25631 21.1972 5.95411 20.8497 6.18755 19.981Z"
          fill={fill}
        />
        <Path
          d="M6.83648 17.626C7.06813 16.7573 8.37033 17.1066 8.13689 17.9753C7.90523 18.8422 6.60304 18.493 6.83648 17.626Z"
          fill={fill}
        />
        <Path
          d="M7.44781 15.2672C7.67946 14.4003 8.98166 14.7478 8.74997 15.6165C8.51656 16.4852 7.21437 16.1359 7.44781 15.2672Z"
          fill={fill}
        />
        <Path
          d="M8.02984 12.8366C8.26149 11.9679 9.56369 12.3172 9.332 13.1841C9.09859 14.0528 7.7964 13.7035 8.02984 12.8366Z"
          fill={fill}
        />
        <Path
          d="M8.69696 10.5584C8.93037 9.6897 10.2308 10.039 9.99912 10.9059C9.76571 11.7746 8.46527 11.4253 8.69696 10.5584Z"
          fill={fill}
        />
        <Path
          d="M9.34976 8.20536C9.58317 7.3384 10.8854 7.68591 10.6519 8.55459C10.4203 9.42155 9.11807 9.07404 9.34976 8.20536Z"
          fill={fill}
        />
        <Path
          d="M2.1353 18.1224C2.58457 17.3432 3.75164 18.0171 3.30237 18.7963C2.85309 19.5738 1.68603 18.8999 2.1353 18.1224Z"
          fill={fill}
        />
        <Path
          d="M3.34965 16.0166C3.79892 15.2391 4.96599 15.913 4.51672 16.6905C4.06745 17.4679 2.90038 16.794 3.34965 16.0166Z"
          fill={fill}
        />
        <Path
          d="M4.56742 13.9068C5.01669 13.1293 6.18376 13.8033 5.73449 14.5807C5.28522 15.3599 4.11816 14.686 4.56742 13.9068Z"
          fill={fill}
        />
        <Path
          d="M5.78178 11.803C6.23105 11.0237 7.39812 11.6977 6.94885 12.4769C6.49958 13.2543 5.33251 12.5804 5.78178 11.803Z"
          fill={fill}
        />
        <Path
          d="M7.02446 9.65439C7.47372 8.87518 8.64079 9.54909 8.19152 10.3283C7.74225 11.1058 6.57519 10.4318 7.02446 9.65439Z"
          fill={fill}
        />
        <Path
          d="M-1.29048 15.2865C-0.65517 14.6512 0.297776 15.6042 -0.337536 16.2395C-0.972848 16.8748 -1.92579 15.9218 -1.29048 15.2865Z"
          fill={fill}
        />
        <Path
          d="M0.434616 13.5247C1.06993 12.8876 2.02288 13.8423 1.38756 14.4776C0.75225 15.1129 -0.200698 14.16 0.434616 13.5247Z"
          fill={fill}
        />
        <Path
          d="M2.15971 11.824C2.79503 11.1887 3.74797 12.1416 3.11266 12.7769C2.47735 13.4122 1.5244 12.4593 2.15971 11.824Z"
          fill={fill}
        />
        <Path
          d="M3.85356 10.0916C4.48887 9.45626 5.44182 10.4092 4.80651 11.0445C4.1712 11.6798 3.21825 10.7269 3.85356 10.0916"
          fill={fill}
        />
        <Path
          d="M5.60649 8.3633C6.2418 7.72798 7.19475 8.68094 6.55943 9.31624C5.92412 9.95155 4.97118 8.99859 5.60649 8.3633"
          fill={fill}
        />
        <Path
          d="M7.33012 6.65932C7.96544 6.02401 8.92014 6.97697 8.28307 7.61226C7.64776 8.24757 6.69481 7.29462 7.33012 6.65932"
          fill={fill}
        />
        <Path
          d="M9.05717 4.8954C9.69249 4.26009 10.6454 5.21305 10.0101 5.84834C9.37481 6.48366 8.42186 5.5307 9.05717 4.8954"
          fill={fill}
        />
        <Path
          d="M15.4851 19.5162C16.352 19.2828 16.7013 20.585 15.8326 20.8166C14.9656 21.05 14.6164 19.7478 15.4851 19.5162Z"
          fill={fill}
        />
        <Path
          d="M14.8621 17.1785C15.729 16.9451 16.0782 18.2473 15.2095 18.4807C14.3426 18.7123 13.9934 17.4102 14.8621 17.1785Z"
          fill={fill}
        />
        <Path
          d="M14.2268 14.7969C15.0938 14.5653 15.443 15.8657 14.5743 16.0991C13.7073 16.3307 13.3581 15.0303 14.2268 14.7969Z"
          fill={fill}
        />
        <Path
          d="M13.6024 12.4593C14.4711 12.2276 14.8203 13.5298 13.9516 13.7614C13.0846 13.9948 12.7354 12.6927 13.6024 12.4593Z"
          fill={fill}
        />
        <Path
          d="M12.9669 10.0795C13.8339 9.84613 14.1831 11.1483 13.3162 11.38C12.4474 11.6134 12.0982 10.3112 12.9669 10.0795Z"
          fill={fill}
        />
        <Path
          d="M12.3419 7.74197C13.2106 7.51032 13.5581 8.8125 12.6912 9.04414C11.8224 9.27755 11.4732 7.97537 12.3419 7.74197Z"
          fill={fill}
        />
        <Path
          d="M19.4529 17.8716C20.2303 17.4223 20.9042 18.5894 20.1268 19.0387C19.3476 19.4879 18.6737 18.3209 19.4529 17.8716Z"
          fill={fill}
        />
        <Path
          d="M18.2403 15.7582C19.0195 15.3089 19.6934 16.476 18.9142 16.9253C18.1368 17.3745 17.4628 16.2075 18.2403 15.7582Z"
          fill={fill}
        />
        <Path
          d="M17.026 13.6436C17.8052 13.1943 18.4791 14.3614 17.6999 14.8106C16.9224 15.2599 16.2485 14.0928 17.026 13.6436Z"
          fill={fill}
        />
        <Path
          d="M15.8136 11.5272C16.5928 11.078 17.2667 12.245 16.4875 12.6943C15.71 13.1436 15.0361 11.9765 15.8136 11.5272Z"
          fill={fill}
        />
        <Path
          d="M14.5606 9.43348C15.3398 8.98421 16.0137 10.1513 15.2345 10.6005C14.4571 11.0498 13.7832 9.88275 14.5606 9.43348Z"
          fill={fill}
        />
        <Path
          d="M22.8721 15.2692C23.5074 14.6321 24.4604 15.5868 23.8251 16.2221C23.1897 16.8574 22.2368 15.9045 22.8721 15.2692"
          fill={fill}
        />
        <Path
          d="M21.1084 13.542C21.7438 12.9067 22.6967 13.8597 22.0614 14.495C21.4261 15.1303 20.4731 14.1773 21.1084 13.542Z"
          fill={fill}
        />
        <Path
          d="M19.4078 11.8187C20.0431 11.1834 20.996 12.1364 20.3607 12.7717C19.7254 13.407 18.7724 12.454 19.4078 11.8187Z"
          fill={fill}
        />
        <Path
          d="M17.6734 10.1249C18.3087 9.48959 19.2616 10.4425 18.6263 11.0778C17.991 11.7131 17.0381 10.7602 17.6734 10.1249Z"
          fill={fill}
        />
        <Path
          d="M15.9454 8.37184C16.5807 7.73653 17.5336 8.68949 16.8983 9.32479C16.263 9.9601 15.31 9.00714 15.9454 8.37184Z"
          fill={fill}
        />
        <Path
          d="M14.2442 6.64675C14.8795 6.01144 15.8325 6.96439 15.1971 7.59969C14.5618 8.235 13.6089 7.28204 14.2442 6.64675Z"
          fill={fill}
        />
        <Path
          d="M12.4825 4.92519C13.1178 4.28987 14.0707 5.24283 13.4354 5.87813C12.8001 6.51344 11.8472 5.56048 12.4825 4.92519Z"
          fill={fill}
        />
      </G>
      <Defs>
        <ClipPath id={id}>
          <Rect width="24" height="24" fill={fill} />
        </ClipPath>
      </Defs>
    </SvgIcon>
  );
};
