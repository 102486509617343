import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgTick: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M9 16.2L4.8 12L3.4 13.4L9 19L21 7.00001L19.6 5.60001L9 16.2Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
