// Use local storage to store QR scan state in case they reload
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useIsKioskMode } from '../app/people-check-in/check-in-hooks';
import { LocalStorage } from '../app/shared/local-storage';

const localStorageVersion = '1';
const localStorageKey = LocalStorage.key('qr-scan', localStorageVersion);

type QrSignScanType = 'c' | 'q' | 'c-corrected' | 'kiosk';
type QrState = { signScanType?: QrSignScanType; timestamp?: Dayjs };

// and verify that the timestamp in the params is within an acceptable range
const ALLOWED_SCAN_TYPES: QrSignScanType[] = ['c', 'q', 'c-corrected'];
export const verifyQrQueryParams = ({
  timestamp,
  signScanType,
}: {
  timestamp?: Dayjs;
  signScanType?: string;
}) => {
  if (
    !dayjs.isDayjs(timestamp) ||
    !ALLOWED_SCAN_TYPES.includes(signScanType as any)
  ) {
    return {};
  }

  // Give them a buffer into the future in case their clock is off
  const futureBuffer = dayjs().add(10, 'minutes');
  // Allow half an hour to complete the questionnaire
  const pastBuffer = dayjs().subtract(30, 'minutes');
  if (!timestamp.isBetween(pastBuffer, futureBuffer, 'minutes')) {
    return {};
  }

  return {
    signScanType: signScanType as QrSignScanType,
    qrTimestamp: timestamp,
  };
};

export const initQrState = (signScanType?: QrSignScanType) =>
  LocalStorage.setItem(localStorageKey, {
    timestamp: dayjs(),
    signScanType,
  });

export const updateQrState = (signScanType: QrSignScanType) => {
  const existingItem = LocalStorage.getItem<QrState>(localStorageKey);
  // Only update the scan type if the state already existed
  if (existingItem) {
    existingItem.signScanType = signScanType;
    LocalStorage.setItem(localStorageKey, existingItem);
  }
};

const getQrState = (): QrState =>
  LocalStorage.getItem<QrState>(localStorageKey) ?? {};

export const useQrState = (): {
  qrTimestamp?: Dayjs;
  signScanType?: QrSignScanType;
} => {
  const { timestamp, signScanType } = useMemo(() => getQrState(), []);

  return verifyQrQueryParams({ timestamp, signScanType });
};

export const usePersistQrState = () => {
  const { state } = useLocation<{ signScanType?: QrSignScanType }>();
  const kioskMode = useIsKioskMode();
  useEffect(() => {
    if (kioskMode || state?.signScanType) {
      initQrState(kioskMode ? 'kiosk' : state.signScanType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
