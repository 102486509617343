import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgHouse: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <Path
        d="M29.3503 17.7501L16.0002 5.8499L2.6501 17.7501L1.3501 16.2501L16 3.1499L30.6499 16.2501L29.3503 17.7501Z"
        fill={fill}
      />
      <Path d="M16 7L5 17V29H13V21H19V29H27V17L16 7Z" fill={fill} />
    </SvgIcon>
  );
};
