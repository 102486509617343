import { Color } from 'xo/styles/restyle/theme';
import { colors } from 'xo/styles/tailwind-theme';

export const hexFromThemeColor = (color: Color): string => {
  const [root, number] = color.split('.');
  const rootColor = colors[root as keyof typeof colors];
  if (number) {
    return rootColor[number as keyof typeof rootColor];
  }
  return rootColor as string;
};
