import classNames from 'classnames';
import React from 'react';
import { OptionalButton } from '../components/optional-button';
import { pluralize } from '../utils';

export interface SettingsQuarantineNightsProps {
  className?: string;
  nights: number;
  type?: 'info' | 'error';
  onClick?: () => void;
}

export const quarantineNightsWrapperClassName =
  'flex items-center justify-center text-center h-6';

export const SettingsQuarantineNights: React.FC<
  SettingsQuarantineNightsProps
> = ({ className, nights, type = 'info', onClick }) => (
  <OptionalButton
    className={classNames(className, quarantineNightsWrapperClassName)}
    onClick={onClick}
  >
    <div
      className={classNames(
        'mr-1 min-h-4 min-w-4 rounded-sm px-0.5 font-extrabold',
        {
          'bg-blue-50 text-blue-600': type === 'info',
          'bg-red-700 text-white': type === 'error',
        },
      )}
    >
      {nights}
    </div>
    {pluralize(nights, 'nt')}
  </OptionalButton>
);
