import { AnimatePresence, MotiView } from 'moti';
import React from 'react';

export interface FadeEnterExitProps {
  visibleKey: string | boolean | number;
  children: React.ReactNode;
}

export const FadeEnterExit: React.FC<FadeEnterExitProps> = ({
  visibleKey,
  children,
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <MotiView
        key={String(visibleKey)}
        from={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </MotiView>
    </AnimatePresence>
  );
};
