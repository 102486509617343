import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { ApiSiteSummaryAdminFragment } from 'xo/graphql/api/site-summary-admin-fragment.generated';
import { Form, FormItem } from '../forms/form';
import { Input } from '../forms/input';
import { useUpdateSiteAdmin } from './admin-hooks';

export const AdminSiteAirtableIdInput: React.FC<{
  site: Pick<ApiSiteSummaryAdminFragment, 'airtableId' | 'id'>;
}> = ({ site }) => {
  const [form] = useForm();

  const { saving, onUpdateSiteAdmin } = useUpdateSiteAdmin();

  return (
    <Form form={form} initialValues={{ airtableId: site.airtableId }}>
      <FormItem noStyle name="airtableId">
        <Input
          size="middle"
          suffix={saving ? <LoadingOutlined spin /> : undefined}
          onBlur={e =>
            e.target.value !== site.airtableId &&
            onUpdateSiteAdmin({
              siteId: site.id,
              airtableId: e.target.value,
              form,
            })
          }
          disabled={saving}
        />
      </FormItem>
    </Form>
  );
};
