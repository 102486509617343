import { PropsWithChildren, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import logger from 'xo/logger';
import { createStoreProvider } from 'xo/store/store-factory';
import {
  WebViewStoreState as RNWebViewStoreState,
  WebViewAction,
  WebViewActionType,
  WebViewNavigateData,
} from 'xo/webview/webview-actions';
import { isRunningInWebView } from 'xo/webview/webview-events';
import { createStore } from 'zustand';

// Stores pieces of mobile state related to the webview that is shared with the web app and causes web app to re-render when updated
export interface WebViewStoreState extends RNWebViewStoreState {
  updateStore: (data: WebViewStoreState) => void;
}

export const createWebViewStore = () =>
  createStore<WebViewStoreState>(set => ({
    push: undefined,
    updateStore: data => set(data),
  }));

type WebViewStore = ReturnType<typeof createWebViewStore>;

const { Provider, useStore } = createStoreProvider<
  WebViewStoreState,
  WebViewStore
>(createWebViewStore);

export const useWebViewStore = useStore;

const WebViewStoreProviderContent = ({ children }: PropsWithChildren) => {
  const history = useHistory();
  const updateStore = useWebViewStore(state => state.updateStore);

  useEffect(() => {
    if (isRunningInWebView()) {
      const handler = (
        e: MessageEvent<
          WebViewAction<WebViewNavigateData | WebViewStoreState> | undefined
        >,
      ) => {
        const { type, data } = e.data ?? {};
        if (!type || !data) {
          logger.error(`received malformed webview message: ${e}`);
          return;
        }

        if (type === WebViewActionType.Navigate) {
          history.push((data as WebViewNavigateData).pathname);
        } else if (type === WebViewActionType.UpdateStore) {
          updateStore(data as WebViewStoreState);
        }
      };

      window.addEventListener('message', handler);

      return () => {
        window.removeEventListener('message', handler);
      };
    }
  }, [history, updateStore]);

  return children;
};

export const WebViewStoreProvider = ({ children }: PropsWithChildren) => (
  <Provider>
    <WebViewStoreProviderContent>{children}</WebViewStoreProviderContent>
  </Provider>
);
