import Fuse from 'fuse.js';
import { get, isString, partition } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Path } from 'react-hook-form';
import {
  OptionType,
  OptionTypeValue,
  SPECIAL_CASE_OPTION_VALUES,
} from './component-hooks';

export const useFuzzySearch = <T extends object>(
  items: readonly T[],
  keys: Path<T>[],
) => {
  const fuse = useMemo(() => {
    keys.forEach(key => {
      const example = items.find(i => get(i, key));
      if (example && !isString(get(example, key))) {
        console.warn(`fuzzy search configured on non-string key: ${key}`);
      }
    });

    return new Fuse(items, {
      keys,
      threshold: 0.2,
    });
  }, [items, keys]);

  return useCallback(
    (searchString: string) => fuse.search(searchString),
    [fuse],
  );
};

export interface UseFuzzySearchOptions<T extends OptionTypeValue> {
  // These options are always retained at the top of the list, regardless of search
  hoistValues?: OptionTypeValue[];
  options: OptionType<T>[];
  enabled?: boolean;
  searchString?: string;
  searchPaths?: Path<OptionType<T>>[];
}

export const useFuzzySearchFilteredOptions = <T extends OptionTypeValue>({
  searchString,
  options,
  searchPaths,
  enabled,
  hoistValues = SPECIAL_CASE_OPTION_VALUES,
}: UseFuzzySearchOptions<T>) => {
  const hoistSearchValueSet = useMemo(
    () => new Set(hoistValues),
    [hoistValues],
  );
  const [hoistedOptions, unhoistedOptions] = useMemo(
    () => partition(options, o => hoistSearchValueSet.has(o.value)),
    [options, hoistSearchValueSet],
  );
  const fuzzySearch = useFuzzySearch(
    unhoistedOptions,
    searchPaths ?? ['label'],
  );

  const filteredOptions = useMemo(() => {
    if (searchString && enabled) {
      const results = fuzzySearch(searchString);
      return hoistedOptions.concat(results.map(r => r.item));
    }

    return options;
  }, [options, searchString, enabled, fuzzySearch, hoistedOptions]);

  return filteredOptions;
};
