import { useMemo } from 'react';
import { useFuzzySearch } from 'xo/hooks/fuzzy-search-hooks';
import { SearchColumnProps, getTableSearchPaths } from './table-utils';

export const useFuzzySearchTableRows = <T extends object>({
  columns,
  data,
  searchString,
}: {
  searchString?: string;
  columns: SearchColumnProps<T>[];
  data: readonly T[];
}) => {
  const searchPaths = useMemo(() => getTableSearchPaths(columns), [columns]);

  const fuzzySearch = useFuzzySearch(data, searchPaths);
  if (searchString && searchPaths.length) {
    const results = fuzzySearch(searchString);
    return results.map(r => r.item);
  }
  return data;
};
