import {
  Cache,
  DataFields,
  Entity,
  ResolveInfo,
  Variables,
  cacheExchange,
} from '@urql/exchange-graphcache';
import { ApiCreateSiteMovementMatrixMutationVariables } from 'xo/graphql/api/create-site-movement-matrix-mutation.generated';
import { ApiResolver } from 'xo/graphql/constants/resolvers.generated';
import { TypeName } from 'xo/graphql/constants/typenames.generated';
import { sharedGraphCacheConfig } from './graphcache-config';

type ResolverNames = {
  create: ApiResolver;
  list: ApiResolver | string;
  /** defaults to the top-level Query */
  entity?: (args: Variables) => Entity;
};
const createListPairs: ResolverNames[] = [
  {
    create: ApiResolver.createAddressBookSite,
    list: ApiResolver.addressBookSitesList,
  },
  {
    create: ApiResolver.createAddressBookContact,
    list: ApiResolver.addressBookContactsList,
  },
  {
    create: ApiResolver.createRoadVehicle,
    list: ApiResolver.vehiclesList,
  },
  {
    create: ApiResolver.createSiteMovementMatrix,
    list: 'siteMovementMatrices',
    entity: args => ({
      __typename: TypeName.Organisation,
      id: (args as ApiCreateSiteMovementMatrixMutationVariables).input
        .organisationId,
    }),
  },
  {
    create: ApiResolver.createOutbreak,
    list: ApiResolver.outbreaksList,
  },
];

const createEntityListLinker =
  (resolverNames: ResolverNames) =>
  (result: DataFields, args: Variables, cache: Cache, _info: ResolveInfo) => {
    const entity = resolverNames.entity?.(args) ?? TypeName.Query;
    const items = cache.resolve(entity, resolverNames.list) as any[];
    if (Array.isArray(items) && result) {
      const newItem = result[resolverNames.create];
      items.push(newItem);
      cache.link(entity, resolverNames.list, items);
    }
  };

export const browserCacheExchange = () =>
  cacheExchange({
    ...sharedGraphCacheConfig,
    updates: {
      Mutation: {
        ...Object.fromEntries(
          createListPairs.map(resolverNames => [
            resolverNames.create,
            createEntityListLinker(resolverNames),
          ]),
        ),
      },
    },
  });
