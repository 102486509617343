// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiSiteBriefSummaryFragment = {
  __typename?: 'Site';
  id: string;
  name: string;
  shortCode: string;
};

export const SiteBriefSummaryFragmentDoc = gql`
  fragment SiteBriefSummary on Site {
    __typename
    id
    name
    shortCode
  }
`;
