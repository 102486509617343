import {
  InputNumber as AntInputNumber,
  InputNumberProps as AntInputNumberProps,
} from 'antd';
import classNames from 'classnames';
import React from 'react';

export interface InputNumberProps extends AntInputNumberProps {}

export const InputNumber: React.FC<InputNumberProps> = props => (
  <AntInputNumber
    {...props}
    className={classNames(props.className, 'xo-ant-input')}
  />
);
