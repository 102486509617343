import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { ApiProvider } from 'xo/graphql/api/enums/provider.generated';
import { ApiPositionFragment } from 'xo/graphql/api/position-fragment.generated';
import { ApiStreetAddressFragment } from 'xo/graphql/api/street-address-fragment.generated';
import { SvgLocation } from 'xo/svg/svg-location';
import { env } from '../env';
import { Input } from '../forms/input';
import './address-autocomplete.overrides.css';

const googleApiKey = env.REACT_APP_GOOGLE_API_KEY!;

// this is slightly different to the one used for backfilling in the backend, so we can distinguish them
const FIXED_PRECISE_LOCATION_UNCERTAINTY = 50.0987654321;

export interface AddressAutoCompleteProps {
  className?: string;
  onSelect: (props: {
    address?: ApiStreetAddressFragment;
    position?: ApiPositionFragment;
  }) => void;
  disabled?: boolean;
  type?: 'suburb' | 'address';
}

export const AddressAutoComplete = ({
  className,
  disabled,
  onSelect,
  type,
}: AddressAutoCompleteProps) => {
  const [googleLib, setGoogleLib] = useState<typeof google.maps.places>();
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    new Loader({
      apiKey: googleApiKey,
      version: 'weekly',
    })
      .importLibrary('places')
      .then((googleLib: typeof google.maps.places) => setGoogleLib(googleLib))
      .catch(console.error);
  }, []);

  const onCleanUp = () => {
    autoCompleteRef.current?.unbindAll();
    document.querySelectorAll('.pac-container')?.forEach(e => e.remove());
  };

  useEffect(() => onCleanUp, []);

  useEffect(() => {
    if (inputRef.current && googleLib) {
      const options: google.maps.places.AutocompleteOptions = {
        componentRestrictions: { country: ['au', 'fj'] },
        fields: ['address_components', 'geometry'],
        types: type === 'suburb' ? ['postal_code', 'locality'] : ['address'],
      };

      autoCompleteRef.current = new googleLib.Autocomplete(
        inputRef.current,
        options,
      );

      autoCompleteRef.current.addListener('place_changed', () => {
        const result = autoCompleteRef.current?.getPlace();
        const addressComponent = (
          type: string,
          property: Exclude<
            keyof google.maps.GeocoderAddressComponent,
            'types'
          > = 'long_name',
        ) => {
          const component = result?.address_components?.find(c =>
            c.types.includes(type),
          );
          return component ? component[property] : undefined;
        };

        const position: ApiPositionFragment | undefined = result?.geometry
          ?.location
          ? {
              lat: result?.geometry?.location?.lat(),
              lng: result?.geometry?.location?.lng(),
              provider: ApiProvider.GoogleMaps,
              // locations derived from this autocomplete will generally be fairly precise (e.g. it
              // doesn't autocomplete "Sydney" to the whole city, and similarly for states etc.). It
              // does autocomplete whole roads, i.e. no street number, but determining the details are hard.
              //
              // FIXME: do some a more accurate estimate of uncertainty based on the returned
              // geometry (see geocode.py for inspiration)
              accLatlng: FIXED_PRECISE_LOCATION_UNCERTAINTY,
            }
          : undefined;

        const unitNumber = addressComponent('subpremise');
        const streetNumber = addressComponent('street_number');
        const street = addressComponent('route');

        const address: ApiStreetAddressFragment | undefined =
          result?.address_components
            ? {
                line1:
                  streetNumber || street
                    ? `${unitNumber ? `${unitNumber}/` : ''}${
                        streetNumber ?? ''
                      } ${street ?? ''}`.trim()
                    : undefined,
                line2: undefined,
                suburb: addressComponent('locality'),
                postcode: addressComponent('postal_code'),
                state: addressComponent(
                  'administrative_area_level_1',
                  'short_name',
                ),
                countryCode: addressComponent('country', 'short_name'),
              }
            : undefined;

        onSelect({ address, position });
      });
    }

    return onCleanUp;
  }, [inputRef, googleLib, onSelect, type]);

  const placeholder = `Enter ${
    type === 'suburb' ? 'suburb or postcode' : 'address'
  }`;

  return (
    <div className={className}>
      <Input
        prefix={<SvgLocation />}
        disabled={!googleLib || disabled}
        placeholder={!googleLib ? 'Loading...' : placeholder}
        inputRef={r => {
          inputRef.current = r;
        }}
      />
    </div>
  );
};
