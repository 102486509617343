import { Overlay } from '@rneui/themed';
import { isString } from 'lodash';
import React from 'react';
import { Box, Text, VStack } from 'xo/core';
import { borderRadii } from 'xo/styles/restyle/border-radii';
import { spacing } from 'xo/styles/restyle/spacing';
import { SvgCross } from 'xo/svg/svg-cross';
import { Button, ButtonVariant } from './button';
import { IconButton } from './icon-button';

export interface ModalButtonProps {
  variant?: ButtonVariant;
  label: string;
  onPress: () => void;
  testID?: string;
}

export interface ModalProps {
  header: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  closeable?: boolean;
  visible: boolean;
  onClose?: () => void;
  okProps?: ModalButtonProps;
  cancelProps?: ModalButtonProps;
  onCancel?: () => void;
}

export const Modal = ({
  header,
  children,
  footer,
  closeable = true,
  visible,
  okProps,
  cancelProps,
  onClose,
}: ModalProps) => {
  return (
    <Overlay
      isVisible={visible}
      onBackdropPress={closeable ? onClose : undefined}
      overlayStyle={{
        padding: spacing['6'],
        borderRadius: borderRadii['2xl'],
        margin: spacing['4'],
        paddingTop: closeable ? spacing['12'] : spacing['6'],
      }}
    >
      <Box position="absolute" right={0} top={0} m="2">
        {closeable && onClose ? (
          <IconButton icon={<SvgCross />} onPress={onClose} />
        ) : null}
      </Box>

      <VStack space="6" align="center" justify="center" w="full">
        <Text
          fontWeight="600"
          color="brandGrey.600"
          fontSize="2xl"
          textAlign="center"
        >
          {header}
        </Text>

        {isString(children) ? (
          <Text fontSize="lg" textAlign="center">
            {children}
          </Text>
        ) : (
          children
        )}

        {okProps && (
          <Box w="full">
            <Button
              w="full"
              variant={okProps.variant ?? 'brand'}
              onPress={okProps.onPress}
              testID={okProps.testID}
            >
              {okProps.label}
            </Button>
          </Box>
        )}
        {cancelProps && (
          <Box w="full">
            <Button
              w="full"
              variant={cancelProps.variant ?? 'brand-ghost'}
              onPress={cancelProps.onPress}
              testID={cancelProps.testID}
            >
              {cancelProps.label}
            </Button>
          </Box>
        )}

        {footer}
      </VStack>
    </Overlay>
  );
};
