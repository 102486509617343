import { ApiFindOrganisationInput } from 'xo/graphql/api/inputs/find-organisation-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
import {
  ApiUserAdminFragment,
  UserAdminFragmentDoc,
} from './user-admin-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiFindOrganisationQueryVariables = Exact<{
  input: ApiFindOrganisationInput;
}>;

export type ApiFindOrganisationQuery = {
  __typename?: 'Query';
  admin: {
    __typename?: 'Admin';
    findOrganisation: {
      __typename?: 'FindOrganisation';
      organisation?: {
        __typename?: 'Organisation';
      } & ApiOrganisationSummaryFragment;
      supportUser?: { __typename?: 'User' } & ApiUserAdminFragment;
    };
  };
};

export const FindOrganisationDocument = gql`
  query FindOrganisation($input: FindOrganisationInput!) {
    __typename
    admin {
      __typename
      findOrganisation(input: $input) {
        __typename
        organisation {
          ...OrganisationSummary
        }
        supportUser {
          ...UserAdmin
        }
      }
    }
  }
  ${OrganisationSummaryFragmentDoc}
  ${UserAdminFragmentDoc}
`;

export function useFindOrganisationQuery(
  options: Omit<Urql.UseQueryArgs<ApiFindOrganisationQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ApiFindOrganisationQuery,
    ApiFindOrganisationQueryVariables
  >({ query: FindOrganisationDocument, ...options });
}
