import { useMemo } from 'react';

export const useModelMap = <T extends { id: string }>(
  items: T[] | undefined,
  key?: keyof T,
) =>
  useMemo(
    () => new Map(items?.map(i => [i[key ?? 'id'] as string, i])),
    [items, key],
  );
