import { Divider } from 'antd';
import { SUPPORT_EMAIL, SUPPORT_PHONE, USER_GUIDE_URL } from 'xo/constants';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgExternalLink } from 'xo/svg/svg-external-link';
import { formatEmailHref } from 'xo/utils/link-utils';
import { info } from '../components/modal';
import { TelLink } from '../components/tel-link';

export const openSupportModal = () =>
  info({
    content: (
      <div className="flex flex-col items-center text-lg">
        <h2 className="mb-4 text-xl font-bold text-blue-600">
          Need some help?
        </h2>
        <p className="mb-4 text-center">You can either:</p>
        <p className="flex items-center">
          Read our{' '}
          <a
            className="ml-1 text-blue-400 underline"
            href={USER_GUIDE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            user guide
          </a>
          <span className="ml-2">
            <SvgExternalLink fill={colors.blue[400]} />
          </span>
        </p>
        <Divider>OR</Divider>
        <p>
          Email us at{' '}
          <a className="text-blue-400" href={formatEmailHref(SUPPORT_EMAIL)}>
            {SUPPORT_EMAIL}
          </a>
        </p>
        <Divider>OR</Divider>
        <p>
          Call us on <TelLink phoneNumber={SUPPORT_PHONE} />
        </p>
        <p>between 9am and 5pm weekdays.</p>
      </div>
    ),
  });
