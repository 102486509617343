import React from 'react';

export interface OptionalButtonProps {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  id?: string;
}

export const OptionalButton: React.FC<OptionalButtonProps> = ({
  onClick,
  disabled,
  ...rest
}) =>
  onClick ? (
    <button onClick={onClick} disabled={disabled} {...rest} />
  ) : (
    <div {...rest} />
  );
