import { isNil } from 'lodash';
import { downloadBlob } from './file-download-utils';

//https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side#answer-68146412
const arraysToCsv = (data: unknown[][]) =>
  data
    .map(
      row =>
        row
          .map((v: any) => (isNil(v) ? '' : String(v))) // convert every value to String
          .map((v: string) => v.replaceAll('"', '""')) // escape double colons
          .map((v: string) => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n'); // rows starting on new lines

export const downloadCsv = ({
  data,
  headers,
  filename,
}: {
  data: any[];
  headers: string[];
  filename: string;
}) => {
  if (data.length > 0) {
    const csv = arraysToCsv([headers].concat(data));

    downloadBlob({
      content: csv,
      filename: filename.endsWith('.csv') ? filename : `${filename}.csv`,
      contentType: 'text/csv;charset=utf-8;',
    });
  }
};
