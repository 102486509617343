import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { isEqual } from 'lodash';
import React from 'react';
import { ApiSiteActiveStatus } from 'xo/graphql/api/enums/site-active-status.generated';
import { ApiSiteSummaryAdminFragment } from 'xo/graphql/api/site-summary-admin-fragment.generated';
import { Select } from '../components/select';
import { Form, FormItem, FormUpdater } from '../forms/form';
import { useUpdateSiteAdmin } from './admin-hooks';

export interface AdminSiteActiveStatusSelectProps {
  site: Pick<ApiSiteSummaryAdminFragment, 'activeStatus' | 'id'>;
}

export const AdminSiteActiveStatusSelect: React.FC<
  AdminSiteActiveStatusSelectProps
> = ({ site }) => {
  const [form] = useForm();

  const { saving, onUpdateSiteAdmin } = useUpdateSiteAdmin();

  const onChange = (form: FormInstance) => {
    const newActiveStatus = form.getFieldValue('activeStatus');
    if (!isEqual(newActiveStatus, site.activeStatus)) {
      onUpdateSiteAdmin({
        siteId: site.id,
        activeStatus: newActiveStatus,
        form,
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        activeStatus: site.activeStatus,
      }}
    >
      <FormUpdater>
        {form => (
          <FormItem noStyle name="activeStatus">
            <Select
              options={Object.entries(ApiSiteActiveStatus).map(
                ([label, value]) => ({ label, value }),
              )}
              suffixIcon={saving ? <LoadingOutlined /> : undefined}
              size="middle"
              onBlur={() => onChange(form)}
              onDeselect={() => onChange(form)}
              disabled={saving}
            />
          </FormItem>
        )}
      </FormUpdater>
    </Form>
  );
};
