import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgWarning: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
