import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgRadioUnchecked: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20V20Z"
        fill={fill}
      ></Path>
    </SvgIcon>
  );
};
