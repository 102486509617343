import { Box, BoxProps } from './box';

export type CenterProps = BoxProps;

/**
 * @deprecated Use Box instead
 */
export const Center = (props: CenterProps) => (
  <Box flex={1} alignItems="center" justifyContent="center" {...props} />
);
