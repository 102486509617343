import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useContext } from 'react';
import { useIsXoAdmin } from '../../hooks/shared-hooks';
import { STAGE } from '../../utils/env-utils';
import { UserContext } from '../account/user-provider';
import { Button } from './button';
import { TooltipButton } from './tooltip-button';

export const StageTag = ({ className }: { className?: string }) => {
  const { xoUIHidden, onSetXoUIHidden } = useContext(UserContext);
  const isAdmin = useIsXoAdmin();

  if (xoUIHidden) return null;

  if (STAGE === 'PROD' && !isAdmin) return null;

  return (
    <Tooltip
      trigger={['click', 'hover']}
      overlay={
        <div className="flex flex-col items-center">
          <Button type="warning" onClick={onSetXoUIHidden}>
            Hide
          </Button>
          <p className="text-center text-sm">
            Hides environment and admin UI until you refresh the page
          </p>
        </div>
      }
    >
      <TooltipButton className={classNames('bg-grey-500', className)}>
        {STAGE}
      </TooltipButton>
    </Tooltip>
  );
};
