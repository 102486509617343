import { ApiModule } from 'xo/graphql/api/enums/module.generated';

import gql from 'graphql-tag';
export type ApiOrganisationBriefSummaryFragment = {
  __typename?: 'Organisation';
  id: string;
  name: string;
  shortCode: string;
  isTest: boolean;
  modules: Array<ApiModule>;
  features: Array<string>;
  phone?: string;
};

export const OrganisationBriefSummaryFragmentDoc = gql`
  fragment OrganisationBriefSummary on Organisation {
    __typename
    id
    name
    shortCode
    isTest
    modules
    features
    phone
  }
`;
