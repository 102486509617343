import { ApiUpdateSiteAdminInput } from 'xo/graphql/api/inputs/update-site-admin-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiUpdateSiteAdminMutationVariables = Exact<{
  input: ApiUpdateSiteAdminInput;
}>;

export type ApiUpdateSiteAdminMutation = {
  __typename?: 'Mutation';
  updateSiteAdmin: { __typename?: 'Site' } & ApiSiteFragment;
};

export const UpdateSiteAdminDocument = gql`
  mutation UpdateSiteAdmin($input: UpdateSiteAdminInput!) {
    __typename
    updateSiteAdmin(input: $input) {
      ...Site
    }
  }
  ${SiteFragmentDoc}
`;

export function useUpdateSiteAdminMutation() {
  return Urql.useMutation<
    ApiUpdateSiteAdminMutation,
    ApiUpdateSiteAdminMutationVariables
  >(UpdateSiteAdminDocument);
}
