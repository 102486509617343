import { ApiUpdateTripEventInput } from 'xo/graphql/api/inputs/update-trip-event-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiTripEventFragment,
  TripEventFragmentDoc,
} from './trip-event-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiUpdateTripEventMutationVariables = Exact<{
  input: ApiUpdateTripEventInput;
}>;

export type ApiUpdateTripEventMutation = {
  __typename?: 'Mutation';
  updateTripEvent: { __typename?: 'TripEvent' } & ApiTripEventFragment;
};

export const UpdateTripEventDocument = gql`
  mutation UpdateTripEvent($input: UpdateTripEventInput!) {
    __typename
    updateTripEvent(input: $input) {
      ...TripEvent
    }
  }
  ${TripEventFragmentDoc}
`;

export function useUpdateTripEventMutation() {
  return Urql.useMutation<
    ApiUpdateTripEventMutation,
    ApiUpdateTripEventMutationVariables
  >(UpdateTripEventDocument);
}
