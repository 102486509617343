import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgDroplets: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M16.2629 4.9882C15.9132 4.38633 15.2701 4.01694 14.5754 4.01694C13.8798 4.01694 13.2376 4.38633 12.8879 4.9882L8.41694 12.733C7.78788 13.8121 7.45601 15.0393 7.45601 16.2879C7.45601 17.5376 7.78788 18.7647 8.41694 19.8437C9.03569 20.9293 9.93194 21.8312 11.0137 22.4566C12.0966 23.0819 13.3256 23.4072 14.5753 23.4007C16.2497 23.4053 17.8715 22.8194 19.155 21.7441C20.4385 20.6697 21.3 19.1754 21.5898 17.5263C21.8786 15.8782 21.5758 14.1795 20.7339 12.7328L16.2629 4.9882ZM19.3829 19.0666C18.3629 20.726 16.547 21.7301 14.5987 21.7121C12.6506 21.6943 10.8542 20.6565 9.8652 18.9783C8.87614 17.2992 8.83958 15.2254 9.7677 13.5128L14.2387 5.76052C14.308 5.63958 14.4374 5.56552 14.5761 5.56552C14.7158 5.56552 14.8443 5.63958 14.9136 5.76052L19.3846 13.5128C19.8777 14.3546 20.1374 15.3137 20.1374 16.2896C20.1374 17.2654 19.8777 18.2236 19.3846 19.0664L19.3829 19.0666ZM7.5384 2.16076C7.39871 2.15888 7.26934 2.23388 7.19995 2.35482L4.36123 7.2729C3.85123 8.14384 3.72843 9.18728 4.02279 10.1529C4.31623 11.1316 5.00246 11.9454 5.9184 12.3992C6.24466 12.5585 6.41808 12.9223 6.33559 13.2757C6.25403 13.6301 5.93811 13.8804 5.57527 13.8795C5.45621 13.8804 5.33809 13.8523 5.23215 13.7979C3.92902 13.1529 2.95121 11.9979 2.52927 10.6066C2.10646 9.22661 2.27988 7.73309 3.00927 6.48821L5.85086 1.57469C6.19961 0.972818 6.84274 0.601562 7.53835 0.601562C8.23397 0.601562 8.8771 0.972818 9.22584 1.57469L10.9058 4.48853C11.1243 4.86072 10.9996 5.34072 10.6274 5.55915C10.2543 5.77758 9.7752 5.6529 9.55678 5.2807L7.87678 2.36686C7.81209 2.2403 7.68084 2.16061 7.53833 2.16061L7.5384 2.16076ZM18.3384 16.1746C18.3375 17.1993 17.9306 18.1818 17.2059 18.9065C16.4812 19.6321 15.4996 20.04 14.474 20.0409C14.0436 20.0409 13.694 19.6922 13.694 19.2609C13.694 18.8296 14.0436 18.4809 14.474 18.4809C15.7471 18.48 16.7793 17.4478 16.7812 16.1746C16.8037 15.7612 17.1458 15.4378 17.5593 15.4378C17.9737 15.4378 18.3149 15.7612 18.3384 16.1746L18.3384 16.1746Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
