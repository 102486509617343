import classNames from 'classnames';
import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgErrorOutline } from 'xo/svg/svg-error-outline';
import { SvgInfo } from 'xo/svg/svg-info';
import { SvgTick } from 'xo/svg/svg-tick';
import { IconText } from './icon-text';

export interface StatusMessageProps {
  type: 'success' | 'error' | 'info';
  className?: string;
  bg?: boolean;
  children?: React.ReactNode;
}

export const StatusMessage: React.FC<StatusMessageProps> = ({
  type,
  className,
  children,
  bg = true,
}) => (
  <div
    className={classNames(className, 'rounded', {
      'p-2': bg,
      'py-2': !bg,
      'text-green-700': type === 'success',
      'text-red-700': type === 'error',
      'text-blue-600': type === 'info',
      'bg-green-100': type === 'success' && bg,
      'bg-red-100': type === 'error' && bg,
      'bg-blue-50': type === 'info' && bg,
    })}
  >
    <IconText
      icon={
        type === 'success' ? (
          <SvgTick fill={colors.green[700]} />
        ) : type === 'error' ? (
          <SvgErrorOutline fill={colors.red[700]} />
        ) : (
          <SvgInfo fill={colors.blue[400]} />
        )
      }
      wrap={true}
    >
      <span className="text-base">{children}</span>
    </IconText>
  </div>
);
