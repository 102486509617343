// Mock these exports from react-native-elements so we don't import icons and problematic files we're not using
export const AirbnbRating = {};
export const Rating = {};
export const Octicons = {};
export const FontAwesome5 = {};
export const Feather = {};
export const SimpleLineIcons = {};
export const AntDesign = {};
export const MaterialIcons = {};
export const MaterialCommunityIcons = {};
export const Zocial = {};
export const FontAwesome = {};
export const Foundation = {};
export const EvilIcons = {};
export const Entypo = {};
export const Fontisto = {};
export const Ionicons = {};
