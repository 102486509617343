import { Skeleton as MotiSkeleton } from 'moti/skeleton';
import { ReactChild } from 'react';
import { spacing } from 'xo/styles/restyle/spacing';
import { Spacing } from 'xo/styles/restyle/theme';

export interface SkeletonProps {
  children?: ReactChild | null | undefined;
  w?: Spacing;
  h?: Spacing;
  show?: boolean;
}

export const Skeleton = ({ w, h, show, children }: SkeletonProps) => (
  <MotiSkeleton
    colorMode="light"
    width={w ? (spacing[w] as any) : undefined}
    height={h ? (spacing[h] as any) : undefined}
    show={show}
  >
    {children}
  </MotiSkeleton>
);
