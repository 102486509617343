import { usePrevious } from '@mantine/hooks';
import {
  Table as AntTable,
  TableColumnProps as AntTableColumnProps,
  TableProps as AntTableProps,
} from 'antd';
import { SortOrder, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import type { Path } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Button } from 'xo/components/button';
import { Box, HStack } from 'xo/core';
import { SvgSearch } from 'xo/svg/svg-search';
import { useQueryParamState, useSearchParams } from '../../hooks/route-hooks';
import { useIsXoAdmin } from '../../hooks/shared-hooks';
import { Input } from '../forms/input';
import { capitalize } from '../utils';
import { downloadCsv } from '../utils/csv-utils';
import { usePaginationQueryParams } from './pagination-hooks';
import { useFuzzySearchTableRows } from './table-hooks';
import { getSearchTooltip } from './table-utils';
import './table.overrides.css';

export const getStringSorter = <T extends {}>(
  field: (e: T) => string | undefined,
) => ({
  compare: (a: T, b: T) =>
    String(field(a) ?? '').localeCompare(String(field(b) ?? '')),
});

export const getNumberSorter = <T extends {}>(
  field: (e: T) => number | undefined,
) => ({
  compare: (a: T, b: T) => (field(a) || 0) - (field(b) || 0),
});

const sortDates = (
  undefinedPosition: 'smallest' | 'largest',
  a?: Dayjs,
  b?: Dayjs,
) => {
  if (!a && !b) return 0;
  if (!a && b) return undefinedPosition === 'largest' ? 1 : -1;
  if (!b && a) return undefinedPosition === 'largest' ? -1 : 1;
  return a!.isAfter(b!) ? 1 : -1;
};

export const getDateSorter = <T extends {}>(
  field: keyof T | ((e: T) => Dayjs | undefined),
  opts?: { undefinedPosition?: 'smallest' | 'largest' },
) => {
  const lookup =
    typeof field === 'function' ? field : (e: T) => e[field] as any;
  return {
    compare: (a: T, b: T) =>
      sortDates(opts?.undefinedPosition ?? 'largest', lookup(a), lookup(b)),
  };
};

export const Column = AntTable.Column;
export type TableColumnProps<T> = AntTableColumnProps<T> & {
  suppressClick?: boolean;
  admin?: boolean;
  hideOnPrint?: boolean;
  // true defaults to using path specified by dataIndex
  search?: true | Path<T> | Path<T>[];
  searchTitle?: string;
};

export interface TableCsvExportProps<T> {
  filename: string;
  transformer: (data: T[]) => any[][];
  headers: string[];
}

export enum RowHighlightType {
  Selected = 'xo-selected-row',
  Generic = 'xo-table-highlighted-row',
}

export interface TableProps<T> extends AntTableProps<T> {
  striped?: boolean;
  columns: TableColumnProps<T>[];
  queryParamPrefix?: boolean;
  rowRoute?: (item: T) => string;
  rowRouteIncludeSearch?: boolean;
  overflowX?: boolean;
  defaultSortField?: keyof T;
  defaultSortDirection?: 'ascend' | 'descend';
  allowOverflow?: boolean;
  exportCsv?: TableCsvExportProps<T> | TableCsvExportProps<T>[];
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
  rowClassName?: (item: T) => RowHighlightType | string;
}

export const Table = <T extends {}>({
  striped = true,
  columns,
  queryParamPrefix,
  rowRoute,
  rowRouteIncludeSearch,
  className,
  defaultSortField = 'date' as keyof T | undefined,
  defaultSortDirection = 'descend',
  allowOverflow,
  exportCsv,
  dataSource,
  headerRight,
  headerLeft,
  ...rest
}: TableProps<T>) => {
  const isXoAdmin = useIsXoAdmin();

  const getParamName = useCallback(
    (name: string) =>
      `${queryParamPrefix ?? ''}${queryParamPrefix ? capitalize(name) : name}`,
    [queryParamPrefix],
  );

  const { currentPage, pageSize, onPaginationChange } =
    usePaginationQueryParams({
      currentPageName: getParamName('page'),
      pageSizeName: getParamName('pageSize'),
      defaultPageSize: rest.pagination
        ? rest.pagination.defaultPageSize
        : undefined,
    });

  const [sortField, setSortField] = useQueryParamState<string>(
    getParamName('sortField'),
    defaultSortField as string,
  );
  const [sortDirection, setSortDirection] = useQueryParamState<string>(
    getParamName('sortDirection'),
    'descend',
  );
  const onTableChange = useCallback(
    (_p: any, _f: any, sorter: SorterResult<T> | SorterResult<T>[]) => {
      if (sorter && !Array.isArray(sorter)) {
        const sortDirection = sorter.order ? String(sorter.order) : undefined;

        setSortField(
          sorter.columnKey && sortDirection
            ? String(sorter.columnKey)
            : undefined,
        );
        setSortDirection(sortDirection);
      }
    },
    [setSortField, setSortDirection],
  );

  const history = useHistory();
  const [searchParams, setSearchParams] = useSearchParams();
  const onCell = useCallback(
    (item: T) => ({
      onClick: rowRoute
        ? () => {
            history.push({
              ...history.location,
              pathname: rowRoute(item),
            });
            // React Router remounts entire tree with this, so only do if set
            if (rowRouteIncludeSearch) {
              setSearchParams(searchParams);
            }

            return item;
          }
        : undefined,
    }),
    [history, rowRoute, setSearchParams, searchParams, rowRouteIncludeSearch],
  );

  const isPrint = useMediaQuery({ print: true });

  const onExport = () => {
    if (dataSource && exportCsv) {
      const configs = Array.isArray(exportCsv) ? exportCsv : [exportCsv];

      configs.forEach((config, i) =>
        // Safari doesnt like multiple concurrent downloads, so stagger them
        setTimeout(
          () =>
            downloadCsv({
              // any[] to get around readonly TS error
              data: config.transformer(dataSource as any[]),
              ...config,
            }),
          i * 1000,
        ),
      );
    }
  };

  const [searchString, setSearchString] = useQueryParamState<string>(
    getParamName('search'),
  );
  const data: readonly T[] = useMemo(() => dataSource ?? [], [dataSource]);
  const prevColumns = usePrevious(columns);
  // memoise columns here so consumers don't have to
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoColumns = useMemo(() => columns, [isEqual(columns, prevColumns)]);
  const filteredRows = useFuzzySearchTableRows({
    columns: memoColumns,
    data,
    searchString,
  });
  const searchTooltip = getSearchTooltip(columns);

  return (
    <>
      <HStack space="2" mb="4">
        {headerLeft}

        {searchTooltip && (
          <Box flex={1}>
            <Input
              prefix={<SvgSearch />}
              placeholder={searchTooltip}
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
            />
          </Box>
        )}

        {exportCsv && (
          <Button onPress={onExport} disabled={!dataSource?.length} size="md">
            Export
          </Button>
        )}

        {headerRight}
      </HStack>

      <div
        className={classNames({
          'overflow-x-auto': !isPrint && !rest.scroll?.x && !allowOverflow,
        })}
      >
        <AntTable
          className={classNames('xo-table', className, {
            'xo-table-striped': striped && !isPrint,
            'xo-table-clickable': rowRoute,
          })}
          pagination={{
            current: currentPage,
            onChange: onPaginationChange,
            pageSize,
            showSizeChanger: true,
          }}
          onChange={onTableChange}
          bordered={true}
          rowKey={item => (item as any).id}
          dataSource={filteredRows}
          {...rest}
        >
          {columns
            .filter(column => !column.admin || isXoAdmin)
            .filter(column => !column.hideOnPrint || !isPrint)
            .map(column => (
              <Column
                key={column.dataIndex as string}
                defaultSortOrder={
                  sortDirection && sortField === column.dataIndex
                    ? (sortDirection as SortOrder)
                    : undefined
                }
                onCell={!column.suppressClick && rowRoute ? onCell : undefined}
                width={`${100 / columns.length}%`}
                {...column}
              />
            ))}
        </AntTable>
      </div>
    </>
  );
};
