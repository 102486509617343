import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { toId } from 'xo/utils/string-utils';
import { useIsKioskMode } from '../people-check-in/check-in-hooks';
import { SystemConfigContext } from '../shared/system-config-provider';
import { AppHeader } from './app-header';
import { Container } from './container';
import { Footer, FooterProps } from './footer';
import { FooterButtonsProps } from './footer-buttons';
import NavBar, { NavBarProps } from './navbar';
import { PageTitle } from './page-title';

export type PageConfigPageTitle = {
  text: string;
  color?: 'black' | 'primary' | 'dark';
  titleTagOnly?: boolean;
  className?: string;
};

export interface PageConfig {
  navbar?: Omit<NavBarProps, 'onSignOut'>;
  pageTitle?: PageConfigPageTitle;
  footer?: FooterProps & FooterButtonsProps;
  noFooter?: boolean;
  noPadding?: boolean;
}

export interface PageContextProps {
  onPageConfig: (config: PageConfig) => void;
  page: PageConfig;
}

export const PageContext = React.createContext<PageContextProps>(null as any);

export const PageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [page, onPageConfig] = useState<PageConfig>({});

  const { navbar, footer, pageTitle, noFooter, noPadding } = page;

  // FIXME Better way of identifying unauthed?
  const kioskMode = useIsKioskMode();

  const systemConfig = useContext(SystemConfigContext);

  return (
    <PageContext.Provider value={{ onPageConfig, page }}>
      <div id="page-provider">
        <PageTitle title={pageTitle?.text ?? navbar?.title} />

        {navbar && kioskMode ? (
          <AppHeader
            {...navbar}
            onClick={navbar.onBack}
            back={!!(navbar.onBack || navbar.backTo)}
            predictedReleaseTime={systemConfig?.predictedNextReleaseTimestampTz}
          />
        ) : navbar ? (
          <NavBar
            {...navbar}
            predictedReleaseTime={systemConfig?.predictedNextReleaseTimestampTz}
          />
        ) : undefined}

        {pageTitle && !pageTitle.titleTagOnly ? (
          <Container>
            <h1
              id={toId(pageTitle?.text)}
              className={classNames(
                'text-2xl font-normal',
                pageTitle.className,
                {
                  'text-blue-400':
                    pageTitle.color === 'primary' || !pageTitle.color,
                  'text-blue-600': pageTitle.color === 'dark',
                  'text-black': pageTitle.color === 'black',
                  'mt-4': navbar && kioskMode,
                },
              )}
            >
              {pageTitle.text}
            </h1>
          </Container>
        ) : null}

        <div
          className={classNames('overflow-x-hidden pb-32', {
            'pt-4': navbar && !noPadding,
          })}
          data-e2e-main-content
        >
          {children}
        </div>

        {footer && !noFooter ? <Footer {...footer} /> : undefined}
      </div>
    </PageContext.Provider>
  );
};
