import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Container } from './container';
import { FadeEnterExit } from './fade-enter-exit';
import { Skeleton } from './skeleton';

export interface PageLoaderProps {
  loading: any;
  large?: boolean;
  children?: React.ReactNode;
}

export const PageLoader: React.FC<PageLoaderProps> = ({
  loading,
  large,
  children,
}) => (
  <AnimatePresence mode="wait">
    <FadeEnterExit key={loading}>
      {loading ? (
        <Container large={large}>
          <Skeleton />
        </Container>
      ) : (
        children
      )}
    </FadeEnterExit>
  </AnimatePresence>
);
