export const selectMessages = {
  defaultPlaceholder: 'Please select',
  nothingToSelect: 'Nothing to select',
  searchForOption: 'Start typing to find option',
  noOptionsFound: (searchString?: string) =>
    `No options found for '${searchString}'`,
  noOptionsAddable: (label?: string) =>
    `No options available. Please add ${label ?? 'your own'}.`,
  noOptionsAddRequiredPlaceholder: `Start typing to add option`,
};
