import AsyncStorage from '@react-native-async-storage/async-storage';
import { defaultJsonReviver } from 'xo/utils/json-utils';
import { COMMIT } from '../../app/env';

export const AppStorage = {
  ...AsyncStorage,

  key: (partialKey: string, version: string | number = COMMIT) => {
    return `${version}:${partialKey}`;
  },

  getItem: async <T>(key: string) => {
    const json = await AsyncStorage.getItem(key);
    if (!json) {
      return null;
    }

    return JSON.parse(json, defaultJsonReviver) as T;
  },

  setItem: async <T>(key: string, data: T) => {
    return await AsyncStorage.setItem(
      key,
      JSON.stringify(data, (_key, value) => {
        // serialize the uri so we can hydrate the File for upload later
        if (value instanceof File) {
          return (value as any).uri;
        }
        return value;
      }),
    );
  },

  // NB. Unhandled potential for race conditions
  setOrMergeItem: async <T>(key: string, data: T) => {
    const existingItem = await AppStorage.getItem(key);
    if (existingItem) {
      await AppStorage.mergeItem(key, JSON.stringify(data));
    } else {
      await AppStorage.setItem(key, data);
    }
  },
};
