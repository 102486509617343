import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgSteak: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M11.1722 3.028C15.2354 3.028 18.2774 5.36145 18.2203 9.50608C18.1856 12.0419 15.5925 12.4648 14.3654 14.4813C13.3482 16.1528 14.6551 20.9248 11.0306 20.9706L10.9378 20.9716C6.50521 20.9716 2.89873 16.9468 2.89873 11.9997C2.89873 7.1555 6.43489 3.0419 11.1713 3.02774H11.1722L11.1722 3.028ZM11.1722 2.30774C6.00383 2.32274 2.17871 6.79286 2.17871 11.9997C2.17871 17.3152 6.09095 21.6916 10.9378 21.6916C10.9715 21.6916 11.0053 21.6916 11.039 21.6906C15.3806 21.6363 14.1374 16.24 14.9803 14.8562C16.1006 13.0158 18.9 12.4562 18.9403 9.51616C19.0031 4.90264 15.569 2.30776 11.1722 2.30776L11.1722 2.30774Z"
        fill={fill}
      />
      <Path
        d="M11.0644 4.46899C7.35567 4.51212 4.33887 7.89091 4.33887 12C4.33887 16.0603 7.21143 19.5552 11.0121 19.5309C11.778 19.5215 12.1624 19.5169 12.4371 16.6248C12.5383 15.5616 12.6339 14.5566 13.1346 13.7335C14.478 11.5266 16.7581 11.0851 16.7797 9.48764C16.8265 6.10603 14.3768 4.44763 11.0648 4.46923L11.0644 4.46899ZM11.0925 9.66547C10.0875 9.66547 9.27377 8.93141 9.27377 8.0258C9.27377 7.12018 10.0884 6.38611 11.0925 6.38611C12.0975 6.38611 12.9113 7.12018 12.9113 8.0258C12.9113 8.93141 12.0966 9.66547 11.0925 9.66547Z"
        fill={fill}
      />
      <Path
        d="M21.8204 9.51645C21.7801 12.4564 18.9807 13.0161 17.8604 14.8564C17.0213 16.2355 18.2513 21.5961 13.9669 21.69C13.9415 21.6272 13.9584 21.6703 13.9331 21.6075C15.2128 20.3812 15.4087 18.3336 15.5437 16.92C15.5981 16.3482 15.6722 15.5663 15.8006 15.3554C16.1193 14.8322 16.6697 14.3953 17.2528 13.9322C18.4181 13.0069 19.8683 11.8557 19.9002 9.52966C19.9312 7.25528 19.1887 5.28454 17.7543 3.83062C17.2977 3.36843 16.7774 2.96717 16.2046 2.62875C16.1577 2.60062 16.1849 2.5275 16.2384 2.53969C19.5345 3.26814 21.8719 5.70289 21.8203 9.51649L21.8204 9.51645Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
