import { spacing } from 'xo/styles/restyle/spacing';
import { Box, BoxProps } from './box';

export type HStackProps = BoxProps & {
  space?: keyof typeof spacing;
};

export const HStack = ({ children, space = '4', ...rest }: HStackProps) => (
  <Box
    direction="row"
    align="center"
    justify="space-between"
    gap={space}
    {...rest}
  >
    {children}
  </Box>
);
