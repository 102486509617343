import { FormInstance, useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import React from 'react';
import Collapse from 'xo/components/collapse';
import { ApiTripEventKind } from 'xo/graphql/api/enums/trip-event-kind.generated';
import { ApiTripEventFragment } from 'xo/graphql/api/trip-event-fragment.generated';
import { useUpdateTripEventMutation } from 'xo/graphql/api/update-trip-event-mutation.generated';
import { fontSize } from 'xo/styles/tailwind-theme';
import { useFlag } from '../../hooks/shared-hooks';
import { Button, ButtonLine } from '../components/button';
import { Link } from '../components/link';
import { Select } from '../components/select';
import { Form, FormItem } from '../forms/form';
import { Input } from '../forms/input';
import { stringsToOptions } from '../utils';

// List courtesy of TripEventKind.as_vehicle_event
const overrideableKinds = new Set([
  ApiTripEventKind.Abattoir,
  ApiTripEventKind.BiosecurityShed,
  ApiTripEventKind.Farm,
  ApiTripEventKind.Hidden,
  ApiTripEventKind.Loading,
  ApiTripEventKind.Unloading,
  ApiTripEventKind.WashBay,
]);

const overrideableOptions = stringsToOptions(
  Object.values(ApiTripEventKind)
    .sort()
    .filter(kind => overrideableKinds.has(kind)),
);

export interface AdminOverrideTripEventProps {
  event: ApiTripEventFragment;
}

export const AdminOverrideTripEvent: React.FC<AdminOverrideTripEventProps> = ({
  event,
}) => {
  const [editing, onSetEditing, onUnsetEditing] = useFlag(false);
  const [{ fetching }, updateTripEvent] = useUpdateTripEventMutation();

  const [form] = useForm();

  const onCancel = () => {
    onUnsetEditing();
    form.resetFields();
  };

  const onUpdate = async (form: FormInstance) => {
    const { kindForDisplay, overrideDescription } = form.getFieldsValue(true);
    await updateTripEvent({
      input: {
        kindForDisplay,
        overrideDescription,
        eventId: event.id,
        kind: event.kind,
      },
    });
    onUnsetEditing();
  };
  const updateable = event.id.startsWith('veh-');

  return (
    <>
      <>
        {updateable && !editing ? (
          <div>
            <Link
              className={classNames({ 'opacity-50': editing })}
              onClick={onSetEditing}
            >
              {event.kindForDisplay}
            </Link>
          </div>
        ) : (
          event.kindForDisplay
        )}
        {event.kind !== event.kindForDisplay && (
          <div className="mt-1">(was {event.kind})</div>
        )}
      </>
      {updateable && (
        <Collapse active={editing}>
          <Form
            form={form}
            initialValues={{
              kindForDisplay:
                event.kind !== event.kindForDisplay
                  ? event.kindForDisplay
                  : undefined,
              overrideDescription: event.overrideDescription,
            }}
          >
            {form => (
              <>
                <FormItem name="kindForDisplay">
                  <Select
                    options={overrideableOptions}
                    size="small"
                    placeholder="New kind"
                  />
                </FormItem>
                <FormItem name="overrideDescription">
                  <Input
                    className="w-full"
                    placeholder="Reason for override"
                    size="small"
                  />
                </FormItem>
                <ButtonLine>
                  <Button
                    type="default"
                    size="small"
                    onClick={onCancel}
                    style={{ fontSize: fontSize.xs }}
                    disabled={fetching}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => onUpdate(form)}
                    style={{ fontSize: fontSize.xs }}
                    disabled={fetching || !form.getFieldValue('kindForDisplay')}
                  >
                    Update
                  </Button>
                </ButtonLine>
              </>
            )}
          </Form>
        </Collapse>
      )}
    </>
  );
};
