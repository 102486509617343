import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgSignOut: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M19.882 10.93h-14.1v2.14h14.1l-2.219 2.217 1.505 1.584L24 12.04l-4.752-4.832-1.506 1.506 2.14 2.217z"
        fill={fill}
      />
      <Path
        d="M17.663 18.375L16.16 16.87l-4.12 4.119-9.029-8.95 9.03-9.03 4.198 4.198 1.504-1.505L12.04-.001 0 12.039l12.04 11.96 5.623-5.624z"
        fill={fill}
      />
    </SvgIcon>
  );
};
