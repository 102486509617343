import { Timeline, Tooltip } from 'antd';
import TimelineItem from 'antd/es/timeline/TimelineItem';
import classNames from 'classnames';
import { last } from 'lodash';
import React from 'react';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { ApiTripMatchFailureReason } from 'xo/graphql/api/enums/trip-match-failure-reason.generated';
import { ApiSubLoadFragment } from 'xo/graphql/api/subload-fragment.generated';
import { ApiTripEventSummaryFragment } from 'xo/graphql/api/trip-event-summary-fragment.generated';
import { colors } from 'xo/styles/tailwind-theme';
import {
  getStopKey,
  getTripSummaryTimelineSubloads,
  timeDiffString,
} from 'xo/transport/trip-summary-utils';
import {
  flexCenterBetween,
  flexCenterCenter,
  flexCenterEnd,
  flexStartBetween,
  flexStartStart,
} from '../app-constants';
import { HelpTooltip } from '../components/help-tooltip';
import {
  siteKindDisplayMap,
  tripEventKindDisplayMap,
} from './transport-constants';
import './trip-summary-timeline.overrides.css';
import { TripTemperatureHumidity } from './trip-temperature-humidity';

export interface TripSummaryTimelineProps {
  loadDescription: string;
  events: ApiTripEventSummaryFragment[];
  subloads: ApiSubLoadFragment[];
  showTimezone: boolean;
  gpsStatus: ApiTripMatchFailureReason | undefined;
}

const timeColumnClassName = 'w-24 ml-1';
const timeHeaderClassName = 'text-xs text-grey-500 font-bold';
const timeSubHeaderClassName = 'text-sm text-grey-500';

// TODO Deprecate this component in favour of React Native one

export const TripSummaryTimeline: React.FC<TripSummaryTimelineProps> = ({
  loadDescription,
  events,
  subloads,
  showTimezone,
  gpsStatus,
}) => {
  const timelineSubloads = getTripSummaryTimelineSubloads({ subloads, events });
  const lastEta = last(last(timelineSubloads)?.stops)?.eta;
  const anyActual = timelineSubloads.some(s =>
    s.stops.some(s => s.actualDateTime),
  );

  return (
    <div
      className={classNames('relative', {
        '-mb-2': lastEta,
        '-mb-6': !lastEta,
      })}
    >
      <div className={flexCenterBetween}>
        {gpsStatus === ApiTripMatchFailureReason.InitialSiteNotVisited ? (
          <div
            className={classNames(
              flexCenterCenter,
              timeHeaderClassName,
              '-ml-1',
            )}
          >
            <HelpTooltip>
              The vehicle assigned for this trip has GPS tracking but we can't
              find matching GPS data
            </HelpTooltip>
            <span className="ml-1">NO MATCHING GPS</span>
          </div>
        ) : (
          <div />
        )}
        <div className={classNames(flexCenterEnd)}>
          <div className={classNames(timeHeaderClassName, timeColumnClassName)}>
            SCHEDULED
          </div>

          <div className={classNames(timeHeaderClassName, timeColumnClassName)}>
            {anyActual && 'ACTUAL'}
          </div>
        </div>
      </div>
      <div className="mt-4 w-full">
        {timelineSubloads.map(({ key, count, stops }) => (
          <Timeline key={key} className="xo-trip-summary-timeline">
            {stops.map((stop, i) => {
              const { marker } = stop.site
                ? siteKindDisplayMap[stop.site.kind]
                : tripEventKindDisplayMap['UNKNOWN'];
              if (!marker) {
                console.warn(
                  'No display styling found for site',
                  stop.site?.kind,
                );
                return null;
              }

              const { icon, color } = marker;
              const isLast = i === stops.length - 1;
              const skipped = stop.status === 'skipped';
              const scheduledTime = stop.scheduledDateTime
                ? stop.scheduledDateTime.format('h:mm a')
                : 'N/A';

              const timezone =
                stop.scheduledTimezoneAbbr && showTimezone ? (
                  <Tooltip overlay={stop.site?.timezone} placement="right">
                    <span
                      className={classNames(
                        timeSubHeaderClassName,
                        '-mt-1 text-left',
                      )}
                    >
                      {stop.scheduledTimezoneAbbr}
                    </span>
                  </Tooltip>
                ) : null;

              const actualTimeDiff =
                stop.scheduledDateTime && stop.actualDateTime ? (
                  <div className={classNames(timeSubHeaderClassName)}>
                    {timeDiffString(
                      stop.scheduledDateTime,
                      stop.actualDateTime,
                    )}
                  </div>
                ) : null;

              const loadCount =
                i === stops.length - 2 ? (
                  <div className="my-2 truncate text-xs uppercase text-grey-600">
                    {count} {loadDescription}
                  </div>
                ) : null;

              const weather = stop.weather ? (
                <TripTemperatureHumidity className="my-1" {...stop.weather} />
              ) : null;

              return (
                <TimelineItem
                  key={getStopKey(stop)}
                  className={classNames({
                    'xo-trip-summary-timeline-last': isLast,
                  })}
                  dot={
                    <div
                      className={classNames(
                        '-mt-1 h-6 w-6 rounded-full shadow-semi-bold',
                        flexCenterCenter,
                      )}
                      style={{ backgroundColor: color }}
                    >
                      {React.cloneElement(icon, {
                        fill: colors.white,
                        scale:
                          stop.site?.kind === ApiSiteKind.Abattoir ? 0.6 : 0.55,
                      })}
                    </div>
                  }
                >
                  <div className={classNames(flexStartBetween)}>
                    {/* Site name */}
                    <div
                      className={classNames('truncate font-bold', {
                        'text-grey-500': skipped,
                      })}
                    >
                      <span>{stop.site?.name ?? 'Unknown'}</span>
                      {skipped && <span> [SKIPPED]</span>}
                      {weather}
                      {stop.eta && (
                        <div className="w-10 rounded-sm bg-blue-50 text-center font-bold text-blue-200">
                          ETA
                        </div>
                      )}
                      {loadCount}
                    </div>

                    {/* Scheduled time */}
                    <div className={classNames(flexStartStart)}>
                      <div
                        className={classNames(timeColumnClassName, {
                          'text-grey-500':
                            skipped ||
                            (!stop.scheduledDateTime && !stop.actualDateTime),
                        })}
                      >
                        <div>{scheduledTime}</div>
                        {timezone}
                      </div>

                      {/* Actual time */}

                      <div className={timeColumnClassName}>
                        {anyActual && (
                          <>
                            <div>
                              {stop.actualDateTime?.format('h:mm a') ?? (
                                <>&nbsp;</>
                              )}
                            </div>
                            {actualTimeDiff}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </TimelineItem>
              );
            })}
          </Timeline>
        ))}
      </div>
    </div>
  );
};
