import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgTruckFilled: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8V8ZM6 18.5C5.17 18.5 4.5 17.83 4.5 17C4.5 16.17 5.17 15.5 6 15.5C6.83 15.5 7.5 16.17 7.5 17C7.5 17.83 6.83 18.5 6 18.5V18.5ZM19.5 9.5L21.46 12H17V9.5H19.5V9.5ZM18 18.5C17.17 18.5 16.5 17.83 16.5 17C16.5 16.17 17.17 15.5 18 15.5C18.83 15.5 19.5 16.17 19.5 17C19.5 17.83 18.83 18.5 18 18.5V18.5Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
