import { Checkbox as AntCheckbox, Tooltip } from 'antd';
import { CheckboxProps as AntCheckboxProps } from 'antd/es/checkbox';
import classNames from 'classnames';
import React from 'react';
import './checkbox.overrides.css';
import { HelpIconButton } from './help-icon-button';

export interface CheckboxProps extends Omit<AntCheckboxProps, 'size'> {
  checkboxPosition?: 'left' | 'right' | 'fullRight';
  tooltip?: string;
  boldChecked?: boolean;
  size?: 'lg' | 'sm';
}

export const Checkbox: React.FC<CheckboxProps> = props => {
  const {
    children,
    checkboxPosition = 'left',
    boldChecked = true,
    size = 'lg',
  } = props;

  const className = classNames(
    'xo-ant-checkbox origin-center bg-transparent',
    {
      'xo-ant-checkbox-large': size === 'lg',
      'xo-checkbox-bold-checked': boldChecked,
      'xo-ant-checkbox-right': checkboxPosition === 'right',
      'xo-ant-checkbox-fullright': checkboxPosition === 'fullRight',
    },
    props.className,
  );

  const checkboxOnly = (
    // @ts-expect-error role isn't defined on antd's checkbox
    <AntCheckbox {...props} className={className} role="checkbox">
      {children}
    </AntCheckbox>
  );

  const checkbox = props.tooltip ? (
    <div className="flex items-center">
      {checkboxOnly}

      {props.tooltip && (
        <Tooltip overlay={props.tooltip}>
          <HelpIconButton />
        </Tooltip>
      )}
    </div>
  ) : (
    checkboxOnly
  );

  if (checkboxPosition !== 'left') {
    return (
      <div
        className={classNames('relative', {
          'inline-block': checkboxPosition === 'right',
          'flex w-full items-center': checkboxPosition === 'fullRight',
        })}
      >
        {checkbox}
      </div>
    );
  }

  return checkbox;
};
