import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgRadioChecked } from 'xo/svg/svg-radio-checked';
import { SvgRadioUnchecked } from 'xo/svg/svg-radio-unchecked';
import { OptionType, OptionValueType } from './component-models';
import { List, selectListClassName } from './list';

export interface SingleSelectListProps {
  className?: string;
  options: OptionType[];
  onChange?: (value?: OptionValueType) => void;
  value?: OptionValueType;
}

export const SingleSelectList: React.FC<SingleSelectListProps> = ({
  className,
  options,
  onChange,
  value,
}) => {
  return (
    <List className={className} role="radiogroup">
      {options.map(o => (
        <button
          key={o.value}
          type="button"
          role="radio"
          aria-checked={value === o.value}
          className={selectListClassName}
          onClick={() => onChange && onChange(o.value)}
        >
          <span>{o.label}</span>
          {React.cloneElement(
            value === o.value ? <SvgRadioChecked /> : <SvgRadioUnchecked />,
            { fill: colors.blue[400] },
          )}
        </button>
      ))}
    </List>
  );
};
