import { captureException } from '@sentry/react';
import { zip } from 'fflate';
import { isString } from 'lodash';
import { onNotifyServerError } from '../notifications';

// https://stackoverflow.com/a/16245768/1256624
const blobPartsFromBase64 = (b64Data: string) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteArray = new Uint8Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteArray[i] = slice.charCodeAt(i);
    }

    byteArrays.push(byteArray);
  }
  return byteArrays;
};

export const downloadBlob = ({
  content,
  filename,
  contentType,
  base64,
}: {
  content: BlobPart | string;
  filename: string;
  contentType: string;
  base64?: boolean;
}) => {
  const pom = document.createElement('a');
  pom.setAttribute('download', filename);

  // iOS Safari doesn't seem to support downloading data: URLs properly
  const parts =
    base64 && isString(content) ? blobPartsFromBase64(content) : [content];
  const blob = new Blob(parts, { type: contentType });
  const url = URL.createObjectURL(blob);

  try {
    pom.href = url;
    pom.click();
  } finally {
    // Clean the URL up straight away. NB. on some devices (e.g. iOS), some files are loaded in the
    // browser directly (not downloaded, despite the download attribute), and revoking right here
    // will stop reloading working, but that's okay... it's better than not support those devices at
    // all.
    URL.revokeObjectURL(url);
  }
};

export interface FileModel {
  url: string;
  filename: string;
}

// note that the files need to be served via the same origin or return an appropriate CORS policy
export const zipAndDownloadFiles = async (
  files: FileModel[],
  zipFilename: string,
) => {
  const fileBuffers = await Promise.all(
    files.map(async ({ filename, url }) => {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      const file = new Uint8Array(buffer);
      return [filename, file];
    }),
  );

  zip(Object.fromEntries(fileBuffers), (err, data) => {
    if (err) {
      console.error(err);
      captureException(err);
      onNotifyServerError();
      return;
    }

    downloadBlob({
      content: data,
      contentType: 'application/octet-stream',
      filename: zipFilename,
    });
  });
};

export const downloadJpg = async (url: string, filename: string) => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const file = new Uint8Array(buffer);

  downloadBlob({
    content: file,
    filename,
    contentType: 'image/jpeg',
  });
};
