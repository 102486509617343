import * as Sentry from '@sentry/react';
import * as React from 'react';
import { Button } from './button';
import { ErrorPage } from './error-page';

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <Sentry.ErrorBoundary
    fallback={
      <ErrorPage
        action={
          <Button className="mt-16" onClick={() => window.location.reload()}>
            Reload
          </Button>
        }
      />
    }
  >
    {children}
  </Sentry.ErrorBoundary>
);
