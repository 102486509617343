import { usePrevious, useWindowEvent } from '@mantine/hooks';
import dayjs, { Dayjs } from 'dayjs';
import { isNull } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ApiFindOrganisationType } from 'xo/graphql/api/enums/find-organisation-type.generated';
import { useFindOrganisationQuery } from 'xo/graphql/api/find-organisation-query.generated';
import { CombinedError } from 'xo/graphql/urql-provider';
import { getOrganisation } from '../../hooks/admin-network-hooks';
import { useIsXoAdmin } from '../../hooks/shared-hooks';
import { UserModel } from '../../models/visitor-log-models';
import { notify } from '../notifications';
import {
  AssumeUserDetails,
  LocalStorage,
  localStorageAutoAssumeLockedKey,
} from '../shared/local-storage';

const assumptionRollingTimeoutSecs = 60 * 60; // 60 mins

export const isAutoAssumptionDisabled = () => {
  const lockAutoAssumeUntil = LocalStorage.getItem<{ date: Dayjs }>(
    localStorageAutoAssumeLockedKey,
  );

  // If assumption was just removed, don't try to automatically reassume
  if (lockAutoAssumeUntil?.date && dayjs().isBefore(lockAutoAssumeUntil.date)) {
    return true;
  }

  return false;
};

const reload = () => {
  // Reload the page with no query string or local storage (except auth) so app state is reset
  LocalStorage.clearAllExceptAuth();
  window.location.search = '';
  window.location.reload();
};

export const clearAssumedUser = (
  opts: { notify: boolean } = { notify: false },
) => {
  const assumedUser = LocalStorage.getAssumedUser();

  const clear = () => {
    LocalStorage.removeItem(LocalStorage.getAssumedUserKey());

    // Lock auto-assumption for a short period, so we don't automatically revert on an auto-assuming page
    LocalStorage.setItem(localStorageAutoAssumeLockedKey, {
      date: dayjs().add(10, 'seconds'),
    });

    reload();
  };

  if (opts.notify) {
    const name = assumedUser?.name ? ` ${assumedUser?.name}` : '';
    notify.info(`Removing assumed user${name}`);
    setTimeout(clear, 2000);
  } else if (assumedUser) {
    clear();
  }
};

export const updateAssumedUser = ({
  id,
  name,
}: {
  id: string;
  name?: string;
}) => {
  const details: AssumeUserDetails = {
    userId: id,
    assumedUntil: dayjs().add(assumptionRollingTimeoutSecs, 'seconds'),
    name: name ?? '',
  };
  // pass the user ID down into the request handler via local storage
  LocalStorage.setAssumedUser(details);

  reload();
};

export const tryAutoAssume = async ({
  type,
  id,
}: {
  type: string;
  id: string;
}) => {
  if (!isAutoAssumptionDisabled()) {
    let data: { supportUser: UserModel } | undefined = undefined;
    try {
      data = await getOrganisation({ type, id });
    } catch (err) {
      console.error(err);
      return false;
    }

    updateAssumedUser(data.supportUser);
  }
};

export interface UseAutoAssumeOnNotFound<T> {
  type?: keyof T;
  id: string;
  data: T | undefined;
  fetching: boolean;
  error: CombinedError | undefined;
}

// NB This is only intended for entities supported through the GraphQL findOrganisation resolver
export const useAutoAssumeOnNotFound = <T>({
  type,
  id,
  data,
  fetching,
  error,
}: UseAutoAssumeOnNotFound<T>) => {
  const isXoAdmin = useIsXoAdmin();
  const shouldQuery =
    isXoAdmin &&
    !fetching &&
    !error &&
    data &&
    type &&
    // We've returned empty data for the query
    isNull(data[type]) &&
    // The query type matches what the API supports for auto-assumption
    Object.values(ApiFindOrganisationType).includes(
      type as ApiFindOrganisationType,
    );

  const [{ data: findData, fetching: findFetching }] = useFindOrganisationQuery(
    {
      pause: !shouldQuery,
      variables: { input: { type: type as ApiFindOrganisationType, id } },
    },
  );

  const prevFindFetching = usePrevious(findFetching);
  useEffect(() => {
    if (
      !findFetching &&
      findData?.admin.findOrganisation.supportUser &&
      findFetching !== prevFindFetching
    ) {
      updateAssumedUser(findData.admin.findOrganisation.supportUser);
    }
  });

  return {
    notFound: !fetching && data && type && isNull(data[type]),
    autoAssuming: findFetching || prevFindFetching,
  };
};

export const useAssumedUser = () =>
  useMemo(() => LocalStorage.getAssumedUser(), []);

let timeoutId: NodeJS.Timeout;
export const useUserAssumptionTimeout = () => {
  const [assumedUserAtMount] = useState(useAssumedUser());

  const onUpdateTimeout = () => {
    const assumedUser = LocalStorage.getAssumedUser();

    if (
      assumedUser?.assumedUntil &&
      dayjs().isAfter(assumedUser.assumedUntil)
    ) {
      clearAssumedUser({ notify: true });
      return;
    }

    if (assumedUser?.userId && assumedUser.assumedUntil) {
      // someone may accidentally leave themselves with an assumed
      // user: this manages that risk by automatically timing out
      const timeToWait = assumedUser.assumedUntil.diff(dayjs(), 'millisecond');

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        clearAssumedUser({ notify: true });
      }, timeToWait);
    }
  };

  useEffect(() => {
    onUpdateTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useWindowEvent('click', () => {
    if (assumedUserAtMount) {
      const assumedUser = LocalStorage.getAssumedUser();
      if (assumedUser) {
        const newDetails: AssumeUserDetails = {
          ...assumedUser,
          assumedUntil: dayjs().add(assumptionRollingTimeoutSecs, 'seconds'),
        };

        LocalStorage.setAssumedUser(newDetails);

        onUpdateTimeout();
      }
    }
  });

  return assumedUserAtMount?.userId;
};
