import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgArrow: React.FC<
  SvgIconProps & { direction: 'l' | 'r' }
> = props => {
  const { fill, direction = 'r' } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d={
          direction === 'l'
            ? 'M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11V11Z'
            : 'M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z'
        }
        fill={fill}
      />
    </SvgIcon>
  );
};
