import { NamePath } from 'antd/es/form/interface';
import { isNil } from 'lodash';
import { VisitModel } from '../models/visitor-log-models';
import { OptionType } from './components/component-models';

export const clamp = (number: number, min: number, max: number) => {
  return Math.min(Math.max(number, min), max);
};

// https://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range
export const randomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const pluralize = (count: number, text: string) =>
  count !== 1 ? text + 's' : text;

export const last = <T>(array: T[]) =>
  array && array.length > 0 ? array[array.length - 1] : undefined;

export const first = <T>(array: T[]) =>
  array && array.length > 0 ? array[0] : undefined;

export const initials = (text: string) => {
  // anything after a word boundary, and all uppercase letters
  const significant = text.match(/\b[A-Za-z0-9]|[A-Z]/g) ?? [];

  const pair =
    significant.length < 2
      ? text.substring(0, 2)
      : significant.slice(0, 2).join('');
  return pair.toUpperCase();
};

export const joinFieldNames = (names: (NamePath | undefined)[]): NamePath =>
  names.filter(n => !isNil(n)).flatMap(n => (Array.isArray(n) ? n! : [n!]));

export const getVisitStates = (visit: Pick<VisitModel, 'history'>) =>
  new Set(visit.history.map(h => h.status));

export const unreachable = (n: never): never => {
  throw new Error("Shouldn't be reachable");
};

export const waitForGlobal = (key: string, callback: () => void) => {
  if ((window as any)[key]) {
    callback();
  } else {
    setTimeout(() => {
      waitForGlobal(key, callback);
    }, 100);
  }
};

export const stringsToOptions = (items: string[]): OptionType[] =>
  items.map(i => ({ label: i, value: i }));

export const formatNameList = (names: string[], connector: string = 'and') => {
  if (names.length <= 1) return names[0] ?? '';

  return `${names.slice(0, names.length - 1).join(', ')}${
    names.length > 1
      ? `${names.length > 2 ? ',' : ''} ${connector} ${last(names)}`
      : ''
  }`;
};
