import { Collapse as AntCollapse } from 'antd';
import './collapse.overrides.css';
import { CollapseProps } from './collapse.props';

const KEY = 'key';

const Collapse = ({
  active,
  renderChildrenCollapsed,
  children,
}: CollapseProps) => (
  <AntCollapse
    ghost
    activeKey={active ? KEY : undefined}
    className="xo-ant-collapse"
  >
    <AntCollapse.Panel
      key={KEY}
      header=""
      showArrow={false}
      forceRender={renderChildrenCollapsed}
    >
      {children}
    </AntCollapse.Panel>
  </AntCollapse>
);

export default Collapse;
