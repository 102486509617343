export enum ApiFormKind {
  Early = 'EARLY',
  EarlyHost = 'EARLY_HOST',
  Incomplete = 'INCOMPLETE',
  Leaving = 'LEAVING',
  Onsite = 'ONSITE',
  OnsiteHost = 'ONSITE_HOST',
  Previsit = 'PREVISIT',
  PrevisitHost = 'PREVISIT_HOST',
}
