import { isValidPhoneNumber } from 'libphonenumber-js/min';

export const isValidAusPhone = (value: string) =>
  isValidPhoneNumber(value, 'AU');

export const EMAIL_REGEX = /^.+@.+\..+$/;
export const isValidEmail = (value: string) => EMAIL_REGEX.test(value);

export const isValidAusMobile = (value: string) =>
  /^(04|\+614|4)[0-9]{8}/.test(value.replace(/ /g, ''));

export const isValidAusMobileOrEmail = (value?: string) =>
  !!(value && (isValidAusMobile(value) || isValidEmail(value)));
