import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiSiteActiveStatus } from 'xo/graphql/api/enums/site-active-status.generated';
import { ApiTransportNotificationKind } from 'xo/graphql/api/enums/transport-notification-kind.generated';

import gql from 'graphql-tag';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
import { ApiPlaceFragment, PlaceFragmentDoc } from './place-fragment.generated';
import {
  ApiScheduledMovementEditabilityFragment,
  ScheduledMovementEditabilityFragmentDoc,
} from './scheduled-movement-editability-fragment.generated';
import {
  ApiSiteSummaryFragment,
  SiteSummaryFragmentDoc,
} from './site-summary-fragment.generated';
export type ApiSiteFragment = {
  __typename?: 'Site';
  airtableId?: string;
  modules: Array<ApiModule>;
  transportNotificationKinds: Array<ApiTransportNotificationKind>;
  deleted: boolean;
  activeStatus: ApiSiteActiveStatus;
  exoflareNotes?: string;
  place?: { __typename?: 'Place' } & ApiPlaceFragment;
  organisation: {
    __typename?: 'Organisation';
  } & ApiOrganisationSummaryFragment;
  canCreateScheduledMovement?: {
    __typename?: 'ScheduledMovementEditability';
  } & ApiScheduledMovementEditabilityFragment;
} & ApiSiteSummaryFragment;

export const SiteFragmentDoc = gql`
  fragment Site on Site {
    __typename
    ...SiteSummary
    airtableId
    modules
    place {
      ...Place
    }
    organisation {
      ...OrganisationSummary
    }
    transportNotificationKinds
    deleted
    activeStatus
    exoflareNotes
    canCreateScheduledMovement {
      ...ScheduledMovementEditability
    }
  }
  ${SiteSummaryFragmentDoc}
  ${PlaceFragmentDoc}
  ${OrganisationSummaryFragmentDoc}
  ${ScheduledMovementEditabilityFragmentDoc}
`;
