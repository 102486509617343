import { isFinite } from 'lodash';

export const toId = (text: string) =>
  text.toLowerCase().replace(new RegExp('[^a-zA-Z0-9_-]+', 'g'), '_');

export const pluralize = (count: number, text: string) =>
  count !== 1 ? text + 's' : text;

export const commaAndAndList = (items: string[]) =>
  items.length === 0
    ? ''
    : items.length === 1
      ? items[0]
      : items.length === 2
        ? `${items[0]} and ${items[1]}`
        : `${items.slice(0, -1).join(', ')}, and ${items[items.length - 1]}`;

export const formatTemperature = (value?: number | null) =>
  isFinite(value) ? `${value!.toFixed(1)}º` : null;

export const firstNameAndInitial = (name: string) =>
  `${name.split(' ')[0].trim()} ${
    name.split(' ')[1]?.trim().substring(0, 1) ?? ''
  }`.trim();

export const isNumericString = (value: string) =>
  !value.replace(/[0-9\ ]/g, '').length;
