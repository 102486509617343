import React from 'react';
import { Box, BoxProps, Text } from 'xo/core';
import { Color } from 'xo/styles/restyle/theme';
import { PasswordResult } from 'xo/utils/password-utils';
import { ProgressBar } from './progress-bar';

export interface PasswordStrengthBarProps extends BoxProps {
  result: PasswordResult;
}

export enum PasswordStrength {
  Weak = 1,
  Medium = 2,
  Good = 3,
  Great = 4,
}

type RenderConfig = {
  barBg: Color;
  highlightBg: Color;
  label: string;
  labelColor: Color;
};

const strengthColorMap: Record<PasswordStrength, RenderConfig> = {
  [PasswordStrength.Weak]: {
    barBg: 'red.800',
    highlightBg: 'red.700',
    labelColor: 'red.800',
    label: 'Weak',
  },
  [PasswordStrength.Medium]: {
    barBg: 'orange.700',
    highlightBg: 'red.800',
    labelColor: 'orange.700',
    label: 'Medium',
  },
  [PasswordStrength.Good]: {
    barBg: 'green.500',
    highlightBg: 'orange.700',
    labelColor: 'green.500',
    label: 'Good',
  },
  [PasswordStrength.Great]: {
    barBg: 'green.700',
    highlightBg: 'green.500',
    labelColor: 'green.700',
    label: 'Great',
  },
};

export const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  result,
  ...rest
}) => {
  const { barBg, highlightBg, labelColor, label } =
    strengthColorMap[result.strength];

  return (
    <Box justify="center" {...rest}>
      <Box direction="row" align="center" gap="4" pr="20" pt="2">
        <ProgressBar
          size="sm"
          barBg={barBg}
          highlightBg={highlightBg}
          current={result.strength}
          total={4}
        />
        <Box mt="-2" w="16">
          <Text fontWeight="600" textAlign="right" color={labelColor}>
            {label}
          </Text>
        </Box>
      </Box>
      {result.prompts.length > 0 && (
        <Box>
          <Text color={labelColor}>{result.prompts[0]}</Text>
        </Box>
      )}
    </Box>
  );
};
