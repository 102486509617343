import React from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { useClipPathId } from 'xo/utils/svg-utils';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgShed: React.FC<SvgIconProps> = props => {
  const { fill } = props;
  const { id, clipPath } = useClipPathId();

  return (
    <SvgIcon {...props}>
      <G clipPath={clipPath}>
        <Path
          d="M12.2203 0L0 10.9997V24H5.8152L8.50032 20.4149L11.19 24H12.8147L15.4998 20.4149L18.1904 24H24.0001V10.9997L12.2203 0ZM5.50032 23.5848V16.4148L8.19 19.9999L5.50032 23.5848ZM5.81532 16.0003H11.1901L8.50044 19.5854L5.81532 16.0003ZM11.5004 23.5848L8.81532 19.9997L11.5004 16.4146V23.5848ZM12.5008 23.5848L12.4998 16.4148L15.1905 19.9999L12.5008 23.5848ZM12.8148 16.0003H18.1903L15.4997 19.5854L12.8148 16.0003ZM18.4999 23.5848L15.8148 19.9997L18.4999 16.4146V23.5848ZM18.4999 15H5.50058V10.9997H18.4999V15Z"
          fill={fill}
        />
      </G>
      <Defs>
        <ClipPath id={id}>
          <Rect width="24" height="24" fill={fill} />
        </ClipPath>
      </Defs>
    </SvgIcon>
  );
};
