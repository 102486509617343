import dayjs, { Dayjs } from 'dayjs';
import { Box, Text } from 'xo/core';
import { renderTimeOrDateTime } from 'xo/date-utils';
import { Container } from './container';

// Releases currrently usually involve 10-15 minutes of downtime, but can take longer in some cases.
const getEstimatedEndTime = (start: Dayjs) => start.add(30, 'minutes');

export interface AppHeaderDowntimeBannerProps {
  predictedReleaseTime?: Dayjs;
  large?: boolean;
}

const betweenText = (predictedReleaseTime: Dayjs) => {
  const start = renderTimeOrDateTime(predictedReleaseTime, dayjs());
  const end = renderTimeOrDateTime(
    getEstimatedEndTime(predictedReleaseTime),
    predictedReleaseTime,
  );
  return `between ${start} - ${end}`;
};

export const AppHeaderDowntimeBanner = ({
  predictedReleaseTime,
  large,
}: AppHeaderDowntimeBannerProps) => {
  return (
    <Box backgroundColor="orange.200" w="full" p="2" shadow="4" mb="1">
      <Container large={large}>
        <Box>
          <Text textAlign="center">
            Scheduled maintenance: the app may be offline{' '}
            <Text>
              {predictedReleaseTime
                ? betweenText(predictedReleaseTime)
                : 'soon'}
            </Text>
            .
          </Text>
        </Box>
      </Container>
    </Box>
  );
};
