export enum ApiSiteKind {
  Abattoir = 'ABATTOIR',
  Farm = 'FARM',
  FarmChicken = 'FARM_CHICKEN',
  FarmChickenBreeder = 'FARM_CHICKEN_BREEDER',
  FarmChickenBreederElite = 'FARM_CHICKEN_BREEDER_ELITE',
  FarmChickenEggsBreeder = 'FARM_CHICKEN_EGGS_BREEDER',
  FarmChickenEggsGradingFloor = 'FARM_CHICKEN_EGGS_GRADING_FLOOR',
  FarmChickenEggsLaying = 'FARM_CHICKEN_EGGS_LAYING',
  FarmChickenEggsRearing = 'FARM_CHICKEN_EGGS_REARING',
  FarmChickenHatchery = 'FARM_CHICKEN_HATCHERY',
  FarmChickenHatcheryBreeder = 'FARM_CHICKEN_HATCHERY_BREEDER',
  FarmChickenRearing = 'FARM_CHICKEN_REARING',
  FarmDairy = 'FARM_DAIRY',
  FarmGeneral = 'FARM_GENERAL',
  Feedlot = 'FEEDLOT',
  FeedMill = 'FEED_MILL',
  FeedMillGeneral = 'FEED_MILL_GENERAL',
  Other = 'OTHER',
  ProcessorChicken = 'PROCESSOR_CHICKEN',
  TransportDepot = 'TRANSPORT_DEPOT',
}
