import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { isEqual } from 'lodash';
import React from 'react';
import { ApiTransportNotificationKind } from 'xo/graphql/api/enums/transport-notification-kind.generated';
import { ApiSiteSummaryAdminFragment } from 'xo/graphql/api/site-summary-admin-fragment.generated';
import { Select } from '../components/select';
import { Form, FormItem, FormUpdater } from '../forms/form';
import { useUpdateSiteAdmin } from './admin-hooks';

export interface AdminSiteTransportNotificationKindsSelectProps {
  site: Pick<ApiSiteSummaryAdminFragment, 'id' | 'transportNotificationKinds'>;
}

const transportNotificationKindLabels: Record<
  ApiTransportNotificationKind,
  string
> = {
  ARRIVAL: 'Arrival',
  ETA: 'ETA',
};

export const AdminSiteTransportNotificationKindsSelect: React.FC<
  AdminSiteTransportNotificationKindsSelectProps
> = ({ site }) => {
  const [form] = useForm();

  const { saving, onUpdateSiteAdmin } = useUpdateSiteAdmin();

  const onChange = (form: FormInstance) => {
    const newKinds = form.getFieldValue('transportNotificationKinds');
    if (!isEqual(newKinds, site.transportNotificationKinds)) {
      onUpdateSiteAdmin({
        siteId: site.id,
        transportNotificationKinds: newKinds,
        form,
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        transportNotificationKinds: site.transportNotificationKinds,
      }}
    >
      <FormUpdater>
        {form => (
          <FormItem noStyle name="transportNotificationKinds">
            <Select
              options={Object.entries(transportNotificationKindLabels).map(
                ([value, label]) => ({ label, value }),
              )}
              placeholder="No transport notifications"
              multi={true}
              suffixIcon={saving ? <LoadingOutlined /> : undefined}
              size="middle"
              onBlur={() => onChange(form)}
              onDeselect={() => onChange(form)}
              disabled={saving}
            />
          </FormItem>
        )}
      </FormUpdater>
    </Form>
  );
};
