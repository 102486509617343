import classNames from 'classnames';

export interface PanelTagProps {
  className?: string;
  children: string;
}

export const PanelTag = ({ className, children }: PanelTagProps) => (
  <div
    className={classNames(
      className,
      'inline-block rounded-md border border-solid border-grey-300 p-1 text-center text-base font-bold text-grey-700',
    )}
  >
    {children}
  </div>
);
