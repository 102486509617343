import { OrgFeatureFlag, UserRole } from 'xo/constants';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiUserFragment } from 'xo/graphql/api/user-fragment.generated';
import { UserModel } from '../models/visitor-log-models';

export type UserForPermissions = (ApiUserFragment | UserModel) & {
  roles?: ApiUserFragment['roles'];
  orgAdmin?: boolean;
};

export type UserPermissions = ReturnType<typeof getPermissions>;

// Munge GraphQL User type with REST API type until the REST API is deprecated
// Be sure to account for the differences when checking permission, allowing for either source
export const getPermissions = (user?: UserForPermissions) => {
  const roles = new Set(user?.roles?.map(r => r.kind));
  const orgModules = new Set(user?.organisation?.modules);
  const orgFeatures = new Set(user?.organisation?.features);
  const isOrgAdmin = user?.orgAdmin || roles.has(UserRole.OrgAdmin);
  const isAirtableDriver = !!user?.airtableDriverId;

  const visitorModule =
    orgModules.has(ApiModule.Visitor) &&
    (!!user?.hostAtSites?.length || isOrgAdmin) &&
    !isAirtableDriver;

  const transportScheduling =
    orgFeatures.has(OrgFeatureFlag.TransportScheduling) &&
    (!!user?.transportAtSites?.length || isOrgAdmin) &&
    !isAirtableDriver;
  const consignmentNote = orgFeatures.has(OrgFeatureFlag.ConsignmentNotes);
  const arrivalDashboard = orgFeatures.has(OrgFeatureFlag.ArrivalDashboard);

  return {
    isOrgAdmin,
    visitor: visitorModule
      ? {
          visitorPrebooking: orgFeatures.has(OrgFeatureFlag.VisitorPrebooking),
          deliveryPrebooking: orgFeatures.has(
            OrgFeatureFlag.DeliveryPrebooking,
          ),
        }
      : undefined,
    transport:
      transportScheduling || consignmentNote || arrivalDashboard
        ? {
            transportScheduling,
            consignmentNote,
            arrivalDashboard: arrivalDashboard
              ? {
                  gatehouse: roles.has(UserRole.OrgGatehouse),
                  lairage: roles.has(UserRole.OrgLairage),
                  planner: roles.has(UserRole.OrgPlanner),
                  uploadArrivalSpreadsheet:
                    isOrgAdmin || roles.has(UserRole.OrgPlanner),
                }
              : undefined,
          }
        : undefined,
  };
};
