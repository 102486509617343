import { message } from 'antd';
import { ReactNode } from 'react';
import { SERVER_ERROR } from 'xo/constants';

export const onNotifyServerError = (message?: string) =>
  notify.error(message ?? SERVER_ERROR);

export const onNotifyOfflineError = () =>
  notify.info(
    'The device appears to be offline. Please try again later when you have Internet connection',
  );

const popupDurationSecs = 3.5;

export type NotifyProps = {
  durationSecs?: number;
};

export const notify = {
  info: (content: ReactNode, props?: NotifyProps) => {
    message.open({
      key: String(content),
      content,
      type: 'info',
      className: 'xo-ant-message-info data-e2e-hide chromatic-ignore',
      duration: props?.durationSecs ?? popupDurationSecs,
    });
  },
  error: (content: ReactNode, props?: NotifyProps) => {
    message.open({
      key: String(content),
      content,
      type: 'error',
      className: 'xo-ant-message-error data-e2e-hide chromatic-ignore',
      duration: props?.durationSecs ?? popupDurationSecs,
    });
  },
};
