import classNames from 'classnames';
import React from 'react';
import { ApiProvider } from 'xo/graphql/api/enums/provider.generated';
import { SvgDroplets } from 'xo/svg/svg-droplets';
import { SvgThermometer } from 'xo/svg/svg-thermometer';
import { flexCenterBetween } from '../app-constants';
import { IconTooltip } from '../components/help-tooltip';
import { IconText } from '../components/icon-text';

const providerNames: Record<ApiProvider, string> = {
  [ApiProvider.Exoflare]: 'ExoFlare mobile app',
  [ApiProvider.Linxio]: 'truck sensor',
  [ApiProvider.Solbox]: 'truck sensor',
  [ApiProvider.Openweather]: 'OpenWeather',
  [ApiProvider.User]: 'ExoFlare user',
  [ApiProvider.GoogleMaps]: 'Google Maps',
};

export interface TripTemperatureHumidityProps {
  temperature: number;
  humidity?: number;
  provider: ApiProvider;
  className?: string;
}

export const TripTemperatureHumidity: React.FC<
  TripTemperatureHumidityProps
> = ({ temperature, humidity, provider, className }) => {
  const hasHumidity = typeof humidity === 'number';

  return (
    <IconTooltip
      className={classNames(
        className,
        'rounded-full bg-grey-100 py-1 font-semibold',
        flexCenterBetween,
      )}
      icon={
        <>
          <div className="mx-1">
            <IconText size="xs" icon={<SvgThermometer />}>
              {temperature.toFixed(0)}ºC
            </IconText>
          </div>
          <div className="mx-1">
            <IconText size="xs" icon={<SvgDroplets />}>
              <span>{hasHumidity ? humidity.toFixed(0) : ' - '}</span>%
            </IconText>
          </div>
        </>
      }
    >
      Temperature <span>{hasHumidity ? 'and humidity ' : ''}</span> at arrival
      time ({providerNames[provider]})
    </IconTooltip>
  );
};
