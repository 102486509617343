import classNames from 'classnames';
import {
  countBy,
  entries,
  flow,
  head,
  last,
  max,
  maxBy,
  omit,
  partialRight,
  uniq,
} from 'lodash';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { ApiCreateSiteMovementMatrixInput } from 'xo/graphql/api/inputs/create-site-movement-matrix-input.generated';
import { ApiSiteMovementMatrixFragment } from 'xo/graphql/api/site-movement-matrix-fragment.generated';
import { ApiSiteMovementRuleFragment } from 'xo/graphql/api/site-movement-rule-fragment.generated';
import { ApiSiteMovementSiteOverrideFragment } from 'xo/graphql/api/site-movement-site-override-fragment.generated';
import { SiteKind, SiteReadModel } from '../../api-models';
import { QuarantineDefaults } from './settings-quarantine-default-inputs';

export enum SiteFeatures {
  Shower = 'SHOWER',
}

const ruleSeparator = '|';

export const getRuleId = ({
  sourceId,
  targetId,
}: {
  sourceId: string;
  targetId: string;
}) => `${sourceId}${ruleSeparator}${targetId}`;

export const parseRuleId = (
  ruleId: string,
): { sourceId: string; targetId: string } => {
  const parts = ruleId.split(ruleSeparator);
  return { sourceId: parts[0], targetId: parts[1] };
};

export type MovementRuleType =
  | 'default'
  | 'siteOverride'
  | 'movementRule'
  // Assigned the largest because it's an unknown site kind
  | 'defaultUnknown';

export interface MovementRule {
  nights: number;
  ruleType: MovementRuleType;
}

export type MovementRuleMap = Record<string, MovementRule>;

export const getLargestSiteQuarantineNights = ({
  defaults,
  siteOverrides,
  movementRules,
}: {
  defaults: QuarantineDefaults;
  siteOverrides: ApiSiteMovementSiteOverrideFragment[];
  movementRules: MovementRuleMap;
}) =>
  max([
    defaults.abattoirQuarantineNights,
    defaults.feedMillQuarantineNights,
    defaults.otherQuarantineNights,
    ...siteOverrides.flatMap(s => [
      s.abattoirQuarantineNights,
      s.feedMillQuarantineNights,
      s.otherQuarantineNights,
    ]),
    ...Object.values(movementRules).map(({ nights }) => nights),
  ])!;

export const movementRulesToRuleMap = (
  rules: ApiSiteMovementRuleFragment[],
): MovementRuleMap =>
  Object.fromEntries(
    rules.map(r => {
      const ruleId = getRuleId({
        sourceId: r.sourceSiteId,
        targetId: r.targetSiteId,
      });

      return [
        ruleId,
        {
          nights: r.quarantineNights,
          ruleType: 'movementRule',
        },
      ];
    }),
  );

const getDefaultQuarantine = ({
  source,
  target,
  defaults,
  siteOverrideMap,
  unknownSiteKindNights,
}: {
  source: SiteReadModel;
  target: SiteReadModel;
  defaults: QuarantineDefaults;
  siteOverrideMap: Map<string, ApiSiteMovementSiteOverrideFragment>;
  unknownSiteKindNights: number;
}): MovementRule => {
  let nights = 0;
  const movement = siteOverrideMap.get(target.id) ?? defaults;

  let ruleType: MovementRuleType = 'default';
  if (source.id === target.id) {
    nights = 0;
  } else if (source.kind === ApiSiteKind.Farm) {
    nights = movement.otherQuarantineNights;
  } else if (source.kind === ApiSiteKind.FeedMill) {
    nights = movement.feedMillQuarantineNights;
  } else if (source.kind === ApiSiteKind.Abattoir) {
    nights = movement.abattoirQuarantineNights;
  } else {
    ruleType = 'defaultUnknown';
    nights = unknownSiteKindNights;
  }

  return {
    nights,
    ruleType:
      siteOverrideMap.get(target.id) &&
      // Check that the site override is different to the default nights before assigning 'siteOverride' type
      getDefaultQuarantine({
        source,
        target,
        defaults,
        unknownSiteKindNights,
        siteOverrideMap: new Map(),
      }).nights !== nights
        ? 'siteOverride'
        : ruleType,
  };
};

export const defaultsAndOverridesToRuleMap = ({
  defaults,
  siteOverrides,
  sites,
  movementRules,
}: {
  sites: SiteReadModel[];
  defaults: QuarantineDefaults;
  siteOverrides: ApiSiteMovementSiteOverrideFragment[];
  movementRules: MovementRuleMap;
}): MovementRuleMap => {
  const siteOverrideMap = new Map(siteOverrides.map(s => [s.siteId, s]));
  const unknownSiteKindNights = getLargestSiteQuarantineNights({
    defaults,
    siteOverrides,
    movementRules,
  });

  return Object.fromEntries(
    sites.flatMap(source =>
      sites
        .filter(target => source.id !== target.id)
        .map(target => {
          const ruleId = getRuleId({
            sourceId: source.id,
            targetId: target.id,
          });

          return [
            ruleId,
            getDefaultQuarantine({
              source,
              target,
              defaults,
              siteOverrideMap,
              unknownSiteKindNights,
            }),
          ];
        }),
    ),
  );
};

export const siteMovementToRuleMap = ({
  siteMovement,
  sites,
}: {
  siteMovement: ApiSiteMovementMatrixFragment;
  sites: SiteReadModel[];
}): MovementRuleMap => {
  const movementRuleMap = movementRulesToRuleMap(siteMovement.rules);

  const defaultRuleMap = defaultsAndOverridesToRuleMap({
    defaults: siteMovement,
    siteOverrides: siteMovement.siteOverrides,
    sites,
    movementRules: movementRuleMap,
  });

  return Object.assign(defaultRuleMap, movementRuleMap);
};

export const updateRuleMap = ({
  existingRuleMap,
  defaults,
  siteOverrides,
  enableOverride,
  sites,
}: {
  existingRuleMap: MovementRuleMap;
  defaults: QuarantineDefaults;
  siteOverrides: ApiSiteMovementSiteOverrideFragment[];
  enableOverride: boolean;
  sites: SiteReadModel[];
}): MovementRuleMap => {
  const movementRuleMap = Object.fromEntries(
    Object.entries(existingRuleMap).filter(
      ([_, rule]) => rule.ruleType === 'movementRule',
    ),
  );

  let defaultsRuleMap = defaultsAndOverridesToRuleMap({
    defaults,
    siteOverrides,
    sites,
    movementRules: movementRuleMap,
  });

  const newRuleMap = enableOverride
    ? defaultsRuleMap
    : Object.assign(defaultsRuleMap, movementRuleMap);

  return newRuleMap;
};

export interface SettingsQuarantineForm {
  enableOverride: boolean;
  ruleMap: MovementRuleMap;
  siteMovement: Omit<ApiSiteMovementMatrixFragment, 'rules'>;
}

export const settingsQuarantineFormToCreateSiteMovementMatrixInput = ({
  form,
  organisationId,
}: {
  form: SettingsQuarantineForm;
  organisationId: string;
}): ApiCreateSiteMovementMatrixInput => ({
  organisationId,
  otherQuarantineNights: form.siteMovement.otherQuarantineNights,
  overseasQuarantineNights: form.siteMovement.overseasQuarantineNights,
  abattoirQuarantineNights: form.siteMovement.abattoirQuarantineNights,
  feedMillQuarantineNights: form.siteMovement.feedMillQuarantineNights,
  outbreakAreaQuarantineNights: form.siteMovement.outbreakAreaQuarantineNights,
  siteOverrides: form.siteMovement.siteOverrides.map(o => omit(o, 'id')),
  rules: Object.entries(form.ruleMap).map(([ruleId, rule]) => {
    const { sourceId, targetId } = parseRuleId(ruleId);
    return {
      sourceSiteId: sourceId,
      targetSiteId: targetId,
      quarantineNights: rule.nights,
    };
  }),
});

export const getRuleTypeClassName = (ruleType: MovementRuleType) =>
  classNames({
    'bg-blue-50': ruleType === 'default' || ruleType === 'defaultUnknown',
    'bg-orange-100': ruleType === 'siteOverride',
    'bg-red-100': ruleType === 'movementRule',
  });

export interface SettingsQuarantineKindToKindSummaryRuleModel {
  source: ApiSiteKind;
  target: ApiSiteKind;
  nights: number;
  exceptions: boolean;
}

export type SettingsQuarantineSummarySiteOverridesModel = Record<
  ApiSiteKind,
  { otherSiteTypes: number; overseas: number; outbreakArea: number }
>;

export type SettingsQuarantineKindToKindSummaryModel = {
  kindToKindMap: Record<
    ApiSiteKind,
    Record<ApiSiteKind, SettingsQuarantineKindToKindSummaryRuleModel>
  >;
  siteOverridesMap: SettingsQuarantineSummarySiteOverridesModel;
};

const getMostCommonNumber = (array: number[]) =>
  parseInt(flow(countBy, entries, partialRight(maxBy, last), head)(array) ?? 0);

export const getKindToKindQuarantineSummary = ({
  siteMovement,
  sites,
}: {
  siteMovement: ApiSiteMovementMatrixFragment;
  sites: SiteReadModel[];
}): SettingsQuarantineKindToKindSummaryModel => {
  const siteMap = new Map(sites.map(s => [s.id, s]));
  const ruleMap = siteMovementToRuleMap({ siteMovement, sites });
  const kinds = uniq(sites.map(s => s.kind));
  const siteKindIdMap = Object.fromEntries(
    kinds.map(k => [k, [] as string[]]),
  ) as Record<SiteKind, string[]>;
  for (const site of sites) {
    siteKindIdMap[site.kind].push(site.id);
  }

  const kindToKindMap = Object.fromEntries(
    kinds.map(source => [
      source,
      Object.fromEntries(
        kinds.map(target => [
          target,
          { source, target, nights: NaN, exceptions: false },
        ]),
      ),
    ]),
  ) as SettingsQuarantineKindToKindSummaryModel['kindToKindMap'];

  // Calculate kind-to-kind map
  for (const sourceKind of kinds) {
    for (const targetKind of kinds) {
      const sourceIds = siteKindIdMap[sourceKind];
      const targetIds = siteKindIdMap[targetKind];

      const allRules = sourceIds
        .flatMap(sourceId =>
          targetIds.map(targetId => ruleMap[getRuleId({ sourceId, targetId })]),
        )
        .filter(Boolean);

      const result = kindToKindMap[sourceKind][targetKind];
      result.nights = getMostCommonNumber(allRules.map(r => r.nights));
      result.exceptions = allRules.some(r => r.nights !== result.nights);
    }
  }

  // Calculate other site types and overseas overrides map
  const siteOverridesMap = Object.fromEntries(
    kinds.map(kind => {
      const siteOverrides = siteMovement.siteOverrides.filter(
        o => siteMap.get(o.siteId)!.kind === kind,
      );
      const otherSiteTypes = !siteOverrides.length
        ? siteMovement.otherQuarantineNights
        : getMostCommonNumber(siteOverrides.map(s => s.otherQuarantineNights));
      const overseas = !siteOverrides.length
        ? siteMovement.overseasQuarantineNights
        : getMostCommonNumber(
            siteOverrides.map(s => s.overseasQuarantineNights),
          );
      const outbreakArea = !siteOverrides.length
        ? siteMovement.outbreakAreaQuarantineNights
        : getMostCommonNumber(
            siteOverrides.map(s => s.outbreakAreaQuarantineNights),
          );

      return [kind, { otherSiteTypes, overseas, outbreakArea }];
    }),
  ) as SettingsQuarantineKindToKindSummaryModel['siteOverridesMap'];

  return { kindToKindMap, siteOverridesMap };
};
