import { AxiosResponse } from 'axios';
import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { XO_ADMIN_ASSUME_USER_HEADER, countryCodeOptions } from 'xo/constants';
import { setCurrentAssumerUserId, setCurrentUserId } from 'xo/globals';
import {
  UserListResponse,
  UserPasswordResetResponse,
  UserResponse,
  UserTermsAndPrivacyRequest,
} from '../api-models';
import { UserContext, useCurrentUser } from '../app/account/user-provider';
import {
  IsValidPhoneNumberParams,
  isValidPhoneNumber,
} from '../app/forms/validators';
import {
  CovidVaccinationCertificateSightingModel,
  UserModel,
  VisitQuestionnaireModel,
} from '../models/visitor-log-models';
import { updateSentryUser } from '../utils/sentry';
import { getApi } from './axios-instance';
import { covidVaccinationSightingTransformer } from './network-transformers';
import { siteQueryKeys } from './site-network-hooks';
import { readUserTransformer, readUsersTransformer } from './transformers';

const userApi = getApi('user');
const userOpenApi = getApi('user', { open: true });

export const userQueryKeys = {
  currentUser: (withAssuming: boolean) => `currentUser:${withAssuming}`,
  getUsers: (kind: 'host' | 'visitor') => `getUsers:${kind}`,
  user: (id: string) => `user:${id}`,
};

export const useUpdateCurrentUser = () => {
  const { user: currentUser, potentiallyAnAdminUser } = useContext(UserContext);
  const client = useQueryClient();

  return (userId: string) => {
    if (userId === currentUser.id || userId === potentiallyAnAdminUser.id) {
      client.resetQueries({ queryKey: siteQueryKeys.getSites });
      [true, false].map(withAssuming =>
        client.resetQueries({
          queryKey: userQueryKeys.currentUser(withAssuming),
        }),
      );
    }
  };
};

export const useGetUsers = (kind: 'host' | 'visitor') => {
  const currentUser = useCurrentUser();
  return useQuery<UserModel[]>(
    userQueryKeys.getUsers(kind),
    () =>
      userApi
        .get<UserListResponse>('/', { params: { kind } })
        .then(response =>
          readUsersTransformer(
            response.data.users,
            currentUser?.organisation?.id,
          ),
        ),
    {
      cacheTime: 0,
    },
  );
};

export const getCurrentUser = ({ withAssuming }: { withAssuming: boolean }) =>
  userApi
    .get<UserResponse>(`/me`, {
      headers: withAssuming
        ? {}
        : ({ [XO_ADMIN_ASSUME_USER_HEADER]: null } as any),
    })
    .then(response => {
      const userId = response.data.user.id;
      if (withAssuming) {
        updateSentryUser({ assumerUserId: userId, xo: true });
        setCurrentAssumerUserId(userId);
      } else {
        updateSentryUser({ id: userId });
        setCurrentUserId(userId);
      }

      return readUserTransformer(response.data.user, undefined);
    });

export const useGetUser = (id: string, enabled?: boolean) =>
  useQuery(
    userQueryKeys.user(id),
    () =>
      userApi
        .get<UserResponse>(id)
        .then(response => readUserTransformer(response.data.user, undefined)),
    { enabled },
  );

export const useGetCurrentUser = ({
  enabled = true,
  withAssuming = true,
}: {
  enabled: boolean;
  withAssuming: boolean;
}) =>
  useQuery<UserModel>(
    userQueryKeys.currentUser(withAssuming),
    () => getCurrentUser({ withAssuming }),
    {
      retry: false,
      enabled,
    },
  );

export const usePostTermsAgreementUnauthed = (
  timestamp: string,
  questionnaireCacheKey?: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ userId }: { userId: string }) => {
      const data: UserTermsAndPrivacyRequest = {
        termsVersion: timestamp,
        privacyVersion: timestamp,
      };

      return userOpenApi
        .post<UserResponse>(`/${userId}/terms-and-privacy`, data)
        .then(response => {
          const questionnaire = questionnaireCacheKey
            ? queryClient.getQueryData<VisitQuestionnaireModel>(
                questionnaireCacheKey,
              )
            : undefined;

          const visitor = readUserTransformer(
            response.data.user,
            questionnaire?.visit.site.organisation.id!,
          );

          if (questionnaireCacheKey && questionnaire) {
            queryClient.setQueryData<VisitQuestionnaireModel>(
              questionnaireCacheKey,
              {
                ...questionnaire,
                visit: {
                  ...questionnaire.visit,
                  visitor,
                },
              },
            );
          }

          return visitor;
        });
    },
    { retry: false },
  );
};

export const usePostTermsAgreementAuthed = (timestamp: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      const data: UserTermsAndPrivacyRequest = {
        termsVersion: timestamp,
        privacyVersion: timestamp,
      };

      return userApi
        .post<UserResponse>(`/me/terms-and-privacy`, data)
        .then(response => {
          [true, false].forEach(withAssuming =>
            queryClient.setQueryData<UserModel | undefined>(
              userQueryKeys.currentUser(withAssuming),
              user => (user ? { ...user, agreedTerms: true } : user),
            ),
          );

          return response.data;
        });
    },
    { retry: false },
  );
};

export const usePostUserCovidVaccinationSighting = () =>
  useMutation(
    (data: CovidVaccinationCertificateSightingModel & { id: string }) => {
      return userApi.post(
        `/${data.id}/covid-vaccination-sighting`,
        covidVaccinationSightingTransformer(data),
      );
    },
  );

export const usePostTriggerPasswordReset = () =>
  useMutation((request: { id: string }) => {
    return userApi
      .post<UserPasswordResetResponse>(`/${request.id}/trigger-password-reset`)
      .then((r: AxiosResponse<UserPasswordResetResponse>) => r.data);
  });

export const useGetIsValidPhoneNumber = ({
  phone,
  requireMobile,
}: Omit<IsValidPhoneNumberParams, 'countryAbbr'>) => {
  const countryAbbr = countryCodeOptions.find(o =>
    phone?.startsWith(o.label),
  )?.value;

  const params = {
    phone,
    requireMobile,
    countryAbbr: countryAbbr!,
  };

  const { data, isFetching } = useQuery(
    [params.countryAbbr, params.phone, params.requireMobile],
    () => isValidPhoneNumber(params),
    {
      enabled: Boolean(phone && countryAbbr),
      staleTime: Number.POSITIVE_INFINITY,
      keepPreviousData: true,
    },
  );

  return {
    valid: data,
    validating: isFetching,
  };
};
