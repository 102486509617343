import { Button, ButtonProps } from 'xo/components/button';
import { VStack } from 'xo/core';
import { SvgUpload } from 'xo/svg/svg-upload';
import { useScreenSize } from '../../hooks/shared-hooks';
import { getPermissions } from '../../utils/permissions';
import { useCurrentUser } from '../account/user-provider';
import { ArrivalDashboardUploadModal } from './arrival-dashboard-upload-modal';

export interface ArrivalDashboardUploadButtonProps
  extends Pick<
    ButtonProps,
    'variant' | 'leftIcon' | 'w' | 'textTransform' | 'rounded' | 'size'
  > {
  responsive?: boolean;
}

export const ArrivalDashboardUploadButton = ({
  responsive,
  ...rest
}: ArrivalDashboardUploadButtonProps) => {
  const user = useCurrentUser();
  const permissions = getPermissions(user);
  const sm = useScreenSize() === 'sm';

  if (!permissions.transport?.arrivalDashboard?.uploadArrivalSpreadsheet) {
    return null;
  }

  return (
    <ArrivalDashboardUploadModal>
      {({ onOpen }) => (
        <VStack>
          <Button
            variant="outline"
            rounded="lg"
            leftIcon={<SvgUpload />}
            onPress={onOpen}
            size="md"
            {...rest}
          >
            {sm && responsive ? '' : 'Upload schedule'}
          </Button>
        </VStack>
      )}
    </ArrivalDashboardUploadModal>
  );
};
