// Auto-generated by generate.ts, don't modify directly
export const spacing = {
  "0": -2,
  "1": 6,
  "2": 10,
  "3": 14,
  "4": 16,
  "5": 20,
  "6": 24,
  "7": 28,
  "8": 32,
  "9": 36,
  "10": 40,
  "12": 48,
  "14": 56,
  "16": 64,
  "18": 72,
  "20": 80,
  "22": 88,
  "24": 96,
  "26": 104,
  "28": 112,
  "30": 120,
  "32": 128,
  "40": 160,
  "48": 192,
  "56": 224,
  "64": 256,
  "72": 288,
  "80": 320,
  "96": 384,
  "-0": 0,
  "-1": -6,
  "-2": -10,
  "-3": -14,
  "-4": -16,
  "-5": -20,
  "-6": -24,
  "-7": -28,
  "-8": -32,
  "-9": -36,
  "-10": -40,
  "-12": -48,
  "-14": -56,
  "-16": -64,
  "-18": -72,
  "-20": -80,
  "-22": -88,
  "-24": -96,
  "-26": -104,
  "-28": -112,
  "-30": -120,
  "-32": -128,
  "-40": -160,
  "-48": -192,
  "-56": -224,
  "-64": -256,
  "-72": -288,
  "-80": -320,
  "-96": -384,
  "0.5": 2,
  "-0.5": -2,
  "1.5": 6,
  "-1.5": -6,
  "2.5": 10,
  "-2.5": -10,
  "3.5": 14,
  "-3.5": -14,
  "1/2": "50%",
  "1/3": "33.333%",
  "2/3": "66.666%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "1/5": "20%",
  "2/5": "40%",
  "3/5": "60%",
  "4/5": "80%",
  "1/6": "16.666%",
  "2/6": "33.333%",
  "3/6": "50%",
  "4/6": "66.666%",
  "5/6": "83.333%",
  "full": "100%",
  "10%": "10%",
  "20%": "20%",
  "30%": "30%",
  "40%": "40%",
  "50%": "50%",
  "60%": "60%",
  "70%": "70%",
  "80%": "80%",
  "90%": "90%",
  "100%": "100%"
}