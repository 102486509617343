import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgTruckFront: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M21.0156 9.53125H20.0312V2.3125C20.0312 1.96387 19.8928 1.63062 19.6467 1.38453C19.4006 1.13844 19.0674 1 18.7188 1H4.28125C3.5558 1 2.96875 1.58705 2.96875 2.3125V9.53125H1.98438C1.44093 9.53125 1 9.97218 1 10.5156C1 11.0591 1.44093 11.5 1.98438 11.5H2.96875V18.0625C2.96875 18.4111 3.10718 18.7444 3.35328 18.9905C3.59938 19.2366 3.93262 19.375 4.28125 19.375H4.9375V21.0156C4.9375 21.5591 5.37843 22 5.92188 22H7.23438C7.77782 22 8.21875 21.5591 8.21875 21.0156V19.375H14.7812V21.0156C14.7812 21.5591 15.2222 22 15.7656 22H17.0781C17.6216 22 18.0625 21.5591 18.0625 21.0156V19.375H18.7188C19.0674 19.375 19.4006 19.2366 19.6467 18.9905C19.8928 18.7444 20.0312 18.4111 20.0312 18.0625V11.5H21.0156C21.5591 11.5 22 11.0591 22 10.5156C22 9.97218 21.5591 9.53125 21.0156 9.53125ZM6.17814 10.6541L7.7534 5.40406H7.75212C7.83671 5.12721 8.09048 4.9375 8.37891 4.9375H14.6133C14.9042 4.93622 15.1593 5.12592 15.2439 5.40406L16.8192 10.6541C16.8794 10.8514 16.8422 11.0681 16.7179 11.2347C16.5949 11.4013 16.4 11.5 16.1924 11.5H6.80735C6.59971 11.5 6.4036 11.4026 6.27927 11.236C6.15494 11.0681 6.1179 10.8527 6.17814 10.6541ZM7.89056 17.4062H6.57806C6.03462 17.4062 5.59368 16.9653 5.59368 16.4218C5.59368 15.8784 6.03462 15.4375 6.57806 15.4375H7.89056C8.434 15.4375 8.87494 15.8784 8.87494 16.4218C8.87494 16.9653 8.434 17.4062 7.89056 17.4062ZM16.4218 17.4062H15.1093C14.5659 17.4062 14.1249 16.9653 14.1249 16.4218C14.1249 15.8784 14.5659 15.4375 15.1093 15.4375H16.4218C16.9653 15.4375 17.4062 15.8784 17.4062 16.4218C17.4062 16.9653 16.9653 17.4062 16.4218 17.4062Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
