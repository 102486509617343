// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import {
  ApiNotificationMethodsFragment,
  NotificationMethodsFragmentDoc,
} from './notification-methods-fragment.generated';
import {
  ApiTransportSiteNotificationPreferencesFragment,
  TransportSiteNotificationPreferencesFragmentDoc,
} from './transport-site-notification-preferences-fragment.generated';
import {
  ApiVisitorSiteNotificationPreferencesFragment,
  VisitorSiteNotificationPreferencesFragmentDoc,
} from './visitor-site-notification-preferences-fragment.generated';
export type ApiUserPreferencesFragment = {
  __typename?: 'UserPreferences';
  quietTimeStart?: string;
  quietTimeEnd?: string;
  eodSummaryNotification: boolean;
  quietTimeSummaryNotification: boolean;
  vehicleFinalTripDestinationArrivalNotification: boolean;
  methods: {
    __typename?: 'NotificationMethods';
  } & ApiNotificationMethodsFragment;
  transportNotifications: Array<
    {
      __typename?: 'TransportSiteNotificationPreferences';
    } & ApiTransportSiteNotificationPreferencesFragment
  >;
  visitorNotifications: Array<
    {
      __typename?: 'VisitorSiteNotificationPreferences';
    } & ApiVisitorSiteNotificationPreferencesFragment
  >;
};

export const UserPreferencesFragmentDoc = gql`
  fragment UserPreferences on UserPreferences {
    __typename
    methods {
      ...NotificationMethods
    }
    quietTimeStart
    quietTimeEnd
    eodSummaryNotification
    quietTimeSummaryNotification
    vehicleFinalTripDestinationArrivalNotification
    transportNotifications {
      ...TransportSiteNotificationPreferences
    }
    visitorNotifications {
      ...VisitorSiteNotificationPreferences
    }
  }
  ${NotificationMethodsFragmentDoc}
  ${TransportSiteNotificationPreferencesFragmentDoc}
  ${VisitorSiteNotificationPreferencesFragmentDoc}
`;
