// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiUserRoleFragment = {
  __typename?: 'UserRole';
  id: string;
  kind: string;
  name: string;
};

export const UserRoleFragmentDoc = gql`
  fragment UserRole on UserRole {
    __typename
    id
    kind
    name
  }
`;
