import React, { ComponentType, LazyExoticComponent, useContext } from 'react';
import { RouteProps } from 'react-router';
import { useIsXoAdmin } from '../../hooks/shared-hooks';
import { SentryRoute } from '../../utils/sentry';
import { errorPageTypes } from './error-page';
import { ErrorPageContext } from './error-page-provider';

export interface AdminRouteProps extends Omit<RouteProps, 'component'> {
  useNotFound?: boolean;
  component?: RouteProps['component'] | LazyExoticComponent<ComponentType<any>>;
}

export const AdminRoute: React.FC<AdminRouteProps> = props => {
  const isXoAdmin = useIsXoAdmin();
  const { onErrorPage } = useContext(ErrorPageContext);
  const { useNotFound } = props;

  if (!isXoAdmin) {
    useNotFound &&
      onErrorPage({
        path: window.location.pathname,
        props: errorPageTypes.NOT_FOUND_PAGE!,
      });
    return null;
  }

  return <SentryRoute {...props} />;
};
