import { motion } from 'framer-motion';
import React from 'react';

export const FadeEnterExit: React.FC<{
  id?: string;
  durationMs?: number;
  children?: React.ReactNode;
  className?: string;
}> = ({ id, children, durationMs = 200, className }) => (
  <motion.div
    id={id}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: durationMs / 1000 }}
    data-e2e-fade-enter-exit
    className={className}
  >
    {children}
  </motion.div>
);
