import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { useClipPathId } from 'xo/utils/svg-utils';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgUpload = (props: SvgIconProps) => {
  const { fill } = props;
  const { clipPath, id } = useClipPathId();

  return (
    <SvgIcon {...props}>
      <G clipPath={clipPath}>
        <Path d="M5 20H19V18H5V20ZM5 10H9V16H15V10H19L12 3L5 10Z" fill={fill} />
      </G>
      <Defs>
        <ClipPath id={id}>
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </SvgIcon>
  );
};
