export enum ApiConsignmentNoteDocumentKind {
  AnimalHealthDeclaration = 'ANIMAL_HEALTH_DECLARATION',
  BiosecurityCertificate = 'BIOSECURITY_CERTIFICATE',
  BrandOrEarmark = 'BRAND_OR_EARMARK',
  DeliveryDocket = 'DELIVERY_DOCKET',
  DownOrDoa = 'DOWN_OR_DOA',
  LivestockAdviceNote = 'LIVESTOCK_ADVICE_NOTE',
  MsaCertificate = 'MSA_CERTIFICATE',
  NfasDeliveryDocket = 'NFAS_DELIVERY_DOCKET',
  NvdEuvdVendorDeclaration = 'NVD_EUVD_VENDOR_DECLARATION',
  OrganicLivestockTransferDeclaration = 'ORGANIC_LIVESTOCK_TRANSFER_DECLARATION',
  Other = 'OTHER',
  SaleyardWaybill = 'SALEYARD_WAYBILL',
  WeighbridgeDocket = 'WEIGHBRIDGE_DOCKET',
}
