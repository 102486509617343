import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgHelp } from 'xo/svg/svg-help';

export interface HelpIconButtonProps {
  className?: string;
  style?: React.CSSProperties;
}

export const HelpIconButton: React.FC<HelpIconButtonProps> = props => (
  <button {...props}>
    <SvgHelp fill={colors.blue[400]} scale={0.85} />
  </button>
);
