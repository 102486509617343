import { Dayjs } from 'dayjs';
import { partition, sortBy } from 'lodash';
import { FormConfigQuestionMostRecentVisitModel } from '../../models/visitor-log-models';

export interface MostRecentVisitEntryExitDateTime {
  entryDateTime: Dayjs;
  exitDateTime?: Dayjs;
}

export interface MostRecentVisitSiteModel {
  siteName: string;
  quarantineNights?: number;
  entryExitDateTimes: MostRecentVisitEntryExitDateTime[];
}

export interface MostRecentVisitsSummaryModel {
  overallCompliant: boolean;
  compliantSites: MostRecentVisitSiteModel[];
  nonCompliantSites: MostRecentVisitSiteModel[];
}

export const getMostRecentVisitsSummary = (
  mostRecentVisits: FormConfigQuestionMostRecentVisitModel[],
): MostRecentVisitsSummaryModel => {
  const [compliant, nonCompliant] = partition(
    mostRecentVisits,
    mrv => mrv.compliant,
  );

  const getSiteVisits = (mrvs: FormConfigQuestionMostRecentVisitModel[]) => {
    const siteVisits = new Map<string, MostRecentVisitSiteModel>();
    for (const mrv of mrvs) {
      const internal = mrv.internalVisit ?? mrv.internalDelivery;
      const siteName = internal?.site.name ?? mrv.externalVisit?.siteName!;
      const entryDateTime =
        internal?.entryDateTime ?? mrv.externalVisit?.entryDateTime!;
      const exitDateTime =
        mrv.internalVisit?.exitDateTime ?? mrv.externalVisit?.exitDateTime;
      const existing = siteVisits.get(siteName);
      if (existing) {
        existing.entryExitDateTimes.push({ entryDateTime, exitDateTime });
      } else {
        siteVisits.set(siteName, {
          siteName,
          entryExitDateTimes: [{ entryDateTime, exitDateTime }],
          quarantineNights: mrv.compliant ? undefined : mrv.quarantineNights,
        });
      }
    }

    return sortBy(
      Array.from(siteVisits.values()).map(sv => ({
        ...sv,
        entryExitDateTimes: sortBy(sv.entryExitDateTimes, d =>
          d.entryDateTime.toDate(),
        ),
      })),
      sv => sv.entryExitDateTimes[0].entryDateTime.toDate(),
    );
  };

  return {
    overallCompliant: nonCompliant.length === 0,
    compliantSites: getSiteVisits(compliant),
    nonCompliantSites: getSiteVisits(nonCompliant),
  };
};
