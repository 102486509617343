import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';

export interface ContainerProps {
  className?: string;
  large?: boolean;
  animated?: boolean;
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
  large,
  animated,
}) => (
  <Row className="h-full px-4">
    <Col
      xs={{ span: 24, offset: 0 }}
      sm={{ span: large ? 24 : 20, offset: large ? 0 : 2 }}
      lg={{ span: large ? 20 : 12, offset: large ? 2 : 6 }}
      className={classNames(className, {
        'transition-all duration-100 ease-out': animated,
      })}
    >
      {children}
    </Col>
  </Row>
);
