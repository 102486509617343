import classNames from 'classnames';
import prettyBytes from 'pretty-bytes';
import { useDropzone } from 'react-dropzone';
import { Link } from 'xo/components/link';
import { SUPPORT_EMAIL } from 'xo/constants';
import { Box, Text } from 'xo/core';
import { useOnEmailLinkPress } from 'xo/hooks/component-hooks';
import { SvgExcel } from 'xo/svg/svg-excel';
import { hexFromThemeColor } from 'xo/utils/theme-utils';
import { FileSelection } from './arrival-dashboard-upload-modal';

export const ArrivalDashboardContact = () => (
  <Box my="6" flexDirection="row" align="center" flexWrap="wrap">
    <Text>
      Contact{' '}
      <Link fontSize="md" onPress={useOnEmailLinkPress(SUPPORT_EMAIL)}>
        {SUPPORT_EMAIL}
      </Link>{' '}
      for template guidance.
    </Text>
  </Box>
);

interface ArrivalDashboardUploadTemplateHintProps {
  textAlignCenter?: boolean;
}

export const ArrivalDashboardUploadTemplateHint = ({
  textAlignCenter,
}: ArrivalDashboardUploadTemplateHintProps) => (
  <Box>
    <Text
      {...{
        textAlign: textAlignCenter ? 'center' : undefined,
      }}
    >
      Only .xlsm or xlsx files under{' '}
      {prettyBytes(MAX_FILE_SIZE_BYTES).toUpperCase()} following template are
      accepted.
    </Text>
  </Box>
);

// 5MB
const MAX_FILE_SIZE_BYTES = 5 * 1000 * 1000;

interface ArrivalDashboardSelectFilePanelProps {
  onSelectFile: (selection: FileSelection) => void;
}

export const ArrivalDashboardSelectFilePanel = ({
  onSelectFile,
}: ArrivalDashboardSelectFilePanelProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) =>
      onSelectFile({
        file: acceptedFiles[0] ?? fileRejections[0]?.file,
        error: fileRejections[0]?.errors[0]?.message,
      }),
    minSize: 0,
    maxSize: MAX_FILE_SIZE_BYTES,
    maxFiles: 1,
  });

  return (
    <div
      {...getRootProps({
        className: classNames('cursor-pointer p-6', {
          'bg-grey-100 hover:bg-grey-200': !isDragActive,
          'bg-grey-50': isDragActive,
        }),
      })}
    >
      <Box align="center">
        <SvgExcel fill={hexFromThemeColor('grey.600')} scale={4} />

        <Box mt="2">
          <Text textAlign="center">Drag and drop files into this window.</Text>
        </Box>
        <ArrivalDashboardUploadTemplateHint textAlignCenter={true} />

        <Box mt="4" py="1" bg="blue.600" w="64">
          <Text textAlign="center" color="white">
            SELECT FILE
          </Text>
        </Box>

        <ArrivalDashboardContact />
      </Box>

      <input
        {...getInputProps({
          className: 'py-4 bg-blue-600 hover:bg-blue-400 text-white',
        })}
      />
    </div>
  );
};
