import AsyncStorage from '@react-native-async-storage/async-storage';
import { v4 as uuidv4, validate as validateUuid } from 'uuid';

const key = 'clientId:1';
const loadClientId = async () => {
  const id = await AsyncStorage.getItem(key);
  if (id && validateUuid(id)) {
    return id;
  }
  const newId = uuidv4();
  await AsyncStorage.setItem(key, newId);
  return newId;
};
let clientId = '';
loadClientId().then(id => {
  clientId = id;
});

export default () => clientId;
