import { Linking, Pressable } from 'react-native';
import { Box, BoxProps, Text, TextProps } from 'xo/core';

export interface LinkProps extends BoxProps {
  href?: string;
  onPress?: () => void;
  fontSize?: TextProps['fontSize'];
  color?: TextProps['color'];
  children?: any;
}

// FIXME Add Web support, use anchor
export const Link = ({
  href,
  onPress,
  fontSize = 'sm',
  color = 'blue.600',
  children,
  ...rest
}: LinkProps) => {
  const onPressCb = () => {
    if (href) {
      Linking.openURL(href);
    }
    onPress && onPress();
  };

  return (
    <Box {...rest}>
      <Pressable onPress={onPressCb}>
        <Text
          fontSize={fontSize}
          color={color}
          textDecorationLine="underline"
          fontWeight="700"
          accessibilityRole="link"
          accessible
        >
          {children}
        </Text>
      </Pressable>
    </Box>
  );
};
