// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import {
  ApiPositionFragment,
  PositionFragmentDoc,
} from './position-fragment.generated';
export type ApiPlaceFragment = {
  __typename?: 'Place';
  id: string;
  name: string;
  focalPoint: { __typename?: 'Position' } & ApiPositionFragment;
};

export const PlaceFragmentDoc = gql`
  fragment Place on Place {
    __typename
    id
    name
    focalPoint {
      ...Position
    }
  }
  ${PositionFragmentDoc}
`;
