import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { ApiSiteSummaryAdminFragment } from 'xo/graphql/api/site-summary-admin-fragment.generated';
import { Form, FormItem } from '../forms/form';
import { TextArea } from '../forms/text-area';
import { useUpdateSiteAdmin } from './admin-hooks';

export const AdminSiteExoflareNotesInput: React.FC<{
  site: Pick<ApiSiteSummaryAdminFragment, 'exoflareNotes' | 'id'>;
}> = ({ site }) => {
  const [form] = useForm();

  const { saving, onUpdateSiteAdmin } = useUpdateSiteAdmin();

  return (
    <Form form={form} initialValues={{ exoflareNotes: site.exoflareNotes }}>
      <FormItem noStyle name="exoflareNotes">
        <TextArea
          size="middle"
          autoSize={{ minRows: 2, maxRows: 4 }}
          onBlur={e =>
            e.target.value !== site.exoflareNotes &&
            onUpdateSiteAdmin({
              siteId: site.id,
              exoflareNotes: e.target.value,
              form,
            })
          }
          disabled={saving}
        />
      </FormItem>
      {saving ? <LoadingOutlined spin /> : undefined}
    </Form>
  );
};
