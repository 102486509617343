// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
export type ApiTransportSiteNotificationPreferencesFragment = {
  __typename?: 'TransportSiteNotificationPreferences';
  thisSiteArrival: boolean;
  thisSiteArrivalIsEditable: boolean;
  thisSiteArrivalScheduledAll: boolean;
  thisSiteArrivalScheduledAllIsEditable: boolean;
  thisSiteArrivalScheduledOutsideScheduledTime: boolean;
  thisSiteArrivalScheduledOutsideScheduledTimeIsEditable: boolean;
  thisSiteArrivalScheduledDiscrepancies: boolean;
  thisSiteArrivalScheduledDiscrepanciesIsEditable: boolean;
  thisSiteArrivalUnexpected: boolean;
  thisSiteArrivalUnexpectedIsEditable: boolean;
  thisSiteEta: boolean;
  thisSiteEtaIsEditable: boolean;
  abattoirDestinationArrival: boolean;
  abattoirDestinationArrivalIsEditable: boolean;
  abattoirDestinationEta: boolean;
  abattoirDestinationEtaIsEditable: boolean;
  farmDestinationArrival: boolean;
  farmDestinationArrivalIsEditable: boolean;
  deletedTrip: boolean;
  deletedTripIsEditable: boolean;
  thisSiteDelayedUnloading: boolean;
  thisSiteDelayedUnloadingIsEditable: boolean;
  thisSiteEndOfDaySummary: boolean;
  thisSiteEndOfDaySummaryIsEditable: boolean;
  thisSiteEndOfWeekSummary: boolean;
  thisSiteEndOfWeekSummaryIsEditable: boolean;
  site: { __typename?: 'Site' } & ApiSiteFragment;
};

export const TransportSiteNotificationPreferencesFragmentDoc = gql`
  fragment TransportSiteNotificationPreferences on TransportSiteNotificationPreferences {
    __typename
    site {
      ...Site
    }
    thisSiteArrival
    thisSiteArrivalIsEditable
    thisSiteArrivalScheduledAll
    thisSiteArrivalScheduledAllIsEditable
    thisSiteArrivalScheduledOutsideScheduledTime
    thisSiteArrivalScheduledOutsideScheduledTimeIsEditable
    thisSiteArrivalScheduledDiscrepancies
    thisSiteArrivalScheduledDiscrepanciesIsEditable
    thisSiteArrivalUnexpected
    thisSiteArrivalUnexpectedIsEditable
    thisSiteEta
    thisSiteEtaIsEditable
    abattoirDestinationArrival
    abattoirDestinationArrivalIsEditable
    abattoirDestinationEta
    abattoirDestinationEtaIsEditable
    farmDestinationArrival
    farmDestinationArrivalIsEditable
    deletedTrip
    deletedTripIsEditable
    thisSiteDelayedUnloading
    thisSiteDelayedUnloadingIsEditable
    thisSiteEndOfDaySummary
    thisSiteEndOfDaySummaryIsEditable
    thisSiteEndOfWeekSummary
    thisSiteEndOfWeekSummaryIsEditable
  }
  ${SiteFragmentDoc}
`;
