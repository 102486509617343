import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiMobileAppConfigFragment,
  MobileAppConfigFragmentDoc,
} from './mobile-app-config-fragment.generated';
import {
  ApiWebAppConfigFragment,
  WebAppConfigFragmentDoc,
} from './web-app-config-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetSystemQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetSystemQuery = {
  __typename?: 'Query';
  system: {
    __typename?: 'System';
    mobileAppConfig: {
      __typename?: 'MobileAppConfig';
    } & ApiMobileAppConfigFragment;
    webAppConfig: { __typename?: 'WebAppConfig' } & ApiWebAppConfigFragment;
  };
};

export const GetSystemDocument = gql`
  query GetSystem {
    __typename
    system {
      __typename
      mobileAppConfig {
        ...MobileAppConfig
      }
      webAppConfig {
        ...WebAppConfig
      }
    }
  }
  ${MobileAppConfigFragmentDoc}
  ${WebAppConfigFragmentDoc}
`;

export function useGetSystemQuery(
  options?: Omit<Urql.UseQueryArgs<ApiGetSystemQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiGetSystemQuery, ApiGetSystemQueryVariables>({
    query: GetSystemDocument,
    ...options,
  });
}
