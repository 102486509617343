// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiSiteRequiredActionsFragment = {
  __typename?: 'Site';
  id: string;
  availableRequiredActions: Array<{
    __typename?: 'RequiredAction';
    id: string;
    label: string;
  }>;
};

export const SiteRequiredActionsFragmentDoc = gql`
  fragment SiteRequiredActions on Site {
    __typename
    id
    availableRequiredActions {
      __typename
      id
      label
    }
  }
`;
