// Auto-generated by generate.ts, don't modify directly
export const colors = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000",
  "white": "#fff",
  "brandOrange.400": "#F5B457",
  "brandOrange.500": "#F0920C",
  "brandGrey.200": "#F9FAFB",
  "brandGrey.400": "#686D76",
  "brandGrey.600": "#373A40",
  "grey.50": "#FAFAFA",
  "grey.100": "#F5F5F5",
  "grey.200": "#EEEEEE",
  "grey.300": "#E0E0E0",
  "grey.400": "#BDBDBD",
  "grey.500": "#9E9E9E",
  "grey.600": "#757575",
  "grey.700": "#616161",
  "grey.800": "#424242",
  "grey.900": "#212121",
  "orange.100": "#FFF0CF",
  "orange.200": "#FEC854",
  "orange.300": "#F2994A",
  "orange.400": "#FD9827",
  "orange.500": "#F0920C",
  "orange.600": "#C4691A",
  "orange.700": "#E65100",
  "blue.50": "#E3F2FD",
  "blue.100": "#BADEFE",
  "blue.200": "#69B6FF",
  "blue.300": "#6DB8FD",
  "blue.340": "#42A5F5",
  "blue.350": "#2196F3",
  "blue.400": "#2589E2",
  "blue.500": "#1565C0",
  "blue.600": "#0C5EB0",
  "blue.700": "#0a549e",
  "red.100": "#FFEBEE",
  "red.400": "#EB5757",
  "red.500": "#E53935",
  "red.700": "#B71C1C",
  "red.800": "#B00200",
  "green.100": "#DCEDC8",
  "green.200": "#bfde9a",
  "green.300": "#9dcd65",
  "green.400": "#19B00C",
  "green.500": "#43A047",
  "green.700": "#558B2F",
  "purple.50": "#F3E5F5",
  "purple.200": "#CE93D8",
  "purple.300": "#E1BEE7",
  "purple.500": "#9C27B0",
  "teal.50": "#E0F2F1",
  "teal.300": "#B3E0DB",
  "teal.400": "#26A69A",
  "teal.500": "#009688",
  "teal.600": "#00897B",
  "indigo.300": "#7986CB",
  "pink.200": "#F48FB1"
}