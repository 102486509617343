import React from 'react';
import { ColorValue } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { LogoVariant } from 'xo/models';
import { colors } from 'xo/styles/tailwind-theme';

type LogoColorScheme = {
  main: ColorValue;
  accent?: ColorValue;
};

const logoOrange = colors.orange[500];

const variantColorScheme: Record<LogoVariant, LogoColorScheme> = {
  [LogoVariant.Black]: {
    main: colors.black,
  },
  [LogoVariant.BlackOrange]: {
    main: colors.black,
    accent: logoOrange,
  },
  [LogoVariant.Orange]: {
    main: logoOrange,
  },
  [LogoVariant.White]: {
    main: colors.white,
  },
  [LogoVariant.WhiteBlack]: {
    main: colors.white,
    accent: colors.black,
  },
  [LogoVariant.WhiteOrange]: {
    main: colors.white,
    accent: logoOrange,
  },
};

interface SvgExoFlareLogoProps extends SvgProps {
  variant: LogoVariant;
}

export const SvgExoFlareLogo: React.FC<SvgExoFlareLogoProps> = ({
  variant = LogoVariant.Black,
  ...props
}) => {
  const textFill = variantColorScheme[variant].main;
  const fullStopFill = variantColorScheme[variant].accent ?? textFill;

  return (
    <Svg
      width="150"
      height="30"
      viewBox="0 0 500 95"
      fill="none"
      role="img"
      {...props}
    >
      <Path
        d="M489.06 95C482.883 95 478.121 90.0951 478.121 84.1576C478.121 78.091 482.883 73.0571 489.06 73.0571C495.238 73.0571 500 78.091 500 84.1576C500 90.0951 495.238 95 489.06 95Z"
        fill={fullStopFill}
      />
      <Path
        d="M407.971 59.7622C407.971 39.3682 421.742 24.1372 440.919 24.1372C461.768 24.1372 473.608 38.0774 473.608 60.1494C473.608 61.4402 473.608 62.731 473.48 64.0217H426.118C426.504 73.0571 433.711 79.5109 443.621 79.5109C452.759 79.5109 458.036 74.0897 459.966 71.25L471.678 80.1562C469.104 84.9321 460.095 95 443.364 95C421.742 95 407.971 80.0272 407.971 59.7622ZM426.633 52.4049H455.59C455.204 44.144 449.284 38.7228 441.176 38.7228C432.81 38.7228 427.405 44.6603 426.633 52.4049Z"
        fill={textFill}
      />
      <Path
        d="M403.619 24.1372V42.2079C390.363 42.2079 385.472 50.4687 385.472 63.8927V93.322H367.711V25.8152H385.086V35.625C388.046 28.913 394.738 24.1372 403.619 24.1372Z"
        fill={textFill}
      />
      <Path
        d="M296.107 72.4117C296.107 59.1168 306.532 50.0815 322.233 50.0815C329.054 50.0815 334.331 52.1467 336.776 53.8247V49.4361C336.776 43.4986 332.4 39.4973 324.55 39.4973C317.857 39.4973 311.68 41.6916 306.66 44.2731L299.968 32.1399C306.017 28.0095 316.184 24.1372 326.48 24.1372C348.102 24.1372 354.537 35.4959 354.537 50.5978V93.322H338.835L337.677 88.9334C334.202 92.6766 329.054 95 321.332 95C307.69 95 296.107 86.61 296.107 72.4117ZM313.867 71.8954C313.867 77.4456 318.887 81.1888 325.579 81.1888C332.272 81.1888 337.162 77.5747 337.162 71.8954C337.162 65.9579 332.272 62.4728 325.45 62.4728C318.629 62.4728 313.867 66.3451 313.867 71.8954Z"
        fill={textFill}
      />
      <Path d="M268.17 93.322V0H285.93V93.322H268.17Z" fill={textFill} />
      <Path
        d="M209.389 93.322V2.96874H255.464V19.3614H227.536V40.6589H251.989V56.9225H227.536V93.322H209.389Z"
        fill={textFill}
      />
      <Path
        d="M125.054 59.7622C125.054 39.2391 140.756 24.1372 161.219 24.1372C181.811 24.1372 197.512 39.3682 197.512 59.7622C197.512 80.1562 182.326 95 161.219 95C140.241 95 125.054 80.1562 125.054 59.7622ZM142.815 59.7622C142.815 71.25 150.666 79.2527 161.219 79.2527C171.901 79.2527 179.752 71.25 179.752 59.7622C179.752 48.2745 171.901 39.8845 161.219 39.8845C150.537 39.8845 142.815 48.1454 142.815 59.7622Z"
        fill={textFill}
      />
      <Path
        d="M101.859 93.322L87.5734 72.1535L73.159 93.322H53.9827L77.6635 58.8587L55.0123 25.8152H74.9608L87.5734 46.3383L100.186 25.8152H120.135L97.4833 58.8587L121.164 93.322H101.859Z"
        fill={textFill}
      />
      <Path
        d="M0 93.322V2.96874H46.332V19.3614H18.1467V39.4973H42.3423V55.6318H18.1467V77.0584H47.4903V93.322H0Z"
        fill={textFill}
      />
    </Svg>
  );
};
