import { clamp, isFinite, isNil } from 'lodash';
import { FieldValues } from 'react-hook-form';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import {
  ControlledFormControl,
  ControlledFormItemProps,
} from './controlled-form-control';
import { FieldDisplay } from './field-display';
import { FormMode, useFormModeContext } from './form';
import { Input, InputProps } from './input';

export type ControlledInputProps<TFieldValues extends FieldValues> =
  ControlledFormItemProps<TFieldValues, InputProps> & {
    renderInput?: (props: InputProps) => JSX.Element;
    isInvalid?: boolean;
    clearable?: boolean;
    example?: string;
    // Only applies to a number input
    max?: number;
    min?: number;
    editable?: boolean;
    renderViewMode?: (value?: string) => JSX.Element;
  };

export const ControlledInput = <TFieldValues extends FieldValues>(
  props: ControlledInputProps<TFieldValues>,
) => {
  const {
    name,
    label,
    example,
    isRequired,
    isInvalid,
    formControl,
    renderInput,
    max,
    min,
    editable = true,
    renderViewMode,
    ...rest
  } = props;

  const isNumberInput =
    rest.keyboardType && ['numeric', 'number-pad'].includes(rest.keyboardType);

  const formMode = useFormModeContext();

  return (
    <ControlledFormControl
      name={name}
      label={label}
      isRequired={isRequired}
      isInvalid={isInvalid}
      autoFocus={rest.autoFocus}
      {...formControl}
    >
      {({ onChange, onBlur, value }) => {
        const onChangeText = (text: string) => {
          const sanitisedText = isNumberInput
            ? text.replace(/[^0-9]/g, '')
            : text;
          const clampedText =
            isNumberInput && sanitisedText && (isFinite(max) || isFinite(min))
              ? clamp(
                  parseInt(sanitisedText),
                  isFinite(min) ? min! : Number.NEGATIVE_INFINITY,
                  isFinite(max) ? max! : Number.POSITIVE_INFINITY,
                )
              : sanitisedText;

          onChange(clampedText);

          rest.onChangeText && rest.onChangeText(text);
        };

        const onBlurCb = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
          onBlur && onBlur();
          rest.onBlur && rest.onBlur(e);
        };

        const inputProps: InputProps = {
          placeholder: example ? `e.g. ${example}` : `Enter ${label}`,
          ...rest,
          invalid: isInvalid,
          onChangeText,
          onBlur: onBlurCb,
          value: isNumberInput && !isNil(value) ? String(value) : value,
        };

        if (
          formMode.mode !== FormMode.Edit ||
          !editable ||
          (rest.disabled && !rest.variant)
        ) {
          return renderViewMode ? (
            renderViewMode(value)
          ) : (
            <FieldDisplay icon={rest.leftIcon} testID={name}>
              {value}
            </FieldDisplay>
          );
        }

        return renderInput ? (
          renderInput(inputProps)
        ) : (
          <Input {...inputProps} />
        );
      }}
    </ControlledFormControl>
  );
};
