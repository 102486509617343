// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiTransporterFragment = {
  __typename?: 'Transporter';
  id: string;
  name: string;
  phone?: string;
};

export const TransporterFragmentDoc = gql`
  fragment Transporter on Transporter {
    __typename
    id
    name
    phone
  }
`;
