// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiVehicleFragment = {
  __typename?: 'Vehicle';
  rego?: string;
  trailers: Array<string>;
  phone?: string;
  trackedPlates: Array<string>;
};

export const VehicleFragmentDoc = gql`
  fragment Vehicle on Vehicle {
    __typename
    rego
    trailers
    phone
    trackedPlates
  }
`;
