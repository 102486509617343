import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { formatShortDate, formatTime } from 'xo/date-utils';
import Flatpickr from 'xo/forms/flatpickr';
import { colors, height } from 'xo/styles/tailwind-theme';
import { SvgCalendarToday } from 'xo/svg/svg-calendar-today';
import { SvgClock } from 'xo/svg/svg-clock';
import { SvgCross } from 'xo/svg/svg-cross';
import './date-time-picker.overrides.css';
import { Input } from './input';

export interface DateTimePickerProps {
  id?: string;
  picker?: 'date' | 'time';
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (date?: Dayjs) => void;
  onFocus?: () => void;
  value?: Dayjs;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  icon?: boolean;
  allowClear?: boolean;
  extraRight?: JSX.Element;
  onClose?: () => void;
  onOpen?: () => void;
  style?: React.CSSProperties;
}

/**
 * @deprecated Use DatePicker or TimePicker from xo/forms instead, and augment with any additional required functionality
 */
export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  id,
  className,
  picker = 'date',
  placeholder,
  disabled,
  onChange,
  value,
  onFocus,
  maxDate,
  minDate,
  icon = true,
  allowClear,
  extraRight,
  onClose,
  onOpen,
  style,
}) => {
  const onChangeCb = useCallback(
    (dates?: Date[]) => {
      if (dates && dates[0]) {
        const newValue = dayjs(dates[0]);
        onChange && onChange(newValue);
      } else {
        onChange && onChange(undefined);
      }
    },
    [onChange],
  );

  const onClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChangeCb();
    },
    [onChangeCb],
  );

  const formatDateOrTime = useCallback(
    (v: Dayjs) => {
      return picker === 'time' ? formatTime(v) : formatShortDate(v);
    },
    [picker],
  );

  return (
    <div id={id} className="relative">
      <Flatpickr
        options={{
          formatDate: (v: Date) => formatDateOrTime(dayjs(v)),
          ...(picker === 'date'
            ? {
                enableTime: false,
                monthSelectorType: 'static',
                defaultDate: new Date(),
                maxDate: maxDate?.endOf('day').toDate(),
                minDate: minDate?.startOf('day').toDate(),
                disableMobile: true,
              }
            : {
                enableTime: true,
                noCalendar: true,
                time_24hr: false,
                enableSeconds: false,
              }),
        }}
        onChange={onChangeCb}
        onClose={onClose}
        onOpen={onOpen}
        value={useMemo(() => value?.toDate(), [value])}
        disabled={disabled}
        render={(_: any, ref: (node: HTMLInputElement | null) => void) => (
          <Input
            inputRef={ref}
            className={className}
            style={{
              minWidth: '120px',
              height: picker === 'time' ? height[10] : undefined,
              ...style,
            }}
            placeholder={placeholder ? placeholder : `Select ${picker}`}
            disabled={disabled}
            prefix={
              icon ? (
                picker === 'date' ? (
                  <SvgCalendarToday />
                ) : (
                  <SvgClock />
                )
              ) : undefined
            }
            value={value ? formatDateOrTime(value) : ''}
            data-e2e-hide
          />
        )}
      />
      {allowClear && value && !disabled ? (
        <button
          className="absolute right-0 top-0 z-50 flex h-10 w-10 items-center justify-center"
          onClick={onClear}
        >
          <div className="m-3 h-4 w-4 rounded-full bg-grey-300 hover:bg-grey-400">
            <SvgCross fill={colors.white} scale={0.65} />
          </div>
        </button>
      ) : undefined}
      {extraRight && (
        <div className="absolute right-0" style={{ top: '-36px' }}>
          {extraRight}
        </div>
      )}
    </div>
  );
};
