import { LocationDescriptor } from 'history';
import { isNil, isString } from 'lodash';

export const getBaseUrl = (
  { protocol }: { protocol: boolean } = { protocol: true },
) => {
  const host = `${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;

  if (!protocol) return host;

  return `${window.location.protocol}//${host}`;
};

export const getWindowUrl = (location: LocationDescriptor) =>
  [
    getBaseUrl({ protocol: true }),
    ...(isString(location)
      ? [location]
      : [
          location.pathname,
          location.search ? `?${location.search}` : undefined,
        ]),
  ]
    .filter(p => !isNil(p))
    .join('');
