import { Dayjs } from 'dayjs';
import { first, flatMap, groupBy, last, sortBy, uniqBy } from 'lodash';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { ApiSiteFragment } from 'xo/graphql/api/site-fragment.generated';
import { ApiSubLoadFragment } from 'xo/graphql/api/sub-load-fragment.generated';
import { ApiTripEventFragment } from 'xo/graphql/api/trip-event-fragment.generated';
import { ApiWeatherObservationFragment } from 'xo/graphql/api/weather-observation-fragment.generated';
import { pluralize } from 'xo/utils/string-utils';

const siteTripEventKinds = new Set(Object.values(ApiSiteKind));

// Represents a journey a pigs from a set of sites to a dropoff site
export interface TripSummaryTimelineSubload {
  key: string;
  count: number;
  pickupDate: Dayjs;
  stops: TripSummaryTimelineStop[];
}

// Represents a single stop on the trip summary timeline
export interface TripSummaryTimelineStop {
  // No site found when Airtable site hasn't been mapped to visitor site
  site: ApiSiteFragment | undefined;
  // May not be any time input into Airtable
  scheduledDateTime: Dayjs | undefined;
  scheduledTimezoneAbbr: string | undefined;
  actualDateTime: Dayjs | undefined;
  status: 'incomplete' | 'complete' | 'skipped';
  eta: boolean;
  destination?: boolean;
  weather?: ApiWeatherObservationFragment;
}

const timelineKey = (s: ApiSubLoadFragment) =>
  `${s.toSite?.id}-${s.scheduledDropOffTime}`;
const stopKey = (s: TripSummaryTimelineStop) =>
  `${s.site?.id}-${s.scheduledDateTime?.format()}`;
export const getTripSummaryTimelineSubloads = ({
  subloads,
  events,
}: {
  subloads: ApiSubLoadFragment[];
  events: ApiTripEventFragment[];
}): TripSummaryTimelineSubload[] => {
  // We're only interested in site events for the timeline
  const siteEvents = events.filter(
    // ApiSiteKind is a subset of ApiTripEventKind
    e => e.site && siteTripEventKinds.has(e.kind as unknown as ApiSiteKind),
  );

  // Need to aggregate subloads by from/to site and times since there might be multiple
  const timelineSubloads: TripSummaryTimelineSubload[] = flatMap(
    groupBy(subloads, timelineKey),
    subloads => {
      const key = timelineKey(subloads[0]);
      const count = sumSubloadCount(subloads);

      const stops: TripSummaryTimelineStop[] = uniqBy(
        subloads.flatMap(s => {
          const fromSiteEvent = siteEvents.find(
            e =>
              e.site!.id === s.fromSite?.id &&
              e.dueStartTime?.isSame(s.scheduledPickupDatetime),
          );

          const toSiteEvent = siteEvents.find(
            e =>
              e.site!.id === s.toSite?.id &&
              e.dueStartTime?.isSame(s.scheduledDropOffDatetime),
          );

          const stops: TripSummaryTimelineStop[] = [
            {
              site: s.fromSite,
              scheduledDateTime: s.scheduledPickupDatetime,
              scheduledTimezoneAbbr: s.scheduledPickupTimezoneAbbr,
              actualDateTime: fromSiteEvent?.actualStartTime,
              status: fromSiteEvent?.actualStartTime
                ? 'complete'
                : 'incomplete',
              eta: !!fromSiteEvent?.predictedStartTime,
              destination: false,
              weather: fromSiteEvent?.weather,
            },
            {
              site: s.toSite,
              scheduledDateTime: s.scheduledDropOffDatetime,
              scheduledTimezoneAbbr: s.scheduledDropOffTimezoneAbbr,
              actualDateTime: toSiteEvent?.actualStartTime,
              status: toSiteEvent?.actualStartTime ? 'complete' : 'incomplete',
              eta: !!toSiteEvent?.predictedStartTime,
              destination: true,
              weather: toSiteEvent?.weather,
            },
          ];

          return stops;
        }),
        stopKey,
      );

      // Always put the destinations last so even if dropoff times are empty they're post-pickup
      // Then, always sort on scheduled time since this is the time they were *supposed* to arrive
      const sortedStops = sortBy(
        stops,
        s => s.destination,
        s => s.scheduledDateTime?.toDate(),
      );
      const sortedSkippedStops = sortedStops.map((s, i) => ({
        ...s,
        // Work out if any stops were skipped
        status:
          s.status === 'incomplete' &&
          sortedStops.slice(i + 1).some(s => s.status === 'complete')
            ? 'skipped'
            : s.status,
      }));
      const sortedSkippedEtaStops = sortedSkippedStops.map((s, i) => ({
        ...s,
        // Only apply ETA flag to last incomplete stop with an ETA
        eta:
          s.eta &&
          s.status === 'incomplete' &&
          sortedSkippedStops.slice(0, i).every(s => s.status === 'complete'),
      }));

      const timelineSubload: TripSummaryTimelineSubload = {
        key,
        count,
        pickupDate: subloads[0].scheduledPickupDate,
        stops: sortedSkippedEtaStops,
      };

      return timelineSubload;
    },
  );

  return sortBy(
    timelineSubloads,
    // Always sort on scheduled time since this is the time they were *supposed* to arrive
    t => first(t.stops)?.scheduledDateTime?.toDate(),
    // If there are multiple timelines with matching pickup times, sort them by drop off time
    t => last(t.stops)?.scheduledDateTime?.toDate(),
  );
};

export const timeDiffString = (expected: Dayjs, actual: Dayjs) => {
  const { totalMinutes, desc } = formatDuration({
    start: expected,
    end: actual,
    compact: true,
  });

  return totalMinutes == 0
    ? 'On time'
    : `${desc} ${totalMinutes > 0 ? 'late' : 'early'}`;
};

export const formatDuration = ({
  compact,
  start,
  end,
}: {
  compact?: boolean;
  start: Dayjs;
  end: Dayjs;
}) => {
  // diff based on just hh:mm, ignoring the seconds completely, e.g. 9:00:59 to 9:01:00 is only one
  // second apart, but we render it as "1 min".
  const alignedStart = start.startOf('minute');
  const alignedEnd = end.startOf('minute');

  const totalMinutes = alignedEnd.diff(alignedStart, 'minutes');

  // render these as absolute values; the parent will add early/late etc. if required
  const absTotalMinutes = Math.abs(totalMinutes);
  const hours = Math.floor(absTotalMinutes / 60);
  const minutes = absTotalMinutes % 60;

  const hoursDesc = compact ? 'h' : `${pluralize(hours, 'hour')}`;
  const minsDesc = compact ? 'min' : `${pluralize(minutes, 'min')}`;

  const format = (desc: string, count?: number) =>
    count ? `${count} ${desc}` : undefined;

  const result = [format(hoursDesc, hours), format(minsDesc, minutes)].filter(
    Boolean,
  );

  return {
    totalMinutes,
    desc: result.length ? result.join(' ') : `0 ${minsDesc}`,
  };
};

export const getStopKey = (stop: TripSummaryTimelineStop) =>
  `${stop.site?.id}-${(
    stop.actualDateTime ?? stop.scheduledDateTime
  )?.format()}`;

export const sumSubloadCount = (subloads: { count: number }[]) =>
  subloads.reduce((acc, n) => acc + n.count, 0);
