// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiDriverFragment = {
  __typename?: 'Driver';
  id: string;
  displayName: string;
  phone?: string;
};

export const DriverFragmentDoc = gql`
  fragment Driver on Driver {
    __typename
    id
    displayName
    phone
  }
`;
