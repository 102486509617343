import { cloneElement } from 'react';
import { Box } from 'xo/core';
import { Color } from 'xo/styles/restyle/theme';
import { hexFromThemeColor } from 'xo/utils/theme-utils';

export type IconCircleType = 'info' | 'info-bold' | 'error';

const styles: Record<IconCircleType, { bg: Color; fill: Color }> = {
  info: { bg: 'blue.50', fill: 'blue.600' },
  'info-bold': { bg: 'blue.400', fill: 'white' },
  error: { bg: 'red.100', fill: 'red.500' },
};

export interface IconCircleProps {
  type: IconCircleType;
  icon: JSX.Element;
  radius?: number;
  iconScale?: number;
}

export const IconCircle = ({
  type,
  icon,
  radius = 12,
  iconScale = 2.3,
}: IconCircleProps) => {
  const { bg, fill } = styles[type];

  return (
    <Box
      align="center"
      justify="center"
      rounded="full"
      bg={bg}
      w={radius * 2}
      h={radius * 2}
    >
      {cloneElement(icon, {
        fill: hexFromThemeColor(fill),
        scale: iconScale,
      })}
    </Box>
  );
};
