import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { FooterButtons, FooterButtonsProps } from './footer-buttons';

export interface FooterProps extends FooterButtonsProps {
  error?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ error, ...rest }) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} data-e2e-footer>
    <motion.div
      className="fixed bottom-0 left-0 z-10 h-20 w-full shadow-t-lg sm:h-16"
      initial="inactive"
      animate={error ? 'error' : rest.middle?.active ? 'active' : 'inactive'}
      variants={{
        inactive: { backgroundColor: colors.blue[600] },
        active: { backgroundColor: colors.grey[50] },
        error: { backgroundColor: colors.red[800] },
      }}
    >
      <AnimatePresence mode="wait">
        <FooterButtons {...rest} />
      </AnimatePresence>
    </motion.div>
  </motion.div>
);
