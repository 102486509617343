import classNames from 'classnames';
import React from 'react';
import { IconCircle } from './icon-circle';
import { Panel } from './panel';

export type PanelMessageType = 'info' | 'warning' | 'error' | 'success';

export interface PanelMessageProps {
  type: PanelMessageType;
  icon?: JSX.Element;
  className?: string;
  externalTo?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const PanelMessage: React.FC<PanelMessageProps> = ({
  type = 'info',
  icon,
  children,
  className,
  externalTo,
  style,
}) => {
  const iconClassName = classNames({
    'bg-blue-400': type === 'info',
    'bg-red-400': type === 'error',
    'bg-orange-300': type === 'warning',
    'bg-green-400': type === 'success',
  });

  const bgClassName = classNames({
    'bg-blue-50': type === 'info',
    'bg-opacity-20': ['error', 'warning', 'success'].includes(type),
    'bg-red-400': type === 'error',
    'bg-orange-300': type === 'warning',
    'bg-green-400': type === 'success',
  });

  return (
    <Panel
      backgroundClassName={classNames(bgClassName, className)}
      style={style}
    >
      <div className="flex flex-col items-center justify-center">
        {icon && (
          <IconCircle
            icon={icon}
            colorClassName={iconClassName}
            externalTo={externalTo}
          />
        )}
        <div className="mb-0 mt-2 w-full text-center text-lg">{children}</div>
      </div>
    </Panel>
  );
};
