import { captureException, captureMessage } from '@sentry/react';
import { Logger } from './logger.types';

const logger: Logger = {
  error: (err: Error | unknown) => {
    console.error(err);
    // sentry needs an Error object here
    captureException(err instanceof Error ? err : JSON.stringify(err));
  },
  warn: (message: any) => {
    console.warn(message);
    captureMessage(message);
  },
  info: (message: any) => {
    console.info(message);
    captureMessage(message);
  },
};

export default logger;
