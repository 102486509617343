import { Modal as AntModal } from 'antd';
import { ModalProps as AntModalProps, ModalFuncProps } from 'antd/es/modal';
import classNames from 'classnames';
import React from 'react';
import './modal.overrides.css';

export interface ModalProps extends AntModalProps {
  hideCancel?: boolean;
  hideOk?: boolean;
}

const getModalProps = (props: ModalProps): ModalProps => ({
  className: classNames('xo-ant-modal', {
    'xo-ant-modal-no-cancel': props.hideCancel,
  }),
  cancelButtonProps: props.hideCancel
    ? { style: { display: 'none' } }
    : undefined,
  okButtonProps: props.hideOk ? { style: { display: 'none' } } : undefined,
  maskClosable: true,
  centered: true,
  closable: false,
  ...props,
});

export const Modal: React.FC<ModalProps> = props => {
  const { children } = props;

  return <AntModal {...getModalProps(props)}>{children}</AntModal>;
};

export const confirm = (props: ModalFuncProps) =>
  AntModal.confirm(getModalProps(props));

export const info = (props: ModalFuncProps) =>
  AntModal.info(getModalProps(props));
