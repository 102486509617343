import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { useClipPathId } from 'xo/utils/svg-utils';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgPaper = (props: SvgIconProps) => {
  const { fill } = props;
  const { id, clipPath } = useClipPathId();

  return (
    <SvgIcon {...props}>
      <G clipPath={clipPath}>
        <Path
          d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"
          fill={fill}
        />
      </G>
      <Defs>
        <ClipPath id={id}>
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </SvgIcon>
  );
};
