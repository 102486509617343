import React, { useContext, useEffect } from 'react';
import { PageConfig, PageContext } from './page-provider';

export const Page: React.FC<PageConfig & { children?: React.ReactNode }> = ({
  navbar,
  footer,
  pageTitle,
  noFooter,
  noPadding,
  children,
}) => {
  const { onPageConfig } = useContext(PageContext);

  useEffect(() => {
    onPageConfig({ navbar, footer, pageTitle, noFooter, noPadding });
  }, [navbar, footer, pageTitle, noFooter, noPadding, onPageConfig]);

  return <>{children}</>;
};
