import { Select as AntSelect, ConfigProvider } from 'antd';
import {
  OptionProps as AntOptionProps,
  SelectProps as AntSelectProps,
  DefaultOptionType,
} from 'antd/es/select';
import classNames from 'classnames';
import { omit, xor } from 'lodash';
import { OptGroupProps } from 'rc-select/lib/OptGroup';
import React, { Fragment, useContext } from 'react';
import { confirm } from 'xo/components/web-modal';
import { OptionTypeValue, useOnClearConfirm } from 'xo/hooks/component-hooks';
import { InputVariant } from 'xo/models';
import { ClearButton } from './clear-button';
import { FormControlContext } from './form-control';
import { InputIcon } from './input-icon';
import { SelectRendererProps } from './select-renderer.props';
import './select.overrides.css';

const bareStyles: React.CSSProperties = {
  paddingLeft: 0,
  fontWeight: '600',
};

export const Option: React.FC<AntOptionProps> = AntSelect.Option;
export const OptGroup: React.FC<OptGroupProps> = AntSelect.OptGroup;

export interface SelectProps extends AntSelectProps<any> {
  multi?: boolean;
  prefixIcon?: JSX.Element;
  emptyMessage?: string;
  customSelectionContent?: JSX.Element;
}

const SelectRendererWeb = <T extends OptionTypeValue>({
  valueSet,
  onOptionSelected,
  multi,
  disabled,
  variant,
  sections,
  searchable,
  searchString,
  selectedOptions,
  options,
  renderSelected,
  placeholder,
  onSearch,
  filteredOptions,
  icon,
  clearable,
  webProps,
  onOpen,
  ariaLabel,
}: SelectRendererProps<T>) => {
  const control = useContext(FormControlContext);

  const {
    className,
    style,
    size,
    children,
    id,
    loading,
    emptyMessage,
    customSelectionContent,
  } = webProps ?? {};

  const onSelect = (value: OptionTypeValue) =>
    onOptionSelected(value, !valueSet.has(value));

  const onClearConfirm = useOnClearConfirm({
    clearable,
    onChange: () => {
      Array.from(valueSet).forEach(value => {
        onOptionSelected(value, false);
      });
    },
    confirm,
  });

  const select = (
    <div className="relative">
      <AntSelect
        id={id}
        optionLabelProp={multi && !children ? 'label' : undefined}
        {...omit(webProps, ['emptyMessage', 'customSelectionContent'])}
        className={classNames('xo-ant-select', {
          'xo-ant-select-icon': icon,
          'xo-ant-select-custom-selection': customSelectionContent,
          'xo-ant-select-bare': variant === InputVariant.Bare,
        })}
        disabled={loading || disabled}
        placeholder={loading ? 'Loading...' : placeholder}
        style={{
          width: '100%',
          ...style,
        }}
        size={size || 'large'}
        mode={multi ? 'multiple' : undefined}
        showArrow={true}
        // https://github.com/ant-design/ant-design/issues/8268
        // Suggestion in this issue says to add this to have dropdown follow parent on scroll.
        // That works, but can cause dropdown to be hidden in some cases, so don't use it.
        // Underlying issue is supposedly fixed in v5, but requires migration
        // getPopupContainer={trigger => trigger.parentNode}
        tagRender={customSelectionContent ? () => <Fragment /> : undefined}
        options={filteredOptions as DefaultOptionType[]}
        value={Array.from(valueSet)}
        searchValue={searchString}
        onSearch={searchable ? onSearch : undefined}
        showSearch={searchable}
        filterOption={false}
        bordered={!variant}
        onChange={value => {
          if (Array.isArray(value)) {
            // only touch the elements that have changed
            xor(value, Array.from(valueSet)).forEach(onSelect);
          } else {
            onSelect(value);
            control?.onBlur();
          }
        }}
        onDropdownVisibleChange={open => {
          if (open && onOpen) {
            onOpen();
            control?.onFocus();
          }
        }}
        onFocus={control?.onFocus}
        onBlur={control?.onBlur}
        aria-label={ariaLabel}
      >
        {children}
      </AntSelect>

      {clearable && valueSet.size > 0 ? (
        <ClearButton
          buttonClassName="absolute right-0 top-0 bottom-0 mr-2"
          onClick={onClearConfirm}
        />
      ) : null}
    </div>
  );

  return (
    <div className={classNames('relative', className)} id={id}>
      {emptyMessage ? (
        <ConfigProvider
          renderEmpty={() => (
            <div className="flex items-center justify-center">
              {emptyMessage}
            </div>
          )}
        >
          {select}
        </ConfigProvider>
      ) : (
        select
      )}
      {icon && (
        <span
          className="absolute"
          style={{
            top: '8px',
            left: '8px',
          }}
        >
          <InputIcon fill={icon?.props.fill} disabled={disabled}>
            {icon}
          </InputIcon>
        </span>
      )}
      {customSelectionContent && (
        <div
          className={classNames(
            'xo-ant-select-custom-selection-content pointer-events-none absolute left-0 top-0 ml-10 mt-1.5',
          )}
        >
          {customSelectionContent}
        </div>
      )}
    </div>
  );
};

export default SelectRendererWeb;
