// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiStreetAddressFragment = {
  __typename?: 'StreetAddress';
  line1?: string;
  line2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  countryCode?: string;
};

export const StreetAddressFragmentDoc = gql`
  fragment StreetAddress on StreetAddress {
    __typename
    line1
    line2
    suburb
    state
    postcode
    countryCode
  }
`;
