import { ApiProvider } from 'xo/graphql/api/enums/provider.generated';

import gql from 'graphql-tag';
export type ApiPositionFragment = {
  __typename?: 'Position';
  lat: number;
  lng: number;
  accLatlng?: number;
  provider?: ApiProvider;
};

export const PositionFragmentDoc = gql`
  fragment Position on Position {
    __typename
    lat
    lng
    accLatlng
    provider
  }
`;
