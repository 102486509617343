import React from 'react';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgTruckSchedule: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.44 4C2.98667 4 1 5.99111 1 8.44444C1 10.8978 2.98667 12.8889 5.44 12.8889C7.89778 12.8889 9.88889 10.8978 9.88889 8.44444C9.88889 5.99111 7.89778 4 5.44 4ZM5.44444 12C3.48 12 1.88889 10.4089 1.88889 8.44444C1.88889 6.48 3.48 4.88889 5.44444 4.88889C7.40889 4.88889 9 6.48 9 8.44444C9 10.4089 7.40889 12 5.44444 12ZM5.66667 6.22222H5V8.88889L7.33333 10.2889L7.66667 9.74222L5.66667 8.55556V6.22222ZM10.7778 8.44439C10.7778 11.3899 8.38996 13.7777 5.44444 13.7777C4.82109 13.7777 4.22272 13.6708 3.66667 13.4742V17.3333H5.44444C5.44444 18.8089 6.63556 20 8.11111 20C9.58667 20 10.7778 18.8089 10.7778 17.3333H16.1111C16.1111 18.8089 17.3022 20 18.7778 20C20.2533 20 21.4444 18.8089 21.4444 17.3333H23.2222V12.8889L20.5556 9.33333H17.8889V5.77778H10.0643C10.5181 6.56223 10.7778 7.47298 10.7778 8.44439ZM8.11111 18.6666C7.37333 18.6666 6.77777 18.0711 6.77777 17.3333C6.77777 16.5955 7.37333 15.9999 8.11111 15.9999C8.84888 15.9999 9.44444 16.5955 9.44444 17.3333C9.44444 18.0711 8.84888 18.6666 8.11111 18.6666ZM20.1111 10.6666L21.8533 12.8888H17.8889V10.6666H20.1111ZM18.7778 18.6666C18.04 18.6666 17.4444 18.0711 17.4444 17.3333C17.4444 16.5955 18.04 15.9999 18.7778 15.9999C19.5156 15.9999 20.1111 16.5955 20.1111 17.3333C20.1111 18.0711 19.5156 18.6666 18.7778 18.6666Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
