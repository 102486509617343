export enum InputVariant {
  // useful for table cells where the whole form control is editable
  Bare = 'bare',
  Editable = 'editable',
}
export enum LogoVariant {
  // used to apply theme colors to the logo svg
  Black = 'black',
  BlackOrange = 'black-orange',
  Orange = 'orange',
  White = 'white',
  WhiteBlack = 'white-black',
  WhiteOrange = 'white-orange',
}
