import { ApiUpdateUserAdminInput } from 'xo/graphql/api/inputs/update-user-admin-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiUserAdminFragment,
  UserAdminFragmentDoc,
} from './user-admin-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiUpdateUserAdminMutationVariables = Exact<{
  input: ApiUpdateUserAdminInput;
}>;

export type ApiUpdateUserAdminMutation = {
  __typename?: 'Mutation';
  updateUserAdmin: { __typename?: 'User' } & ApiUserAdminFragment;
};

export const UpdateUserAdminDocument = gql`
  mutation UpdateUserAdmin($input: UpdateUserAdminInput!) {
    __typename
    updateUserAdmin(input: $input) {
      ...UserAdmin
    }
  }
  ${UserAdminFragmentDoc}
`;

export function useUpdateUserAdminMutation() {
  return Urql.useMutation<
    ApiUpdateUserAdminMutation,
    ApiUpdateUserAdminMutationVariables
  >(UpdateUserAdminDocument);
}
