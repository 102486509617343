import { omit } from 'lodash';

export const textMatchesInput = (input?: string, text?: string) => {
  const searchableText =
    text && text.toLowerCase && text.toLowerCase().replace(/ /g, '');

  return Boolean(
    input &&
      searchableText &&
      String(input)
        ?.toLowerCase()
        .split(/\W+/)
        .every(query => searchableText?.includes(query)),
  );
};

export const objectMatchesInput = <T extends { id?: string }>(
  input: string,
  item: T,
  extraFilterFields?: (item: T) => string[],
) => {
  const fieldValues = Object.values(omit(item, 'id'))
    .concat(extraFilterFields ? extraFilterFields(item) : [])
    .filter(f => f);

  return fieldValues.some(v => textMatchesInput(input, String(v)));
};
