import { useScrollIntoView } from '@mantine/hooks';
import { FormInstance as AntFormInstance } from 'antd/es/form';
import { InternalNamePath } from 'antd/es/form/interface';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useCallback } from 'react';
import { textMatchesInput } from 'xo/utils/search-utils';
import { scrollErrorIntoViewSettings } from '../app/e2e-shared';

// Taken from https://github.com/ant-design/ant-design/blob/47dfa1f2ca81df1d1791b4f58ff4690800493be8/components/form/util.ts#L9
function getFieldId(
  namePath: InternalNamePath,
  formName?: string,
): string | undefined {
  if (!namePath.length) return undefined;

  const mergedId = namePath.join('_');
  return formName ? `${formName}_${mergedId}` : mergedId;
}

export const useScrollToFirstError = <T>(form?: AntFormInstance<T>) => {
  const { targetRef, scrollIntoView } = useScrollIntoView<HTMLElement>(
    scrollErrorIntoViewSettings,
  );
  return useCallback(
    ({ errorFields }: ValidateErrorEntity) => {
      const errorFieldName = errorFields.find(f => f.errors.length > 0)?.name;
      if (errorFieldName) {
        // Implement scroll behaviour same
        form?.scrollToField(errorFieldName, {
          behavior: () => {
            const fieldId = getFieldId(
              Array.isArray(errorFieldName) ? errorFieldName : [errorFieldName],
            );
            if (fieldId) {
              const node = document.getElementById(fieldId);
              if (node) {
                // @ts-ignore
                targetRef.current = node;
                scrollIntoView({ alignment: 'start' });
              }
            }
          },
          scrollMode: 'always',
        });
      }
    },
    [form, targetRef, scrollIntoView],
  );
};

export const useFilterOption = (optionKey: string = 'label') =>
  useCallback(
    (input: string, option?: any) => {
      const label = option && option[optionKey];

      const text =
        typeof label === 'string'
          ? label
          : typeof label?.props?.children === 'string'
            ? label.props.children
            : '';

      return textMatchesInput(input, text);
    },
    [optionKey],
  );
