import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgExcel = (props: SvgIconProps) => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M18.8877 10.7276V8.49536L14.2077 3.83984H5.18423V10.7276H3.83984V18.6231H5.18423V20.1597H18.8887V18.5753H20.16V10.7275L18.8877 10.7276ZM13.8955 5.37532L17.3277 8.78308L13.8955 8.78401V5.37532ZM6.40775 5.06406H12.6722V10.0085H17.64V10.6807L6.40775 10.6798V5.06406ZM14.16 16.3918V17.2562L10.5122 17.2553V12.264H11.5444V16.3917L14.16 16.3918ZM5.18447 17.2553L6.91228 14.6397L5.32789 12.192H6.55228L7.58447 13.8242L8.61666 12.192H9.84011L8.25572 14.6397L10.0079 17.232H8.73572L7.60792 15.5042L6.48011 17.232C6.45573 17.2554 5.18447 17.2553 5.18447 17.2553ZM17.64 19.0797H6.40775V18.5753H17.64V19.0797ZM18.36 16.8475C18.12 17.0396 17.8078 17.1353 17.76 17.1353C17.4478 17.2309 17.1356 17.3275 16.7044 17.3275C16.0322 17.3275 15.5766 17.1831 15.2166 16.8718C14.8566 16.5596 14.6409 16.1518 14.5922 15.5996L15.6244 15.504C15.6722 15.8162 15.84 16.0562 15.9844 16.224C16.2 16.3918 16.44 16.4884 16.7522 16.4884C17.1122 16.4884 17.3278 16.4406 17.52 16.2962C17.6644 16.1518 17.7844 15.984 17.7844 15.7918C17.7844 15.6962 17.76 15.5762 17.6887 15.4796C17.5931 15.3596 17.4731 15.3353 17.3287 15.2875C17.1844 15.2396 16.9209 15.1918 16.5131 15.0953C15.9375 14.9509 15.5287 14.7831 15.3375 14.5909C15.0253 14.3265 14.8819 13.9909 14.8819 13.5831C14.8819 13.2953 15.0263 12.8875 15.2419 12.6953C15.3863 12.5509 15.5541 12.4309 15.7697 12.3597C16.0341 12.264 16.3941 12.2153 16.8019 12.2153C17.4741 12.2153 17.9297 12.3597 18.2419 12.6231C18.5522 12.8875 18.72 13.224 18.72 13.6796L17.6878 13.7274C17.64 13.4631 17.5435 13.2718 17.3756 13.1753C17.2313 13.0796 17.0156 13.0309 16.7035 13.0309C16.3913 13.0309 16.1278 13.0787 15.9835 13.2231C15.8878 13.3187 15.8391 13.4153 15.8391 13.5353C15.8391 13.6553 15.8869 13.7275 15.9835 13.8475C16.1278 13.9675 16.4391 14.0396 16.9678 14.2075C17.4956 14.3275 17.8556 14.4475 18.0956 14.5675C18.0956 14.5675 18.9835 14.8553 18.8156 16.0796C18.7922 16.4396 18.5522 16.704 18.36 16.8475Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
