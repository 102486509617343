import { zodResolver } from '@hookform/resolvers/zod';
import { usePrevious } from '@mantine/hooks';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  DeepPartialSkipArrayKey,
  FieldValues,
  FormProvider,
  FormProviderProps,
  UseFormProps,
  UseFormReset,
  useForm as useRHFForm,
  useWatch,
} from 'react-hook-form';
import { Loader } from 'xo/components/loader';
import { AppStorage } from 'xo/storage/app-storage';
import { ZodSchema } from 'zod';

export enum FormMode {
  Edit = 'EDIT',
  View = 'VIEW',
  Loading = 'LOADING',
}

export interface UseFormPersistProps<TFieldValues extends FieldValues> {
  storageKey: string;
  onLoad?: (values: TFieldValues | null) => TFieldValues | null;
}

export const useForm = <TFieldValues extends FieldValues>({
  schema,
  ...rest
}: UseFormProps<TFieldValues> & {
  schema?: ZodSchema;
} = {}) => {
  const methods = useRHFForm<TFieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: schema && zodResolver(schema),
    ...rest,
  });

  return methods;
};

interface FormContentProps<TFieldValues extends FieldValues> {
  persist?: UseFormPersistProps<TFieldValues>;
  reset: UseFormReset<TFieldValues>;
  children: React.ReactNode;
  onValuesChange?: (values: DeepPartialSkipArrayKey<TFieldValues>) => void;
}

const FormContent = <TFieldValues extends FieldValues>({
  persist,
  reset,
  children,
  onValuesChange,
}: FormContentProps<TFieldValues>) => {
  const values = useWatch<TFieldValues>();
  const prevValues = usePrevious(values);
  useEffect(() => {
    if (!isEqual(values, prevValues)) {
      onValuesChange && onValuesChange(values);

      if (persist) {
        AppStorage.setOrMergeItem(persist.storageKey, values);
      }
    }
  }, [persist, values, onValuesChange, prevValues]);

  const [formReady, setFormReady] = useState(!persist);
  useEffect(() => {
    if (persist) {
      AppStorage.getItem<TFieldValues>(persist.storageKey).then(values => {
        if (values) {
          reset(values);
        }
        persist.onLoad && persist.onLoad(values);
        setFormReady(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return persist ? (
    <Loader loading={!formReady}>{children}</Loader>
  ) : (
    <>{children}</>
  );
};

export interface UseFormModeProps {
  defaultMode?: FormMode;
  loading?: boolean;
}

export const useFormMode = ({
  defaultMode = FormMode.Edit,
  loading = false,
}: UseFormModeProps = {}) => {
  const [mode, setMode] = useState<FormMode>(
    loading ? FormMode.Loading : defaultMode,
  );

  useUpdateFormLoading({ loading, defaultMode, setMode });

  return useMemo(
    () => ({
      mode,
      setMode,
    }),
    [mode, setMode],
  );
};

export const useUpdateFormLoading = ({
  loading,
  defaultMode,
  setMode,
}: {
  loading?: boolean;
  defaultMode: FormMode;
  setMode: UseFormMode['setMode'];
}) => {
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (prevLoading !== loading) {
      setMode(loading ? FormMode.Loading : defaultMode);
    }
  }, [loading, prevLoading, setMode, defaultMode]);
};

export type UseFormMode = ReturnType<typeof useFormMode>;

const FormModeContext = React.createContext<UseFormMode>(null as any);

export interface FormProps<TFieldValues extends FieldValues, TContext>
  extends FormProviderProps<TFieldValues, TContext> {
  children: React.ReactNode;
  persist?: UseFormPersistProps<TFieldValues>;
  onValuesChange?: (values: Partial<TFieldValues>) => void;
  formMode?: UseFormMode;
  loading?: boolean;
  defaultFormMode?: FormMode;
}

export const Form = <TFieldValues extends FieldValues, TContext>({
  children,
  persist,
  onValuesChange,
  formMode: propsFormMode,
  defaultFormMode,
  loading,
  ...methods
}: FormProps<TFieldValues, TContext>) => {
  const stateFormMode = useFormMode({ loading, defaultMode: defaultFormMode });

  useUpdateFormLoading({
    loading,
    defaultMode: defaultFormMode ?? FormMode.Edit,
    setMode: propsFormMode?.setMode ?? stateFormMode?.setMode,
  });

  return (
    <FormModeContext.Provider value={propsFormMode ?? stateFormMode}>
      <FormProvider {...methods}>
        <FormContent
          persist={persist}
          reset={methods.reset}
          onValuesChange={onValuesChange}
        >
          {children}
        </FormContent>
      </FormProvider>
    </FormModeContext.Provider>
  );
};

export const useFormModeContext = (): UseFormMode =>
  useContext(FormModeContext);
