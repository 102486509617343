// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
import {
  ApiSiteSummaryFragment,
  SiteSummaryFragmentDoc,
} from './site-summary-fragment.generated';
import {
  ApiUserSummaryFragment,
  UserSummaryFragmentDoc,
} from './user-summary-fragment.generated';
export type ApiScheduledMovementIngestionFragment = {
  __typename?: 'ScheduledMovementIngestion';
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  createdAtTz?: Dayjs;
  lastUpdatedAtTz?: Dayjs;
  createdBy?: { __typename?: 'User' } & ApiUserSummaryFragment;
  lastUpdatedBy?: { __typename?: 'User' } & ApiUserSummaryFragment;
  schedulingOrganisation: {
    __typename?: 'Organisation';
  } & ApiOrganisationSummaryFragment;
  destinationSite: { __typename?: 'Site' } & ApiSiteSummaryFragment;
  ingestedByUser: { __typename?: 'User' } & ApiUserSummaryFragment;
};

export const ScheduledMovementIngestionFragmentDoc = gql`
  fragment ScheduledMovementIngestion on ScheduledMovementIngestion {
    __typename
    id
    startDate
    endDate
    createdAtTz
    lastUpdatedAtTz
    createdBy {
      ...UserSummary
    }
    lastUpdatedBy {
      ...UserSummary
    }
    schedulingOrganisation {
      ...OrganisationSummary
    }
    destinationSite {
      ...SiteSummary
    }
    ingestedByUser {
      ...UserSummary
    }
  }
  ${UserSummaryFragmentDoc}
  ${OrganisationSummaryFragmentDoc}
  ${SiteSummaryFragmentDoc}
`;
