import React from 'react';
import { ActivityIndicator } from 'react-native';
import { FadeEnterExit } from 'xo/components/fade-enter-exit';
import { Box } from 'xo/core';
import { ErrorHero } from './error-hero';

export interface LoaderProps {
  loading: boolean;
  error?: string;
  children: React.ReactNode;
  onRetry?: () => void;
}

export const Loader: React.FC<LoaderProps> = ({
  loading,
  error,
  onRetry,
  children,
}) => (
  <FadeEnterExit visibleKey={loading ? 'loading' : error ? 'error' : 'none'}>
    {loading || error ? (
      <Box align="center" justify="center" mt="24" mx="4">
        {error ? (
          <>
            <ErrorHero
              title="An error occurred"
              description="Sorry! Something went wrong, and we'll look into it for you."
              retry={{
                onPress: onRetry,
                loading,
                children: 'RETRY',
              }}
            />
          </>
        ) : (
          <ActivityIndicator />
        )}
      </Box>
    ) : (
      <>{children}</>
    )}
  </FadeEnterExit>
);
