import dayjs from 'dayjs';
import { ISO_8601_FORMAT } from 'xo/constants';
import { validateUuidLike } from 'xo/utils/validation-utils';

export const defaultJsonReviver = (_key: string, value: any) => {
  if (value && typeof value === 'string' && !validateUuidLike(value)) {
    const dayjsValue = dayjs.utc(value, ISO_8601_FORMAT, true);
    return dayjsValue.isValid() ? dayjsValue : value;
  }

  return value;
};
