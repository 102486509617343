import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgBarChart: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M10 20H14V4H10V20V20ZM4 20H8V12H4V20V20ZM16 9V20H20V9H16V9Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
