// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import {
  ApiOrganisationBriefSummaryFragment,
  OrganisationBriefSummaryFragmentDoc,
} from './organisation-brief-summary-fragment.generated';
import {
  ApiStreetAddressFragment,
  StreetAddressFragmentDoc,
} from './street-address-fragment.generated';
export type ApiOrganisationSummaryFragment = {
  __typename?: 'Organisation';
  address?: { __typename?: 'StreetAddress' } & ApiStreetAddressFragment;
} & ApiOrganisationBriefSummaryFragment;

export const OrganisationSummaryFragmentDoc = gql`
  fragment OrganisationSummary on Organisation {
    __typename
    ...OrganisationBriefSummary
    address {
      ...StreetAddress
    }
  }
  ${OrganisationBriefSummaryFragmentDoc}
  ${StreetAddressFragmentDoc}
`;
