import { LocationDescriptor } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';

export interface OptionalLinkProps {
  to?: LocationDescriptor;
  className?: string;
  fallback?: JSX.Element;
  children?: React.ReactNode;
}

export const OptionalLink: React.FC<OptionalLinkProps> = ({
  to,
  className,
  children,
  fallback,
  ...rest
}) => {
  return to ? (
    <Link className={className} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    React.cloneElement(fallback ?? <span />, { className, children, ...rest })
  );
};
