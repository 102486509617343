import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApiFormKind } from 'xo/graphql/api/enums/form-kind.generated';
import {
  FormInfoResponse,
  FormKind,
  FormReadModel,
  VisitReadModel,
} from '../api-models';
import { ErrorPageContext } from '../app/components/error-page-provider';
import {
  BaseBiosecurityQuestionnaireModel,
  FormConfigModel,
  QuestionnaireBlockedReason,
  VisitQuestionnaireModel,
} from '../models/visitor-log-models';
import { getApi } from './axios-instance';
import { useQrState } from './qr-hooks';
import {
  blockedReasonTransformer,
  formFinishRequestTransformer,
  readFormConfigTransformer,
  readFormResponsesTransformer,
  readVisitTransformer,
} from './transformers';
import { visitQueryKeys } from './visit-network-hooks';

const formApi = getApi('form');

export const questionnaireKey = (kind: FormKind, idOrToken: string) =>
  `${kind}:${idOrToken}`;

export type PostQuestionnaireVariables = BaseBiosecurityQuestionnaireModel & {
  completionTime?: number;
  qrTimestamp?: Dayjs;
  signScanType?: string;
  kind: FormKind;
  config: FormConfigModel | undefined;
};

const readQuestionnaireTransformer = (response: {
  visit: VisitReadModel;
  form?: FormReadModel;
}): VisitQuestionnaireModel => ({
  id: response.form?.id,
  token: response.form?.token,
  kind: response.form?.kind,
  visit: readVisitTransformer(response.visit),
  config: response.form?.config
    ? readFormConfigTransformer({
        config: response.form.config,
      })
    : undefined,
  responses: response.form ? readFormResponsesTransformer(response.form) : {},
});

// Cache questionnaires by id and token
const useSetQuestionnaireQueryData = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (
      questionnaire: VisitQuestionnaireModel,
      blockedReason: QuestionnaireBlockedReason | undefined,
    ) => {
      if (questionnaire.kind) {
        [questionnaire.id, questionnaire.token]
          .filter(Boolean)
          .forEach(idOrToken => {
            queryClient.setQueryData(
              questionnaireKey(questionnaire.kind!, idOrToken!),
              { ...questionnaire, blockedReason },
            );
          });
      }
    },
    [queryClient],
  );
};

export const usePostQuestionnaireSuccess = () => {
  const queryClient = useQueryClient();
  const setQuestionnaireQueryData = useSetQuestionnaireQueryData();

  return useCallback(
    (response: { visit: VisitReadModel; form?: FormReadModel }) => {
      const questionnaire: VisitQuestionnaireModel =
        readQuestionnaireTransformer(response);

      const blockedReason =
        response.form && response.visit
          ? blockedReasonTransformer(response.form, questionnaire.visit)
          : undefined;

      setQuestionnaireQueryData(questionnaire, blockedReason);

      queryClient.setQueryData(
        visitQueryKeys.visit(response.visit.id),
        questionnaire.visit,
      );

      return questionnaire;
    },
    [queryClient, setQuestionnaireQueryData],
  );
};

export const usePostQuestionnaire = () => {
  const onPostQuestionnaireSuccess = usePostQuestionnaireSuccess();
  const { qrTimestamp, signScanType } = useQrState();

  return useMutation(
    (data: PostQuestionnaireVariables) => {
      const request = formFinishRequestTransformer({
        ...data,
        qrTimestamp,
        signScanType,
      });
      // XO-4711 keeps coming up and we don't know why, so we're over-logging to see if we can
      // narrow it down
      console.info(
        `Finishing questionnaire ${data.id}: data.answers keys=${JSON.stringify(
          Object.keys(data.answers ?? {}),
        )} request.questions keys=${JSON.stringify(
          Object.keys(request.questions ?? {}),
        )}`,
      );

      return formApi
        .post(`/${data.id}/finish`, request)
        .then(r => onPostQuestionnaireSuccess(r.data));
    },
    { retry: false },
  );
};

export const useGetQuestionnaire = ({
  id,
  kind,
  enabled = true,
  refetchInterval,
}: {
  id: string;
  kind: FormKind;
  enabled?: boolean;
  refetchInterval?: number;
}) => {
  const { onErrorPage } = useContext(ErrorPageContext);
  const setQuestionnaireQueryData = useSetQuestionnaireQueryData();

  return useQuery<VisitQuestionnaireModel>(
    questionnaireKey(kind, id),
    () =>
      getApi('form', { notifyOnError: false, onErrorPage })
        .get(`/${id}`)
        .then((response: AxiosResponse<FormInfoResponse>) => {
          const visit = readVisitTransformer(response.data.visit);
          const blockedReason = blockedReasonTransformer(
            response.data.form,
            visit,
          );

          const questionnaire: VisitQuestionnaireModel =
            readQuestionnaireTransformer(response.data);

          setQuestionnaireQueryData(questionnaire, blockedReason);

          return { ...questionnaire, blockedReason };
        }),
    {
      retry: false,
      enabled,
      refetchInterval,
      // cache the previsit indefinitely because it doesn't change and if we fetch it we get denySubmitReason: TOO_LATE
      staleTime: kind === ApiFormKind.Previsit ? Infinity : undefined,
    },
  );
};
