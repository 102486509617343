import dayjs from 'dayjs';
import { cloneDeep, isNil } from 'lodash';
import { Exchange } from 'urql';
import { map, pipe } from 'wonka';
import { parseDate } from '../../date-utils';
import importedPaths from '../codegen/transformer-type-paths.json';

export type ApiTransformerTypePaths = {
  [typeName: string]: {
    field: string;
    transformer: string;
  }[];
};

export type ApiTransformers = Record<string, (value: any, parent: any) => any>;
export const apiTransformers: ApiTransformers = {
  date: (v?: string) => (v ? parseDate(v) : v),
  localDateTime: (v?: string) => (v ? dayjs(v) : v),
  duration: (v?: number) => (v ? { seconds: v } : v),
};

const justAnObjectPrototype = Object.getPrototypeOf({});

export const transformApiRequest = (obj: any): any => {
  // recursively strip out any __typename fields
  if (Array.isArray(obj)) {
    return obj.map(transformApiRequest);
  }

  if (
    obj !== null &&
    typeof obj === 'object' &&
    Object.getPrototypeOf(obj) === justAnObjectPrototype
  ) {
    // Only recur into 'raw' objects, created like {a: 123} or JSON.parse(...), rather than a new
    // Date() or new Blob() or whatever.
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, _]) => key !== '__typename')
        .map(([key, value]) => [key, transformApiRequest(value)]),
    );
  }

  return obj;
};

const iterate = (
  obj: any,
  transformers: ApiTransformers,
  paths: ApiTransformerTypePaths,
) => {
  if (Array.isArray(obj)) {
    obj.forEach(o => iterate(o, transformers, paths));
    return;
  }

  // NB. Make sure everything has a __typename, incl. the queries
  if (isNil(obj) || !obj.hasOwnProperty('__typename')) return;

  let typeName = obj.__typename;
  Object.entries(obj).forEach(([key, value]) => {
    if (paths[typeName]) {
      paths[typeName]
        .filter(p => p.field === key)
        .forEach(({ transformer }) => {
          if (transformers[transformer]) {
            obj[key] = transformers[transformer](value, obj);
          }
        });
    }

    if (typeof value === 'object') {
      iterate(value, transformers, paths);
    }
  });
};

export const transformApiResponse = (
  obj: any,
  transformers: ApiTransformers = apiTransformers,
  paths: ApiTransformerTypePaths = importedPaths,
) => {
  const copy = cloneDeep(obj);

  iterate(copy, transformers, paths);

  return copy;
};

export const transformerExchange: Exchange =
  ({ forward }) =>
  operations$ =>
    pipe(
      operations$,
      map(op => {
        op.variables = transformApiRequest(op.variables);
        return op;
      }),
      forward,
      map(result => {
        result.data = transformApiResponse(result.data);
        return result;
      }),
    );
