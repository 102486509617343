import { Exact, Scalars } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import { ApiTripFragment, TripFragmentDoc } from './trip-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetTripQueryVariables = Exact<{
  id: Scalars['TripID']['input'];
}>;

export type ApiGetTripQuery = {
  __typename?: 'Query';
  trip?: { __typename?: 'Trip' } & ApiTripFragment;
};

export const GetTripDocument = gql`
  query GetTrip($id: TripID!) {
    __typename
    trip(id: $id) {
      ...Trip
    }
  }
  ${TripFragmentDoc}
`;

export function useGetTripQuery(
  options: Omit<Urql.UseQueryArgs<ApiGetTripQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiGetTripQuery, ApiGetTripQueryVariables>({
    query: GetTripDocument,
    ...options,
  });
}
