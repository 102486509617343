import React from 'react';
import { Switch } from 'react-router-dom';
import { SentryRoute } from '../../utils/sentry';
import { NotFoundPage } from './error-page';

export const NotFoundSwitch: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <Switch>
    {children}
    <SentryRoute>
      <NotFoundPage />
    </SentryRoute>
  </Switch>
);
