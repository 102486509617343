import { Radio as AntRadio } from 'antd';
import {
  RadioGroupProps as AntRadioGroupProps,
  RadioProps as AntRadioProps,
} from 'antd/es/radio';
import classNames from 'classnames';
import { omit } from 'lodash';
import React from 'react';
import { fontSize, margin } from 'xo/styles/tailwind-theme';
import { OptionType } from './component-models';
import './radio-group.overrides.css';

export interface RadioGroupProps extends AntRadioGroupProps {
  horizontal?: boolean;
  options: (Omit<OptionType, 'label'> & {
    label: string | JSX.Element;
    subLabel?: string | JSX.Element;
  } & AntRadioProps)[];
  boldLabel?: boolean;
}

export const RadioGroup: React.FC<RadioGroupProps> = props => {
  const { options, size, horizontal, boldLabel } = props;

  return (
    <AntRadio.Group
      size={size || 'large'}
      {...omit(props, 'options')}
      className={classNames(props.className, 'xo-radio-group')}
    >
      <div
        className={classNames({
          'flex items-center': horizontal,
        })}
      >
        {options.map(({ label, subLabel, value, style }, i) => (
          <AntRadio
            key={i}
            value={value}
            style={{
              display: 'flex',
              alignItems: 'start',
              fontSize: size === 'middle' ? fontSize.base : fontSize.lg,
              whiteSpace: 'normal',
              marginTop: !horizontal ? margin[4] : undefined,
              marginBottom: !horizontal ? margin[4] : undefined,
              marginLeft: i > 0 && horizontal ? margin[8] : undefined,
              ...style,
            }}
          >
            <div
              className={classNames({ 'font-bold': boldLabel })}
              style={{ marginTop: '-5px' }}
            >
              {label}
            </div>
            <div className="text-sm">{subLabel}</div>
          </AntRadio>
        ))}
      </div>
    </AntRadio.Group>
  );
};
