export enum ApiTripEventKind {
  Abattoir = 'ABATTOIR',
  Accident = 'ACCIDENT',
  BiosecurityShed = 'BIOSECURITY_SHED',
  EndTrip = 'END_TRIP',
  Farm = 'FARM',
  Hidden = 'HIDDEN',
  Loading = 'LOADING',
  PaperDeliveryCheckIn = 'PAPER_DELIVERY_CHECK_IN',
  QrDeliveryCheckIn = 'QR_DELIVERY_CHECK_IN',
  StartTrip = 'START_TRIP',
  TruckDepot = 'TRUCK_DEPOT',
  UnexpectedStop = 'UNEXPECTED_STOP',
  Unknown = 'UNKNOWN',
  Unloading = 'UNLOADING',
  UnrelatedAccident = 'UNRELATED_ACCIDENT',
  WashBay = 'WASH_BAY',
}
