import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgCog: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73 9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.54 5.05 19.27 4.97 19.05 5.05L16.56 6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H10C9.75001 2 9.54001 2.18 9.51001 2.42L9.13001 5.07C8.52001 5.32 7.96001 5.66 7.44001 6.05L4.95001 5.05C4.72001 4.96 4.46001 5.05 4.34001 5.27L2.34001 8.73C2.21001 8.95 2.27001 9.22 2.46001 9.37L4.57001 11.02C4.53001 11.34 4.50001 11.67 4.50001 12C4.50001 12.33 4.53001 12.66 4.57001 12.98L2.46001 14.63C2.27001 14.78 2.22001 15.05 2.34001 15.27L4.34001 18.73C4.46001 18.95 4.73001 19.03 4.95001 18.95L7.44001 17.95C7.96001 18.35 8.52001 18.68 9.13001 18.93L9.51001 21.58C9.54001 21.82 9.75001 22 10 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87 18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.28 19.04 19.54 18.95 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM12 15.5C10.07 15.5 8.50001 13.93 8.50001 12C8.50001 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
