import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { FadeEnterExit } from './fade-enter-exit';
import { Skeleton } from './skeleton';

export interface SkeletonLoaderProps {
  loading: any;
  large?: boolean;
  children?: React.ReactNode;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  loading,
  children,
}) => (
  <AnimatePresence mode="wait">
    <FadeEnterExit key={loading}>
      {loading ? <Skeleton /> : children}
    </FadeEnterExit>
  </AnimatePresence>
);
