import classNames from 'classnames';
import React from 'react';
import { OptionalLink } from '../components/optional-link';

interface AdminIdsTableProps {
  entities: {
    name: string;
    id?: string;
    link?: (id: string | undefined) => string;
  }[];
}
export const AdminIdsTable: React.FC<AdminIdsTableProps> = ({ entities }) => (
  <table className="mt-2 text-xs">
    <thead className="border-b-2 border-blue-600 uppercase text-blue-600">
      <tr>
        <th>Entity</th>
        <th>ID</th>
      </tr>
    </thead>
    <tbody>
      {entities.map(({ name, id, link }, idx) => (
        <tr
          className={classNames('pt-2', {
            'bg-grey-100': idx % 2 === 1,
          })}
        >
          <td>{name}</td>
          <td>
            <OptionalLink
              to={link && id && link(id)}
              className={classNames({
                'text-blue-600 underline': !!(link && id),
              })}
            >
              <code>{id}</code>
            </OptionalLink>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
