import { Select as AntSelect, ConfigProvider } from 'antd';
import { SelectProps as AntSelectProps } from 'antd/es/select';
import classNames from 'classnames';
import { omit } from 'lodash';
import React, { Fragment } from 'react';
import { useFilterOption } from '../../hooks/form-hooks';
import './select.overrides.css';

export const Option = AntSelect.Option;
export const OptGroup = AntSelect.OptGroup;

export interface SelectProps extends AntSelectProps<any> {
  multi?: boolean;
  prefixIcon?: JSX.Element;
  emptyMessage?: string;
  customSelectionContent?: JSX.Element;
}

/**
 * @deprecated Use Select from xo/forms/select instead
 */
export const Select: React.FC<SelectProps> = props => {
  const {
    style,
    size,
    children,
    multi,
    prefixIcon,
    id,
    loading,
    emptyMessage,
    customSelectionContent,
  } = props;

  // Provide a default filter option, can be overridden if desired
  const onFilterOption = useFilterOption();

  const select = (
    <AntSelect
      optionLabelProp={multi && !children ? 'label' : undefined}
      filterOption={onFilterOption}
      {...omit(props, [
        'prefixIcon',
        'multi',
        'id',
        'loading',
        'emptyMessage',
        'className',
        'customSelectionContent',
      ])}
      className={classNames('xo-ant-select', {
        'xo-ant-select-icon': prefixIcon,
        'xo-ant-select-custom-selection': customSelectionContent,
      })}
      disabled={loading || props.disabled}
      placeholder={loading ? 'Loading...' : props.placeholder}
      style={{ width: '100%', ...style }}
      size={size || 'large'}
      mode={multi ? 'multiple' : undefined}
      showArrow={true}
      // https://github.com/ant-design/ant-design/issues/8268
      // Makes popup follow when trigger moves or scrolled
      getPopupContainer={trigger => trigger.parentNode}
      tagRender={customSelectionContent ? () => <Fragment /> : undefined}
    >
      {children}
    </AntSelect>
  );

  return (
    <div className={classNames('relative', props.className)} id={id}>
      {emptyMessage ? (
        <ConfigProvider
          renderEmpty={() => (
            <div className="flex items-center justify-center">
              {emptyMessage}
            </div>
          )}
        >
          {select}
        </ConfigProvider>
      ) : (
        select
      )}
      {prefixIcon && (
        <span className="absolute" style={{ top: '8px', left: '8px' }}>
          {prefixIcon}
        </span>
      )}
      {customSelectionContent && (
        <div
          className={classNames(
            'xo-ant-select-custom-selection-content pointer-events-none absolute left-0 top-0 ml-10 mt-1.5',
          )}
        >
          {customSelectionContent}
        </div>
      )}
    </div>
  );
};
