import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSiteQuery } from 'xo/graphql/api/get-site-query.generated';
import { visitorRoutes } from 'xo/navigation/web-routes';
import { useDeleteSite } from '../../hooks/site-network-hooks';
import { CopyLink } from '../components/copy-link';
import { DeleteButton } from '../components/delete-button';
import { Header } from '../components/header';
import { Panel } from '../components/panel';
import { SkeletonLoader } from '../components/skeleton-loader';
import { FormItem } from '../forms/form';
import { AdminSiteActiveStatusSelect } from './admin-site-active-status-select';
import { AdminSiteAirtableIdInput } from './admin-site-airtable-id-input';
import { AdminSiteExoflareNotesInput } from './admin-site-exoflare-notes-input';
import { AdminSiteModuleSelect } from './admin-site-module-select';
import { AdminSiteTransportNotificationKindsSelect } from './admin-site-transport-notification-kinds-select';

export const AdminSite: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [{ data, fetching: siteLoading }] = useGetSiteQuery({
    variables: { id },
  });
  const site = data?.site;
  const { isLoading: deleteLoading, mutate: deleteSite } = useDeleteSite();

  const onDelete = useCallback(() => {
    deleteSite({ id });
  }, [id, deleteSite]);

  return (
    <Panel className="my-4">
      <SkeletonLoader loading={siteLoading}>
        <Header level={2} underline={true} className="mb-4">
          Site admin
        </Header>

        {site && (
          <>
            <CopyLink to={visitorRoutes.siteRoot(site.shortCode)}>
              QR check-in app
            </CopyLink>
            <Row gutter={[16, 16]} className="mt-2">
              <Col span={8}>
                <FormItem label="Airtable ID">
                  <AdminSiteAirtableIdInput site={site} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="Modules">
                  <AdminSiteModuleSelect site={site} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="Transport notifications">
                  <AdminSiteTransportNotificationKindsSelect site={site} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt-2">
              <Col span={8}>
                <FormItem label="Active">
                  <AdminSiteActiveStatusSelect site={site} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt-2">
              <Col span={24}>
                <FormItem label="ExoFlare internal note">
                  <AdminSiteExoflareNotesInput site={site} />
                </FormItem>
              </Col>
            </Row>
          </>
        )}

        <Header className="my-4" level={2} underline={true}>
          Delete
        </Header>
        <DeleteButton
          entity="site"
          deleted={site?.deleted}
          deleting={deleteLoading}
          onClick={onDelete}
        />
      </SkeletonLoader>
    </Panel>
  );
};
