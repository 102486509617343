import React from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  get,
  useFormContext,
} from 'react-hook-form';
import { BoxProps } from 'xo/core';
import { FormMode, useFormModeContext } from './form';
import { FormControl, FormControlProps } from './form-control';
import { ScrollToErrorField } from './scroll-to-error-field';

export type ControlledFormItemProps<
  TFieldValues extends FieldValues,
  TFormItemProps extends {},
> = Omit<TFormItemProps, 'onChange' | 'value' | 'highlight'> &
  Pick<ControlledFormControlProps<TFieldValues>, 'name' | 'label'> & {
    formControl?: Omit<FormControlProps, 'children'>;
    isRequired?: boolean;
    enableHighlight?: boolean;
  };

export interface ControlledFormControlRenderProps<
  TFieldValues extends FieldValues,
> extends ControllerRenderProps<TFieldValues, Path<TFieldValues>> {
  error?: string;
}

export type ControlledFormControlProps<TFieldValues extends FieldValues> = Omit<
  BoxProps,
  'children'
> &
  Omit<FormControlProps, 'children'> & {
    label?: string;
    name: Path<TFieldValues>;
    isRequired?: boolean;
    children: (
      props: ControlledFormControlRenderProps<TFieldValues>,
    ) => React.ReactNode;
  };

export const ControlledFormControl = <TFieldValues extends FieldValues>({
  name,
  label,
  isRequired,
  children,
  ...rest
}: ControlledFormControlProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>();
  const formMode = useFormModeContext();

  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => {
        const error = get(errors, name)?.message?.toString();
        const viewing = formMode.mode !== FormMode.Edit;
        return (
          <>
            <ScrollToErrorField name={name} error={error} />
            <FormControl
              label={label}
              isRequired={viewing ? undefined : isRequired}
              fieldError={viewing ? undefined : error}
              {...rest}
              helperBelow={viewing ? undefined : rest.helperBelow}
              testID={name}
            >
              {children({ ...field, error })}
            </FormControl>
          </>
        );
      }}
    />
  );
};
