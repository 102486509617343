import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgSpeechBubble: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2V2ZM9 11H7V9H9V11V11ZM13 11H11V9H13V11V11ZM17 11H15V9H17V11V11Z"
        fill={fill}
      ></Path>
    </SvgIcon>
  );
};
