// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiMobileAppConfigFragment = {
  __typename?: 'MobileAppConfig';
  versionCurrent?: string;
  versionOldestSupported?: string;
};

export const MobileAppConfigFragmentDoc = gql`
  fragment MobileAppConfig on MobileAppConfig {
    __typename
    versionCurrent
    versionOldestSupported
  }
`;
