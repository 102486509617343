import { colors } from 'xo/styles/tailwind-theme';
import { SvgWarning } from 'xo/svg/svg-warning';
import { Button, ButtonProps } from './button';
import { IconHero, IconHeroProps } from './icon-hero';

export interface ErrorHeroProps extends Omit<IconHeroProps, 'icon'> {
  icon?: JSX.Element;
  retry?: ButtonProps;
}

export const ErrorHero = ({
  icon,
  title,
  description,
  retry,
}: ErrorHeroProps) => (
  <>
    <IconHero
      type="error"
      icon={icon ?? <SvgWarning fill={colors.red[800]} scale={3} />}
      title={title}
      description={description}
    />
    {retry && (
      <Button mt="8" {...retry}>
        RETRY
      </Button>
    )}
  </>
);
