import React, { useCallback } from 'react';
import { UseArray, useArray } from 'react-hanger';
import { tryAutoAssume } from '../admin/admin-assumption-utils';
import { ErrorPageProps, errorPageTypes } from './error-page';

export type AutoAssumeType =
  | 'visit'
  | 'delivery'
  | 'visit-series'
  | 'trip'
  | 'site';

export type OnNotFoundAdminAutoAssumeProps = {
  type: AutoAssumeType;
  id: string;
};

export interface ErrorPageConfig {
  path: string;
  props: ErrorPageProps;
}

export interface ErrorPageContextProps {
  onErrorPage: (config: ErrorPageConfig) => Promise<void>;
  onNotFoundAdminAutoAssume: (
    props: OnNotFoundAdminAutoAssumeProps,
  ) => (() => Promise<void>) | undefined;
  errorPages: UseArray<ErrorPageConfig>;
}

export const ErrorPageContext = React.createContext<ErrorPageContextProps>(
  {} as any,
);

export const ErrorPageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const errorPages = useArray<ErrorPageConfig>([]);

  const onErrorPage = useCallback(
    (config: ErrorPageConfig) => {
      errorPages.add(config);
      return Promise.resolve();
    },
    [errorPages],
  );

  const onNotFoundAdminAutoAssume = useCallback(
    ({ type, id }: OnNotFoundAdminAutoAssumeProps) =>
      async () => {
        tryAutoAssume({ type, id });
        onErrorPage({
          path: window.location.pathname,
          props: errorPageTypes.NOT_FOUND_PAGE!,
        });
      },
    [onErrorPage],
  );

  return (
    <ErrorPageContext.Provider
      value={{
        onErrorPage,
        onNotFoundAdminAutoAssume,
        errorPages,
      }}
    >
      {children}
    </ErrorPageContext.Provider>
  );
};
