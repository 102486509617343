import { Exact, Scalars } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import { ApiUserFragment, UserFragmentDoc } from './user-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApiGetUserQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User' } & ApiUserFragment;
};

export const GetUserDocument = gql`
  query GetUser($id: ID!) {
    __typename
    user(id: $id) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useGetUserQuery(
  options: Omit<Urql.UseQueryArgs<ApiGetUserQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiGetUserQuery, ApiGetUserQueryVariables>({
    query: GetUserDocument,
    ...options,
  });
}
