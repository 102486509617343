// generated by constants-no-id-types-plugin.js

// These GraphQL types don't have an ID so need to be marked as uncacheable for urql otherwise we get warnings
export const NO_ID_TYPES = [
  'Admin',
  'ArrivalDashboardMetric',
  'ConsignmentNoteIdReservation',
  'ConsignmentNoteIdReservationBatch',
  'ConsignmentNoteStockLoadingChecklist',
  'CovidVaccinationCertificateSighting',
  'Definition',
  'Filter',
  'FindOrganisation',
  'FormCurrentUpdater',
  'IngestScheduledMovementSpreadsheetError',
  'MobileAppConfig',
  'NotificationMethods',
  'Page',
  'PlotlyData',
  'PlotlyNamedTrace',
  'Position',
  'PositionDirectionInTime',
  'PositionInTime',
  'QuestionAssessment',
  'QuestionCalendar',
  'QuestionExpects',
  'QuestionExpectsOption',
  'QuestionMostRecentVisit',
  'QuestionPanel',
  'QuestionRemembered',
  'QuestionReview',
  'QuestionWarning',
  'QuestionnaireCondition',
  'ScheduledMovementDaySummary',
  'ScheduledMovementEditability',
  'ScheduledMovementKillDaySummary',
  'ScheduledMovementOnTimeSummary',
  'ScheduledMovementQuantitySummary',
  'ScheduledMovementStockEditability',
  'ScheduledMovementSummary',
  'ScheduledMovementUnloadedSummary',
  'SendTestNotificationResult',
  'StreetAddress',
  'System',
  'TransportSiteNotificationPreferences',
  'TripExportExcel',
  'TripFilterDefaults',
  'TripsFilter',
  'UserError',
  'UserErrorConflict',
  'UserPreferences',
  'VaccineStatuses',
  'Vehicle',
  'VehicleEventEta',
  'VisitOtherSite',
  'VisitSitesVisitedWithinOrgQuarantine',
  'VisitStateHistoryItem',
  'VisitorSiteNotificationPreferences',
  'WebAppConfig',
];
