import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { ApiTripEventKind } from 'xo/graphql/api/enums/trip-event-kind.generated';
import { ApiTripKind } from 'xo/graphql/api/enums/trip-kind.generated';
import { ApiTripStatus } from 'xo/graphql/api/enums/trip-status.generated';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgDepot } from 'xo/svg/svg-depot';
import { SvgLocation } from 'xo/svg/svg-location';
import { SvgShed } from 'xo/svg/svg-shed';
import { SvgSpray } from 'xo/svg/svg-spray';
import { SvgSteak } from 'xo/svg/svg-steak';
import { SvgWarning } from 'xo/svg/svg-warning';
import { OptionType } from '../components/component-models';

// TODO Deprecate these definitions for React Native ones in shared project

type TripEventDisplay = {
  name: string;
  marker?: TripEventDisplayMarker;
  instant?: boolean;
};

type TripEventDisplayMarker = {
  color: string;
  icon: JSX.Element;
  border?: boolean;
};

export const defaultLocationDisplay: TripEventDisplay = {
  marker: {
    color: colors.blue[200],
    icon: <SvgLocation />,
  },
  name: 'Other',
};

export const siteKindsSet = new Set(Object.values(ApiSiteKind));

export const siteKindDisplayMap: Record<ApiSiteKind, TripEventDisplay> = {
  [ApiSiteKind.Abattoir]: {
    marker: {
      color: colors.pink[200],
      icon: <SvgSteak />,
    },
    name: 'Abattoir',
  },
  [ApiSiteKind.Farm]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Piggery',
  },
  [ApiSiteKind.FarmDairy]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Dairy farm',
  },
  [ApiSiteKind.FarmGeneral]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Producer',
  },
  [ApiSiteKind.FarmChicken]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - chicken broiler',
  },
  [ApiSiteKind.FarmChickenBreeder]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - chicken production breeder',
  },
  [ApiSiteKind.FarmChickenBreederElite]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - chicken elite breeder',
  },
  [ApiSiteKind.FarmChickenEggsBreeder]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - layer breeder',
  },
  [ApiSiteKind.FarmChickenEggsGradingFloor]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - layer grading floor',
  },
  [ApiSiteKind.FarmChickenEggsLaying]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - layer production',
  },
  [ApiSiteKind.FarmChickenEggsRearing]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - layer rearing',
  },
  [ApiSiteKind.FarmChickenRearing]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - chicken rearing breeder',
  },
  [ApiSiteKind.FarmChickenHatchery]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - chicken hatchery (for broiler)',
  },
  [ApiSiteKind.FarmChickenHatcheryBreeder]: {
    marker: {
      color: colors.teal[400],
      icon: <SvgShed scale={0.8} />,
    },
    name: 'Farm - chicken hatchery (for breeder)',
  },
  [ApiSiteKind.FeedMill]: { ...defaultLocationDisplay, name: 'Feed mill' },
  [ApiSiteKind.FeedMillGeneral]: {
    ...defaultLocationDisplay,
    name: 'Feed mill',
  },
  [ApiSiteKind.Feedlot]: { ...defaultLocationDisplay, name: 'Feedlot' },
  [ApiSiteKind.ProcessorChicken]: {
    marker: {
      color: colors.pink[200],
      icon: <SvgSteak />,
    },
    name: 'Chicken processor',
  },
  [ApiSiteKind.TransportDepot]: {
    marker: {
      color: colors.purple[200],
      icon: <SvgDepot scale={0.8} />,
    },
    name: 'Depot',
  },
  [ApiSiteKind.Other]: { ...defaultLocationDisplay, name: 'Site' },
};

export const tripEventKindDisplayMap: Record<
  ApiTripEventKind,
  TripEventDisplay
> = Object.assign({}, siteKindDisplayMap, {
  [ApiTripEventKind.Accident]: {
    marker: {
      color: colors.red[400],
      icon: <SvgWarning scale={0.8} />,
    },
    name: 'Accident',
  },
  [ApiTripEventKind.TruckDepot]: {
    marker: {
      color: colors.purple[200],
      icon: <SvgDepot scale={0.8} />,
    },
    name: 'Depot',
  },
  [ApiTripEventKind.Unknown]: {
    marker: {
      color: colors.grey[400],
      icon: <span className="text-2xl font-bold text-white">?</span>,
      border: true,
    },
    name: 'Unknown',
  },
  [ApiTripEventKind.WashBay]: {
    marker: {
      color: colors.indigo[300],
      icon: <SvgSpray />,
    },
    name: 'Wash bay',
  },
  [ApiTripEventKind.UnrelatedAccident]: {
    marker: {
      color: colors.red[400],
      icon: <SvgWarning scale={0.8} />,
    },
    name: 'Unrelated accident',
  },
  [ApiTripEventKind.UnexpectedStop]: {
    name: 'Unexpected stop',
  },
  [ApiTripEventKind.BiosecurityShed]: {
    name: 'Biosecurity shed',
  },
  [ApiTripEventKind.Loading]: {
    name: 'Stop at loading ramp',
  },
  [ApiTripEventKind.Unloading]: {
    name: 'Stop at loading ramp',
  },
  [ApiTripEventKind.StartTrip]: {
    name: 'Start of trip',
    instant: true,
  },
  [ApiTripEventKind.EndTrip]: {
    name: 'End of trip',
    instant: true,
  },
  [ApiTripEventKind.Farm]: {
    name: 'Stop at site',
  },
  [ApiTripEventKind.Abattoir]: {
    name: 'Stop at site',
  },
  [ApiTripEventKind.Hidden]: {
    name: '(Hidden)',
  },
  [ApiTripEventKind.QrDeliveryCheckIn]: {
    name: 'QR check-in at biosecurity shed',
    instant: true,
  },
  [ApiTripEventKind.PaperDeliveryCheckIn]: {
    name: 'Paper check-in at biosecurity shed',
    instant: true,
  },
});

export const warningTripEventKinds = new Set([
  ApiTripEventKind.Accident,
  ApiTripEventKind.UnrelatedAccident,
  ApiTripEventKind.UnexpectedStop,
]);

export const successTripEventKinds = new Set([
  ApiTripEventKind.BiosecurityShed,
  ApiTripEventKind.WashBay,
]);

export const tripStatusDisplayMap: Record<
  ApiTripStatus,
  { className: string; text: string }
> = {
  [ApiTripStatus.Scheduled]: {
    className: 'bg-grey-200 text-black',
    text: 'Scheduled',
  },
  [ApiTripStatus.Cancelled]: {
    className: 'bg-red-400 text-white',
    text: 'Cancelled',
  },
  [ApiTripStatus.OnTheWay]: {
    className: 'bg-blue-50 text-black',
    text: 'On the way',
  },
  [ApiTripStatus.Loading]: {
    className: 'bg-blue-50 text-blue-600',
    text: 'Loading',
  },
  [ApiTripStatus.Driving]: {
    className: 'bg-blue-50 text-blue-600',
    text: 'Driving',
  },
  [ApiTripStatus.Unloading]: {
    className: 'bg-blue-50 text-blue-600',
    text: 'Unloading',
  },
  [ApiTripStatus.Completed]: {
    className: 'bg-grey-200 text-blue-600',
    text: 'Completed',
  },
};

export const tripStatuses = [
  ApiTripStatus.Scheduled,
  ApiTripStatus.Loading,
  ApiTripStatus.Driving,
  ApiTripStatus.Unloading,
  ApiTripStatus.Completed,
];

export enum GpsTracking {
  HasGps = 'HAS_GPS',
  NoGps = 'NO_GPS',
}

export const gpsFilterOptions: OptionType[] = [
  { label: 'Yes', value: GpsTracking.HasGps },
  { label: 'No', value: GpsTracking.NoGps },
];

export const gpsFilterLabelMap = new Map(
  gpsFilterOptions.map(o => [o.value, o.label]),
);

export const destinationTypeOptions: OptionType[] = [
  { label: 'To abattoir', value: ApiTripKind.Abattoir },
  { label: 'To other farms', value: ApiTripKind.InternalFarm },
];

export const destinationTypeFilterLabelMap = new Map(
  destinationTypeOptions.map(o => [o.value, o.label]),
);
