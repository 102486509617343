import { Tabs as AntTabs } from 'antd';
import { TabPaneProps, TabsProps } from 'antd/es/tabs';
import React from 'react';
import './tabs.overrides.css';

export const Tabs: React.FC<TabsProps> = props => <AntTabs {...props} />;

export const TabPane: React.FC<TabPaneProps> = props => (
  <AntTabs.TabPane {...props} />
);
