import { ApiNotificationKind } from 'xo/graphql/api/enums/notification-kind.generated';
import { ApiSendTestNotificationInput } from 'xo/graphql/api/inputs/send-test-notification-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiSendTestNotificationMutationVariables = Exact<{
  input: ApiSendTestNotificationInput;
}>;

export type ApiSendTestNotificationMutation = {
  __typename?: 'Mutation';
  sendTestNotification: {
    __typename?: 'SendTestNotificationResult';
    sentVia: Array<ApiNotificationKind>;
  };
};

export const SendTestNotificationDocument = gql`
  mutation SendTestNotification($input: SendTestNotificationInput!) {
    __typename
    sendTestNotification(input: $input) {
      __typename
      sentVia
    }
  }
`;

export function useSendTestNotificationMutation() {
  return Urql.useMutation<
    ApiSendTestNotificationMutation,
    ApiSendTestNotificationMutationVariables
  >(SendTestNotificationDocument);
}
