import { Exact, Scalars } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
import {
  ApiSiteRequiredActionsFragment,
  SiteRequiredActionsFragmentDoc,
} from './site-required-actions-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetSiteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ApiGetSiteQuery = {
  __typename?: 'Query';
  site?: { __typename?: 'Site' } & ApiSiteFragment &
    ApiSiteRequiredActionsFragment;
};

export const GetSiteDocument = gql`
  query GetSite($id: ID!) {
    __typename
    site(id: $id) {
      __typename
      ...Site
      ...SiteRequiredActions
    }
  }
  ${SiteFragmentDoc}
  ${SiteRequiredActionsFragmentDoc}
`;

export function useGetSiteQuery(
  options: Omit<Urql.UseQueryArgs<ApiGetSiteQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiGetSiteQuery, ApiGetSiteQueryVariables>({
    query: GetSiteDocument,
    ...options,
  });
}
