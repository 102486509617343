import { useTimeout } from '@mantine/hooks';
import { debounce, uniq } from 'lodash';
import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Linking } from 'react-native';
import { USER_GUIDE_URL } from 'xo/constants';
import { ConfirmClearProps } from 'xo/forms/component-models';
import { formatEmailHref, formatPhoneNumberHref } from 'xo/utils/link-utils';
import { setFromValue } from 'xo/utils/option-utils';

export type OptionTypeValue = string | number | boolean | null;
export type MaybeMultiOptionTypeValue = OptionTypeValue | OptionTypeValue[];

// FIXME Move models to form/component-models.ts
export interface OptionType<T extends OptionTypeValue> {
  // FIXME Generic type for label too
  label: string | React.ReactNode;
  value: T;
  key: string;
  groupName?: string;
}

export interface IconOptionType<T extends OptionTypeValue>
  extends OptionType<T> {
  icon: JSX.Element;
}

export const OTHER_OPTION_VALUE = 'OTHER';
export const OTHER_OPTION: OptionType<string> = {
  key: 'other',
  label: 'Other',
  value: OTHER_OPTION_VALUE,
};

export const UNKNOWN_OPTION_VALUE = 'UNKNOWN';
export const UNKNOWN_OPTION: OptionType<string> = {
  key: 'unknown',
  label: 'UNKNOWN',
  value: UNKNOWN_OPTION_VALUE,
};

export const SPECIAL_CASE_OPTION_VALUES = [
  OTHER_OPTION_VALUE,
  UNKNOWN_OPTION_VALUE,
];

export const useOnOptionSelected =
  ({
    multi,
    value,
    onChange,
    clearable = true,
  }: {
    value?: MaybeMultiOptionTypeValue;
    multi?: boolean;
    onChange?: (value?: MaybeMultiOptionTypeValue) => void;
    clearable?: boolean;
  }) =>
  (optionValue: OptionTypeValue, selected: boolean) => {
    const valueSet = setFromValue(value);

    if (!multi) {
      valueSet.clear();
    }

    if (selected) {
      valueSet.add(optionValue);
    } else {
      valueSet.delete(optionValue);
    }
    const newValues = Array.from(valueSet);

    if (onChange && (clearable || (!clearable && newValues.length))) {
      if (multi) {
        onChange(newValues);
      } else {
        onChange(newValues[0] ?? null);
      }
    }
  };

export const useDisclose = (initialOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialOpen ?? false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

export const useDebouncedValue = <T>(value: T, ms: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedValue(value);
    }, ms);

    return () => {
      clearTimeout(id);
    };
  }, [value, ms]);

  return debouncedValue;
};

export const useDebouncedCallback = <T extends (...args: any[]) => any>(
  callback: T,
  debounceMs: number,
  deps: DependencyList,
) => {
  const debouncedCallback = useMemo(
    () => debounce(callback, debounceMs),
    [callback, debounceMs],
  );

  return useCallback(debouncedCallback, [debouncedCallback, ...deps]);
};

export const useOnEmailLinkPress = (email: string) => () =>
  Linking.openURL(formatEmailHref(email));

export const useOnPhoneLinkPress = (phoneNumber: string) => () =>
  Linking.openURL(formatPhoneNumberHref(phoneNumber));

export const onContactSupport = () => Linking.openURL(USER_GUIDE_URL);

export const openURL = (url: string) => Linking.openURL(url);

export interface UseMultiSelectListProps<TOptionTypeValue extends string> {
  options: OptionType<TOptionTypeValue>[];
  values: TOptionTypeValue[];
  onChange?: (values: TOptionTypeValue[]) => void;
}

export const useMultiSelectList = <TOptionTypeValue extends string>({
  options,
  values,
  onChange,
}: UseMultiSelectListProps<TOptionTypeValue>) => {
  const optionSet = new Set(options.map(v => v.value));
  const valueSet = new Set(values?.filter(v => optionSet.has(v)));
  const allSelected = options?.every(v => valueSet.has(v.value));
  const allIndeterminate = !allSelected && values?.some(v => valueSet.has(v));

  const onSelectAll = () => {
    let newValues: TOptionTypeValue[] = [];
    if (valueSet.size === 0) {
      newValues = options.map(o => o.value);
    }

    onChange && onChange(uniq(newValues));
  };

  const onSelectOption = (value: TOptionTypeValue) => {
    let newValue = values ?? [];
    if (valueSet.has(value)) {
      newValue = newValue.filter(v => v !== value);
    } else {
      newValue = newValue.concat(value);
    }
    onChange && onChange(uniq(newValue));
  };

  return {
    valueSet,
    allSelected,
    allIndeterminate,
    onSelectAll,
    onSelectOption,
  };
};

export const useFlag = (
  initial: boolean,
): [boolean, () => void, () => void] => {
  const [flag, setFlag] = useState(initial);

  const setTrue = useCallback(() => setFlag(true), [setFlag]);
  const setFalse = useCallback(() => setFlag(false), [setFlag]);

  return [flag, setTrue, setFalse];
};

export const useTimedFlag = (timeMs: number = 200): [boolean, () => void] => {
  const [error, setError] = useState(false);
  const { start } = useTimeout(() => setError(false), timeMs);

  return [
    error,
    useCallback(() => {
      setError(true);
      start();
    }, [setError, start]),
  ];
};

export interface UseOnClearConfirm {
  onChange: () => void;
  onBlur?: () => void;
  clearable?: boolean | ConfirmClearProps;
  // FIXME only have a web implementation of this so far
  confirm: (props: {
    title: string;
    content: JSX.Element;
    okText: string;
    cancelText: string;
    onOk: () => void;
  }) => void;
}

export const useOnClearConfirm = ({
  clearable,
  onChange,
  onBlur,
  confirm,
}: UseOnClearConfirm) => {
  return (e?: React.MouseEvent) => {
    if (clearable) {
      e?.stopPropagation && e.stopPropagation();

      const onComplete = () => {
        onChange();
        onBlur && onBlur();
      };

      if (typeof clearable === 'boolean') {
        onComplete();
      } else {
        confirm({
          title: clearable!.title,
          content: clearable!.description,
          okText: 'CLEAR',
          cancelText: 'CANCEL',
          onOk: onComplete,
        });
      }
    }
  };
};
