import { Timeline as AntdTimeline } from 'antd';
import {
  TimelineItemProps as AntdTimelineItemProps,
  TimelineProps as AntdTimelineProps,
} from 'antd/es/timeline';
import classNames from 'classnames';
import { omit } from 'lodash';
import React from 'react';
import './timeline.overrides.css';

export interface TimelineProps extends AntdTimelineProps {}

export interface TimelineItemProps extends AntdTimelineItemProps {
  nextIncomplete?: boolean;
}

export const Timeline: React.FC<TimelineProps> = props => (
  <AntdTimeline
    {...props}
    style={{ ...props.style, marginLeft: '16px' }}
    className={classNames('xo-timeline', props.className)}
  />
);

export const TimelineItem: React.FC<TimelineItemProps> = props => (
  <AntdTimeline.Item
    {...omit(props, 'nextIncomplete')}
    className={classNames(props.className, {
      'xo-timeline-item-incomplete': props.nextIncomplete,
      'xo-timeline-item-complete': !props.nextIncomplete,
    })}
  />
);
