import { Skeleton as AntSkeleton } from 'antd';
import React from 'react';
import { margin } from 'xo/styles/tailwind-theme';
import { clamp, randomInt } from '../utils';

export const PAGE_SKELETON = [
  30, 30, 70, 50, 100, 50, 100, 50, 100, 50, 100, 50, 100,
];

export interface SkeletonProps {
  widths?: number[];
}

export const Skeleton: React.FC<SkeletonProps> = ({
  widths = PAGE_SKELETON,
}) => (
  <>
    {widths.map((width, i) => (
      <AntSkeleton
        key={i}
        active
        title={{
          // Randomize the width of the skeleton to look more like natural text
          // and clamp it between 0 and 100
          width: `${clamp(randomInt(width - 1, width + 1), 0, 100)}%`,
          style: { marginTop: margin[6] },
        }}
        paragraph={false}
      />
    ))}
  </>
);
