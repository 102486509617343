import { ApiRiskAssessment } from 'xo/graphql/api/enums/risk-assessment.generated';
import { ApiTripEventKind } from 'xo/graphql/api/enums/trip-event-kind.generated';

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
import {
  ApiPositionFragment,
  PositionFragmentDoc,
} from './position-fragment.generated';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
import {
  ApiWeatherObservationFragment,
  WeatherObservationFragmentDoc,
} from './weather-observation-fragment.generated';
export type ApiTripEventFragment = {
  __typename?: 'TripEvent';
  id: string;
  kind: ApiTripEventKind;
  kindOriginal?: ApiTripEventKind;
  kindForDisplay: ApiTripEventKind;
  overrideDescription?: string;
  actualStartTime?: Dayjs;
  actualEndTime?: Dayjs;
  predictedStartTime?: Dayjs;
  riskLevel?: ApiRiskAssessment;
  dueStartTime?: Dayjs;
  site?: { __typename?: 'Site' } & ApiSiteFragment;
  position?: { __typename?: 'Position' } & ApiPositionFragment;
  weather?: {
    __typename?: 'WeatherObservation';
  } & ApiWeatherObservationFragment;
};

export const TripEventFragmentDoc = gql`
  fragment TripEvent on TripEvent {
    __typename
    id
    kind
    kindOriginal
    kindForDisplay
    overrideDescription
    actualStartTime
    actualEndTime
    predictedStartTime
    riskLevel
    site {
      ...Site
    }
    position {
      ...Position
    }
    dueStartTime
    weather {
      ...WeatherObservation
    }
  }
  ${SiteFragmentDoc}
  ${PositionFragmentDoc}
  ${WeatherObservationFragmentDoc}
`;
