import { Col, ColProps, Row } from 'antd';
import React, { useCallback } from 'react';
import { ButtonToggle } from './button-toggle';
import { OptionValueType } from './component-models';

declare type ButtonGroupValues =
  | OptionValueType[]
  | OptionValueType
  | undefined;

export interface ButtonGroupProps {
  id?: string;
  options: {
    label: string | JSX.Element;
    value: OptionValueType;
    subLabel?: string | JSX.Element;
    bgActiveClassName?: string;
    col?: ColProps;
  }[];
  onChange?: (values: ButtonGroupValues) => void;
  value?: ButtonGroupValues;
  multi?: boolean;
  disabled?: boolean;
  size?: 'lg' | 'sm';
  invert?: boolean;
  clearable?: boolean;
}

const setFromValue = (v: ButtonGroupValues) =>
  new Set(Array.isArray(v) ? v : v !== undefined ? [v] : []);

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  id,
  options,
  multi,
  value,
  onChange,
  disabled,
  size = 'lg',
  invert,
  clearable = true,
}) => {
  const onOptionClick = useCallback(
    (optionValue: number | string, checked: boolean) => {
      const valueSet = setFromValue(value);

      if (!multi) {
        valueSet.clear();
      }

      if (checked) {
        valueSet.add(optionValue);
      } else {
        valueSet.delete(optionValue);
      }
      const newValues = Array.from(valueSet);

      if (onChange && (clearable || (!clearable && newValues.length))) {
        if (multi) {
          onChange(newValues);
        } else {
          onChange(newValues[0]);
        }
      }
    },
    [multi, onChange, value, clearable],
  );

  const allValues = setFromValue(value);

  return (
    <Row id={id} gutter={[8, 8]}>
      {options.map(({ label, value, bgActiveClassName, col, subLabel }, i) => (
        <Col
          key={value?.toString() || i}
          span={!col ? 24 / options.length : undefined}
          {...col}
        >
          <ButtonToggle
            onClick={onOptionClick}
            value={value}
            checked={allValues.has(value)}
            rounded={options.length > 2}
            disabled={disabled}
            bgActiveClassName={bgActiveClassName}
            size={size}
            invert={invert}
            subLabel={subLabel}
          >
            {label}
          </ButtonToggle>
        </Col>
      ))}
    </Row>
  );
};
