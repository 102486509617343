import { ApiScheduledMovementIngestionErrorKind } from 'xo/graphql/api/enums/scheduled-movement-ingestion-error-kind.generated';

import gql from 'graphql-tag';
export type ApiIngestScheduledMovementSpreadsheetErrorFragment = {
  __typename?: 'IngestScheduledMovementSpreadsheetError';
  kind: ApiScheduledMovementIngestionErrorKind;
  message: string;
};

export const IngestScheduledMovementSpreadsheetErrorFragmentDoc = gql`
  fragment IngestScheduledMovementSpreadsheetError on IngestScheduledMovementSpreadsheetError {
    __typename
    kind
    message
  }
`;
