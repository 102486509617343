export enum WebViewEventType {
  // the webview can trigger the mobile app to sign out
  SignOut = 'SIGN_OUT',
  // webview console logs get piped out of the webview for logging
  Console = 'CONSOLE',
  // the webview can trigger the mobile app to navigate to app settings
  AppSettings = 'APP_SETTINGS',
  // the webview can trigger the mobile app to prompt the user for push notification permissions
  PromptPushPermission = 'PROMPT_PUSH_PERMISSION',
}

export interface WebViewConsoleEventData {
  level: 'log' | 'debug' | 'info' | 'warn' | 'error';
  msg: string;
}

export interface WebViewEvent<T = undefined> {
  type: WebViewEventType;
  data?: T;
}

// can add a second generic data param if needed
export const postWebViewMessage = <T = undefined>(
  type: WebViewEventType,
  data?: T,
) => {
  const event: WebViewEvent<T> = { type, data };
  // if the web app is running in the mobile webview, ReactNativeWebView will be defined
  (window as any).ReactNativeWebView?.postMessage(JSON.stringify(event));
};

export const isRunningInWebView = () => !!(window as any).ReactNativeWebView;
