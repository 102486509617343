import {
  RNStyleProperty,
  BoxProps as RestyleBoxProps,
  createBox,
  createRestyleComponent,
  createRestyleFunction,
} from '@shopify/restyle';
import React from 'react';
import { FlexStyle, Platform, ViewProps } from 'react-native';
import { shadow } from 'xo/styles/restyle/shadow';
import {
  BorderRadius,
  BorderWidth,
  Responsive,
  RestyleTheme,
  Shadow,
  Spacing,
} from 'xo/styles/restyle/theme';

const RawBox = createBox<RestyleTheme, RestyleBoxProps<RestyleTheme, true>>();

const rounded = createRestyleFunction({
  property: 'rounded',
  styleProperty: 'borderRadius',
  themeKey: 'rounded',
});

const w = createRestyleFunction({
  property: 'w',
  styleProperty: 'width',
  themeKey: 'spacing',
});

const h = createRestyleFunction({
  property: 'h',
  styleProperty: 'height',
  themeKey: 'spacing',
});

const width = createRestyleFunction({
  property: 'width',
  styleProperty: 'width',
  themeKey: 'spacing',
});

const height = createRestyleFunction({
  property: 'height',
  styleProperty: 'height',
  themeKey: 'spacing',
});

const align = createRestyleFunction({
  property: 'align',
  styleProperty: 'alignItems',
});

const justify = createRestyleFunction({
  property: 'justify',
  styleProperty: 'justifyContent',
});

const direction = createRestyleFunction({
  property: 'direction',
  styleProperty: 'flexDirection',
});

const borderColor = createRestyleFunction({
  property: 'borderColor',
  styleProperty: 'borderColor',
  themeKey: 'colors',
});

const display = createRestyleFunction({
  property: 'display',
  styleProperty: 'display',
});

const borderWidths = [
  'borderWidth',
  'borderBottomWidth',
  'borderTopWidth',
  'borderRightWidth',
  'borderLeftWidth',
].map(property =>
  createRestyleFunction({
    property,
    styleProperty: property as RNStyleProperty,
    themeKey: 'borderWidth',
  }),
);

export type BoxProps = Omit<
  RestyleBoxProps<RestyleTheme>,
  | 'height'
  | 'h'
  | 'width'
  | 'w'
  | 'borderWidth'
  | 'borderBottomWidth'
  | 'borderTopWidth'
  | 'borderRightWidth'
  | 'borderLeftWidth'
  | 'margin'
  | 'm'
  | 'mx'
  | 'my'
  | 'mt'
  | 'mb'
  | 'ml'
  | 'mr'
  | 'padding'
  | 'p'
  | 'px'
  | 'py'
  | 'pt'
  | 'pb'
  | 'pl'
  | 'pr'
> &
  ViewProps & {
    shadow?: Responsive<Shadow>;
    w?: Responsive<Spacing | number>;
    width?: Responsive<Spacing | number>;
    h?: Responsive<Spacing | number>;
    height?: Responsive<Spacing | number>;
    margin?: Responsive<Spacing | number>;
    m?: Responsive<Spacing | number>;
    mx?: Responsive<Spacing | number>;
    my?: Responsive<Spacing | number>;
    mt?: Responsive<Spacing | number>;
    mb?: Responsive<Spacing | number>;
    ml?: Responsive<Spacing | number>;
    mr?: Responsive<Spacing | number>;
    padding?: Responsive<Spacing | number>;
    p?: Responsive<Spacing | number>;
    px?: Responsive<Spacing | number>;
    py?: Responsive<Spacing | number>;
    pt?: Responsive<Spacing | number>;
    pb?: Responsive<Spacing | number>;
    pl?: Responsive<Spacing | number>;
    pr?: Responsive<Spacing | number>;
    rounded?: Responsive<BorderRadius>;
    borderWidth?: Responsive<BorderWidth | number>;
    borderBottomWidth?: Responsive<BorderWidth | number>;
    borderTopWidth?: Responsive<BorderWidth | number>;
    borderRightWidth?: Responsive<BorderWidth | number>;
    borderLeftWidth?: Responsive<BorderWidth | number>;
    align?: Responsive<FlexStyle['alignItems']>;
    justify?: Responsive<FlexStyle['justifyContent']>;
    direction?: Responsive<FlexStyle['flexDirection']>;
    display?: Responsive<FlexStyle['display']>;
    href?: string;
    className?: string;
  };

const RestyleBox = createRestyleComponent<BoxProps, RestyleTheme>(
  [
    rounded,
    w,
    width,
    h,
    height,
    align,
    justify,
    direction,
    borderColor,
    display,
    // FIXME Typing problem
    ...(borderWidths as any),
  ],
  RawBox,
);

export const Box = React.forwardRef<typeof RestyleBox, BoxProps>(
  ({ shadow: shadowProp, className, ...rest }: BoxProps, ref) => {
    // FIXME Responsive shadows don't actually work atm
    // Expand shadow single prop into multiple props (supporting responsive objects too) because we can't do that with restyle
    const shadowProperties = shadowProp
      ? typeof shadowProp === 'string'
        ? shadow[shadowProp]
        : Object.fromEntries(
            Object.entries(shadow).map(([key, value]) => [
              key,
              (shadow as any)[value as any],
            ]),
          )
      : undefined;

    const style = [
      {
        ...(Platform.select({
          web: { zIndex: 'auto' },
        }) as any),
        ...(rest.style as any),
      },
      // css flag allows passing classes on web
      // https://github.com/necolas/react-native-web/releases/tag/0.18.0
      { $$css: true, className: className || null },
    ];

    // FIXME Restyle's createBox adds z-index: 0 to web divs, so override that with auto default, need to create an issue on their repo
    return (
      <RestyleBox
        ref={ref}
        {...rest}
        {...shadowProperties}
        style={Object.keys(style).length ? style : undefined}
      />
    );
  },
);
