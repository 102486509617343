import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg from 'react-native-svg';

export interface SvgIconProps {
  scale?: number;
  fill?: string;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  viewBox?: string;
  width?: number;
  height?: number;
  ariaLabel?: string;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  scale = 1,
  style,
  children,
  viewBox,
  width = 24,
  height = 24,
  ariaLabel,
}) => {
  return (
    <Svg
      width={Math.round(width * scale)}
      height={Math.round(height * scale)}
      viewBox={viewBox ?? `0 0 ${width} ${height}`}
      fill="none"
      style={style}
      aria-label={ariaLabel}
    >
      {children}
    </Svg>
  );
};
