import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgHelp } from 'xo/svg/svg-help';
import { Tooltip } from './tooltip';
import { TooltipRendererProps } from './tooltip-renderer.props';

export interface HelpTooltipProps {
  children: TooltipRendererProps['overlay'];
}

export const HelpTooltip: React.FC<HelpTooltipProps> = ({ children }) => (
  <Tooltip overlay={children}>
    <SvgHelp fill={colors.blue[400]} />
  </Tooltip>
);
