import React from 'react';
import { Link } from 'react-router-dom';
import { visitorRoutes } from 'xo/navigation/web-routes';
import { SiteSummaryModel } from '../../api-models';

export interface AdminCheckInAppLinkProps {
  site: SiteSummaryModel;
}

export const AdminCheckInAppLink: React.FC<AdminCheckInAppLinkProps> = ({
  site,
}) => (
  <Link
    className="my-6 text-base font-bold uppercase text-blue-600 underline"
    to={visitorRoutes.siteRoot(site.shortCode)}
    target="_blank"
  >
    QR check-in app for {site.name}
  </Link>
);
