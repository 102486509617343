import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { appRoutes } from 'xo/navigation/web-routes';
import {
  useDeleteDelivery,
  useGetDelivery,
} from '../../hooks/delivery-network-hooks';
import { DeleteButton } from '../components/delete-button';
import { Header } from '../components/header';
import { Panel } from '../components/panel';
import { PanelMessage } from '../components/panel-message';
import { SkeletonLoader } from '../components/skeleton-loader';
import { AdminIdsTable } from './admin-ids-table';
import { nowAfterSoftDeleteCutOff } from './admin-utils';

export const AdminDelivery: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading: deliveryLoading, data: delivery } = useGetDelivery(id);
  const { isLoading: deleteLoading, mutate: deleteDelivery } =
    useDeleteDelivery();

  const onDelete = useCallback(() => {
    deleteDelivery({ id });
  }, [id, deleteDelivery]);

  const afterSoftDeleteCutOff = nowAfterSoftDeleteCutOff(delivery?.createdAt);
  return (
    <Panel className="my-4">
      <SkeletonLoader loading={deliveryLoading}>
        <Header level={2} underline={true} className="mb-4">
          Delivery admin
        </Header>

        <DeleteButton
          entity="delivery"
          deleted={delivery?.deleted}
          deleting={deleteLoading}
          onClick={onDelete}
          disabled={afterSoftDeleteCutOff}
        />
        {afterSoftDeleteCutOff && (
          <PanelMessage type="warning" className="mt-2">
            This delivery cannot be deleted because it is from a previous
            quarter, for which reporting has already been finalised. Contact
            engineering to discuss options, if required.
          </PanelMessage>
        )}

        <Header level={2} className="mb-2 mt-8">
          IDs
        </Header>
        <AdminIdsTable
          entities={[
            {
              name: 'Delivery',
              id: delivery?.id,
              link: appRoutes.deliveries.details,
            },
            {
              name: 'Site',
              id: delivery?.site.id,
              link: appRoutes.settings.siteDetails,
            },
            { name: 'Delivery person', id: delivery?.deliveryPerson.id },
          ]}
        />
      </SkeletonLoader>
    </Panel>
  );
};
