import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiSiteKioskFragment,
  SiteKioskFragmentDoc,
} from './site-kiosk-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetSitesKioskModeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApiGetSitesKioskModeQuery = {
  __typename?: 'Query';
  sites: Array<{ __typename?: 'Site' } & ApiSiteKioskFragment>;
};

export const GetSitesKioskModeDocument = gql`
  query GetSitesKioskMode {
    __typename
    sites(input: {}) {
      ...SiteKiosk
    }
  }
  ${SiteKioskFragmentDoc}
`;

export function useGetSitesKioskModeQuery(
  options?: Omit<
    Urql.UseQueryArgs<ApiGetSitesKioskModeQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ApiGetSitesKioskModeQuery,
    ApiGetSitesKioskModeQueryVariables
  >({ query: GetSitesKioskModeDocument, ...options });
}
