import React, { Fragment, useContext } from 'react';
import { DetailedSiteReadModel } from '../../api-models';
import {
  hasTransportModuleAccess,
  hasVisitorModuleAccess,
} from '../../hooks/auth-utils';
import { useFlag, useIsLoggedIn } from '../../hooks/shared-hooks';
import { useGetSites } from '../../hooks/site-network-hooks';
import { useGetCurrentUser } from '../../hooks/user-network-hooks';
import { UserModel } from '../../models/visitor-log-models';
import { PageLoader } from '../components/page-loader';
import { useKioskRedirect } from '../people-check-in/check-in-hooks';
import { TermsPageAuthed } from './terms-page-authed';

export interface UserContextProps {
  // FIXME Make optional, user doesn't exist outside of authed app
  user: UserModel;
  potentiallyAnAdminUser: UserModel;
  sites: DetailedSiteReadModel[];
  visitorSites: DetailedSiteReadModel[];
  transportSites: DetailedSiteReadModel[];
  assumedUserId?: string;
  xoUIHidden: boolean;
  onSetXoUIHidden: () => void;
}

export const UserContext = React.createContext<UserContextProps>({} as any);

export const UserProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [xoUIHidden, onSetXoUIHidden] = useFlag(false);
  const isLoggedIn = useIsLoggedIn();

  // It takes one (for non-admins) or two (for XO admins) queries to work out who's actually logged
  // in: the first query tells us who is actually logged in, but that may not be quite the right
  // person if its an admin who's assumed another identity.
  const { data: potentiallyAnAdminUser, isLoading: potentialAdminLoading } =
    useGetCurrentUser({
      enabled: isLoggedIn,
      withAssuming: false,
    });
  const isXoAdmin = potentiallyAnAdminUser?.kind === 'XO_ADMIN';
  const { data: adminAssumedUser, isLoading: adminAssumedUserLoading } =
    useGetCurrentUser({
      enabled: isLoggedIn && isXoAdmin,
      withAssuming: true,
    });
  const user =
    !potentialAdminLoading && !adminAssumedUserLoading
      ? adminAssumedUser ?? potentiallyAnAdminUser
      : undefined;
  const { data: sites } = useGetSites(isLoggedIn);

  const renderChildrenWhenNotLoggedIn = !isLoggedIn;
  const renderChildrenWhenLoggedIn =
    isLoggedIn &&
    user &&
    sites &&
    potentiallyAnAdminUser &&
    (user.agreedTerms || isXoAdmin);

  const renderTerms = isLoggedIn && !isXoAdmin && user && !user.agreedTerms;

  const renderChildren =
    renderChildrenWhenNotLoggedIn || renderChildrenWhenLoggedIn;

  useKioskRedirect(potentiallyAnAdminUser);

  return (
    <UserContext.Provider
      value={{
        user: user!,
        sites: sites ?? [],
        visitorSites: sites?.filter(hasVisitorModuleAccess) ?? [],
        transportSites: sites?.filter(hasTransportModuleAccess) ?? [],
        potentiallyAnAdminUser: potentiallyAnAdminUser!,
        xoUIHidden,
        onSetXoUIHidden,
      }}
    >
      <Fragment>
        {renderTerms ? (
          <TermsPageAuthed />
        ) : renderChildren ? (
          children
        ) : (
          <PageLoader loading={true} />
        )}
      </Fragment>
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const { user } = useContext(UserContext);
  return user;
};
