import React from 'react';
import { ClipPath, Defs, G, Path } from 'react-native-svg';
import { useClipPathId } from 'xo/utils/svg-utils';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgMinus: React.FC<SvgIconProps> = props => {
  const { fill } = props;
  const { id, clipPath } = useClipPathId();

  return (
    <SvgIcon {...props}>
      <G clipPath={clipPath}>
        <Path d="M19 13H5v-2h14v2Z" fill={fill} />
      </G>
      <Defs>
        <ClipPath id={id}>
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </SvgIcon>
  );
};
