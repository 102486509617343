import { Dayjs } from 'dayjs';
import { isFinite, isNil, isString } from 'lodash';
import React, { cloneElement, useContext } from 'react';
import { Skeleton } from 'xo/components/skeleton';
import { Box, BoxProps, Text, TextProps } from 'xo/core';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCalendarToday } from 'xo/svg/svg-calendar-today';
import { SvgClock } from 'xo/svg/svg-clock';
import { TestIDs } from 'xo/tests/test-ids';
import { hasValue } from 'xo/utils/validation-utils';
import { FormMode, useFormModeContext } from './form';
import { FormControlContext } from './form-control';

export interface FieldDisplayProps extends BoxProps {
  icon?: JSX.Element;
  children: string | React.ReactNode;
  testID?: string;
  text?: TextProps;
}

export const FieldDisplay = ({
  icon,
  children,
  testID,
  text,
  ...rest
}: FieldDisplayProps) => {
  const loading = useFormModeContext()?.mode === FormMode.Loading;
  const highlight = useContext(FormControlContext)?.showHighlight;

  const textProps: TextProps = {
    fontWeight: '600',
    color: highlight ? 'blue.600' : 'black',
    fontSize: 'lg',
    ...text,
  };

  return (
    <Box
      direction="row"
      align="center"
      py="2"
      testID={TestIDs.FieldDisplay.value(testID)}
      {...rest}
    >
      {icon && (
        <Box mr="2">
          {cloneElement(icon, {
            fill: highlight ? colors.blue[600] : colors.black,
          })}
        </Box>
      )}

      <Box flex={1}>
        <Skeleton w="full" show={loading}>
          <>
            {isNil(children) || isString(children) || isFinite(children) ? (
              <>
                {hasValue(children) ? (
                  <Text {...textProps}>{children}</Text>
                ) : (
                  <Box pl={icon ? undefined : '2'}>
                    <Text {...textProps}>-</Text>
                  </Box>
                )}
              </>
            ) : (
              children
            )}
          </>
        </Skeleton>
      </Box>
    </Box>
  );
};

export const TimeFieldDisplay = ({
  time,
  format = 'h:mmA',
  icon = true,
  ...rest
}: { time?: Dayjs; format?: string; icon?: boolean } & Omit<
  FieldDisplayProps,
  'children' | 'icon'
>) => (
  <FieldDisplay icon={icon ? <SvgClock /> : undefined} {...rest}>
    {time?.format(format)}
  </FieldDisplay>
);

export const DateFieldDisplay = ({
  date,
  format = 'D/MM/YYYY',
  icon = true,
  ...rest
}: { date?: Dayjs; format?: string; icon?: boolean } & Omit<
  FieldDisplayProps,
  'children' | 'icon'
>) => (
  <FieldDisplay icon={icon ? <SvgCalendarToday /> : undefined} {...rest}>
    {date?.format(format)}
  </FieldDisplay>
);
