import { env } from '../app/env';

export const STAGE =
  env.REACT_APP_PROXY_STAGE?.toUpperCase() ??
  env.REACT_APP_STAGE?.toUpperCase() ??
  'LOCAL';

// Also see android.package and ios.bundleIdentifier in the mobile app.config.ts
export const NATIVE_URL_SCHEME =
  STAGE === 'PROD'
    ? 'io.exoflare.app'
    : STAGE === 'UAT'
      ? 'io.exoflare.uat'
      : 'io.exoflare.development';
