import { ApiUserQueryKind } from 'xo/graphql/api/enums/user-query-kind.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiUserSummaryFragment,
  UserSummaryFragmentDoc,
} from './user-summary-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetUsersAdminMinimalQueryVariables = Exact<{
  kind: ApiUserQueryKind;
}>;

export type ApiGetUsersAdminMinimalQuery = {
  __typename?: 'Query';
  admin: {
    __typename?: 'Admin';
    users: Array<{ __typename?: 'User' } & ApiUserSummaryFragment>;
  };
};

export const GetUsersAdminMinimalDocument = gql`
  query GetUsersAdminMinimal($kind: UserQueryKind!) {
    __typename
    admin {
      __typename
      users(kind: $kind) {
        ...UserSummary
      }
    }
  }
  ${UserSummaryFragmentDoc}
`;

export function useGetUsersAdminMinimalQuery(
  options: Omit<
    Urql.UseQueryArgs<ApiGetUsersAdminMinimalQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ApiGetUsersAdminMinimalQuery,
    ApiGetUsersAdminMinimalQueryVariables
  >({ query: GetUsersAdminMinimalDocument, ...options });
}
