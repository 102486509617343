import React from 'react';

const RETRY_COUNT = 10;

const retry = <T>(func: () => Promise<T>, retries: number, delayMs: number) =>
  new Promise<T>((resolve, reject) =>
    func()
      .then(resolve)
      .catch((err: any) => {
        if (retries > 0) {
          setTimeout(() => retry(func, retries - 1, delayMs), delayMs);
          return;
        }

        reject(err);
      }),
  );

export const retryLazy = (func: () => Promise<any>) =>
  React.lazy(() => retry(func, RETRY_COUNT, 1000));
