import { History } from 'history';
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { configureAuth } from 'xo/auth-utils';
import { ApiFormKind } from 'xo/graphql/api/enums/form-kind.generated';
import { browserCacheExchange } from 'xo/graphql/urql-browser';
import { UrqlClientProvider, UrqlProvider } from 'xo/graphql/urql-provider';
import {
  appRoutes,
  arrivalDashboardRoutes,
  consignmentNoteRoutes,
  getCheckInFormRoute,
  transportRoutes,
  visitorRoutes,
} from 'xo/navigation/web-routes';
import { RestyleThemeProvider } from 'xo/styles/restyle/restyle-theme-provider';
import { isRunningInWebView } from 'xo/webview/webview-events';
import { useAnalytics } from '../hooks/analytics';
import {
  getRequestHeaders,
  useUserModulePermissions,
} from '../hooks/auth-utils';
import { useIsXoAdmin } from '../hooks/shared-hooks';
import '../utils/sentry';
import { SentryRoute } from '../utils/sentry';
import { PermissionProvider } from './account/permission-provider';
import { SessionProvider } from './account/session-provider';
import { TermsPageUnauthed } from './account/terms-page-unauthed';
import { UserContext, UserProvider } from './account/user-provider';
import { AdminRoute } from './components/admin-route';
import { ErrorBoundary } from './components/error-boundary';
import { ErrorPage } from './components/error-page';
import {
  ErrorPageContext,
  ErrorPageProvider,
} from './components/error-page-provider';
import { LoggedInRoute } from './components/logged-in-route';
import { NotFoundSwitch } from './components/not-found-switch';
import { PageLoader } from './components/page-loader';
import { StageTag } from './components/stage-tag';
import { env } from './env';
import { retryLazy } from './load-utils';
import { onNotifyOfflineError, onNotifyServerError } from './notifications';
import { LocalStorage } from './shared/local-storage';
import { StorageProvider } from './shared/storage-provider';
import { SystemConfigProvider } from './shared/system-config-provider';
import { WebViewStoreProvider } from './webview/webview-store';

/*
  ACCOUNTS
*/
const AppLogin = retryLazy(
  () => import('./account/app-login' /* webpackChunkName: "login" */),
);
const AppSignUp = retryLazy(
  () => import('./account/app-sign-up' /* webpackChunkName: "sign-up" */),
);

/*
  SETTINGS
*/
const AppSettings = retryLazy(
  () => import('./settings/app-settings') /* webpackChunkName: "settings" */,
);

/*
  PEOPLE
*/
const AppPeopleQuestionnaire = retryLazy(
  () =>
    import(
      './people-check-in/app-people-questionnaire' /* webpackChunkName: "people-check-in" */
    ),
);
const AppPeopleSignout = retryLazy(
  () =>
    import(
      './people-check-in/app-people-signout' /* webpackChunkName: "people-signout" */
    ),
);
const AppPeopleOffice = retryLazy(
  () =>
    import(
      './people-office/app-people-office' /* webpackChunkName: "people-office" */
    ),
);
const AppPeopleCheckIn = retryLazy(
  () =>
    import(
      './people-check-in/app-people-check-in' /* webpackChunkName: "people-check-in" */
    ),
);
const AppPeopleIncompleteVisit = retryLazy(
  () =>
    import(
      './people-check-in/app-people-incomplete-visit' /* webpackChunkName: "people-incomplete" */
    ),
);

/*
  TRANSPORT
*/
const AppTransport = retryLazy(
  () => import('./transport/app-transport' /* webpackChunkName: "transport" */),
);
const AppConsignmentNotes = retryLazy(
  () =>
    import(
      './consignment-notes/app-consignment-notes' /* webpackChunkName: "consignment-notes" */
    ),
);
const AppArrivalDashboard = retryLazy(
  () =>
    import(
      './arrival-dashboard/app-arrival-dashboard' /* webpackChunkName: "arrival-dashboard" */
    ),
);
const AppArrivalMetrics = retryLazy(
  () =>
    import(
      './arrival-metrics/app-arrival-metrics' /* webpackChunkName: "arrival-metrics" */
    ),
);
const AppDriver = retryLazy(
  () => import('./account/app-driver') /* webpackChunkName: "driver" */,
);
const AppSwitchToMobile = retryLazy(
  () =>
    import(
      './account/app-switch-to-mobile' /* webpackChunkName: "switch-mobile" */
    ),
);

/*
  XO ADMIN
*/
const AppAdmin = retryLazy(
  () => import('./admin/app-admin' /* webpackChunkName: "admin" */),
);
const AdminDrawer = retryLazy(
  () => import('./admin/admin-drawer' /* webpackChunkName: "admin-drawer" */),
);

// This captures the trailing path for the root site check-in redirect (eg. /q/:siteShortCode/verify)
const getCheckInRedirectPath = (root: string, history: History<unknown>) => {
  const segments = history.location.pathname.split('/');
  const rootLength = root.split('/').length;

  const remainder = segments.slice(rootLength);
  const redirectPath = `${root}${
    remainder.length ? `/${remainder.join('/')}` : ''
  }`;

  return redirectPath;
};

export const setupAppConfig = () => {
  let config = JSON.parse(document.getElementById('global-config')!.innerText);

  console.info({
    'ExoFlare build info': config,
  });

  LocalStorage.commitBackend = config.COMMIT_BACKEND;
  LocalStorage.commitFrontend = config.COMMIT_FRONTEND;

  configureAuth({
    poolId: config.COGNITO_POOL_ID,
    // if the web app is running inside the mobile webview, we want to register the mobile client ID so the mobile app can transfer creds into local storage and not require a second login
    clientId: isRunningInWebView()
      ? config.COGNITO_POOL_MOBILE_CLIENT_ID
      : config.COGNITO_POOL_WEB_CLIENT_ID,
  });
};

export const AppContent: React.FC = () => {
  const { errorPages } = useContext(ErrorPageContext);
  const history = useHistory();
  const {
    visitorPermission,
    transportSchedulingPermission,
    consignmentNotePermission,
    arrivalDashboardPermission,
  } = useUserModulePermissions();
  const isXoAdmin = useIsXoAdmin();
  const { user } = useContext(UserContext);

  useAnalytics();

  return (
    <div className="font-sans">
      <React.Suspense fallback={<PageLoader loading={true} />}>
        <AdminRoute>
          <div className="fixed right-0 top-0 z-50 mr-1 mt-1">
            <StageTag />
            <AdminDrawer />
          </div>
        </AdminRoute>
        <NotFoundSwitch>
          {/* ERRORS */}
          {errorPages?.value.map(({ path, props }) => (
            <SentryRoute key={path} path={path} exact>
              <ErrorPage {...props} />
            </SentryRoute>
          ))}

          {/* ADMIN */}
          <AdminRoute
            path={appRoutes.admin.root}
            component={AppAdmin}
            useNotFound={true}
          />

          {/* TRANSPORT */}
          {transportSchedulingPermission || isXoAdmin ? (
            <SentryRoute path={transportRoutes.root} component={AppTransport} />
          ) : null}

          {/* ARRIVALS */}
          {arrivalDashboardPermission && (
            <SentryRoute
              path={arrivalDashboardRoutes.root}
              component={AppArrivalDashboard}
            />
          )}
          {arrivalDashboardPermission && (
            <SentryRoute
              path={appRoutes.arrivalMetrics}
              component={AppArrivalMetrics}
            />
          )}

          {/* CONSIGNMENT NOTES */}
          {consignmentNotePermission || isXoAdmin ? (
            <SentryRoute
              path={consignmentNoteRoutes.root}
              component={AppConsignmentNotes}
            />
          ) : null}

          <SentryRoute path={appRoutes.settings.root} component={AppSettings} />

          {[
            ApiFormKind.Previsit,
            ApiFormKind.Early,
            ApiFormKind.Onsite,
          ].flatMap(kind =>
            [
              undefined,
              visitorRoutes.siteRoot(),
              visitorRoutes.orgRoot(),
              visitorRoutes.kioskRoot(),
            ].flatMap(root => (
              <SentryRoute
                key={kind}
                path={
                  // map every combination of kind and root so each check-in app type can route to a nested questionnaire, and also route questionnaires with no root
                  root
                    ? getCheckInFormRoute({ root, kind })
                    : visitorRoutes.forms[kind]()
                }
              >
                <AppPeopleQuestionnaire kind={kind} />
              </SentryRoute>
            )),
          )}

          <SentryRoute
            path={visitorRoutes.forms.LEAVING()}
            component={AppPeopleSignout}
          />
          <SentryRoute
            path={visitorRoutes.forms.INCOMPLETE()}
            component={AppPeopleIncompleteVisit}
          />
          <SentryRoute
            path={visitorRoutes.terms}
            component={TermsPageUnauthed}
          />
          <SentryRoute
            path={[visitorRoutes.siteRoot(), visitorRoutes.orgRoot()]}
            component={AppPeopleCheckIn}
          />
          <LoggedInRoute path={visitorRoutes.kioskRoot()}>
            <AppPeopleCheckIn />
          </LoggedInRoute>
          <SentryRoute
            path={appRoutes.mobile.switchToApp}
            component={AppSwitchToMobile}
          />
          <SentryRoute
            path={[appRoutes.mobile.short, appRoutes.mobile.long]}
            render={() => <AppDriver autoRedirect={true} />}
          />
          <Redirect
            path="/request-visit/:orgShortCode"
            to={{
              pathname: getCheckInRedirectPath(
                visitorRoutes.orgRoot(),
                history,
              ),
              search: history.location.search,
            }}
          />

          {/* shorter URL = better QR code, separate to allow distinguishing scan vs. typed */}
          <Redirect
            path="/q/:siteShortCode"
            to={{
              pathname: getCheckInRedirectPath(
                visitorRoutes.siteRoot(),
                history,
              ),
              state: { signScanType: 'q' },
              search: history.location.search,
            }}
          />
          <Redirect
            path="/c/:siteShortCode"
            to={{
              pathname: getCheckInRedirectPath(
                visitorRoutes.siteRoot(),
                history,
              ),
              state: { signScanType: 'c' },
              search: history.location.search,
            }}
          />

          {/* LOGIN */}
          <SentryRoute path={appRoutes.account.login} component={AppLogin} />
          {/* shorter URL = more space in SMS messages */}
          <Redirect
            path="/li"
            to={{ ...history.location, pathname: appRoutes.account.login }}
          />
          <Redirect
            path="/lr"
            to={{
              ...history.location,
              pathname: appRoutes.account.resetPassword,
            }}
          />
          {/* SIGN UP */}
          <SentryRoute path={appRoutes.account.signUp} component={AppSignUp} />

          {/* Maintain backwards compatibilty with deprecated routes */}
          <Redirect exact path="/visits" to={appRoutes.schedule} />
          <Redirect exact path="/connote" to={consignmentNoteRoutes.root} />

          {/* ROOT REDIRECTS */}
          {user?.airtableDriverId && consignmentNotePermission && !isXoAdmin ? (
            <SentryRoute path="/" component={AppDriver} />
          ) : visitorPermission || isXoAdmin ? (
            <SentryRoute path="/" component={AppPeopleOffice} />
          ) : consignmentNotePermission ? (
            <Redirect path="/" to={consignmentNoteRoutes.root} />
          ) : transportSchedulingPermission ? (
            <Redirect path="/" to={transportRoutes.root} />
          ) : user?.organisation ? (
            <Redirect path="/" to={appRoutes.settings.userDetails(user.id)} />
          ) : (
            <Redirect
              path="/"
              to={{
                pathname: appRoutes.account.login,
                state: { redirect: history.location },
              }}
            />
          )}
        </NotFoundSwitch>
      </React.Suspense>
    </div>
  );
};

export const UrqlWebProvider = ({ children }: PropsWithChildren) => (
  <UrqlProvider
    getRequestHeaders={getRequestHeaders}
    onNotifyServerError={onNotifyServerError}
    onOfflineError={onNotifyOfflineError}
    onCommitMismatch={() => window.location.reload()}
    cacheExchange={browserCacheExchange}
    stage={env.REACT_APP_STAGE}
    alwaysNotifyOfflineErrors
  >
    {children}
  </UrqlProvider>
);

export const App: React.FC = () => {
  useEffect(() => setupAppConfig(), []);

  return (
    <RestyleThemeProvider>
      <ErrorBoundary>
        <StorageProvider>
          <UrqlClientProvider>
            <SessionProvider>
              <UserProvider>
                <ErrorPageProvider>
                  <UrqlWebProvider>
                    <PermissionProvider>
                      <SystemConfigProvider>
                        <WebViewStoreProvider>
                          <AppContent />
                        </WebViewStoreProvider>
                      </SystemConfigProvider>
                    </PermissionProvider>
                  </UrqlWebProvider>
                </ErrorPageProvider>
              </UserProvider>
            </SessionProvider>
          </UrqlClientProvider>
        </StorageProvider>
      </ErrorBoundary>
    </RestyleThemeProvider>
  );
};
