import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useFormSubmit } from '../../hooks/shared-hooks';
import { usePostTermsAgreementAuthed } from '../../hooks/user-network-hooks';
import { ClickthroughAgreement } from '../components/clickthrough-agreement';
import { Container } from '../components/container';
import { Page } from '../components/page';
import { PageProvider } from '../components/page-provider';
import { useRedirectOnLogin } from '../people-office/visitor-log-hooks';

export const TermsPageAuthed: React.FC = () => {
  const redirectOnLogin = useRedirectOnLogin();

  const [form] = useForm();

  // Take a T&Cs version timestamp on page load rather than submission for better accuracy
  const timestamp = useMemo(() => dayjs().toISOString(), []);
  const { mutate: termsAgreement } = usePostTermsAgreementAuthed(timestamp);
  const { onSubmit: onTermsAgree, loading: termsLoading } = useFormSubmit({
    form,
    onSubmit: termsAgreement,
    onSuccess: redirectOnLogin,
  });

  return (
    <PageProvider>
      <Page
        footer={{
          middle: {
            active: true,
            label: 'Continue',
            onClick: onTermsAgree,
            loading: termsLoading,
          },
        }}
      >
        <Container>
          <ClickthroughAgreement form={form} />
        </Container>
      </Page>
    </PageProvider>
  );
};
