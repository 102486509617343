import {
  createRestyleComponent,
  createRestyleFunction,
  createText,
  TextProps as RestyleTextProps,
} from '@shopify/restyle';
import React from 'react';
import { Platform, TextProps as RNTextProps } from 'react-native';
import {
  FontSize,
  FontWeight,
  Responsive,
  RestyleTheme,
} from 'xo/styles/restyle/theme';

export const RawText = createText<RestyleTheme>();

const fontSizeFunc = createRestyleFunction({
  property: 'fontSize',
  styleProperty: 'fontSize',
  themeKey: 'fontSize',
});

const fontWeightFunc = createRestyleFunction({
  property: 'fontWeight',
  styleProperty: Platform.select({ native: 'fontFamily', web: 'fontWeight' }),
  themeKey: 'fontWeight',
});

export type TextProps = Omit<RestyleTextProps<RestyleTheme>, 'fontSize'> &
  Omit<RNTextProps, 'fontWeight' | 'fontFamily' | 'fontSize'> & {
    // FIXME Why do we need to manually type this?
    fontSize?: Responsive<FontSize>;
    fontWeight?: Responsive<FontWeight>;
    // FIXME LocationDescriptor
    href?: string;
  } & { isTruncated?: boolean };

const RestyleText = createRestyleComponent<TextProps, RestyleTheme>(
  [fontSizeFunc, fontWeightFunc],
  RawText,
);

export const Text = React.forwardRef<typeof RestyleText, TextProps>(
  ({ isTruncated, ...rest }: TextProps, ref) => {
    return (
      <RestyleText
        ref={ref}
        numberOfLines={isTruncated ? 1 : undefined}
        {...rest}
      />
    );
  },
);
