import { IconNode } from '@rneui/base';
import { Input as RNEInput, InputProps as RNEInputProps } from '@rneui/themed';
import {
  cloneElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ActivityIndicator,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TextInputContentSizeChangeEventData,
  TextInputFocusEventData,
  TextInputProps,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { IconButton } from 'xo/components/icon-button';
import { Box, BoxProps } from 'xo/core';
import { InputVariant } from 'xo/models';
import { borderRadii } from 'xo/styles/restyle/border-radii';
import { fontSize } from 'xo/styles/restyle/font-size';
import { spacing } from 'xo/styles/restyle/spacing';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCross } from 'xo/svg/svg-cross';
import { hasValue } from 'xo/utils/validation-utils';
import { FormControlContext } from './form-control';

type RenderIconProps = { icon?: JSX.Element } & BoxProps;

export type InputProps = Omit<RNEInputProps, 'editable'> &
  TextInputProps & {
    invalid?: boolean;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    clearable?: boolean;
    variant?: InputVariant;
    textAlignCenter?: boolean;
    inputStyle?: StyleProp<TextStyle>;
    loading?: boolean;
  };

const rightIconContainerStyle: StyleProp<ViewStyle> = {
  position: 'absolute',
  top: 0,
  right: 2,
  bottom: 0,
  marginTop: -2,
};

export const Input = ({
  onFocus,
  onBlur,
  invalid,
  leftIcon,
  rightIcon,
  disabled,
  clearable = Platform.OS !== 'web',
  value,
  onChangeText,
  multiline,
  variant,
  textAlignCenter,
  inputStyle,
  loading,
  ...rest
}: InputProps) => {
  const control = useContext(FormControlContext);
  const [focused, setFocused] = useState(false);

  const onFocusCb = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (!disabled) {
        setFocused(true);
        onFocus && onFocus(e);
        control?.onFocus && control.onFocus();
      }
    },
    [setFocused, onFocus, disabled, control],
  );

  const onBlurCb = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (!disabled) {
        setFocused(false);
        onBlur && onBlur(e);
        control?.onBlur && control.onBlur();
      }
    },
    [setFocused, onBlur, disabled, control],
  );

  const renderIcon = useCallback(
    ({ icon, ...rest }: RenderIconProps) => {
      const renderedIcon = icon
        ? cloneElement(icon, {
            fill: disabled
              ? colors.grey[400]
              : invalid
                ? colors.red[500]
                : focused
                  ? colors.blue[400]
                  : colors.black,
          })
        : undefined;

      return Platform.OS !== 'web' ? (
        renderedIcon
      ) : (
        <Box {...rest}>{renderedIcon}</Box>
      );
    },
    [invalid, focused, disabled],
  );

  const onClear = useCallback(
    () => onChangeText && onChangeText(''),
    [onChangeText],
  );

  // autosize a multiline input based on the text contents
  const [height, setHeight] = useState(spacing[10]);
  const onContentSizeChange = useCallback(
    (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
      if (multiline) {
        setHeight(e.nativeEvent.contentSize.height);
      }
    },
    [setHeight, multiline],
  );

  const defaultVariant = !variant;
  const editableVariant = variant === InputVariant.Editable;
  const bareVariant = variant === InputVariant.Bare;

  const rightIconProps: RenderIconProps | undefined = useMemo(
    () =>
      loading
        ? {
            icon: <ActivityIndicator color={colors.grey[500]} />,
          }
        : rightIcon
          ? { icon: rightIcon }
          : hasValue(value) && clearable
            ? {
                icon: (
                  <IconButton
                    variant="grey"
                    icon={<SvgCross />}
                    onPress={onClear}
                    size="md"
                  />
                ),
              }
            : undefined,
    [clearable, loading, onClear, rightIcon, value],
  );

  const leftIconProp: IconNode | undefined = useMemo(
    () => leftIcon && renderIcon({ icon: leftIcon, ml: '2', mr: '-1' }),
    [leftIcon, renderIcon],
  );
  const rightIconProp: IconNode | undefined = useMemo(
    () => (rightIconProps ? renderIcon(rightIconProps) : undefined),
    [rightIconProps, renderIcon],
  );
  const inputStyleProp: StyleProp<TextStyle> = useMemo(
    () =>
      [
        styles.input,
        bareVariant ? styles.inputBare : styles.inputDefault,
        inputStyle,
      ].concat(textAlignCenter ? styles.inputTextAlignCenter : []),
    [bareVariant, inputStyle, textAlignCenter],
  );
  const inputContainerStyleProp: StyleProp<ViewStyle> = useMemo(
    () => [
      styles.inputContainer,
      ...[
        defaultVariant
          ? styles.inputContainerDefault
          : editableVariant
            ? styles.inputContainerEditable
            : styles.inputContainerBare,
      ],
      multiline
        ? styles.inputContainerMultiLine
        : styles.inputContainerSingleLine,
      ...(invalid
        ? [styles.inputContainerInvalid]
        : focused && !bareVariant
          ? [styles.inputContainerFocused]
          : []),
    ],
    [bareVariant, defaultVariant, editableVariant, focused, invalid, multiline],
  );

  const styleProp: StyleProp<TextStyle> = useMemo(
    () => [
      styles.element,
      { height, minHeight: multiline ? multilineMinHeight : undefined },
    ],
    [height, multiline],
  );

  return (
    <RNEInput
      {...rest}
      nativeID={rest.id}
      ref={rest.ref as any}
      onFocus={onFocusCb}
      onBlur={onBlurCb}
      renderErrorMessage={false}
      inputStyle={inputStyleProp}
      placeholderTextColor={!defaultVariant ? colors.black : colors.grey[500]}
      placeholder={
        !defaultVariant && !rest.placeholder && !focused
          ? '-'
          : rest.placeholder
      }
      inputContainerStyle={inputContainerStyleProp}
      // These container styles don't get applied on web from ElementsThemeProvider for some reason
      containerStyle={styles.container}
      leftIcon={leftIconProp}
      rightIconContainerStyle={rightIconContainerStyle}
      rightIcon={rightIconProp}
      disabled={disabled || loading}
      value={value}
      onChangeText={onChangeText}
      onContentSizeChange={onContentSizeChange}
      multiline={multiline}
      // https://github.com/facebook/react-native/issues/16826
      // without scroll disabled, the multiline flag prevents KeyboardAvoidingView from working when the keyboard hides the input
      scrollEnabled={!multiline}
      // FIXME Inputs have a weird outline on Chrome from the user agent stylesheet. Need to work out why
      style={styleProp}
      autoCapitalize="none"
    />
  );
};

const multilineMinHeight = spacing[12];

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  inputContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: borderRadii.sm,
  },
  inputContainerDefault: {
    borderColor: Platform.select({
      web: colors.grey[400],
      native: colors.grey[300],
    }),
    backgroundColor: colors.white,
  },
  inputContainerBare: {
    borderColor: colors.transparent,
    backgroundColor: colors.transparent,
  },
  inputContainerEditable: {
    borderColor: colors.grey[300],
    backgroundColor: colors.grey[100],
  },
  inputContainerSingleLine: {
    height: spacing[10],
  },
  inputContainerMultiLine: {
    paddingTop: spacing[1],
    paddingBottom: spacing[1],
  },
  inputContainerFocused: {
    borderColor: colors.blue[400],
    backgroundColor: colors.blue[50],
  },
  inputContainerInvalid: {
    borderColor: colors.red[500],
    backgroundColor: colors.red[100],
  },
  input: {
    fontSize: Platform.select({ web: fontSize.md, native: fontSize.lg }),
    fontFamily: Platform.select({
      web: 'Nunito',
      native: 'Nunito_400Regular',
    }),
  },
  inputDefault: {
    paddingHorizontal: spacing[2],
  },
  inputBare: {
    paddingHorizontal: 0,
    fontWeight: '600',
  },
  inputTextAlignCenter: {
    textAlign: 'center',
  },
  element: Platform.select({
    web: {
      outlineWidth: 0,
    },
  }) as any,
});
