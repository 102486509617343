import React, { useContext } from 'react';
import { PageLoader } from '../components/page-loader';
import { useCurrentUser } from './user-provider';

export enum PermissionObject {
  User = 'user',
}

export enum PermissionAction {
  Update = 'update',
}

export interface PermissionContextProps {
  canAccess: (object: PermissionObject, action: PermissionAction) => boolean;
}

export const PermissionContext = React.createContext<PermissionContextProps>(
  {} as any,
);

export const PermissionProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const user = useCurrentUser();
  // Temp shim until API is passing through non-casbin perms (only current check is on Role dropdown in user settings)
  const canAccess = (object: PermissionObject, action: PermissionAction) =>
    !!user?.orgAdmin;

  return (
    <PermissionContext.Provider
      value={{
        canAccess,
      }}
    >
      <PageLoader loading={false}>{children}</PageLoader>
    </PermissionContext.Provider>
  );
};

export const useCanAccess = () => {
  const { canAccess } = useContext(PermissionContext);
  return canAccess;
};
