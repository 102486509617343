import { PropsWithChildren, createContext, useContext } from 'react';
import { LocalStorage } from './local-storage';

export interface StorageContextProps {
  syncStorage: Storage;
}

export const StorageContext = createContext<StorageContextProps>(null as any);

export type StorageProviderProps = Partial<StorageContextProps> &
  PropsWithChildren;

// enables easier mocking of localStorage in tests and stories
export const StorageProvider = ({
  syncStorage: propsSyncStorage,
  children,
}: StorageProviderProps) => {
  // Update LocalStorage usages so storage calls use this syncStorage, and eventually AsyncStorage
  LocalStorage.storage = propsSyncStorage ?? localStorage;

  return (
    <StorageContext.Provider value={{ syncStorage: LocalStorage.storage }}>
      {children}
    </StorageContext.Provider>
  );
};

export const useSyncStorage = () => useContext(StorageContext).syncStorage;
