import { CombinedError } from 'urql';
import { v4 } from 'uuid';

// "The Internet connection appears to be offline" is thrown by expo-image
export const isOfflineErrorMessage = (message: string | undefined) =>
  (message &&
    /request failed|failed to fetch|network\s?error/i.test(message)) ||
  message?.includes('offline');

// Updated from https://github.com/urql-graphql/urql/blob/main/exchanges/graphcache/src/offlineExchange.ts#L109
export const isOfflineError = (error: undefined | CombinedError) =>
  Boolean(
    error &&
      // handle offline errors from axios too with code===ERR_NETWORK check
      (error.networkError || (error as any).code === 'ERR_NETWORK') &&
      !error.response &&
      ((typeof navigator !== 'undefined' && navigator.onLine === false) ||
        isOfflineErrorMessage(error.networkError?.message)),
  );

export const offlineIdPrefix = `OFFLINE-`;
export const offlineId = () => `${offlineIdPrefix}${v4()}`;
