// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
export type ApiPositionDirectionInTimeFragment = {
  __typename?: 'PositionDirectionInTime';
  lat: number;
  lng: number;
  timestamp: Dayjs;
  direction: number;
};

export const PositionDirectionInTimeFragmentDoc = gql`
  fragment PositionDirectionInTime on PositionDirectionInTime {
    __typename
    lat
    lng
    timestamp
    direction
  }
`;
