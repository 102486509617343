import { AuthConfig, AuthUtilities } from '@urql/exchange-auth';

// Adapted from https://github.com/FormidableLabs/urql/tree/main/exchanges/auth#quick-start-guide
export const getAuthExchangeConfig =
  (getRequestHeaders: () => Promise<Record<string, string>>) =>
  async (utils: AuthUtilities): Promise<AuthConfig> => {
    let headers = await getRequestHeaders();

    return {
      addAuthToOperation: operation => utils.appendHeaders(operation, headers),
      // Return true to signal that we should always refresh auth
      willAuthError: () => true,
      didAuthError: (error, _operation) => error.response?.status === 401,
      refreshAuth: async () => {
        headers = await getRequestHeaders();
      },
    };
  };
