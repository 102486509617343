import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export interface SvgCaretProps extends SvgIconProps {
  direction?: 'u' | 'd' | 'l' | 'r';
}

export const SvgCaret = (props: SvgCaretProps) => {
  const { fill, direction = 'u' } = props;

  const rotate = {
    u: '-90deg',
    r: '0deg',
    d: '90deg',
    l: '180deg',
  }[direction];

  return (
    <SvgIcon {...props} style={{ transform: [{ rotate }] }}>
      <Path
        d="M8.59 16.34L13.17 11.75L8.59 7.16L10 5.75L16 11.75L10 17.75L8.59 16.34Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
