// if there might be multiple of the same test ID rendered, use a prefixer
const prefixer = (suffix: string) => (prefix: string | undefined) =>
  prefix ? `${prefix}-${suffix}` : undefined;

export const TestIDs = {
  FieldDisplay: { value: prefixer('field-display') },
  Input: 'RNE__Input__text-input',
  SelectablePanel: {
    placeholder: prefixer('placeholder-text'),
    value: prefixer('selected-text'),
    panel: prefixer('panel'),
  },
  Select: {
    searchInput: prefixer('search-input'),
    searchInfo: prefixer('search-info'),
    bottomSheetHeader: prefixer('bottom-sheet-header'),
    item: prefixer('item'),
    noneFound: prefixer('none-found'),
    addButton: prefixer('add-button'),
  },
  ConsignmentNoteWizardPage: {
    submitButton: 'submit-button',
  },
  DeleteButton: {
    cancel: prefixer('cancel'),
    confirm: prefixer('confirm'),
    delete: prefixer('delete'),
  },
  PhotosPicker: {
    deleteButton: 'delete',
    keepButton: 'keep',
  },
  PhotosPickerItem: {
    delete: prefixer('delete'),
  },
  VersionLocker: {
    text: 'version-locker-text',
  },
  SignatureInput: {
    done: prefixer('done'),
    clear: prefixer('clear'),
    panel: prefixer('panel'),
    canvas: prefixer('canvas'),
  },
  SignatureDisplay: {
    image: prefixer('image'),
  },
  SinglePageQuestionnaire: {
    submitButton: 'submit-button',
  },
};
