import React from 'react';
import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgThermometer: React.FC<SvgIconProps> = props => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        d="M15.192 14.9516V3.79161C15.192 2.01561 13.752 0.599609 12 0.599609C10.224 0.599609 8.80801 2.03961 8.80801 3.79161V14.9516C7.77601 15.8636 7.17601 17.1596 7.17601 18.5516C7.17601 21.2156 9.336 23.3756 12 23.3756C14.664 23.3756 16.824 21.2156 16.824 18.5516C16.824 17.1836 16.224 15.8636 15.192 14.9516ZM12 22.2236C9.984 22.2236 8.352 20.5916 8.352 18.5756C8.352 17.3516 8.976 16.1996 9.984 15.5276V3.79161C9.984 2.66361 10.896 1.75161 12.024 1.75161C13.152 1.75161 14.04 2.66361 14.04 3.79161V15.5276C15.048 16.1996 15.672 17.3276 15.672 18.5756C15.648 20.5916 14.016 22.2236 12 22.2236Z"
        fill={fill}
      />
      <Path
        d="M12.864 16.2476V7.63157C12.864 7.15157 12.48 6.76758 12 6.76758C11.52 6.76758 11.136 7.15157 11.136 7.63157V16.2476C10.176 16.6076 9.504 17.5196 9.504 18.5756C9.504 19.9436 10.608 21.0716 12 21.0716C13.368 21.0716 14.496 19.9676 14.496 18.5756C14.496 17.4956 13.8 16.5836 12.864 16.2476Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
