import { FormInstance } from 'antd';
import { ApiUpdateSiteAdminInput } from 'xo/graphql/api/inputs/update-site-admin-input.generated';
import { useUpdateSiteAdminMutation } from 'xo/graphql/api/update-site-airtableid-mutation.generated';
import { useFlag } from '../../hooks/shared-hooks';
import { useUpdateQueryClientSite } from '../../hooks/site-network-hooks';

export const useUpdateSiteAdmin = () => {
  const [saving, onSetSaving, onUnsetSaving] = useFlag(false);
  const updateSiteCache = useUpdateQueryClientSite();
  const [, updateSiteAdmin] = useUpdateSiteAdminMutation();

  const onUpdateSiteAdmin = ({
    form,
    ...input
  }: ApiUpdateSiteAdminInput & { form: FormInstance }) => {
    onSetSaving();
    updateSiteAdmin({
      input,
    })
      .then(result => {
        if (result?.data?.updateSiteAdmin) {
          const site = result.data.updateSiteAdmin;
          updateSiteCache({
            id: site.id,
            airtableId: site.airtableId,
            modules: site.modules,
            activeStatus: site.activeStatus,
          });
          form.setFields([
            {
              name: 'airtableId',
              value: site.airtableId,
            },
          ]);
        }
      })
      .finally(() => onUnsetSaving());
  };

  return {
    saving,
    onUpdateSiteAdmin,
  };
};
