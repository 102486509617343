import { useEffect } from 'react';
import { DeviceEventEmitter } from 'react-native';

export const emitSignOut = () => DeviceEventEmitter.emit('signOut');

export const useSignOutListener = (onSignOut: () => void) => {
  useEffect(() => {
    // Use DeviceEventEmitter to sign out so that that UrqlProvider can sign out and still have GraphQL mutations be called as part of signing out
    const listener = DeviceEventEmitter.addListener('signOut', onSignOut);

    return () => {
      listener.remove();
    };
  }, [onSignOut]);
};
