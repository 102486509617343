import { Input as AntInput } from 'antd';
import { TextAreaProps as AntTextAreaProps } from 'antd/es/input';
import classNames from 'classnames';
import React from 'react';
import './input.overrides.css';

export interface TextAreaProps extends AntTextAreaProps {}

export const TextArea: React.FC<TextAreaProps> = props => (
  <AntInput.TextArea
    {...props}
    className={classNames(props.className, 'xo-ant-input')}
  />
);
