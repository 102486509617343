import { useCallback } from 'react';
import { useIntQueryParam } from '../../hooks/route-hooks';

export interface PaginationModel {
  currentPage: number;
  pageSize: number;
  onPaginationChange: (page: number, pageSize?: number) => void;
}

export const usePaginationQueryParams = ({
  currentPageName = 'page',
  pageSizeName = 'pageSize',
  defaultPageSize = 20,
}: {
  currentPageName?: string;
  pageSizeName?: string;
  defaultPageSize?: number;
} = {}): PaginationModel => {
  const [currentPage, setCurrentPage] = useIntQueryParam(currentPageName, 1);
  const [pageSize, setPageSize] = useIntQueryParam(
    pageSizeName,
    defaultPageSize,
  );
  const onPaginationChange = useCallback(
    (page: number, pageSize?: number) => {
      setCurrentPage(page);
      if (pageSize) {
        setPageSize(pageSize);
      }
    },
    [setCurrentPage, setPageSize],
  );

  return {
    currentPage: currentPage ?? 1,
    pageSize: pageSize ?? defaultPageSize,
    onPaginationChange,
  };
};
