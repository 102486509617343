import { ApiAnimalSex } from 'xo/graphql/api/enums/animal-sex.generated';

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
export type ApiSubLoadFragment = {
  __typename?: 'SubLoad';
  id: string;
  count: number;
  animalKind?: string;
  animalSex: ApiAnimalSex;
  animalTattoo?: string;
  killDay?: string;
  notes?: string;
  totalWeightTonnes?: number;
  avgWeightKg?: number;
  buyer?: string;
  scheduledPickupTime?: string;
  scheduledPickupDate: Dayjs;
  scheduledPickupDatetime?: Dayjs;
  scheduledPickupTimezoneAbbr?: string;
  scheduledDropOffTime?: string;
  scheduledDropOffDatetime?: Dayjs;
  scheduledDropOffTimezoneAbbr?: string;
  fromSite?: { __typename?: 'Site' } & ApiSiteFragment;
  toSite?: { __typename?: 'Site' } & ApiSiteFragment;
};

export const SubLoadFragmentDoc = gql`
  fragment SubLoad on SubLoad {
    __typename
    id
    count
    animalKind
    animalSex
    animalTattoo
    fromSite {
      ...Site
    }
    toSite {
      ...Site
    }
    killDay
    notes
    totalWeightTonnes
    avgWeightKg
    buyer
    scheduledPickupTime
    scheduledPickupDate
    scheduledPickupDatetime
    scheduledPickupTimezoneAbbr
    scheduledDropOffTime
    scheduledDropOffDatetime
    scheduledDropOffTimezoneAbbr
  }
  ${SiteFragmentDoc}
`;
