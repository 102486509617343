// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
export type ApiWebAppConfigFragment = {
  __typename?: 'WebAppConfig';
  selfSignupCode?: string;
  predictedNextReleaseTimestampTz?: Dayjs;
};

export const WebAppConfigFragmentDoc = gql`
  fragment WebAppConfig on WebAppConfig {
    __typename
    selfSignupCode
    predictedNextReleaseTimestampTz
  }
`;
